import { Analyses } from "./credit-score-model"
import { Refund, Subscription } from "./plan-model"

export interface Profile {
  id: string,
  created_at: string,
  updated_at: string,
  first_name: string,
  middle_name: string,
  last_name: string,
  gender: string,
  role: string,
  email: string,
  picture_uri: string,
  phone_number: string,
  whatsapp_number: string,
  account_status: string
}

export const initialProfile: Profile = {
  id: '',
  created_at: '',
  updated_at: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  gender: '',
  role: '',
  email: '',
  picture_uri: '',
  phone_number: '',
  whatsapp_number: '',
  account_status: ''
}

export interface Owner {
  id: string,
  created_at: string,
  updated_at: string,
  profile: Profile
}

export interface Manager {
  id: string,
  created_at: string,
  updated_at: string,
  owner_fk: string,
  profile: Profile
}

export interface Executive {
  id: string,
  created_at: string,
  updated_at: string,
  manager_fk: string,
  profile: Profile
}

export type User = Owner | Manager | Executive;

export interface Document {
  name: string,
  type: string
  uri: string,
  password?: string,
  created_at?: string,
  updated_at?: string
}

export const initialDocument: Document = {
  name: '',
  type: '',
  uri: '',
  password: ''
}

export interface Reference {
  name: string,
  phone_number: string,
}

export const initialReference: Reference = {
  name: '',
  phone_number: ''
}

export interface Customer {
  id: string,
  created_at: string,
  updated_at: string,
  bcb_user_id?: string,
  dob: string,
  pan_number: string,
  pan_uri: string,
  aadhaar_number: string,
  employment_type: string,
  business_nature: string,
  employer_name: string,
  annual_income: number,
  city: string,
  state: string,
  pin_code: string,
  i2i_borrower_id: string,
  is_profile_complete: boolean,
  is_cbl_requested: boolean,
  is_consultation_requested: boolean,
  is_eligible_for_ocb: boolean,
  exp_ocb_wo_sub: boolean,
  profile: Profile,
  executive_fk?: string,
  executive_email?: string,
  manager_email?: string,
  subscription?: Subscription,
  subscriptions: Subscription[],
  refunds: Refund[],
  analyses?: Analyses[],
  documents: Document[],
  references: Reference[],
}

export const initialCustomer: Customer = {
  id: '',
  created_at: '',
  updated_at: '',
  dob: '',
  pan_number: '',
  pan_uri: '',
  aadhaar_number: '',
  employment_type: '',
  business_nature: '',
  employer_name: '',
  annual_income: 0,
  city: '',
  state: '',
  pin_code: '',
  i2i_borrower_id: '',
  is_profile_complete: false,
  is_cbl_requested: false,
  is_consultation_requested: false,
  is_eligible_for_ocb: false,
  exp_ocb_wo_sub: false,
  profile: initialProfile,
  subscriptions: [],
  refunds: [],
  documents: [],
  references: []
}

export type PaymentLinkMode = {
  mode: string;
  amount: string;
};

export const initialPaymentLinkMode: PaymentLinkMode = {
  mode: '',
  amount: '',
};

export interface PaymentLinkHistory {
  created_at: string,
  amount: number,
  status: string,
  mode: string,
  link: string,
  expiry: string,
}

export type IRefund = {
  refund_amount: number;
  refund_note: string;
  refund_type : string;
}

export const initialRefund: IRefund = {
  refund_amount: 0,
  refund_note: '',
  refund_type: ''
}