import Card from '../elements/Card';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'clsx';
import ButtonGroup from '../elements/button/ButtonGroup';
import ColumnTogglerButton from './ColumnTogglerButton';
import TablePagination from './TablePagination';

type Props<T> = {
  columns: ColumnDef<T>[];
  data: T[];
  showColumnToggle?: boolean;
};

const DataTable = <T extends unknown>({
  columns,
  data,
  showColumnToggle = true,
}: Props<T>) => {
  const [columnVisibility, setColumnVisibility] = useState({});

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
  });

  return (
    <>
      {showColumnToggle && (
        <ButtonGroup>
          <ColumnTogglerButton table={table} />
        </ButtonGroup>
      )}

      <Card className="!p-0 overflow-hidden">
        <div className="block min-w-full overflow-x-scroll overflow-y-hidden">
          <table className="w-full overflow-hidden divide-y divide-gray-300 rounded-lg">
            <thead className="bg-gray-50">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      scope="col"
                      className="py-3.5 sm:pr-3 pl-2 pr-1 text-left text-xs md:text-lg break-words font-semibold bg-primary-50 text-primary-500 sm:pl-6 capitalize"
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={cx(
                            'inline-flex group select-none',
                            header.column.getCanSort() && 'cursor-pointer'
                          )}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}

                          {{
                            asc: (
                              <ChevronUpIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            ),
                            desc: (
                              <ChevronDownIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            ),
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={`py-4 pl-2 sm:pr-3 text-xs md:text-md text-gray-900 break-words md:whitespace-nowrap sm:pl-6 ${
                        parseInt(cell.id) % 2 != 0 &&
                        'bg-primary-50 bg-opacity-30'
                      }`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {data.length >= 10 && <TablePagination table={table} />}
      </Card>
    </>
  );
};

export default DataTable;
