import { Reducer } from "react";
import { LoginActionTypes } from "./login-enum";
import { LoginActions, LoginState } from "./login-interface";
import {initialUserDetails} from "../login-model"

export const initialCurrentLoginState: LoginState = {
  loading: false,
  isLoggedIn: false,
  error: "",
  User: initialUserDetails,
};

export const authenticationReducer: Reducer<LoginState, LoginActions> = (
  state = initialCurrentLoginState,
  action: LoginActions
) => {
  switch (action.type) {
    case LoginActionTypes.LOGINREQUEST:
      return { ...state, loading: action.loading };
    case LoginActionTypes.LOGINSUCCESS:
      return { ...state, isLoggedIn: action.isLoggedIn };
    case LoginActionTypes.LOGINERROR:
      return { ...state, error: action.error };
      case LoginActionTypes.LOGINGETUSER:      
        return { ...state, User: action.User };
    case LoginActionTypes.LOGOUT:
      return initialCurrentLoginState;
    case LoginActionTypes.CLEARUSER:
      return initialCurrentLoginState;


    default:
      return state;
  }
};
