import React, { useEffect, useState, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Customer } from '@appTypes/user-model';
import FullPageLoader from '../../../common/components/elements/loader/FullPageLoader';
import { useQuery } from '../../../lib/react-query';
import { apiEndpoint } from '../../../utils/endpoint';
import DataTable from '../../../common/components/tanstack-table';
import { ManagerColumn } from '../../../common/components/data-table/TableColumn';
import Section from '../../../common/layouts/PageSection';

export const ALL_MANAGER_QUERY_KEY = 'QUERY_ALL_MANAGER';

interface ChildComponentProps {
  ref: any;
}

const ManagerList: React.ForwardRefRenderFunction<any, ChildComponentProps> = (
  props,
  ref
) => {
  const [listOfManagers, setListOfManagers] = useState([]);
  const { data, isLoading, refetch } = useQuery<{ managers: Customer[] }>(
    apiEndpoint.owners.managers.getManagers,
    ALL_MANAGER_QUERY_KEY,
    {},
    true,
    { staleTime: 0, cacheTime: 0 }
  );

  React.useImperativeHandle(ref, () => ({
    refetchData: refetch,
  }));

  useEffect(() => {
    if (!isLoading && data?.managers.length > 0) {
      const modifiedData = data?.managers.map((item: Customer) => ({
        ...item,
        combinedName: `${item.profile.first_name || ''} ${
          item.profile.middle_name || ''
        } ${item.profile.last_name || ''}`,
      }));
      setListOfManagers(modifiedData);
    }
  }, [data, isLoading]);

  const columns: ColumnDef<Customer>[] = useMemo(
    () => ManagerColumn('manager'),
    []
  );
  if (isLoading) return <FullPageLoader isOpen={true} />;

  return (
    <Section title="List of Managers">
      <DataTable columns={columns} data={listOfManagers} />
    </Section>
  );
};

export default React.forwardRef(ManagerList);
