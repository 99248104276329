import { Reference } from '@appTypes/user-model';
import DataTable from '../../../common/components/tanstack-table';
import { P } from '../../../common/components/elements/text';
import Section from '../../../common/layouts/PageSection';
import { useMemo } from 'react';
import { TableHeaderBuilder } from 'react-table-utils';
import { apiEndpoint } from '../../../utils/endpoint';
import { useQueryData } from '../../../lib/react-query';
import InlineLoader from '../../../common/components/elements/loader/InlineLoader';
import { PhoneNumberVariationDetails } from '@appTypes/personal-info-variation-model';

const CRIF_REFERENCE = 'CRIF_REFERENCE';

type Props = {
  userId: string;
};

const CrifReference: React.FC<Props> = ({ userId }) => {
  const { data, isLoading } = useQueryData(
    apiEndpoint.creditAnalysis.getPersonalInformationVariation(userId),
    CRIF_REFERENCE + userId,
    {},
    false,
    {
      staleTime: 1000 * 60 * 60 * 24,
      cacheTime: 1000 * 60 * 60 * 24,
    }
  );

  const headerColumns = new TableHeaderBuilder<PhoneNumberVariationDetails>()
    .add('report_date', (col) =>
      col.header('Report Date').cell((value: any) => (
        <>
          <P>{value}</P>
        </>
      ))
    )
    .add('value', (col) =>
      col.header('Phone Number').cell((value: any) => (
        <>
          <P>{value}</P>
        </>
      ))
    )
    .build();

  const columns = useMemo(() => headerColumns, [headerColumns]);

  if (isLoading) return <InlineLoader />;
  if (!data) return <></>;

  const phoneNumberVariation =
    // @ts-ignore
    data?.detailed_personal_information_variations
      ?.personal_information_variations_details?.phone_number_variations;

  return (
    <Section title="CRIF Reference">
      <DataTable
        columns={columns}
        data={phoneNumberVariation}
        showColumnToggle={false}
      />
    </Section>
  );
};

export default CrifReference;
