import clsx from 'clsx';
import { P } from '.';

type Props = {
  title?: string;
  className?: string;
};

const Indicator: React.FC<Props> = ({ className, title }) => {
  return (
    <P className={clsx('flex items-center')}>
      <span
        className={clsx(
          'flex w-2.5 h-2.5 rounded-full mr-1.5 flex-shrink-0',
          className
        )}
      ></span>
      {title}
    </P>
  );
};

export default Indicator;
