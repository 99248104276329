import { apiEndpoint } from "../../utils/endpoint";
import { role } from "../../utils/constants/index";
import { useQuery } from "../../lib/react-query";
import { useRole } from "./use-user";
import { Customer } from "@appTypes/user-model";

const EXECUTIVE_BY_ID_QUERY_KEY = 'ALL_EXECUTIVE';

export const useExecutive = (): {
  data: {executives: Customer[]};
  isLoading: boolean;
  refetch: () => void;
} => {
  const { data, isLoading, refetch } = useQuery<{executives: Customer[]}>(apiEndpoint.common.getExecutives, EXECUTIVE_BY_ID_QUERY_KEY, {}, false, {
    staleTime: 1000 * 30,
    cacheTime: 1000 * 30,
  });

  return {
    data,
    isLoading,
    refetch,
  };
};