import * as y from 'yup';
import { TableHeaderBuilder } from 'react-table-utils';
import { ArrowPathIcon, LinkIcon } from '@heroicons/react/24/outline';
import Form from '../../../common/components/form';
import { H3, H5 } from '../../../common/components/elements/text';
import Modal from '../../../common/layouts/Modal';
import ListInput, {
  Option,
} from '../../../common/components/elements/form/ListInput';
import {
  linkModeType,
  paymentLinkModeOptions,
} from '../../../utils/constants/index';
import {
  formatDate,
  formatMoney,
  formatDateWithTimeAndSeconds,
} from '../../../utils/formatter';
import {
  filterPaymentLinkTransactions,
  truncateString,
} from '../../../utils/index';
import {
  PaymentLinkMode,
  initialPaymentLinkMode,
} from '../../../common/types/user-model';
import { RequestType, useMutation, useQuery } from '../../../lib/react-query';
import { apiEndpoint } from '../../../utils/endpoint';
import { useEffect, useState } from 'react';
import { Plan } from '@appTypes/subscription-model';
import Tag from '../../../common/components/elements/Tag';
import { showSuccessToast } from '../../../utils/toast';
import InlineLoader from '../../../common/components/elements/loader/InlineLoader';
import { Subscription } from '@appTypes/plan-model';
import { OutlineButton } from '../../../common/components/elements/button';
import DataTable from '../../../common/components/tanstack-table';
import LinkedItem from '../../../common/components/elements/LinkedItem';
import { useCustomerById } from '../../../common/hooks/use-customer';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Tooltip } from 'antd';

const schema = y.object().shape({
  mode: y.string().required(),
  amount: y.string().required(),
});

type PaymentLinkModalProps = {
  customerId: string;
  whatsAppNo: string;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
};

const PaymentLinkModal: React.FC<PaymentLinkModalProps> = ({
  customerId,
  whatsAppNo,
  isModalOpen,
  setIsModalOpen,
}) => {
  const { trackEvent } = useMatomo();
  const [amountOptions, setAmountOptions] = useState<Option[]>([]);
  const [linkHistory, setLinkHistory] = useState<Subscription[]>([]);
  const [selectedLinkId, setSelectedLinkId] = useState<string>('');
  const [cancelPaymentLoading, setCancelPaymentLoading] =
    useState<boolean>(false);

  const {
    data: customerData,
    isLoading: customerDataLoading,
    refetch,
  } = useCustomerById(customerId);
  const { data, isLoading } = useQuery<{ plans: Plan[] }>(
    apiEndpoint.common.getAllPlans,
    'ALL_PLANS',
    {},
    false,
    {
      staleTime: 100000,
      cacheTime: 100000,
    }
  );

  const { mutateAsync } = useMutation<{ link_url: string }>(
    apiEndpoint.common.createPaymentLink,
    RequestType.Post,
    '',
    true
  );

  const { mutateAsync: cancelPaymentLink } = useMutation(
    apiEndpoint.common.cancelPaymentLink(selectedLinkId),
    RequestType.Get,
    '',
    true
  );

  useEffect(() => {
    if (!isLoading && data) {
      const amountOptions = data.plans
        .filter((plan: Plan) => plan.is_available)
        .map((plan: Plan) => ({
          value: plan.id,
          label: `${plan.discounted_price_with_gst} (${plan.category})`,
        }));

      setAmountOptions(amountOptions);
    }

    if (!customerDataLoading && customerData) {
      setLinkHistory(
        filterPaymentLinkTransactions(customerData.customer.subscriptions)
      );
    }
  }, [customerData, customerDataLoading, data, isLoading]);

  const headerColumns = new TableHeaderBuilder<Subscription>()
    .add('created_at', (col) =>
      col.header('Generated Date').cell((value: any) => (
        <>
          <H5 className="md:text-lg whitespace-nowrap break-words">
            {formatDate(value)}
          </H5>
        </>
      ))
    )
    .add('amount', (col) =>
      col.header('Amount').cell((value: any) => (
        <>
          <H5 className="text-primary-500 text-bold md:text-lg">
            {formatMoney(value)}
          </H5>
        </>
      ))
    )
    .add('combined_key', (col) =>
      col.header('Status').cell((value: any) =>
        value[1] === 'PAID' ? (
          <Tag className="text-green-800 bg-green-100 border border-green-800 rounded-md">
            {value[1]}
          </Tag>
        ) : value[1] === 'ACTIVE' ? (
          <>
            <Tag className="text-blue-800 bg-blue-100 border border-blue-800 rounded-md">
              {value[1]}
            </Tag>
            {cancelPaymentLoading && selectedLinkId === value[0] ? (
              <InlineLoader />
            ) : (
              <Tooltip title="Click here to cancel the payment link">
                <LinkedItem
                  href={''}
                  onClick={async () => {
                    setCancelPaymentLoading(true);
                    setSelectedLinkId(value[0]);

                    try {
                      // @ts-ignore
                      const { message } = await cancelPaymentLink(value[0]);
                      showSuccessToast(message);
                    } catch (err) {}

                    refetch();
                    setCancelPaymentLoading(false);
                  }}
                >
                  <H5 className="underline text-red-800 cursor-pointer">
                    Cancel Link
                  </H5>
                </LinkedItem>
              </Tooltip>
            )}
          </>
        ) : (
          <Tag className="text-red-800 bg-red-100 border border-red-800 rounded-md">
            {value[1]}
          </Tag>
        )
      )
    )
    .add('payment_link_url', (col) =>
      col.header('Payment Link').cell((value: any) => (
        <Tooltip title={value}>
          <H5 className="text-primary-500 text-bold md:text-lg">
            {truncateString(value, 30)}
          </H5>
        </Tooltip>
      ))
    )
    .add('created_at', (col) =>
      col.header('Expiry').cell((value: any) => (
        <Tooltip
          title={`Link Expires on ${formatDateWithTimeAndSeconds(value, 12)}`}
          className="-translate-x-1 left-6"
        >
          <H5 className="md:text-lg whitespace-nowrap break-words">
            {formatDateWithTimeAndSeconds(value, 12)}
          </H5>
        </Tooltip>
      ))
    )
    .build();

  if (isLoading || customerDataLoading) return <InlineLoader />;

  return (
    <Modal
      isOpen={isModalOpen}
      dismissButton={true}
      panelClassName="!w-auto min-w-[1000px] mt-20"
      closeModal={() => setIsModalOpen(false)}
    >
      <div className="flex items-center justify-center">
        <H3 className="text-center mb-4">Create Payment Link</H3>
        <Tooltip title="To maintain simplicity and prevent confusion, please note that you can have only one active payment link status at a time. To create a new active status, kindly deactivate the existing one.">
          <img
            src="../assets/images/info_icon.png"
            alt="info"
            className="mb-3 ml-1"
          />
        </Tooltip>
      </div>
      <div className="space-y-6">
        <Form
          initialValues={initialPaymentLinkMode}
          schema={schema}
          onSubmit={async (value: PaymentLinkMode, reset) => {
            const payload = {
              plan_id: value.amount,
              customer_id: customerId,
            };

            try {
              const { data } = await mutateAsync(payload);

              reset();
              showSuccessToast('Payment link created successfully');
              if (value.mode === linkModeType.whatsApp) {
                window.open(
                  `https://wa.me/${whatsAppNo}?text=${data.link_url}`,
                  '_blank'
                );
              }

              trackEvent({
                category: 'Subscription',
                action: `Create Payment Link`,
                name: `Amount: Rs${value.amount}`,
                value: 1,
              });

              refetch();
            } catch (err) {}
          }}
          submitButton={{
            text: 'Create Link',
            IconRight: LinkIcon,
            className: 'mx-auto',
          }}
        >
          <Form.Row>
            <ListInput
              options={paymentLinkModeOptions}
              name="mode"
              label="Mode"
              required
            />
            <ListInput
              options={amountOptions}
              name="amount"
              label="Amount"
              required
            />
          </Form.Row>
        </Form>
        {linkHistory.length > 0 && (
          <div>
            <div className="text-right">
              <OutlineButton
                className="border border-brand-orange-500 bg-brand-orange-100"
                Icon={ArrowPathIcon}
                onClick={() => refetch()}
              >
                Refresh
              </OutlineButton>
            </div>
            <DataTable
              columns={headerColumns}
              data={linkHistory}
              showColumnToggle={false}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PaymentLinkModal;
