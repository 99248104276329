import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Upload,
  Row,
  Col
} from "antd";
import type { ColumnsType } from "antd/es/table";

import {
  PlusCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
const { TextArea } = Input;


const ServicePage = () => {

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const onFinish = (value:any) => {
    console.log(value)
  }

  useEffect(()=> {
    
  })

  return (
    <div>

      <Row>
        <Col span={24} className="cms-main">
          <Button className="btn btn-primary add-button" type="primary" onClick={showModal} >
          <PlusCircleFilled />  Add
          </Button>
          <Modal
          title={'Create New Service'}
            open={open}
            footer={null}
            onCancel={hideModal}
            destroyOnClose={true}
          >
            <Row>
              <Col span={24}>
                <Form
                  name="complex-form"
                  layout="vertical"
                  initialValues={{
                    
                  }}
                  onFinish={onFinish}
                >
                  <Row>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <Form.Item label="Service Title">
                        <Space>
                          <Form.Item name="page_title" noStyle rules={[{ required: true, message: "Title is required" }]}>
                            <Input />
                          </Form.Item>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <Form.Item label="Service Name">
                        <Space>
                          <Form.Item name="page_name" noStyle
                            >
                            <Input />
                          </Form.Item>
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row><Col lg={12} md={24} sm={24} xs={24}>
                      <Form.Item label="Service Image">
                        <Upload listType="picture" maxCount={1}>
                          <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                      </Form.Item>
                    </Col></Row>
                  <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                      <Form.Item label="Service Description" name="page_content" className="blog-content-area">
                        <TextArea rows={4}/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={{ span:8, offset: 16 }} md={24} sm={24} xs={24}>
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="primary" htmlType="submit" className="btn btn-primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
      <div>
        <Table
          scroll={{ x: 1100 }}
        />
      </div>
    </div>
  );
};

export default ServicePage;
