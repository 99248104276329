import { useCallback, useEffect, useState } from 'react';
import { CrifHistory } from '@appTypes/credit-score-model';
import { Component } from '@appTypes/index';
import InlineLoader from '../../common/components/elements/loader/InlineLoader';
import Section from '../../common/layouts/PageSection';
import { useQuery } from '../../lib/react-query';
import { apiEndpoint } from '../../utils/endpoint';
import { P, H5, H4 } from '../../common/components/elements/text';
import { CrifHistoryColumn } from '../../common/components/data-table/TableColumn';
import Table from '../../common/components/data-table';
import Divider from '../../common/components/elements/Divider';

type CrifHistorySectionProps = {
  customerId: string;
};

const CrifHistorySection: Component<CrifHistorySectionProps> = ({
  customerId,
}) => {
  const [crifHistory, setCrifHistory] = useState<CrifHistory[]>([]);
  const { data, isLoading } = useQuery(
    apiEndpoint.creditAnalysis.getCrifJson(customerId),
    'json-data' + customerId,
    {},
    false,
    {
      staleTime: 100000,
      cacheTime: 100000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  useEffect(() => {
    if (!isLoading && data?.crif_jsons) {
      const jsonData = data.crif_jsons;
      jsonData.forEach((item: any) => {
        item.report_json = {
          isValid: item.is_valid,
          error_message: item.error_message,
          report_json: item.report_json,
          report_date: item.report_date,
        };
      });
      setCrifHistory(jsonData);
    }
  }, [data, isLoading]);

  const column = CrifHistoryColumn();

  const renderRowSubComponent = useCallback(({ row }: any) => {
    const {
      question_one,
      question_two,
      question_three,
      answer_one,
      answer_two,
      answer_three,
    } = row.original;
    return (
      <>
      <H4 className="my-2">Questions answered by customer</H4>
      <div className="px-10 py-4 bg-gray-100 border-2 border-gray-400">
        {question_one ? (
          <>
            <H5 className="text-gray-600">
              Q1{') '}
              {question_one}
            </H5>
            <P className="text-gray-600">
              User answered: {answer_one || 'N/A'}
            </P>
            <Divider className="my-2" />
          </>
        ) : (
          <H5 className="text-gray-600 text-center">No questions found</H5>
        )}
        {question_two && (
          <>
            <H5 className="text-gray-600">
              Q2{') '}
              {question_two}
            </H5>
            <P className="text-gray-600">
              User answered: {answer_two || 'N/A'}
            </P>
            <Divider className="my-2" />
          </>
        )}
        {question_three && (
          <>
            <H5 className="text-gray-600">
              Q3{') '}
              {question_three}
            </H5>
            <P className="text-gray-600">
              User answered: {answer_three || 'N/A'}
            </P>
          </>
        )}
      </div>
        </>
    );
  }, []);

  if (isLoading) {
    return <InlineLoader className="text-primary-500 text-center" />;
  }

  return (
    <Section title="CRIF History">
      <Table
        columns={column}
        data={crifHistory}
        renderRowSubComponent={renderRowSubComponent}
      />
    </Section>
  );
};

export default CrifHistorySection;

export const handleCrifDownload = (jsonData: any, reportDate: String) => {
  const jsonBlob = new Blob([jsonData], {
    type: 'application/json',
  });

  const jsonBlobURL = URL.createObjectURL(jsonBlob);
  const downloadLink = document.createElement('a');
  downloadLink.href = jsonBlobURL;
  downloadLink.download = `${reportDate}.json`;

  downloadLink.click();
  URL.revokeObjectURL(jsonBlobURL);
};
