import { Table } from '@tanstack/react-table';
import React from 'react';
import { Component } from '@appTypes/.';
import { OutlineButton, ButtonProps } from '../elements/button';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';

const PAGE_SIZES = [10, 20, 30, 40, 50, 100];

type Props<T> = {
  table: Table<T>;
};

const PaginationButton: Component<ButtonProps> = (props) => {
  return <OutlineButton className="!p-1 disabled:opacity-40" {...props} />;
};

const TablePagination = ({ table, totalDataCount, handlePageSizeChange }: any) => {
  table.pageCount = Math.ceil(totalDataCount / table.state.pageSize);
  table.canNextPage = true;
  if (table.pageCount <= table.state.pageIndex + 1) table.canNextPage = false;

  return (
    <div className="flex flex-wrap items-center justify-center gap-2 p-3 md:justify-between bg-gray-50">
      <div className="flex items-center gap-2">
        <span className="flex items-center gap-1 text-sm gray-800">
          Go to page:
          <input
            type="number"
            defaultValue={table.state.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.gotoPage(page);
            }}
            className="w-16 p-1 border rounded"
            min="1"
            max={table.pageCount}
          />
        </span>

        <select
          className="text-sm bg-transparent outline-none"
          value={table.state.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
            handlePageSizeChange(e.target.value);
          }}
        >
          {PAGE_SIZES.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

      <div className="flex items-center gap-2">
        <PaginationButton
          onClick={() => table.gotoPage(0)}
          disabled={!table.canPreviousPage}
          Icon={ChevronDoubleLeftIcon}
        />

        <PaginationButton
          onClick={() => table.previousPage()}
          disabled={!table.canPreviousPage}
          Icon={ChevronLeftIcon}
        />

        <span className="mx-4 text-sm text-gray-800">
          Page: <strong>{table.state.pageIndex + 1}</strong> of{' '}
          <strong>{table.pageCount}</strong>
        </span>

        <PaginationButton
          onClick={() => table.nextPage()}
          disabled={!table.canNextPage}
          Icon={ChevronRightIcon}
        />

        <PaginationButton
          onClick={() => table.gotoPage(table.pageCount - 1)}
          disabled={!table.canNextPage}
          Icon={ChevronDoubleRightIcon}
        />
      </div>
    </div>
  );
};

export default TablePagination;
