import { useMemo, useRef } from 'react';
import { Document } from '@appTypes/user-model';
import { documentNameByType } from '../../../utils/constants/index';
import { P } from '../../../common/components/elements/text';
import { TableHeaderBuilder } from 'react-table-utils';
import Section from '../../../common/layouts/PageSection';
import DataTable from '../../../common/components/tanstack-table';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import LinkedItem from '../../../common/components/elements/LinkedItem';
import { handleFileDownload } from '../components/DownloadDocuments';
import { DocumentInfoField } from 'components/userprofile/customerProfile';

type Props = {
  documentData: Document[];
};

const AllDocument: React.FC<Props> = ({ documentData }) => {
  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);

  const headerColumns = new TableHeaderBuilder<Document>()
    .add('serial', (col) =>
      col.header('S.No').accessorFn((_, index) => index + 1)
    )
    .add('name', (col) =>
      col.header('Name').cell((value: any) => (
        <>
          <P>{documentNameByType[value]}</P>
        </>
      ))
    )
    .add('uri', (col) =>
      col.header('Document').cell((value: any) => (
        <>
          {value ? (
            <DocumentInfoField title="" image="bankstatment.png" fileName={value}>
              <LinkedItem
                href=""
                onClick={() => handleFileDownload(value, downloadLinkRef)}
                className={`mt-2 text-end`}
              >
                <ArrowDownTrayIcon className="h-6 w-6 text-primary-500" />
              </LinkedItem>
              <a ref={downloadLinkRef} style={{ display: 'none' }} />
            </DocumentInfoField>
          ) : (
            <>-</>
          )}
        </>
      ))
    )
    .add('password', (col) =>
      col.header('Password').cell((value: any) => (
        <>
          <P>{value}</P>
        </>
      ))
    )
    .build();

  const columns = useMemo(() => headerColumns, []);

  return (
    <Section title="All Documents">
      <DataTable columns={columns} data={documentData} showColumnToggle={false}/>
    </Section>
  );
};

export default AllDocument;
