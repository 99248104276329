import ActionIcon from "../components/elements/ActionIcon";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useCallback, useState } from "react";
import cx from "clsx";

type Props = {
  isOpen: boolean;
  closeModal?: () => void;
  dismissButton?: boolean;
  panelClassName?: string;
  children?: React.ReactNode;
};

export const useModal = (defaultIsOpen: boolean = false) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  return { isOpen, openModal, closeModal };
};

const Modal: React.FC<Props> = ({
  children,
  isOpen,
  closeModal = () => {},
  dismissButton = true,
  panelClassName,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 left-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={cx(
                  "w-full p-6 text-left align-middle transition-all transform bg-white rounded-lg shadow-xl lg:w-2/5",
                  panelClassName
                )}
              >
                <div className="relative">
                  {dismissButton && (
                    <ActionIcon
                      Icon={XMarkIcon}
                      className="absolute top-0 right-0 w-8 h-8 p-2 text-white rounded-xl -mt-9 -mr-9 bg-brand-orange-500"
                      onClick={closeModal}
                    />
                  )}
                </div>

                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export type { Props as ModalProps };
export default Modal;
