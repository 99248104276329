import { getImageByType } from '../../../utils/index';
import { getDocumentFromS3, getInvoiceFromS3 } from '../../../utils/s3';
import { showInfoToast } from '../../../utils/toast';

type Props = {
  fileLocation: string;
  downloadLinkRef: any;
};

export const handleInvoiceDownload = (
  fileLocation: string,
  downloadLinkRef: any,
  fileCategory: string = 'invoices'
) => {
  getInvoiceFromS3(`${fileLocation}.pdf`, fileCategory)
    .then((res) => {
      const imageType = getImageByType(fileLocation);
      const fileNameWithExtension = fileLocation.split('/').pop();
      const [fileName, extension] = fileNameWithExtension
        ? fileNameWithExtension.split('.')
        : ['', ''];

      const blob = new Blob([res.Body as Uint8Array], {
        type: `${imageType}/${extension}`,
      });

      if (downloadLinkRef.current) {
        const url = URL.createObjectURL(blob);
        downloadLinkRef.current.href = url;
        downloadLinkRef.current.download = `${fileCategory}.pdf`;
        downloadLinkRef.current.click();
        URL.revokeObjectURL(url);
      }
    })
    .catch((err) => {
      showInfoToast('Something went wrong while downloading invoice');
    });
};

export const handleFileDownload = (
  fileLocation: string,
  downloadLinkRef: any
) => {
  getDocumentFromS3(fileLocation)
    .then((res) => {
      const imageType = getImageByType(fileLocation);
      const fileNameWithExtension = fileLocation.split('/').pop();
      const [fileName, extension] = fileNameWithExtension
        ? fileNameWithExtension.split('.')
        : ['', ''];

      const name = fileName.split('-')[0];

      const blob = new Blob([res.Body as Uint8Array], {
        type: `${imageType}/${extension}`,
      });

      if (downloadLinkRef.current) {
        const url = URL.createObjectURL(blob);
        downloadLinkRef.current.href = url;
        downloadLinkRef.current.download = `${name}.${extension}`!;
        downloadLinkRef.current.click();
        URL.revokeObjectURL(url);
      }
    })
    .catch((err) => {
      showInfoToast('Something went wrong while downloading file');
    });
};
