import { Col, Row } from 'antd';
import Form from '../../common/components/form';
import {
  initialAcceptInvitationCredentials,
  initialResetPasswordCredentials,
} from '../../common/types/auth-model';
import { resetPasswordSchema } from '../../common/schema/auth';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import Input from '../../common/components/elements/form/Input';
import LinkedItem from '../../common/components/elements/LinkedItem';
import { roleOptionsWithoutOwner } from '../../utils/constants/index';
import ListInput from '../../common/components/elements/form/ListInput';
import { useInvite } from '../../common/hooks/mutations/use-auth';
import { PAGE } from '../../utils/constants/page';
import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { storage } from '../../utils/storage';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useQueryClient } from '@tanstack/react-query';

const AcceptInvite = () => {
  const { acceptInvitation } = useInvite();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { trackEvent, pushInstruction } = useMatomo();

  useEffect(() => {
    if (storage.getToken()) {
      navigate(PAGE.dashboard.href);
    }
  }, []);

  return (
    <div className="login-page">
      <Row>
        <Col lg={8} md={12} sm={20} xs={24} className="login-box">
          <Row>
            <Col span={24}>
              <div className="illustration-wrapper">
                <img src="../assets/images/logo1.svg" alt="logo" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="mb-10">
                <Form
                  initialValues={initialAcceptInvitationCredentials}
                  schema={resetPasswordSchema}
                  onSubmit={async (data) => {
                    try {
                      const response = await acceptInvitation({
                        ...data,
                        otp: data.otp.toString(),
                      });

                      if (response) {
                        trackEvent({
                          category: 'Accept Invitation',
                          action: 'Authenticates a user',
                          name: data.role + ': ' + data.email,
                          value: 1,
                        });

                        pushInstruction('setUserId', `${data.email}`);
                        var _paq = window._paq || [];
                        queryClient.setQueryData(['email'], `${data.email}`);
                        _paq.push(['setUserId', `${data.email}`]);

                        navigate(PAGE.dashboard.href);
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  submitButton={{
                    text: 'Set My Password',
                    IconRight: ChevronDoubleRightIcon,
                    className: 'w-full mx-auto',
                  }}
                >
                  <h3>Accept Invitation</h3>
                  <p>Create your new password below</p>
                  <ListInput
                    options={roleOptionsWithoutOwner}
                    name="role"
                    label="Role"
                    required
                  />
                  <Input name="email" placeholder="Email" required />
                  <Input
                    name="password"
                    placeholder="New Password"
                    type="password"
                    required
                  />
                  <Input name="otp" placeholder="OTP" type="number" required />
                </Form>
                <div className="text-sm text-center mt-2">
                  <LinkedItem
                    href={PAGE.login.href}
                    className="font-bold text-primary-300"
                  >
                    Cancel
                  </LinkedItem>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AcceptInvite;
