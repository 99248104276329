import axios from "axios";

export const PostApiCallLogin = (url: string, body: any) => {
  return axios
    .post(url, body)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      return reason?.response?.data;
    });
};

export const PostApiCall = (url: string, body: any, token:any) => {
  
  return axios
    .post(url, body, {
      headers: {
        Authorization:
          "Bearer " +
          token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      return reason?.response?.data;
    });
};

export const UploadApiCall = (url: string, body: any, token:any) => {
  return axios
    .post(url, body, {
      headers: {
        Authorization:
          "Bearer " +
          token,
          "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      return reason?.response?.data;
    });
};

export const UploadPutApiCall = (url: string, body: any, token:any) => {
  return axios
    .put(url, body, {
      headers: {
        Authorization:
          "Bearer " +
          token,
          "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      return reason?.response?.data;
    });
};

export const GetApiCall = (url: string, token:any) => {
  const config = {
    headers: {
      Authorization:
        "Bearer " +
        token,
    },
  };
  return axios
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      return reason?.response?.data;
    });
};

export const DeleteApiCall = (url: string, body: any, token:any) => {
  
  return axios
    .delete(url, {
      headers: {
        Authorization:
          "Bearer " +
          token,
      },
      data: body,
    })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      return reason?.response?.data;
    });
};



export const PutApiCall = (url: string, body: any, token:any) => {
   return axios
    .put(url, body, {
      headers: {
        Authorization:
          "Bearer " +
          token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      return reason?.response?.data;
    });
};
