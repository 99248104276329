import React from 'react';
import { Component } from '@appTypes/.';
import ConditionalWrapper from '../components/ConditionalWrapper';
import cx from 'clsx';
import { H2, H5, H4, H3 } from '../components/elements/text';

type SectionBodyProps = {
  title?: string;
  text?: string;
  children?: React.ReactNode;
};

export type Props = {
  id?: string;
  container?: boolean;
  containerClassName?: string;
  secondary?: boolean;
  children?: React.ReactNode;
} & SectionBodyProps;

const SectionBody: Component<SectionBodyProps> = ({
  children,
  title,
  text,
}) => {
  return (
    <>
      {title && (
        <H2 className={cx('text-center', !text && 'mb-4')}>{title}</H2>
      )}

      {text && <H5 className="mb-4 text-center md:mb-4 text-gray-500">{text}</H5>}

      {children}
    </>
  );
};

const Section: Component<Props> = ({
  id = '',
  container = true,
  children,
  className,
  containerClassName,
  secondary = false,
  ...rest
}) => {
  return (
    <div
      id={id}
      className={`py-2 ${
        secondary && 'bg-opacity-20 bg-secondary2-100'
      } ${className}}`}
    >
      {/* wrap into container */}
      <ConditionalWrapper
        condition={container}
        wrapper={(children) => (
          <div className={cx('mx-auto', containerClassName)}>{children}</div>
        )}
      >
        <SectionBody {...rest}>{children}</SectionBody>
      </ConditionalWrapper>
    </div>
  );
};

export default Section;
