import { useEffect, useState } from 'react';
import { apiEndpoint } from '../../utils/endpoint';
import { useQuery, useQueryData } from '../../lib/react-query';
import { useLocation, useParams } from 'react-router-dom';
import { H1, H3, H5, P } from '../../common/components/elements/text';
import DataTable from '../../common/components/tanstack-table/index';
import {
  getCreditEnquiryFlags,
  removeCharacterFromAmount,
} from '../../utils/index';
import {
  AllEnqDetail,
  ICreditEnquiry,
} from '../../common/types/credit-enquiry-model';
import { Flag } from '../../common/types/credit-analysis';
import { formatMoney } from '../../utils/formatter';
import { TableHeaderBuilder } from 'react-table-utils';
import { useRole } from '../../common/hooks/use-user';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';

const ALL_ENQUIRY = 'ALL_ENQUIRY';

const AllEnquiry = () => {
  const [totalEnquiry, setTotalEnquiry] = useState(0);
  const [creditEnquiryData, setCreditEnquiryData] = useState<AllEnqDetail[]>(
    []
  );
  const [flag, setFlag] = useState<Flag>();

  const router = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const reportId = queryParams.get('report_id');
  const userId = router.id as string;

  const { currentUserRole } = useRole();
  const { data, isLoading } = useQueryData(
    apiEndpoint.creditAnalysis.getCreditEnquiry(currentUserRole, userId),
    ALL_ENQUIRY + userId
  );

  useEffect(() => {
    if (!isLoading && data) {
      // @ts-ignore
      const { all_inquiries_count, all_inquiries_details } = data.filter(
        (item: ICreditEnquiry) =>
          item.detailed_all_inquiries.report_id === reportId
      )[0].detailed_all_inquiries;
      setTotalEnquiry(all_inquiries_count);
      setCreditEnquiryData(all_inquiries_details);
      setFlag(getCreditEnquiryFlags(all_inquiries_count));
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <FullPageLoader isOpen={true} />;
  }

  // if (creditEnquiryData.length === 0) {
  //   return <NotFoundBox title="Credit Inquiry" />;
  // }

  const headerColumns = new TableHeaderBuilder<AllEnqDetail>()
    .add('account', (col) =>
      col
        .header('LENDER')
        .cell((value) => <H5 className="text-primary-500 py-1">{value}</H5>)
    )
    .add('inquiry_date', (col) =>
      col
        .header('ENQUIRY DATE')
        .cell((value: string) => <H5 className="text-primary-500">{value}</H5>)
    )
    .add('inquiry_amount', (col) =>
      col
        .header('ENQUIRY AMOUNT')
        .cell((value: string) => (
          <H5 className="text-primary-500 text-bold">
            {formatMoney(removeCharacterFromAmount(value))}
          </H5>
        ))
    )
    .build();

  return (
    <div className="py-4">
      <H1 className="text-center">Credit Enquiry</H1>
      <div className="flex py-2">
        <H5 className="text-gray-500">Enquiries in till 2023: </H5>
        <H5
          className={`text-lg text-bold ${
            flag?.color === 'green' ? 'text-green-600' : 'text-red-600'
          } pl-2`}
        >
          {totalEnquiry}
        </H5>
      </div>
      {creditEnquiryData.length > 0 && (
        <DataTable columns={headerColumns} data={creditEnquiryData} />
      )}
    </div>
  );
};

export default AllEnquiry;
