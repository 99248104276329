export const url = "http://127.0.0.1:8000/v1/";

export const imageUrl = "https://api.bharatcreditbuilder.com";

export const userManagementUrl = url + "admin/usermanagement";

export const roleManagementUrl = url + "admin/rolemanagement";

export const dashboardManagementUrl = url + "admin/dashboardallrecords";

export const subscriptionUrl = url + "plan/getallplanlist";

export const updateSubscriptionPlanUrl = url + "plan/updateplan";

export const createSubscriptionPlanUrl = url + "plan/createplan";

export const deleteSubscriptionPlanUrl = url + "plan/deleteplan";

export const editProfileRolemanagementUrl = url + "user/editprofile";

export const getProfileUrl = url + "user/getprofile";

export const createProfileRolemanagementUrl = url + "user/createprofile";

export const deleteProfileRolemanagementUrl = url + "user/deleteprofile";

export const cmsManagementUrl = url + "cms/cmsallpage";

export const cmsManagementUpdateUrl = url + "cms/cmsupdatepage";

export const faqUrl = url + "faq/getallfaq";

export const updateFaqUrl = url + "faq/updatefaq";

export const createFaqUrl = url + "faq/createfaq";

export const deleteFaqUrl = url + "faq/deletefaq";

export const blogsUrl = url + "blog/getallblog";

export const updateBlogsUrl = url + "blog/updateblog";

export const createBlogsUrl = url + "blog/createblog";

export const deleteBlogsUrl = url + "blog/deleteblog";

export const contactusUrl = url + "cms/getallcontactus";

export const userSignUpUrl = url + "report/getallUserSignUp";

export const registeredUserUrl = url + "report/getallRegisteredUser";

export const executiveListUrl = url + "report/getAllExecutives";

export const managerListUrl = url + "report/getAllManagers";

export const negativeAccountsUrl = url + "report/getNegativeAccounts";

export const subscriptionDetailsUrl = url + "report/getSubscriptionDetails";

export const assignExecutiveListUrl = url + "admin/assignExecutiveToUser";

export const addFeedbckUsrAccUrl = url + "report/addFeedback";

export const getAllAssignbyPersonsUrl = url + "report/getAllAssignbyPersons";

export const getUserPreviousPlansUrl = url + "plan/getPreviousPlans";

export const getUserAllPlansUrl = url + "report/getUserAllPlans";

export const getFeedbckUsrAccUrl = url + "report/getFeedback";

export const updateFeedbckUsrAccUrl = url + "report/updateFeedback";

export const userSignUpFilterUrl = url + "report/userSignUpFilter";

export const userSusbcriptionDetailsFilterUrl = url + "report/getSubscriptionDetailsFilter";

export const userManagementDurationFilter = url + "admin/userManagementDurationFilter";

export const callConnectUrl = url + "call/connectCall";

export const getCrifPullLimitUrl = url + "setting/getCrifPullLimit";

export const setCrifPullLimitUrl = url + "setting/setCrifPullLimit";