import { Col, Row } from 'antd';
import { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from '../../common/components/form';
import { initialResetPasswordCredentials } from '../../common/types/auth-model';
import { resetPasswordSchema } from '../../common/schema/auth';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import Input from '../../common/components/elements/form/Input';
import LinkedItem from '../../common/components/elements/LinkedItem';
import {
  useResetPassword,
  useOTPVerify,
} from '../../common/hooks/mutations/use-auth';
import { showInfoToast } from '../../utils/toast';
import { P } from '../../common/components/elements/text';
import InlineLoader from '../../common/components/elements/loader/InlineLoader';
import { PAGE } from '../../utils/constants/page';
import { useQueryClient } from '@tanstack/react-query';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const CreateNewPassword = () => {
  const [seconds, setSeconds] = useState(59);
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const lastEmailSentAt = useRef<number | undefined>();

  const navigate = useNavigate();
  const { resetPassword } = useResetPassword();
  const { resendOTP, isLoading } = useOTPVerify();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { trackEvent, pushInstruction } = useMatomo();

  const [email, setEmail] = useState(location.state?.email);
  const [role, setRole] = useState(location.state?.role);
  initialResetPasswordCredentials.email = email;

  const handleResenOTP = () => {
    if (
      lastEmailSentAt.current &&
      new Date().getTime() - lastEmailSentAt.current <= seconds * 1000
    ) {
      showInfoToast(`You need to wait for ${seconds} seconds!`);
      return;
    }

    lastEmailSentAt.current = new Date().getTime();
    resendOTP({ email, role });
    setSeconds(59);
  };

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(
        () => setSeconds((prevSeconds) => prevSeconds - 1),
        1000
      );

      return () => clearInterval(timer);
    }
  }, [seconds]);

  return (
    <div className="login-page">
      <Row>
        <Col lg={8} md={12} sm={20} xs={24} className="login-box">
          <Row>
            <Col span={24}>
              <div className="illustration-wrapper">
                <img src="../assets/images/logo1.svg" alt="logo" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="mb-10">
                <Form
                  initialValues={initialResetPasswordCredentials}
                  schema={resetPasswordSchema}
                  onSubmit={async (data) => {
                    const response = await resetPassword({
                      ...data,
                      email,
                      role,
                      otp: data.otp.toString(),
                    });
                    if (response) {
                      trackEvent({
                        category: 'Reset Password',
                        action: 'Authenticates a user',
                        name: data.role + ': ' + data.email,
                        value: 1,
                      });

                      pushInstruction('setUserId', `${data.email}`);
                      var _paq = window._paq || [];
                      queryClient.setQueryData(['email'], `${data.email}`);
                      _paq.push(['setUserId', `${data.email}`]);

                      navigate(PAGE.login.href);
                    } else console.log('reset password failed');
                  }}
                  submitButton={{
                    text: 'Set My Password',
                    IconRight: ChevronDoubleRightIcon,
                    className: 'w-full mx-auto',
                  }}
                >
                  <h3>Create New Password</h3>
                  <p>Create your new password below</p>
                  <Input name="email" placeholder="Email" required />
                  <Input
                    name="password"
                    placeholder="New Password"
                    type="password"
                    required
                  />
                  <Input name="otp" placeholder="OTP" type="number" required />
                  <div className="text-center">
                    {seconds === 0 ? (
                      <span className="text-red-500 text-md sm:text-lg">
                        OTP verification time has expired!
                      </span>
                    ) : (
                      <P className="text-gray-600">
                        Time Left&nbsp;
                        <span className="text-green-500 font-bold">
                          00:{seconds}
                        </span>
                      </P>
                    )}
                  </div>
                  {isLoading ? (
                    <div className="flex justify-end">
                      <InlineLoader className="text-primary-500" />
                    </div>
                  ) : (
                    <div className="text-sm text-right">
                      <LinkedItem
                        href="#"
                        className="font-bold text-primary-300"
                        onClick={() => handleResenOTP()}
                      >
                        Resend OTP
                      </LinkedItem>
                    </div>
                  )}
                </Form>
                <div className="text-sm text-center mt-2">
                  <LinkedItem
                    href={PAGE.login.href}
                    className="font-bold text-primary-300"
                  >
                    Cancel
                  </LinkedItem>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateNewPassword;
