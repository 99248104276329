import React, { useRef, useState } from 'react';
import {
  Button,
  Form,
  FormInstance,
  Input,
  Modal,
  Space,
  Row,
  Col,
} from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { withAuth } from '../../common/hoc/withAuth';
import { role } from '../../utils/constants/index';
import { useInviteMutation } from '../../common/hooks/mutations/use-assign-mutation';
import { useRole } from '../../common/hooks/use-user';
import ManagerList from '../../modules/managers/section/ManagerList';
import ExecutiveList from '../../modules/executives/section/ExecutiveList';

const RoleManagement = () => {
  const managerRef = useRef<any>(null);
  const executiveRef = useRef<any>(null);
  const { currentUserRole } = useRole();

  const mutateInvite = useInviteMutation();

  const LocalizedModal = () => {
    const formRef = React.createRef<FormInstance>();
    const onFinish = async (data: any) => {
      try {
        await mutateInvite(data);
        if (managerRef.current && currentUserRole === role.owner) {
          managerRef.current.refetchData();
        } else if (executiveRef.current && currentUserRole === role.manager) {
          executiveRef.current.refetchData();
        }
      } catch (e) {
        console.log(e);
      }
    };
    const [open, setOpen] = useState(false);

    const showModal = () => {
      setOpen(true);
    };

    const hideModal = () => {
      setOpen(false);
    };

    return (
      <>
        <Row>
          <Col span={24}>
            {currentUserRole !== role.executive && (
              <Button
                className="btn btn-primary add-button"
                type="primary"
                onClick={showModal}
              >
                <PlusCircleFilled /> Add
              </Button>
            )}
            <Modal
              title={`Create ${currentUserRole === role.owner ? 'Manager' : 'Executive'}`}
              open={open}
              footer={null}
              onCancel={hideModal}
            >
              <Row>
                <Col span={24}>
                  <Form
                    name="complex-form"
                    layout="vertical"
                    ref={formRef}
                    onFinish={onFinish}
                  >
                    <Row>
                      <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item label="Email" required>
                          <Space>
                            <Form.Item
                              name="email"
                              noStyle
                              rules={[
                                {
                                  required: true,
                                  message: 'Email is required',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item label="Phone Number" required>
                          <Space>
                            <Form.Item
                              name="phone_number"
                              noStyle
                              rules={[
                                {
                                  required: true,
                                  message: 'Phone Number is required',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ span: 8, offset: 16 }} md={24} sm={24} xs={24}>
                        <Form.Item wrapperCol={{ span: 24 }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="btn btn-primary"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Modal>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      <div className="content-button-style">
        <Space>
          <LocalizedModal />
        </Space>
      </div>
      {currentUserRole === role.owner && <ManagerList ref={managerRef} />}
      {currentUserRole === role.manager && <ExecutiveList ref={executiveRef} />}
    </div>
  );
};

export default withAuth()(RoleManagement);
