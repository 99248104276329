import { BankStatementAction } from "@appTypes/bank-statement-model";
import { Document } from "@appTypes/user-model";
import { calculateNetCashFlow, filterBankStatementData, getAverageMonthlyBalance, getDefaultAmount, getLowestBalance, groupObjectsByPhoneNumber } from "../../utils/index";
import { getJsonFromS3 } from "../../utils/s3";

export const BANK_STATEMENT_QUERY_KEY = "QUERY_BANK_STATEMENT";

export const BankStatement = async (bankStatement: Document) => {
  try {

    if (!bankStatement) return {
      data: {
        analysis: {
          by_account: undefined
        },
        applicant_type: "",
        status: [],
        confirmed_pii: {}
      }, isBankStatementAvailable: false, isBankStatementEmpty: true, isBankStatementFailed: false
    };

    let response = await getJsonFromS3(bankStatement.uri);
    let completeBankAnalysis: BankStatementAction = response.data;

    if (completeBankAnalysis.status[0].status === 'failed') return {
      data: {
        analysis: {
          by_account: undefined
        },
        applicant_type: "",
        status: [],
        confirmed_pii: {}
      }, isBankStatementAvailable: true, isBankStatementEmpty: true, isBankStatementFailed: true
    }

    if (Object.keys(completeBankAnalysis?.analysis?.by_account).length === 0) {
      return {
        data: completeBankAnalysis,
        isBankStatementAvailable: true,
        isBankStatementEmpty: true,
        isBankStatementFailed: false
      }
    }

    return {
      data: completeBankAnalysis,
      isBankStatementAvailable: true,
      isBankStatementEmpty: false,
      isBankStatementFailed: false
    };
  } catch (error) {
    return {
      data: {
        analysis: {
          by_account: undefined
        },
        applicant_type: "",
        status: [],
        confirmed_pii: {}
      }, isBankStatementAvailable: false, isBankStatementEmpty: true, isBankStatementFailed: false
    };
  };
};

export const CalculateBankStatementAnalysis = async (documents: Document[]) => {
  const bankStatement = filterBankStatementData(documents);
  if (!bankStatement) return { averageMonthlyBalanceChange: 0, lowestBalance: 0, netCashFlow: 0, defaultAmount: 0, isBankStatementAvailable: false, isBankStatementEmpty: true, isBankStatementFailed: false };

  const { data, isBankStatementAvailable, isBankStatementEmpty, isBankStatementFailed } = await BankStatement(bankStatement);

  if (isBankStatementFailed) return { averageMonthlyBalanceChange: 0, lowestBalance: 0, netCashFlow: 0, defaultAmount: 0, isBankStatementAvailable: true, isBankStatementEmpty: false, isBankStatementFailed: true };
  if (isBankStatementEmpty) return { averageMonthlyBalanceChange: 0, lowestBalance: 0, netCashFlow: 0, defaultAmount: 0, isBankStatementAvailable: true, isBankStatementEmpty: true, isBankStatementFailed: false };

  const completeAnalysisByAccount = data?.analysis?.by_account;
  const accountNumber = Object.keys(completeAnalysisByAccount)[0];
  const completeDetail = completeAnalysisByAccount[accountNumber];

  let netCashFlow;
  if (!netCashFlow) {
    netCashFlow = calculateNetCashFlow(completeDetail.net_cash_flow);
  }

  let averageMonthlyBalanceChange;
  if (!averageMonthlyBalanceChange) {
    averageMonthlyBalanceChange = getAverageMonthlyBalance(completeDetail.monthly_average_balance);
  }

  let lowestBalance;
  if (!lowestBalance) {
    const monthlyLowestBalance = getLowestBalance(completeDetail.closing_balances);
    lowestBalance = monthlyLowestBalance.reduce((min, current) => {
      if (current.lowest_balance < min) {
        return current.lowest_balance;
      }
      return min;
    }, Infinity);
  }

  const defaultAmount = getDefaultAmount(completeDetail.default_transactions);

  return { averageMonthlyBalanceChange, lowestBalance, netCashFlow, defaultAmount, isBankStatementAvailable, isBankStatementEmpty, isBankStatementFailed };
}

export const BankAccountInformation = async (documents: Document[]) => {
  const bankStatement = filterBankStatementData(documents);

  if (!bankStatement) return { status: 'failed', account_number: null, ifss: null, account_holder_name: null, bank_name: null, father_fname: null, father_lname: null };
  const { data, isBankStatementEmpty, isBankStatementFailed } = await BankStatement(bankStatement);

  if (isBankStatementFailed || isBankStatementEmpty) return { status: 'failed', account_number: null, ifsc: null, account_holder_name: null, bank_name: null, father_fname: null, father_lname: null };

  if (data?.confirmed_pii && Object.keys(data?.confirmed_pii).length != 0) {
    return {
      ...data.confirmed_pii,
      status: 'succeeded'
    }
  }

  return { status: 'failed', account_number: null, ifsc: null, account_holder_name: null, bank_name: null, father_fname: null, father_lname: null };
}

export const TransactionReference = async (documents: Document[]) => {
  const bankStatement = filterBankStatementData(documents);
  if (!bankStatement) return { status: 'failed', transaction_reference: [] };

  const { data, isBankStatementEmpty, isBankStatementFailed } = await BankStatement(bankStatement);
  if (isBankStatementFailed || isBankStatementEmpty) return { status: 'failed', transaction_reference: [] };

  const completeAnalysisByAccount = data?.analysis?.by_account;
  const accountNumber = Object.keys(completeAnalysisByAccount)[0];
  const transactions = completeAnalysisByAccount[accountNumber].all_transactions;

  const transactionReference = groupObjectsByPhoneNumber(transactions);

  const transactionReferenceArray = Object.keys(transactionReference).map(phoneNumber => ({
    phone_number: phoneNumber,
    totalDeposits: transactionReference[phoneNumber].depositsSum,
    totalWithdrawals: transactionReference[phoneNumber].withdrawalsSum,
    frequency: transactionReference[phoneNumber].frequency,
    transactions: transactionReference[phoneNumber].objects,
  }));
    
  return { status: 'succeeded', transaction_reference: transactionReferenceArray };
}