import { Category, Transaction } from '@appTypes/bank-statement-model';
import { H1, H5, P } from '../../common/components/elements/text';
import { Document } from '@appTypes/user-model';
import { useCustomerById } from '../../common/hooks/use-customer';
import { useFullPageLoader } from '../../common/hooks/use-full-page-loader';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { formatDateWithoutTime, formatMoney } from '../../utils/formatter';
import {
  filterBankStatementData,
  filterDefaultTransactions,
  getDefaultAmount,
  getDefaultTransactionFlags,
  removeCharacterFromAmount,
  truncateString,
} from '../../utils/index';
import { Tag } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableHeaderBuilder } from 'react-table-utils';
import { BANK_STATEMENT_ANALYSIS_KEY } from './netCashFlow';
import { BankStatement } from '../../common/hooks/use-bank-statement';
import MonthlyBalanceLineChart from './components/MonthlyBalance';
import DataTable from '../../common/components/tanstack-table';

type Props = {};

const DefaultTransaction: React.FC<Props> = () => {
  const { showLoader, hideLoader } = useFullPageLoader();
  const queryClient = useQueryClient();
  const [defaultTransactionData, setDefaultTransactionData] = useState<
    Transaction[]
  >([]);
  const [totalDefaultAmount, setTotalDefaultAmount] = useState(0);
  const [chartData, setChartData] = useState<{
    months: string[];
    lineChartData: [
      { label: string; data: number[]; color: string; borderColor: string }
    ];
  }>({
    months: [],
    lineChartData: [
      {
        label: 'Default Transactions',
        data: [],
        color: 'rgba(37, 99, 235, 0.1)',
        borderColor: 'rgb(37, 99, 235)',
      },
    ],
  });

  const router = useParams();
  const userId = router.id as string;

  const { data, isLoading } = useCustomerById(userId);

  const getDefaultTransactions = async (documents: Document[]) => {
    showLoader();

    let completeAnalysisData;
    const bankStatementAnalysisData = queryClient.getQueryData([
      BANK_STATEMENT_ANALYSIS_KEY + userId,
    ]);

    if (bankStatementAnalysisData) {
      completeAnalysisData = bankStatementAnalysisData;
      hideLoader();
    } else {
      const bankStatement = filterBankStatementData(documents);
      const { data, isBankStatementAvailable, isBankStatementEmpty } =
        await BankStatement(bankStatement);
      hideLoader();

      if (!isBankStatementAvailable || isBankStatementEmpty) return;
      completeAnalysisData = data;
      queryClient.setQueryData([BANK_STATEMENT_ANALYSIS_KEY + userId], data);
    }
    const completeAnalysisByAccount =
      // @ts-ignore
      completeAnalysisData?.analysis?.by_account;
    const accountNumber = Object.keys(completeAnalysisByAccount)[0];
    const completeDetail = completeAnalysisByAccount[accountNumber];

    setChartData({
      months: [],
      lineChartData: [
        {
          label: 'Default Transactions',
          data: [],
          color: 'rgba(37, 99, 235, 0.1)',
          borderColor: 'rgb(37, 99, 235)',
        },
      ],
    });
    setDefaultTransactionData([]);

    let defaultTransactions = queryClient.getQueryData<Transaction[]>([
      'DEFAULT_TRANSACTIONS',
    ]);

    if (!defaultTransactions) {
      defaultTransactions = filterDefaultTransactions(
        completeDetail.default_transactions
      );
      queryClient.setQueryData(['DEFAULT_TRANSACTIONS'], defaultTransactions);
    }

    const totalDefaultAmount = getDefaultAmount(defaultTransactions);
    setTotalDefaultAmount(totalDefaultAmount);

    defaultTransactions.forEach((item) => {
      setChartData((prev: any) => {
        const updatedLineChartData = prev.lineChartData.map(
          (chart: { label: string; data: any }) => {
            if (chart.label === 'Default Transactions') {
              return {
                ...chart,
                data: [...chart.data, item.withdrawal],
              };
            }
            return chart;
          }
        );

        return {
          months: [...prev.months, `${formatDateWithoutTime(item.date)}`],
          lineChartData: updatedLineChartData,
        };
      });
    });

    setDefaultTransactionData(defaultTransactions);
  };

  useEffect(() => {
    if (!isLoading && data?.customer)
      getDefaultTransactions(data.customer.documents);
  }, [data, isLoading]);

  const headerColumns = new TableHeaderBuilder<Transaction>()
    .add('date', (col) =>
      col
        .header('Date')
        .cell((value) => <P>{formatDateWithoutTime(value)}</P>)
    )
    .add('category.hierarchy', (col) =>
      col.header('Category').cell((value: any) => (
        <>
          {value.map((category: string) => (
            <Tag color="blue" key={category}>
              {category}
            </Tag>
          ))}
        </>
      ))
    )
    .add('particular', (col) =>
      col.header('Particular').cell((value) => (
        <div className="group flex relative">
          <span
            className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 text-lg text-gray-100 rounded-md absolute left-1/2
            -translate-x-1/2 -translate-y-full opacity-0 m-4 mx-auto duration-150 ease-in-out"
            style={{ top: '-1rem' }}
          >
            {value}
          </span>
          <P className="">{truncateString(value, 40)}</P>
        </div>
      ))
    )
    .add('party', (col) =>
      col
        .header('Party')
        .cell((value) => (
          <>{value ? <P>{value}</P> : <P>Miscellaneous</P>}</>
        ))
    )
    .add('withdrawal', (col) =>
      col
        .header('Default')
        .cell((value) => (
          <P className="text-red-600">
            {formatMoney(removeCharacterFromAmount(value))}
          </P>
        ))
    )
    .add('balance', (col) =>
      col
        .header('Balance')
        .cell((value) => (
          <P>{formatMoney(removeCharacterFromAmount(value))}</P>
        ))
    )
    .build();

  const columns: ColumnDef<Transaction>[] = useMemo(() => headerColumns, []);

  return (
    <div className="space-y-4">
      <H1 className="text-center">Default Transactions</H1>
      <div className="flex">
        <H5 className="text-gray-500">Total Default Amount: </H5>
        <H5
          className={`text-lg text-bold ${
            getDefaultTransactionFlags(totalDefaultAmount).color === 'green'
              ? 'text-green-600'
              : 'text-red-600'
          } pl-2`}
        >
          {formatMoney(totalDefaultAmount)}{' '}
        </H5>
      </div>
      <div className="flex">
        <H5 className="text-gray-500">Total Default: </H5>
        <H5 className={`text-lg text-bold pl-2`}>
          {defaultTransactionData.length}{' '}
        </H5>
      </div>
      {defaultTransactionData.length > 0 && (
        <>
          <DataTable columns={columns} data={defaultTransactionData} />
          <MonthlyBalanceLineChart
            months={chartData.months}
            lineChartData={chartData.lineChartData}
            title="Default Transactions"
          />
        </>
      )}
    </div>
  );
};

export default DefaultTransaction;
