import { useMemo } from 'react';
import {
  Dropdown,
  MenuProps,
  DatePicker,
} from 'antd';
import './userSignup-style.scss';
import { withAuth } from '../../../common/hoc/withAuth';
import { TableHeaderBuilder } from 'react-table-utils';
import { Customer } from '../../../common/types/user-model';
import { P } from '../../../common/components/elements/text';
import { formatDateWithTimeAndSeconds } from '../../../utils/formatter';
import DataTable from '../../../common/components/tanstack-table';
import { ColumnDef } from '@tanstack/react-table';
import { useCustomer } from '../../../common/hooks/use-customer';
import FullPageLoader from '../../../common/components/elements/loader/FullPageLoader';
import Section from '../../../common/layouts/PageSection';

const { RangePicker } = DatePicker;

const UserSignUpPage = () => {
  const { data, isLoading } = useCustomer();

  const headerColumns = new TableHeaderBuilder<Customer>()
    .add('serial', (col) =>
      col.header('S.No').accessorFn((_, index) => index + 1)
    )
    .add('profile.phone_number', (col) =>
      col
        .header('Phone Number')
        .cell((value: any) => <>{value ? <P>{value}</P> : '-'}</>)
    )
    .add('created_at', (col) =>
      col
        .header('SignUp Date')
        .cell((value: any) => (
          <>{value ? <P>{formatDateWithTimeAndSeconds(value)}</P> : '-'}</>
        ))
    )
    .build();

  const columns: ColumnDef<Customer>[] = useMemo(() => headerColumns, []);
  if (isLoading) return <FullPageLoader isOpen={true} />;

  if (!data?.customers) return <></>;

  const allCustomers = data?.customers;

  return (
    <Section title="Customer SignUp List">
      <DataTable
        columns={columns}
        data={allCustomers}
        showColumnToggle={false}
      />
    </Section>
  );
};

export default withAuth()(UserSignUpPage);
