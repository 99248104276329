import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
  useGlobalFilter,
} from 'react-table';

import TablePagination from './paginatgeUI';
import Card from '../elements/Card';
import { SuccessButton, InfoButton } from '../elements/button';
import {
  UserPlusIcon,
  TrashIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { DefaultColumnFilter, fuzzyTextFilterFn } from './TableFilter';
import ButtonGroup from '../elements/button/ButtonGroup';
import DataTableColumnTogglerButton from './DataTableColumnToggler';
import { useFullPageLoader } from '../../hooks/use-full-page-loader';
import DownloadExcelSheet from '../../../modules/customer/components/DownloadExcelSheet';
import { storeSingleItem } from '../../../utils/storage';
import './table-style.css';
import { useSticky } from 'react-table-sticky';

type Props<T> = {
  columns: ColumnDef<T>[];
  data: T[];
};

type ColumnInstance<T> = {
  getHeaderProps: any;
  getSortByToggleProps: any;
  getGroupByToggleProps: any;
  id: string;
  Header: string | React.ReactNode;
  accessor: keyof T;
  canGroupBy?: boolean;
  isGrouped?: boolean;
  isSorted?: boolean;
  isSortedDesc?: boolean;
  canFilter?: boolean;
  render: (key: any) => React.ReactNode;
};

type TableProps<T> = {
  columns: any;
  data: T[];
  resetData?: () => void;
  isExcelSheetButtonVisible?: boolean;
  showAssignButton?: boolean;
  totalDataCount?: number;
  excelSheetName?: string;
  handleAssign?: () => void;
  excelSheetUrl?: string;
  paginateData: (
    pageSize: number,
    pageIndex: number,
    orderBy: string,
    desc: boolean
  ) => void;
  searchCustomer?: (
    searchText: string,
    pageSize: number,
    pageIndex: number
  ) => void;
};

const Table = <T extends {}>({
  columns,
  data,
  resetData,
  isExcelSheetButtonVisible = false,
  showAssignButton = false,
  totalDataCount,
  excelSheetName,
  excelSheetUrl,
  handleAssign,
  paginateData,
  searchCustomer,
}: TableProps<T>) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [queryParams, setQueryParams] = useState(
    '&subscription_status=both&score_range=10'
  );
  const [currentSortedColumn, setCurrentSortedColumn] = useState('updated_at');
  const [desc, setDesc] = useState(true);
  const [currentFilterColumn, setCurrentFilterColumn] = useState(
    'is_profile_complete'
  );
  const [currentFilterDesc, setCurrentFilterDesc] = useState(true);
  const [currentFilters, setCurrentFilters] = useState('sorted');

  const { showLoader, hideLoader } = useFullPageLoader();
  const originalData = data;

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows: any, id: string | number, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const table = useTable(
    {
      // @ts-ignore
      columns,
      data,
      // @ts-ignore
      // @ts-ignore
      defaultColumn,
      // @ts-ignore
      filterTypes,
      // @ts-ignore
      // pageCount: Math.ceil(totalDataCount / pageSize),
      initialState: {
        // pageIndex: 0,
        // pageSize: 10,
        // @ts-ignore
        // sortBy: [
        //   {
        //     id: 'updated_at',
        //     desc: true,
        //   },
        // ],
        // groupBy: ['lastName'],
        expanded: {},
        filters: [],
        globalFilter: '',
      },
      // We also need to pass this so the page doesn't change
      // when we edit the data.
      autoResetPage: false,
      autoResetSelectedRows: false,
      disableMultiSort: true,
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useSticky
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    rows,
    // @ts-ignore
    setGlobalFilter,
    // @ts-ignore
    preGlobalFilteredRows,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    pageCount = 100,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    state: {
      // @ts-ignore
      pageIndex,
      // @ts-ignore
      pageSize,
    },
  } = table;

  // @ts-ignore
  table.pageCount = Math.ceil(totalDataCount / 30);
  useEffect(() => {
    showLoader();
    console.log('page size', pageSize, pageIndex);

    if (currentFilters === 'sorted') {
      paginateData(
        // @ts-ignore
        table.state.pageSize,
        // @ts-ignore
        table.state.pageIndex,
        currentSortedColumn,
        desc,
        // @ts-ignore
        queryParams
      );
    } else if (currentFilters === 'filter') {
      paginateData(
        // @ts-ignore
        table.state.pageSize,
        // @ts-ignore
        table.state.pageIndex,
        'updated_at',
        currentFilterDesc,
        // @ts-ignore
        queryParams
      );
    }
    hideLoader();
    // @ts-ignore
  }, [table.state.pageIndex, table.state.pageSize]);

  const handleSearchClick = async () => {
    // @ts-ignore
    searchCustomer(searchQuery.trim().replace(/\s+/g, ''));
  };

  const handleSearchInputKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      // @ts-ignore
      handleSearchClick();
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    // @ts-ignore
    searchCustomer('', table.state.pageIndex, table.state.pageSize);
  };

  const handleSearchInputChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    if (e.target.value === '') {
      handleClearSearch();
    }
    setSearchQuery(e.target.value);
  };

  const handleFilterColumn = (column: any) => {
    if (column.id === 'updated_at' || column.id === 'created_at') {
      setCurrentFilters('sorted');
      if (column.id !== currentSortedColumn) {
        setCurrentSortedColumn(column.id);
        setDesc(true);

        paginateData(
          // @ts-ignore
          table.state.pageSize,
          // @ts-ignore
          table.state.pageIndex,
          column.id,
          true,
          // @ts-ignore
          queryParams
        );
      } else {
        setDesc(!desc);
        paginateData(
          // @ts-ignore
          table.state.pageSize,
          // @ts-ignore
          table.state.pageIndex,
          column.id,
          !desc,
          // @ts-ignore
          queryParams
        );
      }
    }
  };

  const onFilterChange = (column: any) => {
    let columnId = column.id;
    if (columnId === 'subscriptions' || columnId === 'score') {
      let currentQueryParam = '';

      let creditScoreFilter =
        storeSingleItem.getItem('creditScoreFilter') || '10';
      let subscriptionFilter =
        storeSingleItem.getItem('subscriptionFilter') || 'both';

      setCurrentFilters('filter');

      currentQueryParam += `&subscription_status=${subscriptionFilter}`;
      currentQueryParam += `&score_range=${creditScoreFilter}`;

      setQueryParams(currentQueryParam);
      paginateData(
        10,
        0,
        'updated_at',
        true,
        // @ts-ignore
        currentQueryParam
      );
    }
  };

  const handlePageSizeChange = (pageSize: number) => {
    if (currentFilters === 'sorted') {
      // @ts-ignore
      paginateData(pageSize, table.state.pageIndex, currentSortedColumn, desc);
    } else if (currentFilters === 'filter') {
      paginateData(
        pageSize,
        // @ts-ignore
        table.state.pageIndex,
        currentFilterColumn,
        currentFilterDesc
      );
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row mb-4 justify-between">
        <div className="flex space-x-2">
          {isExcelSheetButtonVisible && (
            // @ts-ignore
            <DownloadExcelSheet
              apiUrl={excelSheetUrl}
              fileName={excelSheetName}
              totalCustomers={totalDataCount}
            />
          )}
          {showAssignButton && (
            <SuccessButton Icon={UserPlusIcon} onClick={handleAssign}>
              Assign
            </SuccessButton>
          )}
        </div>
        <div className="flex space-x-2">
          <div className="max-w-md mx-auto w-full justify-center sm:flex">
            <div className="relative flex items-center h-10 border border-gray-200 focus:border-primary-400 focus:ring focus:ring-primary-400 rounded-lg focus-within:shadow-md bg-white ">
              <input
                className="h-full w-full outline-none text-sm px-3 py-2 placeholder-gray-400 text-gray-700"
                type="text"
                onKeyPress={handleSearchInputKeyPress}
                value={searchQuery || ''}
                onChange={handleSearchInputChange}
                placeholder={`Search ${totalDataCount} records...`}
              />
              <button
                className="py-2 px-4 hover:bg-gray-200"
                onClick={handleSearchClick}
              >
                <MagnifyingGlassIcon className="w-6 h-6 text-gray-300"></MagnifyingGlassIcon>
              </button>
            </div>
          </div>
          {searchQuery && (
            <InfoButton onClick={handleClearSearch} IconRight={TrashIcon}>
              Clear
            </InfoButton>
          )}
          <ButtonGroup>
            <DataTableColumnTogglerButton table={table} />
          </ButtonGroup>
        </div>
      </div>
      <Card className="!p-0 overflow-hidden ">
        <div className="block min-w-full overflow-x-scroll overflow-y-hidden">
          <table
            {...getTableProps()}
            className="w-full overflow-hidden divide-y divide-gray-300 rounded-lg"
          >
            <thead className="bg-gray-100">
              {headerGroups.map((headerGroup: any) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="text-left text-xs md:text-lg font-semibold bg-primary-50 text-primary-500 capitalize"
                    >
                      <div onClick={() => handleFilterColumn(column)}>
                        <span {...column.getSortByToggleProps()}>
                          {column.render('Header')}
                          {column.id === currentSortedColumn
                            ? desc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </div>
                      <div
                        className="inline-flex group select-none"
                        onChange={() => onFilterChange(column)}
                      >
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className="bg-white divide-y divide-gray-200"
            >
              {rows.map((row: any, rowIndex: any) => {
                prepareRow(row);
                return (
                  <tr key={rowIndex} {...row.getRowProps()}>
                    {row.cells.map((cell: any, cellIndex: any) => {
                      return (
                        <td
                          key={cellIndex}
                          {...cell.getCellProps()}
                          className={`border-b border-r border-gray-300 py-4 pl-2 sm:pr-3 text-xs md:text-md text-gray-900 break-words md:whitespace-nowrap sm:pl-6 ${
                            cellIndex % 2 != 0 && 'bg-primary-50 bg-opacity-30'
                          }`}
                        >
                          <div
                            className={`bg-white py-2 ${
                              cellIndex % 2 != 0 &&
                              'bg-primary-50 bg-opacity-30'
                            }`}
                          >
                            {cell.render('Cell')}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {data.length >= 10 && (
          <TablePagination
            table={table}
            totalDataCount={totalDataCount}
            handlePageSizeChange={handlePageSizeChange}
          />
        )}
      </Card>
    </>
  );
};

export default Table;
