import Card from '../../../common/components/elements/Card';
import LinkedItem from '../../../common/components/elements/LinkedItem';
import ProgressBar from '../../../common/components/elements/form/ProgressBar';
import { H2, H3, H4, P } from '../../../common/components/elements/text';
import {
  ArrowLongRightIcon,
  HandThumbUpIcon,
  LightBulbIcon,
} from '@heroicons/react/24/outline';
import { FLAG_TYPES, FlagType } from '../../../utils/index';

export type flag = {
  performance: string;
  suggestion: string;
  value: any;
  type: string;
  color: string;
};

type Props = {
  name: string;
  flag: flag;
  href?: string;
};

const CreditScoreCard: React.FC<Props> = ({ name, flag, href = '' }) => {
  return (
    <Card className="w-60 max-w-auto flex flex-col items-center justify-center space-y-4 py-6 border border-gray-300 hover:border-primary-500">
      <H4 className="text-primary-500">{name}</H4>
      <H2
        className={`${
          flag.color === 'red' ? 'text-red-600' : 'text-green-600'
        } text-4xl md:text-4xl`}
      >
        {flag.value}
        {flag.type === FLAG_TYPES.percentage ? '%' : ''}
      </H2>

      {flag.type === FLAG_TYPES.percentage && (
        <>
          <ProgressBar percentage={`${flag.value}%`} color={flag.color} />
        </>
      )}

      <P
        className={`flex items-center justify-center w-16 h-16 text-center capitalize rounded-full font-bold text-gray-600 ${
          flag.color === 'red' ? 'bg-red-100' : 'bg-green-100'
        }`}
      >
        {flag.performance}
      </P>

      <div className="flex">
        {flag.color === 'red' ? (
          <LightBulbIcon className="h-5 w-5 mr-1" />
        ) : (
          <HandThumbUpIcon className="h-5 w-5 mr-1" />
        )}
        <P
          className={`font-semibold text-xs ${
            flag.color === 'red' ? 'text-red-500' : 'text-green-500'
          }`}
        >
          {flag.suggestion}
        </P>
      </div>

      <LinkedItem href={href}>
        <ArrowLongRightIcon className="w-10 h-10 text-gray-600 hover:bg-secondary2-50 hover:text-primary-500 hover:rounded-full" />
      </LinkedItem>
    </Card>
  );
};

export default CreditScoreCard;
