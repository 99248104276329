import * as y from 'yup';
import { ReceiptRefundIcon } from '@heroicons/react/24/outline';
import Form from '../../../common/components/form';
import { H3 } from '../../../common/components/elements/text';
import Modal from '../../../common/layouts/Modal';
import ListInput from '../../../common/components/elements/form/ListInput';
import { refundTypeOptions } from '../../../utils/constants/index';
import Input from '../../../common/components/elements/form/Input';
import { initialRefund } from '../../../common/types/user-model';
import TextArea from '../../../common/components/elements/form/TextArea';
import { showSuccessToast } from '../../../utils/toast';
import { RequestType, useMutation } from '../../../lib/react-query';
import { apiEndpoint } from '../../../utils/endpoint';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const refundSchema = y.object().shape({
  refund_amount: y.number().required(),
  refund_note: y.string(),
  refund_type: y.string().required(),
});

type PaymentLinkModalProps = {
  subscriptionId: string;
  refundAmount: number;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
};

const RefundModal: React.FC<PaymentLinkModalProps> = ({
  subscriptionId,
  refundAmount,
  isModalOpen,
  setIsModalOpen,
}) => {
  const { trackEvent } = useMatomo();
  const { mutateAsync } = useMutation(
    apiEndpoint.common.refundSubscription,
    RequestType.Post,
    '',
    true
  );

  initialRefund.refund_amount = refundAmount;

  return (
    <Modal
      isOpen={isModalOpen}
      dismissButton={true}
      panelClassName="!w-auto min-w-[500px]"
      closeModal={() => setIsModalOpen(false)}
    >
      <H3 className="text-center mb-4">Refund</H3>
      <Form
        initialValues={initialRefund}
        schema={refundSchema}
        submitButton={{
          text: 'Submit',
          IconRight: ReceiptRefundIcon,
          className: 'mx-auto disabled:opacity-50',
        }}
        onSubmit={async (value, reset) => {
          const payload = { subscription_id: subscriptionId, ...value };

          try {
            // @ts-ignore
            const { data, message } = await mutateAsync(payload);
            trackEvent({
              category: 'Subscription',
              action: 'Refund Initiated',
              name: `Amount: Rs${value.refund_amount}`,
              value: 1,
            });
            reset();
            showSuccessToast(message || 'Refund successful');
            setIsModalOpen(false);
          } catch (err) {}
        }}
      >
        <Form.Row>
          <ListInput
            options={refundTypeOptions}
            name="refund_type"
            label="Refund Type"
            required
          />
          <Input
            name="refund_amount"
            label="Refund Amount"
            placeholder="Enter amount to be refunded"
            required
          />
        </Form.Row>
        <TextArea
          name="refund_note"
          label="Description"
          placeholder="Reason for refund"
        />
      </Form>
    </Modal>
  );
};

export default RefundModal;
