import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  PaginationProps,
  Space,
  Table,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  Row,
  Col,
  Spin
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { IBlogsModel, initialBlogModel } from "./blogs-model";
import {
  DeleteApiCall,
  GetApiCall,
  UploadApiCall,
  UploadPutApiCall,
} from "../../../utils/apis";
import {
  blogsUrl,
  createBlogsUrl,
  deleteBlogsUrl,
  updateBlogsUrl,
  imageUrl
} from "../../../utils/constant";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  UploadOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import "./blogs-style.scss";
import ReactQuill from "react-quill";
import { RcFile } from "antd/es/upload";

const BlogsPage = () => {
  const [blogEditor, setBlogEditor] = useState("");
  const [loading, setLoading] = useState(false);

  const [blogList, setBlogList] = useState<IBlogsModel[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [isSelectedBlog, setSelectedBlog] =
    useState<IBlogsModel>(initialBlogModel);

  const [isBlogEdited, setIsBlogEdited] = useState(false);

  const currentPageSize = 8;
  let userData:any = localStorage.getItem("user")
  let token:any = JSON.parse(userData)?.token;

  const onPaginationChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setCurrentPage(current);
  };

  const editBlog = (editBlog: IBlogsModel) => {
    setIsBlogEdited(true);
    setSelectedBlog(editBlog);
    setBlogEditor(editBlog.blog_page_content);
    showModal();
  };

  useEffect(() => {
    getBlogList();
  }, []);
  const getBlogList = ()=>{
    setLoading(true)
    GetApiCall(blogsUrl,token).then((data) => {
      if (data.status === true) {
        setBlogList(data.allblogs);
        setLoading(false)
      }
      else {
        message.error(data.message)
        setLoading(false);
      }
    });
  }
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setIsBlogEdited(false);
    setSelectedBlog(initialBlogModel);
    setUploadFile((uploadFile) => []);
    setBlogEditor("");
    setOpen(false);
  };

  const formRef = React.createRef<FormInstance>();
  const [uploadFile, setUploadFile] = useState<UploadFile[]>([]);
  const onFinish = (values: any) => {
    if (isBlogEdited) {
      const blogFormData = new FormData();
      if (uploadFile.length > 0) {
        blogFormData.append("blog_page_image", uploadFile[0] as RcFile);
      } else {
        blogFormData.append("blog_page_image", isSelectedBlog.blog_page_image);
      }
      blogFormData.append("blog_page_id", "3");
      blogFormData.append("id", isSelectedBlog.id);
      blogFormData.append("blog_page_content", blogEditor);
      blogFormData.append("blog_page_heading", values.blog_page_heading);
      UploadPutApiCall(updateBlogsUrl, blogFormData, token).then((data) => {
        hideModal();
        setUploadFile((uploadFile) => []);
        setBlogEditor("");
        message.info(`Blog updated successfully`);
        getBlogList()
      });
    } else {
      const blogFormData = new FormData();
      blogFormData.append("blog_page_image", uploadFile[0] as RcFile);
      blogFormData.append("blog_page_id", "3");
      blogFormData.append("blog_page_content", blogEditor);
      blogFormData.append("blog_page_heading", values.blog_page_heading);
      UploadApiCall(createBlogsUrl, blogFormData, token).then((data) => {
        if(blogList.length){
          const newFAQ = [...blogList];
          newFAQ.unshift(data.blogCreated);
          setBlogList(newFAQ);
        }
        
        formRef.current!.resetFields();
        hideModal();
        setUploadFile((uploadFile) => []);
        setBlogEditor("");
        message.info(`Blog added successfully`);
      });
    }
  };

  const deleteBlog = (deleteBlog: IBlogsModel) => {
    setLoading(true)
    DeleteApiCall(deleteBlogsUrl, deleteBlog, token).then((data) => {
      if (data.status === true) {
        const removeSubscription = [...blogList];
        const deleteIndex = blogList.findIndex(
          (subIndex) => subIndex.id === deleteBlog.id
        );
        removeSubscription.splice(deleteIndex, 1);
        setBlogList(removeSubscription);
        setLoading(false)
        message.info(`Blog deleted successfully`);
      }
    });
  };

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      setUploadFile([...uploadFile, file]);
      return false;
    },
  };

  const columns: ColumnsType<IBlogsModel> = [
    {
      title: "Sl. No",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: number) =>
        (currentPage - 1) * 8 + index + 1,
        width:70
    },

    {
      title: "Heading",
      dataIndex: "blog_page_heading",
      key: "blog_page_heading",
    },
    {
      title: "Content",
      dataIndex: "blog_page_content",
      key: "blog_page_content",
      width:1000
    },
    {
      title: "Image",
      dataIndex: "blog_page_image",
      key: "blog_page_image",
      render: (text: any, record:any) => (
        record.blog_page_image ? <img style={{width:'100px'}} src={`${imageUrl}download/${record.blog_page_image}`}/> 
        : <img style={{width:'100px'}} src="./assets/images/no-image-icon.jpg"/>
      ),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_: any, record) => {
        const { confirm } = Modal;
        const showDeleteConfirm = () => {
          confirm({
            title: "Are you sure delete this Blogs?",
            icon: <ExclamationCircleFilled />,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
              deleteBlog(record);
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        };
        return (
          <>
            <Typography.Link onClick={() => editBlog(record)}>
              <EditOutlined style={{ padding: 14 }} />
            </Typography.Link>
            <Typography.Link onClick={showDeleteConfirm}>
              <DeleteOutlined />
            </Typography.Link>
          </>
        );
      },
    },
  ];

  return (
    <Spin spinning={loading} tip="Loading..." size="large"> 
    <div>
      <Row>
        <Col span={24} className="cms-main">
          <Button className="btn btn-primary add-button" type="primary" onClick={showModal}>
          <PlusCircleFilled />  Add
          </Button>
          <Modal
            title={isBlogEdited ? "Update Blog" : "Create Blog"}
            open={open}
            footer={null}
            onCancel={hideModal}
            destroyOnClose={true}
          >
            <Row>
              <Col span={24}>
                <Form
                  name="complex-form"
                  layout="vertical"
                  ref={formRef}
                  initialValues={{
                    blog_page_heading: isSelectedBlog.blog_page_heading,
                  }}
                  onFinish={onFinish}
                >
                  <Row>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <Form.Item label="Blog Heading">
                        <Space>
                          <Form.Item name="blog_page_heading" noStyle rules={[{ required: true, message: "Title is required" }]}>
                            <Input />
                          </Form.Item>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                      <Form.Item label="Blog Image">
                        <Upload {...uploadProps} listType="picture" maxCount={1}>
                          <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                      <Form.Item label="Blog Content" className="blog-content-area">
                        <ReactQuill theme="snow" value={blogEditor} onChange={setBlogEditor} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={{ span:8, offset: 16 }} md={24} sm={24} xs={24}>
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="primary" htmlType="submit" className="btn btn-primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>


      <div>
        <Table
          columns={columns}
          dataSource={blogList}
          pagination={{
            pageSize: currentPageSize,
            onChange: onPaginationChange,
            position: ['bottomCenter']
          }}
        />
      </div>
    </div>
    </Spin>
  );
};

export default BlogsPage;
