import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Table,
  Row,
  Col,
  Spin,
  Dropdown,
  MenuProps,
  DatePicker
} from "antd";
import { useNavigate } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { SubscriptionDetailsModel, ISubscriptionDetailsModel } from "./subscription_details-model";
import {
  GetApiCall,
  PostApiCall
} from "../../../utils/apis";
import {
  subscriptionDetailsUrl,
  userSusbcriptionDetailsFilterUrl
} from "../../../utils/constant";
import {
  FileExcelOutlined,
  DownOutlined
} from "@ant-design/icons";
import "./subscriptionDetails-style.scss";
import dayjs from "dayjs";
import { CSVLink } from "react-csv"

const { RangePicker } = DatePicker;

const SubscriptionDetailsPage = () => {
  let Navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [subscriptionDetailsList, setSubscriptionDetailsList] = useState<ISubscriptionDetailsModel[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const currentPageSize = 8;
  let userData: any = localStorage.getItem("user")
  let token: any = JSON.parse(userData)?.token;

  useEffect(() => {
    getSubscriptionDetails();
  }, []);
  const getSubscriptionDetails = () => {
    setLoading(true)
    GetApiCall(subscriptionDetailsUrl, token).then((data: SubscriptionDetailsModel) => {
      const users = data.user;
      setSubscriptionDetailsList(users);
      setLoading(false)
    });
  }
  const [open, setOpen] = useState(false);
  const [rangeOpen, setRangeOpen] = useState(false);

  const [isSelectedSubscription, setSelectedSubscription] = useState({purchase_date: "", expiry_date: "", plan_price: "", invoice_num: ""});

  const getSubscriptionDetailsExportData = () => {
    let csv_arr: any[] = [];
    let user_filtered_data = subscriptionDetailsList.map((x: any, index: any) => {
      let idx = (index + 1).toString();
      return {
        sno: idx,
        name: x.name,
        subs_plan: `${x.plan} (${x.plan_type} Month)`,
        neg_acc_notPaid: x.neg_acc_notPaid,
        neg_acc_paid: x.neg_acc_paid,
        total_neg_acc: x.total_neg_acc,
        dispute_notPaid: x.dispute_notPaid,
        disputePaid: x.disputePaid,
        total_dispute: x.total_dispute
      }
    })

    csv_arr = [...user_filtered_data];
    return csv_arr;
  }

  const onClick: MenuProps["onClick"] = ({ key }) => {
    
  };

  const actionOnClick = () => {
    
  };

  const getFilteredData = (filter: any) => {
    if (filter.start_date !== '' && filter.end_date !== '') {
      setLoading(true)
      PostApiCall(userSusbcriptionDetailsFilterUrl, filter, token).then((data: SubscriptionDetailsModel) => {
        if (data.status === true) {
          const users = data.user;
          setSubscriptionDetailsList(users);
          setRangeOpen(false);
          setLoading(false);
        }
        else {
          setRangeOpen(false);
          getSubscriptionDetails();
          setLoading(false);
        }
      })
    }
    else {
      setRangeOpen(false);
      getSubscriptionDetails();
    }
  }

  const onDateChange = (date:any, dateString:any) => {
    let startDate = dateString[0];
    let endDate = dateString[1];
    let dateFilerData = {
      start_date: startDate,
      end_date: endDate
    }
    getFilteredData(dateFilerData);
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setRangeOpen(true);
  };

  const handleOpenChange = (flag: boolean) => {
    setRangeOpen(flag);
  };

    const hideModal = () => {
      setOpen(false);
    };

  const headers = [
    { label: "S.No.", key: "sno" },
  { label: "Name", key: "name" },
  { label: "Subscription Plan", key: "subs_plan" },
  { label: "Negative Account Not Paid", key: "neg_acc_notPaid" },
  { label: "Negative Account Paid", key: "neg_acc_paid" },
  { label: "Total Negative Account", key: "total_neg_acc" },
  { label: "Disputes Identified Not Paid", key: "dispute_notPaid" },
  { label: "Disputes Identified Paid", key: "disputePaid" },
  { label: "Total Disputes Account", key: "total_dispute" }
  ];

  const items = [
    { key: "mobile", label: "Mobile" },
    { key: "doj", label: "Date of Joining" }
  ];

  const filterItems: MenuProps['items'] = [
    {key: '1',
    label: (
      <RangePicker onChange={onDateChange}/>
    ),
  }
  ];

  const columns: ColumnsType<ISubscriptionDetailsModel> = [
    {
      title: "Sl. No",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: number) =>
        (currentPage - 1) * currentPageSize + index + 1,
      width: 70
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
          <a onClick={() => Navigate(`/usersubscriptiondetails/${record.bcbUserId}`)}>
          {record.name}
          </a>
      ),
    },
    {
      title: "Subscription Plan",
      dataIndex: "plan",
      key: "plan",
      render: (text: any, record: any) => (
        <>
        <p>{record.plan}</p>
        <p>({record.plan_type} Month)</p>
        </>
      ),
    },
    {
      title: "Purchase Date",
      dataIndex: "purchase_date",
      key: "purchase_date",
      render: (text: any, record: any) => (
        record.purchase_date ? (
          <div style={{ width: 100 }}>{dayjs(text).format("D MMM, YYYY")}</div>
        ) : (
          "-"
        )
      ),
      width: 150
    },
    {
      title: "Purchase Time",
      dataIndex: "purchase_date",
      key: "purchase_date",
      render: (text: any, record: any) => (
        record.purchase_date ? (
          <div style={{ width: 100 }}>{dayjs(text).format("h:mm A")}</div>
        ) : (
          "-"
        )
      ),
      width: 150
    },
    {
      title: "Price",
      dataIndex: "plan_price",
      key: "plan_price",
      width: 150
    },
  ];

  return (
    <Spin spinning={loading} tip="Loading..." size="large">
      <div>
        <Row>
          <Col span={24} className="cms-main">
            <Col span={18}>
              <h4>Subscription Details</h4>
            </Col>
            <Col span={6}>
              <Space>
                <Dropdown
                  menu={{ items, onClick }}
                  dropdownRender={(menu) => (
                    <div onClick={() => actionOnClick()}>{menu}</div>
                  )}>
                  <a onClick={(e) => e.preventDefault()}>
                    Sort by <DownOutlined />
                  </a>
                </Dropdown>
                <Dropdown
                  menu={{ items: filterItems, onClick: handleMenuClick }} onOpenChange={handleOpenChange} trigger={['click']} open={rangeOpen}>
                  <a onClick={(e) => e.preventDefault()}>
                    Filter <DownOutlined />
                  </a>
                </Dropdown>
                <Button className="btn btn-primary add-button" type="primary">
                  <FileExcelOutlined />
                  <CSVLink
                    filename={"Subscription_Details.csv"}
                    data={getSubscriptionDetailsExportData()}
                    headers={headers}
                    className="btn btn-primary"
                  >
                    Download
                  </CSVLink>
                </Button>
              </Space>
            </Col>
            <Modal
            title={"Subscription Details"}
            open={open}
            footer={null}
            onCancel={hideModal}
            destroyOnClose={true}
          >
            <Row>
              <Col span={24}>
                <p><strong>Date of Purchase:</strong> {dayjs(isSelectedSubscription?.purchase_date).format("DD-MM-YYYY")}</p>
                <p><strong>Date of Expiry:</strong> {dayjs(isSelectedSubscription?.expiry_date).format("DD-MM-YYYY")}</p>
                <p><strong>Price:</strong> {isSelectedSubscription.plan_price}</p>
                <p><strong>Invoice Number:</strong> {isSelectedSubscription.invoice_num}</p>
              </Col>
            </Row>
          </Modal>
          </Col>
        </Row>


        <div>
          <Table
            columns={columns}
            dataSource={subscriptionDetailsList}
          />
        </div>
      </div>
    </Spin>
  );
};

export default SubscriptionDetailsPage;
