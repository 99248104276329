import React, { useEffect, useState } from "react";
import {
  FormInstance,
  PaginationProps,
  Table,
  UploadFile,
  Row,
  Col,
  Spin,
  MenuProps
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { IUsersSubscriptionDetailsModel } from "./usersSubscriptionDetails-model";
import {
  GetApiCall
} from "../../../utils/apis";
import {
  getUserAllPlansUrl
} from "../../../utils/constant";

import "./userSubscriptionDetails-style.scss";
import dayjs from "dayjs";

const UserSubscriptionDetailsPage = () => {
  const [loading, setLoading] = useState(false);

  const [planList, setPlanList] = useState<IUsersSubscriptionDetailsModel[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const currentPageSize = 8;
  let userData: any = localStorage.getItem("user")
  let token: any = JSON.parse(userData)?.token;

  const onPaginationChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setCurrentPage(current);
  };

  let UserId = window.location.pathname.split('/')[2]

  useEffect(() => {
    getUserPlanList();
  }, []);
  const getUserPlanList = () => {
    setLoading(true)
    GetApiCall(getUserAllPlansUrl+`/${UserId}`, token).then((data) => {
      if (data.status === true) {
        const users = data.user;
        setPlanList(users);
        setLoading(false)
      }
      else {
        setPlanList([]);
        setLoading(false)
      }
    });
  }
  const [open, setOpen] = useState(false);


  const getUserExportData = () => {
    let csv_arr: any[] = [];
    if (planList.length > 0) {
      let user_filtered_data = planList.map((x: any, index: any) => {
        let idx = (index + 1).toString();
        return {
          sno: idx,
          mobile_num: x.mobile_num,
          createdAt: dayjs(x.createdAt).format("D MMM, YYYY")
        }
      })

      csv_arr = [...user_filtered_data];
    }
    return csv_arr;
  }

  const formRef = React.createRef<FormInstance>();
  const [uploadFile, setUploadFile] = useState<UploadFile[]>([]);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    
  };

  const actionOnClick = () => {
    
  };

  const headers = [
    { label: "S.No.", key: "sno" },
  { label: "Phone Number", key: "mobile_num" },
  { label: "Joining Date", key: "createdAt" }
  ];

  const items = [
    { key: "mobile", label: "Mobile" },
    { key: "doj", label: "Date of Joining" }
  ];

  const columns: ColumnsType<IUsersSubscriptionDetailsModel> = [
    {
      title: "Sl. No",
      dataIndex: "sno",
      key: "id",
      render: (text: any, record: any, index: number) =>
        (currentPage - 1) * currentPageSize + index + 1,
      width: 70
    },
    {
      title: "Plan Group",
      dataIndex: "plan_group",
      key: "plan_group",
    },
    {
      title: "Plan Type",
      dataIndex: "plan_type",
      key: "plan_type"
    },
    {
      title: "Purchase Date",
      dataIndex: "purchase_date",
      key: "purchase_date",
      render: (text: any, record: any) => (
        record.purchase_date ? (
          <div style={{ width: 100 }}>{dayjs(text).format("D MMM, YYYY")}</div>
        ) : (
          "-"
        )
      ),
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (text: any, record: any) => (
        record.expiry_date ? (
          <div style={{ width: 100 }}>{dayjs(text).format("D MMM, YYYY")}</div>
        ) : (
          "-"
        )
      ),
    },
    {
      title: "Plan Price",
      dataIndex: "plan_price",
      key: "plan_price"
    },
  ];

  return (
    <Spin spinning={loading} tip="Loading..." size="large">
      <div>
        <Row>
          <Col span={24} className="cms-main">
            <Col span={18}>
              <h4>User Subscription Details</h4>
            </Col>
            <Col span={6}>
            </Col>
          </Col>
        </Row>


        <div>
          <Table
            columns={columns}
            dataSource={planList}
            pagination={{
              pageSize: currentPageSize,
              onChange: onPaginationChange,
              position: ['bottomCenter']
            }}
          />
        </div>
      </div>
    </Spin>
  );
};

export default UserSubscriptionDetailsPage;
