import { useEffect, useRef, useState } from 'react';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import {
  useCustomer,
  useCustomerWithInvalidCrifPulls,
} from '../../common/hooks/use-customer';
import Table from '../../common/components/data-table/paginateTable';
import { CompleteProfileColumn } from '../../common/components/data-table/TableColumn';
import { dashboardState, role } from '../../utils/constants/index';
import Section from '../../common/layouts/PageSection';
import { useRole } from '../../common/hooks/use-user';
import { apiEndpoint } from '../../utils/endpoint';
import { useStatistics } from '../../common/hooks/use-statistics';
import axios from '../../lib/axios';
import { withAuth } from '../../common/hoc/withAuth';

const CrifPullFailed = () => {
  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);
  const skipResetRef = useRef(false);
  const { currentUserRole } = useRole();
  const columns = CompleteProfileColumn(downloadLinkRef);

  const { data: stats, isLoading: statsLoading } = useStatistics();
  const { data: customersData, isLoading } = useCustomerWithInvalidCrifPulls();
  const [data, setData] = useState(
    customersData?.length > 0 ? customersData : []
  );
  const [originalData, setOriginalData] = useState(data);
  const [totalDataCount, setTotalDataCount] = useState(0);

  useEffect(() => {
    if (!isLoading && !statsLoading) {
      setData(customersData?.length > 0 ? customersData : []);
      setTotalDataCount(stats?.customers_with_invalid_crif_pulls_count);
    }
  }, [customersData, isLoading, statsLoading]);

  const resetData = () => {
    skipResetRef.current = true;
    setData(originalData);
  };

  const paginateData = async (pageSize: number, pageIndex: number) => {
    const skip = pageSize * pageIndex;
    const limit = pageSize;

    const { data } = await axios.get(
      apiEndpoint.common.getCustomerWithFailedCrifPull(
        skip,
        limit,
        'updated_at',
        true
      )
    );

    setData([]);
    // @ts-ignore
    setData(data);
    // @ts-ignore
    setOriginalData(data);
  };

  const searchCustomer = async (
    name: string,
    pageIndex: number = 0,
    pageSize: number = 10
  ) => {
    if (name === '') {
      paginateData(pageSize, pageIndex);
    } else {
      const { data } = await axios.get(
        apiEndpoint.common.searchCustomer.customerWithFailedCrifPull(name)
      );
      setData([]);
      // @ts-ignore
      setData(data);
      // @ts-ignore
      setOriginalData(data);
    }
  };

  if (isLoading) {
    return <FullPageLoader isOpen={true} className="text-brand-orange-500" />;
  }

  const isExcelSheetButtonVisible =
    currentUserRole === role.owner || currentUserRole === role.manager;

  return (
    <Section
      title={dashboardState.customers_with_invalid_crif_pulls_count.status}
    >
      <Table
        columns={columns}
        data={data}
        resetData={resetData}
        isExcelSheetButtonVisible={isExcelSheetButtonVisible}
        paginateData={paginateData}
        searchCustomer={searchCustomer}
        totalDataCount={totalDataCount}
        excelSheetUrl={`stats?customers_with_invalid_crif_pulls=true&`}
        excelSheetName={`Failed_CRIF_Pulls-${(new Date().toLocaleDateString()).replace(/[:\\/?*\[\]]/g, "-")}`}

      />
    </Section>
  );
};

export default withAuth()(CrifPullFailed);
