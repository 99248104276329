import React, { useRef, useEffect, useState } from 'react';
import type { ChartData, ChartArea } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { P } from '../../../common/components/elements/text';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

interface DataLabelContext {
  active: boolean;
  dataIndex: number;
  dataset: {
    data: number[];
    label: string;
  };
}

function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const colorStart = '#000080';
  const colorMid = '#5353c6';
  const colorEnd = '#5b5bef';

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

type GradientChartProps = {
  labels: string[];
  datasets: number[];
  vintageMonth?: number[];
};

const CreditReportChart: React.FC<GradientChartProps> = ({
  labels,
  datasets,
  vintageMonth = []
}) => {
  const chartRef = useRef<ChartJS>(null);
  const [chartData, setChartData] = useState<ChartData<'bar'>>({
    datasets: [],
  });

  const data = {
    labels,
    datasets: [
      {
        label: 'Credit Score',
        data: datasets,
        pointRadius: 6,
        lineTension: 0.5,
        spanGaps: true,
        pointRotation: 180,
        pointHoverRadius: 8,
      },
    ],
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;

    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        borderColor: createGradient(chart.ctx, chart.chartArea),
      })),
    };

    // @ts-ignore
    setChartData(chartData);
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
        text: 'Credit Score',
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        opacity: 0,
        callbacks: {
          label: function (context: DataLabelContext) {
            const i = context.dataIndex;
            const prev = context.dataset.data[i - 1];
            const diff =
              prev !== undefined ? prev - context.dataset.data[i] : 0;
            const glyph = diff < 0 ? '▲' : diff > 0 ? '▼' : '◆';

            return (
              context.dataset.label +
              ': ' +
              context.dataset.data[i] +
              ' (' +
              glyph +
              Math.abs(diff) +
              ')'
            );
          },
        },
      },
    },
    animations: {
      tension: {
        duration: 5000,
        easing: 'linear',
        from: 1,
        to: 0,
        loop: true,
      },
      radius: {
        duration: 400,
        easing: 'linear',
        loop: (context: DataLabelContext) => context.active,
      },
    },
    scales: {
      x: {
        responsive: true,
        size: 10,
        ticks: {
          callback: function (value: number, index: number, values: number[]) {
            return `M${vintageMonth[value]}`;
          },
        },
        border: {
          color: 'gray',
        },
        grid: {
          tickColor: '#000080',
        },
      },
      y: {
        stacked: true,
        max: 900,
        ticks: {
          color: 'gray',
        },
        border: {
          color: 'gray',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
  };

  return (
    <>
      <div className="w-full lg:w-3/4 bg-white rounded-lg shadow-md border border-gray-300 p-4 lg:p-10 h-[180px] sm:h-[250px] lg:h-[400px] xl:h-[500px] items-center">
        {/* @ts-ignore */}
        <Chart ref={chartRef} type="line" data={chartData} options={options} />
        <div className="flex justify-center items-center">
          <P className="text-xs text-gray-400">Powered By</P>
          <img
            src="../assets/images/crif_banner.png"
            alt="CRIF"
            className="h-12"
          />
        </div>
      </div>
    </>
  );
};

export default CreditReportChart;
