import {
  formatDate,
  formatDateWithTime,
  formatDateWithTimeAndSeconds,
  formatMoney,
} from '../../../utils/formatter';
import React, { useMemo } from 'react';
import moment from 'moment';
import {
  DocumentUploadedCell,
  ProfileCompletionCell,
  EmailVerificationCell,
  LoanRequestCell,
  SubscriptionCell,
  SubscriptionEndDate,
} from './TableCell';
import {
  DateRangeColumnFilter,
  DocumentUploadedFilter,
  documentUploadedFilter,
  SingleDateColumnFilter,
  ActionButtons,
  profileCompletionFilter,
  ProfileCompletionFilter,
  CBLRequestedFilter,
  AccountStatusFilter,
  SubscriptionFilter,
  subscriptionFilter,
  CreditScoreFilter,
} from './TableFilter';
import { H5, P } from '../elements/text';
import { TableHeaderBuilder } from 'react-table-utils';
import { Customer } from '@appTypes/user-model';
import LinkedItem from '../elements/LinkedItem';
import Tag from '../elements/Tag';
import {
  ArrowDownTrayIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { DocumentInfoField } from 'components/userprofile/customerProfile';
import { handleCrifDownload } from '../../../modules/creditScore/CrifHistorySection';
import { Tooltip } from 'antd';
import { filterValidAnalyses } from '../../../utils/utils';

type ColumnInstance<T> = {
  getHeaderProps: any;
  getSortByToggleProps: any;
  getGroupByToggleProps: any;
  id: string;
  Header: string | React.ReactNode;
  accessor: keyof T;
  canGroupBy?: boolean;
  isGrouped?: boolean;
  isSorted?: boolean;
  isSortedDesc?: boolean;
  canFilter?: boolean;
  render: (key: any) => React.ReactNode;
};

type Column = {
  Header: string;
  accessor: string;
  filter?: string;
  Filter?: React.ElementType;
  aggregate?: string;
  Aggregated?: React.FC<{ value: any }>;
};

const commonColumns = [
  {
    Header: 'Name',
    accessor: (row: any) =>
      `${row.profile.first_name || ''} ${row.profile.middle_name || ''} ${
        row.profile.last_name || ''
      }`,
    Cell: ({ value, row }: any) => (
      <div className="flex flex-col gap-2">
        <div className="flex">
          <LinkedItem href={`/customer/${row.original.id}`}>
            <P className="hover:underline">{value}</P>
          </LinkedItem>
          {row.original.is_profile_complete ? (
            <Tooltip
              title="Profile is complete"
              className={`${value && '-translate-x-1/4'}`}
            >
              <CheckIcon className="text-green-700 bg-emerald-200 h-5 w-5 rounded-full ml-2" />
            </Tooltip>
          ) : (
            <Tooltip title="Profile is incomplete" className="-translate-x-1/4">
              <XMarkIcon className="text-red-700 bg-red-300 h-5 w-5 rounded-full ml-2" />
            </Tooltip>
          )}
        </div>
      </div>
    ),
    disableFilters: true,
    sticky: 'left',
  },
  // {
  //   Header: 'Email',
  //   accessor: 'profile.email',
  //   aggregate: 'uniqueCount',
  //   Cell: ({ value }: any) => (
  //     <LinkedItem href={`mailto:${value}`}>
  //       <P className="hover:underline">{value}</P>
  //     </LinkedItem>
  //   ),
  //   disableFilters: true,
  // },
  {
    Header: 'SignUp Date',
    accessor: 'created_at',
    disableFilters: true,
    // Filter: DateRangeColumnFilter,
    // filter: (rows: any[], id: any, filterValue: any) => {
    //   const { startDate, endDate } = filterValue || {};
    //   if (!startDate || !endDate) return rows;

    //   return rows.filter((row) => {
    //     const dateValue = row.values[id];
    //     return dateValue >= startDate && dateValue <= endDate;
    //   });
    // },
    Cell: ({ value }: any) => <P>{formatDateWithTimeAndSeconds(value)}</P>,
  },
  {
    Header: 'Updated Date',
    accessor: 'updated_at',
    // filter: 'between',
    Cell: ({ row }: any) => {
      if (row.original?.updated_at > row.original?.profile?.updated_at) {
        return <P>{formatDateWithTimeAndSeconds(row.original?.updated_at)}</P>;
      }
      return (
        <P>{formatDateWithTimeAndSeconds(row.original?.profile?.updated_at)}</P>
      );
    },
    disableFilters: true,
    // Filter: SingleDateColumnFilter,
    // filter: (rows: any[], id: any, filterValue: any) => {
    //   const startDate = filterValue || {};
    //   if (!startDate) return rows;

    //   return rows.filter((row) => {
    //     const dateA = formatDateWithTime(row?.original?.profile?.updated_at);
    //     const dateB = moment(startDate).format('DD-MM-YYYY');
    //     return dateA === dateB;
    //   });
    // },
  },
  {
    Header: 'Phone  ',
    accessor: 'profile.phone_number',
    Cell: ({ value }: any) => (
      <LinkedItem href={`https://wa.me/${value}`}>
        <P>{value}</P>
      </LinkedItem>
    ),
    disableFilters: true,
  },
  // {
  //   Header: 'Profile Completion',
  //   accessor: 'is_profile_complete',
  //   Cell: ProfileCompletionCell,
  //   Filter: ProfileCompletionFilter,
  //   filter: profileCompletionFilter,
  // },
  // {
  //   Header: 'Document Uploaded',
  //   accessor: 'is_profile_complete-1',
  //   Cell: DocumentUploadedCell,
  //   Filter: DocumentUploadedFilter,
  //   filter: documentUploadedFilter,
  // },
];

const creditScoreColumn = [
  {
    Header: 'Credit Score',
    accessor: 'score',
    Cell: ({ row }: any) => {
      const creditLen = row?.original?.analyses ? row?.original?.analyses : [];

      if (creditLen.length == 0) return <P>-</P>;
      creditLen.sort((a: any, b: any) => {
        return (
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );
      });

      const creditAnalysis = filterValidAnalyses(creditLen);
      if (creditAnalysis.length == 0) return <P>-</P>;

      return <P>{creditAnalysis[0]?.score}</P>;
    },
    Filter: CreditScoreFilter
  },
  {
    Header: 'Latest Crif Pull Date',
    accessor: 'latest_crif_pull_date',
    // filter: (rows: any[], id: any, filterValue: any) => {
    //   const startDate = filterValue || {};
    //   if (!startDate) return rows;

    //   return rows.filter((row) => {
    //     if (!row?.original?.analyses) return false;
    //     const dateValue =
    //       row?.original?.analyses[row?.original?.analyses.length - 1]
    //         ?.report_date;
    //     return dateValue == startDate;
    //   });
    // },
    // Filter: SingleDateColumnFilter,
    disableFilters: true,
    Cell: ({ row }: any) => {
      const creditLen = row?.original?.analyses ? row?.original?.analyses : [];
      if (creditLen.length == 0) return <div>-</div>;
      creditLen.sort((a: any, b: any) => {
        return (
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );
      });
      return <P>{formatDate(creditLen[0]?.report_date)}</P>;
    },
  },
  // {
  //   Header: 'CRIF Pull Count',
  //   accessor: 'analyses.length',
  //   Cell: ({ row }: any) => {
  //     const count = row?.original?.analyses
  //       ? row?.original?.analyses.length
  //       : 0;
  //     return <P>{count}</P>;
  //   },
  //   filter: 'equals',
  //   disableFilters: true,
  //   sortType: (rowA: any, rowB: any, columnId: any) => {
  //     const countA = rowA.original?.analyses
  //       ? rowA.original?.analyses.length
  //       : 0;
  //     const countB = rowB.original?.analyses
  //       ? rowB.original?.analyses.length
  //       : 0;

  //     return countA - countB;
  //   },
  // },
  // {
  //   Header: 'CRIF Pull History',
  //   accessor: 'analyses',
  //   Cell: ({ row }: any) => {
  //     const creditLen = row?.original?.analyses ? row?.original?.analyses : [];
  //     if (creditLen.length == 0) return <div>-</div>;
  //     creditLen.sort((a: any, b: any) => {
  //       return (
  //         new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  //       );
  //     });

  //     return creditLen.map((item: any, index: number) => {
  //       return (
  //         <P key={index}>
  //           score: {item.score}, date: {item.report_date}
  //         </P>
  //       );
  //     });
  //   },
  //   disableFilters: true,
  // },
  // {
  //   Header: 'Account Status',
  //   accessor: 'profile.account_status',
  //   Cell: EmailVerificationCell,
  //   Filter: AccountStatusFilter,
  // },
  // {
  //   Header: 'Loan Request',
  //   accessor: 'is_cbl_requested',
  //   Cell: LoanRequestCell,
  //   Filter: CBLRequestedFilter,
  // },
  {
    Header: 'Subscription',
    accessor: 'subscriptions',
    Cell: SubscriptionCell,
    Filter: SubscriptionFilter,
    // filter: subscriptionFilter,
  },
  {
    Header: 'Subscription End Date',
    accessor: 'subscription-1',
    Cell: SubscriptionEndDate,
    disableFilters: true,
    // Filter: SubscriptionFilter,
    // filter: subscriptionFilter,
  },
  {
    Header: 'Active Accounts',
    accessor: 'active_account',
    Cell: ({ row }: any) => {
      const creditLen = row?.original?.analyses ? row?.original?.analyses : [];

      if (creditLen.length == 0) return <P>-</P>;
      creditLen.sort((a: any, b: any) => {
        return (
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );
      });

      const creditAnalysis = filterValidAnalyses(creditLen);
      if (creditAnalysis.length == 0) return <P>-</P>;

      return <P>{creditAnalysis[0]?.active_accounts_count}</P>;
    },
    disableFilters: true,
  },
  {
    Header: 'Overdue Accounts',
    accessor: 'overdue_account',
    Cell: ({ row }: any) => {
      const creditLen = row?.original?.analyses ? row?.original?.analyses : [];

      if (creditLen.length == 0) return <P>-</P>;
      creditLen.sort((a: any, b: any) => {
        return (
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );
      });

      const creditAnalysis = filterValidAnalyses(creditLen);
      if (creditAnalysis.length == 0) return <P>-</P>;

      return <P>{creditAnalysis[0]?.overdue_accounts_count}</P>;
    },
    disableFilters: true,
  },
];

export const CompleteProfileColumn = (downloadLinkRef: any) => {
  // @ts-ignore
  const columns: ColumnInstance[] = React.useMemo(
    () => [
      ...commonColumns,
      ...creditScoreColumn,
      // {
      //   Header: 'Invoice',
      //   accessor: 'invoice',
      //   Cell: ({ row }: any) => (
      //     <div>
      //       {row?.original?.subscription &&
      //       Object.keys(row?.original?.subscription).length > 0 ? (
      //         <>
      //           <LinkedItem
      //             href=""
      //             className={`mt-2`}
      //             onClick={() =>
      //               handleInvoiceDownload(
      //                 row?.original.subscription?.invoice_number,
      //                 downloadLinkRef
      //               )
      //             }
      //           >
      //             <ArrowDownTrayIcon className="h-6 w-6 text-primary-500" />
      //           </LinkedItem>
      //           <a ref={downloadLinkRef} style={{ display: 'none' }} />
      //         </>
      //       ) : (
      //         '-'
      //       )}
      //     </div>
      //   ),
      //   disableFilters: true,
      // },
      // {
      //   Header: 'Assigned Executive',
      //   accessor: 'executive_email',
      //   Cell: ({ value }: any) => (
      //     <>{value !== '' ? <P>{value}</P> : <P>-</P>}</>
      //   ),
      //   disableFilters: true,
      // },
      {
        Header: 'View Profile',
        accessor: 'action',
        Cell: ({ row }: any) => (
          <ActionButtons id={row.original.id} prefix="customer" />
        ),
        disableFilters: true,
      },
    ],
    []
  );
  return columns;
};

export const IncompleteProfileColumn = () => {
  // @ts-ignore
  const columns: ColumnInstance[] = React.useMemo(
    () => [
      ...commonColumns,
      // {
      //   Header: 'Assigned Executive',
      //   accessor: 'executive_email',
      //   Cell: ({ value }: any) => (
      //     <>{value !== '' ? <P>{value}</P> : <P>-</P>}</>
      //   ),
      //   disableFilters: true,
      // },
      {
        Header: 'View Profile',
        accessor: 'action',
        Cell: ({ row }: any) => (
          <ActionButtons id={row.original.id} prefix="customer" />
        ),
        disableFilters: true,
      },
    ],
    []
  );

  return columns;
};

export const ManagerColumn = (href: string) => {
  const columns = new TableHeaderBuilder<Customer>()
    .add('id', (col) =>
      col
        .header('View Profile')
        .cell((value) => <ActionButtons id={value} prefix={href} />)
    )
    .add('combinedName', (col) =>
      col.header('Name').cell((value: any) => <P>{value}</P>)
    )
    .add('profile.email', (col) =>
      col.header('Email').cell((value: any) => <P>{value}</P>)
    )
    .add('profile.phone_number', (col) =>
      col.header('Phone Number').cell((value: any) => <P>{value}</P>)
    )
    .add('profile.role', (col) =>
      col
        .header('Role')
        .cell((value: any) => (
          <Tag className="bg-gray-200 border-gray-900 text-gray-900">
            {value}
          </Tag>
        ))
    )
    .add('profile.account_status', (col) =>
      col
        .header('Account Status')
        .cell((value: any) => (
          <>
            {value === 'active' ? (
              <Tag className="bg-green-100 border-green-500 text-green-500">
                {value}
              </Tag>
            ) : (
              <Tag className="bg-red-100 border-red-500 text-red-500">
                {value}
              </Tag>
            )}
          </>
        ))
    )
    .build();

  return columns;
};

export const ExecutiveColumn = (
  toggleCheckbox: (id: string) => void,
  href: string = 'executive'
) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Select',
        accessor: 'id',
        Cell: ({ row }: any) => (
          <input
            type="checkbox"
            checked={row.isSelected}
            onChange={() => toggleCheckbox(row)}
            className="h-5 w-5 text-center border-gray-300 rounded text-blue-500 focus:ring-blue-400 cursor-pointer ring-blue-300 focus:ring-2 focus:outline-none"
          />
        ),
        disableFilters: true,
      },
      {
        Header: 'Assigned Manager',
        accessor: 'manager_email',
        Cell: ({ value }: any) => (
          <>{value !== '' ? <P>{value}</P> : <P>-</P>}</>
        ),
        disableFilters: true,
      },
      {
        Header: 'Name',
        accessor: (row: any) =>
          `${row.profile.first_name || ''} ${row.profile.middle_name || ''} ${
            row.profile.last_name || ''
          }`,
        Cell: ({ value, row }: any) => (
          <LinkedItem href={`/customer/${row.original.id}`}>
            <P className="hover:underline">{value}</P>
          </LinkedItem>
        ),
        disableFilters: true,
        sticky: 'left',
      },
      {
        Header: 'Email',
        accessor: 'profile.email',
        aggregate: 'uniqueCount',
        Cell: ({ value }: any) => (
          <LinkedItem href={`mailto:${value}`}>
            <P>{value}</P>
          </LinkedItem>
        ),
        disableFilters: true,
      },
      {
        Header: 'SignUp Date',
        accessor: 'created_at',
        Cell: ({ value }: any) => <P>{formatDateWithTimeAndSeconds(value)}</P>,
        disableFilters: true,
      },
      {
        Header: 'Updated Date',
        accessor: 'updated_at',
        // filter: 'between',
        Cell: ({ row }: any) => {
          if (row.original?.updated_at > row.original?.profile?.updated_at) {
            return (
              <P>{formatDateWithTimeAndSeconds(row.original?.updated_at)}</P>
            );
          }
          return (
            <P>
              {formatDateWithTimeAndSeconds(row.original?.profile?.updated_at)}
            </P>
          );
        },
        Filter: SingleDateColumnFilter,
        filter: (rows: any[], id: any, filterValue: any) => {
          const startDate = filterValue || {};
          if (!startDate) return rows;

          return rows.filter((row) => {
            const dateA = formatDateWithTime(
              row?.original?.profile?.updated_at
            );
            const dateB = moment(startDate).format('DD-MM-YYYY');
            return dateA === dateB;
          });
        },
      },
      {
        Header: 'Phone Number',
        accessor: 'profile.phone_number',
        Cell: ({ value }: any) => (
          <LinkedItem href={`https://wa.me/${value}`}>
            <P>{value}</P>
          </LinkedItem>
        ),
        disableFilters: true,
      },
      {
        Header: 'Account Status',
        accessor: 'profile.account_status',
        Cell: EmailVerificationCell,
        Filter: AccountStatusFilter,
      },
      {
        Header: 'View Profile',
        accessor: 'action',
        Cell: ({ row }: any) => (
          <ActionButtons id={row.original.id} prefix={href} />
        ),
        disableFilters: true,
      },
    ],
    []
  );

  return columns;
};

export const AssignExecutiveColumn = (href: string = 'executive') => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row: any) =>
          `${row.profile.first_name || ''} ${row.profile.middle_name || ''} ${
            row.profile.last_name || ''
          }`,
        Cell: ({ value, row }: any) => (
          <LinkedItem href={`/customer/${row.original.id}`}>
            <P className="hover:underline">{value}</P>
          </LinkedItem>
        ),
        disableFilters: true,
        sticky: 'left',
      },
      {
        Header: 'Email',
        accessor: 'profile.email',
        aggregate: 'uniqueCount',
        Cell: ({ value }: any) => (
          <LinkedItem href={`mailto:${value}`}>
            <P>{value}</P>
          </LinkedItem>
        ),
        disableFilters: true,
      },
      {
        Header: 'SignUp Date',
        accessor: 'created_at',
        Cell: ({ value }: any) => <P>{formatDateWithTimeAndSeconds(value)}</P>,
        disableFilters: true,
      },
      {
        Header: 'Updated Date',
        accessor: 'updated_at',
        // filter: 'between',
        Cell: ({ row }: any) => {
          if (row.original?.updated_at > row.original?.profile?.updated_at) {
            return (
              <P>{formatDateWithTimeAndSeconds(row.original?.updated_at)}</P>
            );
          }
          return (
            <P>
              {formatDateWithTimeAndSeconds(row.original?.profile?.updated_at)}
            </P>
          );
        },
        Filter: SingleDateColumnFilter,
        filter: (rows: any[], id: any, filterValue: any) => {
          const startDate = filterValue || {};
          if (!startDate) return rows;

          return rows.filter((row) => {
            const dateA = formatDateWithTime(
              row?.original?.profile?.updated_at
            );
            const dateB = moment(startDate).format('DD-MM-YYYY');
            return dateA === dateB;
          });
        },
      },
      {
        Header: 'Phone Number',
        accessor: 'profile.phone_number',
        Cell: ({ value }: any) => (
          <LinkedItem href={`https://wa.me/${value}`}>
            <P>{value}</P>
          </LinkedItem>
        ),
        disableFilters: true,
      },
      {
        Header: 'Account Status',
        accessor: 'profile.account_status',
        Cell: EmailVerificationCell,
        Filter: AccountStatusFilter,
      },
      {
        Header: 'View Profile',
        accessor: 'action',
        Cell: ({ row }: any) => (
          <ActionButtons id={row.original.id} prefix={href} />
        ),
        disableFilters: true,
      },
    ],
    []
  );

  return columns;
};

const commonColumnsForAssign = [
  {
    Header: 'Name',
    accessor: (row: any) =>
      `${row.profile.first_name || ''} ${row.profile.middle_name || ''} ${
        row.profile.last_name || ''
      }`,
    Cell: ({ value, row }: any) => (
      <LinkedItem href={`/customer/${row.original.id}`}>
        <P className="hover:underline">{value}</P>
      </LinkedItem>
    ),
    disableFilters: true,
    sticky: 'left',
  },
  {
    Header: 'Credit Score',
    accessor: 'score',
    Cell: ({ row }: any) => {
      const creditLen = row?.original?.analyses ? row?.original?.analyses : [];

      if (creditLen.length == 0) return <P>-</P>;
      creditLen.sort((a: any, b: any) => {
        return (
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );
      });

      return <P>{creditLen[0]?.score}</P>;
    },
    disableFilters: true,
  },
  {
    Header: 'Subscription',
    accessor: 'subscriptions',
    Cell: SubscriptionCell,
    disableFilters: true,
    // Filter: SubscriptionFilter,
    // filter: subscriptionFilter,
  },
  {
    Header: 'Loan Request',
    accessor: 'is_cbl_requested',
    Cell: LoanRequestCell,
    Filter: CBLRequestedFilter,
  },
  {
    Header: 'Profile Completion',
    accessor: 'is_profile_complete',
    Cell: ProfileCompletionCell,
    Filter: ProfileCompletionFilter,
    filter: profileCompletionFilter,
  },
  {
    Header: 'SignUp Date',
    accessor: 'created_at',
    Filter: DateRangeColumnFilter,
    filter: (rows: any[], id: any, filterValue: any) => {
      const { startDate, endDate } = filterValue || {};
      if (!startDate || !endDate) return rows;

      return rows.filter((row) => {
        const dateValue = row.values[id];
        return dateValue >= startDate && dateValue <= endDate;
      });
    },
    Cell: ({ value }: any) => <P>{formatDateWithTimeAndSeconds(value)}</P>,
  },
  {
    Header: 'Updated Date',
    accessor: 'updated_at',
    Cell: ({ row }: any) => {
      if (row.original?.updated_at > row.original?.profile?.updated_at) {
        return <P>{formatDateWithTimeAndSeconds(row.original?.updated_at)}</P>;
      }
      return (
        <P>{formatDateWithTimeAndSeconds(row.original?.profile?.updated_at)}</P>
      );
    },
    Filter: SingleDateColumnFilter,
    filter: (rows: any[], id: any, filterValue: any) => {
      const startDate = filterValue || {};
      if (!startDate) return rows;

      return rows.filter((row) => {
        const dateA = formatDateWithTime(row?.original?.profile?.updated_at);
        const dateB = moment(startDate).format('DD-MM-YYYY');
        return dateA === dateB;
      });
    },
  },
  {
    Header: 'Email',
    accessor: 'profile.email',
    aggregate: 'uniqueCount',
    Cell: ({ value }: any) => (
      <LinkedItem href={`mailto:${value}`}>
        <P>{value}</P>
      </LinkedItem>
    ),
    disableFilters: true,
  },
  {
    Header: 'Phone Number',
    accessor: 'profile.phone_number',
    Cell: ({ value }: any) => (
      <LinkedItem href={`https://wa.me/${value}`}>
        <P>{value}</P>
      </LinkedItem>
    ),
    disableFilters: true,
  },
  {
    Header: 'Document Uploaded',
    accessor: 'is_profile_complete-1',
    Cell: DocumentUploadedCell,
    Filter: DocumentUploadedFilter,
    filter: documentUploadedFilter,
  },
  {
    Header: 'Latest Crif Pull Date',
    accessor: 'latest_crif_pull_date',
    filter: (rows: any[], id: any, filterValue: any) => {
      const startDate = filterValue || {};
      if (!startDate) return rows;

      return rows.filter((row) => {
        if (!row?.original?.analyses) return false;
        const dateValue =
          row?.original?.analyses[row?.original?.analyses.length - 1]
            ?.report_date;
        return dateValue == startDate;
      });
    },
    Filter: SingleDateColumnFilter,
    Cell: ({ row }: any) => {
      const creditLen = row?.original?.analyses ? row?.original?.analyses : [];
      if (creditLen.length == 0) return <div>-</div>;
      creditLen.sort((a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });

      return <P>{formatDate(creditLen[0]?.report_date)}</P>;
    },
  },
  {
    Header: 'CRIF Pull Count',
    accessor: 'analyses.length',
    Cell: ({ row }: any) => {
      const count = row?.original?.analyses
        ? row?.original?.analyses.length
        : 0;
      return <P>{count}</P>;
    },
    filter: 'equals',
    disableFilters: true,
    sortType: (rowA: any, rowB: any, columnId: any) => {
      const countA = rowA.original?.analyses
        ? rowA.original?.analyses.length
        : 0;
      const countB = rowB.original?.analyses
        ? rowB.original?.analyses.length
        : 0;

      return countA - countB;
    },
  },
  {
    Header: 'CRIF Pull History',
    accessor: 'analyses',
    Cell: ({ row }: any) => {
      const creditLen = row?.original?.analyses ? row?.original?.analyses : [];
      if (creditLen.length == 0) return <div>-</div>;
      creditLen.sort((a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });

      return creditLen.map((item: any, index: number) => {
        return (
          <P key={index}>
            score: {item.score}, date: {item.report_date}
          </P>
        );
      });
    },
    disableFilters: true,
  },
  {
    Header: 'Account Status',
    accessor: 'profile.account_status',
    Cell: EmailVerificationCell,
    Filter: AccountStatusFilter,
  },
];

export const AssignCustomerColumn = (href: string = 'customer') => {
  const columns = useMemo(
    () => [
      ...commonColumnsForAssign,
      {
        Header: 'View Profile',
        accessor: 'action',
        Cell: ({ row }: any) => (
          <ActionButtons id={row.original.id} prefix={href} />
        ),
        disableFilters: true,
      },
    ],
    []
  );

  return columns;
};

export const CustomerColumn = (
  toggleCheckbox: (id: string) => void,
  href: string = 'customer',
  toggleSelectAll: (rows: any) => void
) => {
  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Cell: ({ row }: any) => (
          <input
            type="checkbox"
            checked={row.isSelected}
            onChange={() => toggleCheckbox(row)}
            className="h-5 w-5 border-gray-300 rounded text-blue-500 focus:ring-blue-400 cursor-pointer ring-blue-300 focus:ring-2 focus:outline-none"
          />
        ),
        Header: ({ getToggleAllRowsSelectedProps, rows }: any) => (
          <>
            <P>Select</P>
            <input
              type="checkbox"
              {...getToggleAllRowsSelectedProps()}
              onClick={() => toggleSelectAll(rows)}
              className="h-5 w-5 border-gray-300 ml-2 text-center rounded text-blue-500 focus:ring-blue-400 cursor-pointer ring-blue-300 focus:ring-2 focus:outline-none"
            />
          </>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Assigned Executive',
        accessor: 'executive_email',
        Cell: ({ value }: any) => (
          <>{value !== '' ? <P>{value}</P> : <P>-</P>}</>
        ),
        disableFilters: true,
      },
      ...commonColumnsForAssign,
      {
        Header: 'View Profile',
        accessor: 'action',
        Cell: ({ row }: any) => (
          <ActionButtons id={row.original.id} prefix={href} />
        ),
        disableFilters: true,
      },
    ],
    []
  );

  return columns;
};

export const BankStatementReferenceColumn = () => {
  const columns = useMemo(
    () => [
      {
        id: 'expander',
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <ChevronDownIcon className="h-12 w-12" />
            ) : (
              <ChevronRightIcon className="h-12 w-12" />
            )}
          </span>
        ),
        Cell: ({ row }: any) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <ChevronDownIcon className="h-10 w-10" />
            ) : (
              <ChevronRightIcon className="h-10 w-10" />
            )}
          </span>
        ),
      },
      {
        Header: 'Phone Number',
        accessor: 'phone_number',
        Cell: ({ value }: any) => (
          <LinkedItem href={`https://wa.me/${value}`}>
            <P>{value}</P>
          </LinkedItem>
        ),
        disableFilters: true,
      },
      {
        Header: 'Credits',
        accessor: 'totalDeposits',
        Cell: ({ value }: any) => (
          <Tooltip
            title={`Total deposits for this phone number: ${formatMoney(
              value
            )}`}
          >
            <P className=" text-bold md:text-lg">{formatMoney(value)}</P>
          </Tooltip>
        ),
        disableFilters: true,
      },
      {
        Header: 'Debits',
        accessor: 'totalWithdrawals',
        Cell: ({ value }: any) => (
          <Tooltip
            title={`Total withdrawals for this phone number: ${formatMoney(
              value
            )}`}
          >
            <P className="text-bold md:text-lg">{formatMoney(value)}</P>
          </Tooltip>
        ),
        disableFilters: true,
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        Cell: ({ value }: any) => <P className="text-gray-600">{value}</P>,
        disableFilters: true,
      },
    ],
    []
  );

  return columns;
};

export const CrifHistoryColumn = () => {
  const columns = useMemo(
    () => [
      {
        id: 'expander',
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <ChevronDownIcon className="h-12 w-12" />
            ) : (
              <ChevronRightIcon className="h-12 w-12" />
            )}
          </span>
        ),
        Cell: ({ row }: any) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <ChevronDownIcon className="h-10 w-10" />
            ) : (
              <ChevronRightIcon className="h-10 w-10" />
            )}
          </span>
        ),
      },
      {
        Header: 'CRIF Pull Date',
        accessor: 'report_date',
        Cell: ({ value }: any) => (
          <P className="md:text-lg whitespace-nowrap break-words">
            {formatDate(value)}
          </P>
        ),
        disableFilters: true,
      },
      {
        Header: 'Credit Score',
        accessor: 'score',
        Cell: ({ value }: any) => <P>{value}</P>,
        disableFilters: true,
      },
      {
        Header: 'Pulled By',
        accessor: 'pulled_by_role',
        Cell: ({ value, row }: any) => (
          <>
            <P>
              {value}:{' '}
              {value === 'customer'
                ? row.original?.pulled_by_phone_number
                : row.original?.pulled_by_email}{' '}
            </P>
          </>
        ),
        disableFilters: true,
      },
      {
        Header: 'JSON data',
        accessor: 'report_json',
        Cell: ({ value }: any) => (
          <>
            {value.isValid ? (
              <DocumentInfoField
                title=""
                image="invoice1.png"
                fileName={value.report_json}
              >
                <LinkedItem
                  href=""
                  className={`mt-2`}
                  onClick={() =>
                    handleCrifDownload(
                      value.report_json,
                      formatDate(value.report_date)
                    )
                  }
                >
                  <ArrowDownTrayIcon className="h-6 w-6 text-primary-500" />
                </LinkedItem>
              </DocumentInfoField>
            ) : (
              <P className="md:text-lg whitespace-nowrap break-words">
                {value.error_message}
              </P>
            )}
          </>
        ),
        disableFilters: true,
      },
    ],
    []
  );
  return columns;
};
