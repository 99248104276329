import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { formatMoney, formatNumberToK } from '../../../utils/formatter';
import { H2, H3, P } from '../../../common/components/elements/text';
import Card from '../../../common/components/elements/Card';
import Indicator from '../../../common/components/elements/text/Indicator';
import { Component } from '@appTypes/index';
import { removeCharacterFromAmount } from '../../../utils/index';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type Props = {
  months: string[],
  grossIncomeData: number[],
}

const GrossIncomeBarChart: Component<Props> = ({months, grossIncomeData}) => {
  const colors = grossIncomeData.map((income) => (income >= 0 ? 'rgb(109, 253, 181,0.5)' : 'rgb(255, 99, 132,0.5)'));
  const hoverColors = grossIncomeData.map((income) => (income >= 0 ? 'rgb(109, 253, 181,1)' : 'rgb(255, 99, 132,1)'));
  
  const data = {
    labels: months,
    datasets: [
      {
        label: 'Gross Income',
        data: grossIncomeData,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
        hoverBackgroundColor: hoverColors,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        responsive: true,
        ticks: {
          callback: (value: any) => `₹${(formatNumberToK(removeCharacterFromAmount(value)))}`,
        },
      },
    },
  };

  return (
    <Card className="w-full max-w-auto sm:space-y-2 sm:p-8  border border-gray-300">
      <div className="flex justify-between">
        <H3 className="text-primary-500 sm">Net Cash Flow Chart</H3>
        <div className="flex flex-col">
          <Indicator title="Money Overspent" className="bg-red-300" />
          <Indicator title="Money Saved" className="bg-green-300" />
        </div>
      </div>
      <Bar data={data} options={options} />
    </Card>
  );
};

export default GrossIncomeBarChart;
