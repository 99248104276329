import { apiEndpoint } from '../../utils/endpoint';
import { useQueryData } from '../../lib/react-query';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { H1, H5 } from '../../common/components/elements/text';
import DataTable from '../../common/components/tanstack-table/index';
import {
  getCurrDetailsFlags,
  removeCharacterFromAmount,
} from '../../utils/index';
import { formatMoney } from '../../utils/formatter';
import { TableHeaderBuilder } from 'react-table-utils';
import { Cur, CurDetail } from '../../common/types/cur-model';
import { useRole } from '../../common/hooks/use-user';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { Flag } from '../../common/types/credit-analysis';

export const CURRENT_BANK = 'CURRENT_BANK';

const CreditUtilizationRatio = () => {
  const [curTillDate, setCurTillDate] = useState(0);
  const [currDetails, setCurrDetails] = useState<CurDetail[]>([]);
  const [flag, setFlag] = useState<Flag>();

  const router = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const reportId = queryParams.get('report_id');
  const userId = router.id as string;

  const { currentUserRole } = useRole();
  const { data, isLoading } = useQueryData(
    apiEndpoint.creditAnalysis.getCreditUtilizationRatio(
      currentUserRole,
      userId
    ),
    CURRENT_BANK + userId
  );

  useEffect(() => {
    if (!isLoading && data) {
      const {
        credit_utilization_ratio_details,
        credit_utilization_ratio_till_report_date,
        // @ts-ignore
      } = data.filter(
        (item: Cur) =>
          item.detailed_credit_utilization_ratio.report_id === reportId
      )[0].detailed_credit_utilization_ratio;

      setCurTillDate(credit_utilization_ratio_till_report_date);
      setCurrDetails(credit_utilization_ratio_details);
      setFlag(getCurrDetailsFlags(credit_utilization_ratio_till_report_date));
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <FullPageLoader isOpen={true} />;
  }

  const headerColumns = new TableHeaderBuilder<CurDetail>()
    .add('card', (col) =>
      col
        .header('CARD')
        .cell((value: string) => (
          <H5 className="text-primary-500 break-md md:whitespace-nowrap">
            {value}
          </H5>
        ))
    )
    .add('limit', (col) =>
      col
        .header('LIMIT')
        .cell((value: string) => (
          <H5 className="text-primary-500">
            {formatMoney(removeCharacterFromAmount(value))}
          </H5>
        ))
    )
    .add('balance', (col) =>
      col
        .header('BALANCE')
        .cell((value: string) => (
          <H5 className="text-primary-500 text-bold">
            {formatMoney(removeCharacterFromAmount(value))}
          </H5>
        ))
    )
    .add('credit_utilization_ratio', (col) =>
      col
        .header('CUR')
        .cell((value: string) => <H5 className="text-primary-500">{value}%</H5>)
    )
    .build();

  return (
    <div className="py-4">
      <H1 className="text-center">Credit Utilization Ratio</H1>
      <div className="flex py-2">
        <H5 className="text-gray-500">CUR till 2023: </H5>
        <H5
          className={`text-lg text-bold ${
            getCurrDetailsFlags(curTillDate!).color === 'green'
              ? 'text-green-600'
              : 'text-red-600'
          } pl-2`}
        >
          {curTillDate}%{''}
        </H5>
      </div>
      {currDetails.length > 0 && (
        <DataTable columns={headerColumns} data={currDetails} />
      )}
    </div>
  );
};

export default CreditUtilizationRatio;
