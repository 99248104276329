import { apiEndpoint } from '../../utils/endpoint';
import { useQueryData } from '../../lib/react-query';
import { useLocation, useParams } from 'react-router-dom';
import { H1, H5 } from '../../common/components/elements/text';
import DataTable from '../../common/components/tanstack-table/index';
import { useEffect, useState } from 'react';
import { formatMoney } from '../../utils/formatter';
import {
  convertCardNumber,
  getCurrDetailsFlags,
  removeCharacterFromAmount,
} from '../../utils/index';
import { Tag } from 'antd';
import { TableHeaderBuilder } from 'react-table-utils';
import { Flag } from '../../common/types/credit-analysis';
import {
  AccountsCreditBehavior,
  ICreditBehavior,
} from '../../common/types/credit-behavior-model';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { useRole } from '../../common/hooks/use-user';

const CREDIT_BEHAVIOR = 'CREDIT_BEHAVIOR';

const CreditBehavior = () => {
  const [creditRating, setCreditRating] = useState(0);
  const [totalOutstanding, setTotalOutstanding] = useState(0);
  const [creditBehaviorData, setCreditBehaviorData] = useState<
    AccountsCreditBehavior[]
  >([]);
  const [flag, setFlag] = useState<Flag>();

  const router = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const reportId = queryParams.get('report_id');
  const userId = router.id as string;

  const { currentUserRole } = useRole();
  const { data, isLoading } = useQueryData(
    apiEndpoint.creditAnalysis.getCreditBehavior(currentUserRole, userId),
    CREDIT_BEHAVIOR + userId
  );

  useEffect(() => {
    if (!isLoading && data) {
      // @ts-ignore
      const { credit_behavior, credit_behavior_details } = data.filter(
        (item: ICreditBehavior) =>
          item.detailed_credit_behavior.report_id === reportId
      )[0].detailed_credit_behavior;

      setCreditRating(credit_behavior);
      setCreditBehaviorData(credit_behavior_details);
      setFlag(getCurrDetailsFlags(credit_behavior));

      const total = credit_behavior_details.reduce(
        (acc: any, item: AccountsCreditBehavior) => {
          return (
            acc + parseInt(removeCharacterFromAmount(item.outstanding_amount))
          );
        },
        0
      );
      setTotalOutstanding(total);
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <FullPageLoader isOpen={true} />;
  }

  // if (creditBehaviorData.length === 0) {
  //   return <NotFoundBox title="Credit Behavior" />;
  // }

  if (creditBehaviorData.length) {
    creditBehaviorData.forEach((item) => {
      // @ts-ignore
      item.account_combined = [];
      // @ts-ignore
      item.account_combined = [
        item.account_status,
        item.account,
        item.account_number,
      ];
    });
  }

  const headerColumns = new TableHeaderBuilder<AccountsCreditBehavior>()
    .add('account_combined', (col) =>
      col.header('LENDER DETAILS').cell((value) => (
        <>
          {value[0] === 'Active' ? (
            <Tag className="text-green-500 bg-green-100">{value[0]}</Tag>
          ) : (
            <Tag className="text-red-500 bg-red-100">{value[0]}</Tag>
          )}
          <H5 className="text-primary-500 py-1">{value[1]},</H5>
          <H5 className="text-primary-500 break-all">
            A/C- {convertCardNumber(value[2])}
          </H5>
        </>
      ))
    )
    .add('sanctioned_amount', (col) =>
      col
        .header('SANCTIONED AMOUNT')
        .cell((value: string) => (
          <H5 className="text-primary-500">
            {formatMoney(removeCharacterFromAmount(value))}
          </H5>
        ))
    )
    .add('outstanding_amount', (col) =>
      col
        .header('OUTSTANDING AMOUNT')
        .cell((value: string) => (
          <H5 className="text-primary-500 text-bold">
            {formatMoney(removeCharacterFromAmount(value))}
          </H5>
        ))
    )
    .add('credit_behavior', (col) =>
      col
        .header('CB')
        .cell((value: string) => <H5 className="text-primary-500">{value}%</H5>)
    )
    .build();

  return (
    <div className="py-4">
      <H1 className="text-center">Credit Behavior</H1>
      <div className="flex py-2">
        <H5 className="text-gray-500">Overall rating till 2023: </H5>
        <H5
          className={`text-lg text-bold ${
            getCurrDetailsFlags(creditRating!).color === 'green'
              ? 'text-green-600'
              : 'text-red-600'
          } pl-2`}
        >
          {creditRating}%{' '}
        </H5>
      </div>
      {creditBehaviorData.length > 0 && (
        <DataTable columns={headerColumns} data={creditBehaviorData} />
      )}
      <div className="flex py-2">
        <H5 className="text-gray-500">Total Outstanding Amount: </H5>
        <H5 className="text-lg text-bold text-primary-500 pl-2">
          {formatMoney(totalOutstanding)}
        </H5>
      </div>
    </div>
  );
};

export default CreditBehavior;
