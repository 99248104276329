import { Customer } from '@appTypes/user-model';
import { useEffect, useState } from 'react';
import { useAssignCustomersMutation } from '../../../common/hooks/mutations/use-assign-mutation';
import Table from '../../../common/components/data-table';
import Section from '../../../common/layouts/PageSection';
import { CustomerColumn, AssignCustomerColumn } from '../../../common/components/data-table/TableColumn';

type Props = {
  customerList: Customer[];
  assignButtonVisible: boolean;
  executiveId: string;
  title: string;
  text?: string;
  refetch: () => void;
}

const ListOfCustomer: React.FC<Props> = ({customerList, assignButtonVisible, executiveId, title, text, refetch}) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>([]);
  const [customers, setCustomers] = useState<Customer[]>(customerList);
  const [originalCustomers, setOriginalCustomers] =
    useState<Customer[]>(customerList);

  const mutateExecutive = useAssignCustomersMutation();

  useEffect(() => {
    setCustomers(customerList);
    setOriginalCustomers(customerList);
  }, [customerList]);

  const toggleCheckbox = (row: any) => {
    row.toggleRowSelected();
    if (!row.isSelected) {
      setSelectedCustomerIds((prev) => {
        return [...prev, row.values.id];
      });
    } else {
      setSelectedCustomerIds((prev) => {
        return prev.filter((id) => id !== row.values.id);
      });
    }
  };

  let rowSelected = false;

  const toggleSelectAll = (rows: any) => {
    if (!rowSelected) {
      const allUserIds = rows.map((row: any) => row.original.id); 
      setSelectedCustomerIds(allUserIds);
      rowSelected = true
    } else {
      setSelectedCustomerIds([]);
      rowSelected = false
    }
  };

  const resetData = () => setCustomers(originalCustomers);

  const handleAssign = () => {
    mutateExecutive({
      executive_id: executiveId,
      customer_ids: selectedCustomerIds,
    }).then(() => {
      refetch();
    });
  };

  let columns = CustomerColumn(toggleCheckbox, 'customer', toggleSelectAll);

  if (!assignButtonVisible) columns = AssignCustomerColumn('customer');

  return (
    <Section title={title} text={text}>
      <Table
        columns={columns}
        data={customers}
        resetData={resetData}
        isExcelSheetButtonVisible={false}
        showAssignButton={assignButtonVisible}
        handleAssign={handleAssign}
        renderRowSubComponent={() => null}
        isSearchable={true}
      />
    </Section>
  );
};

export default ListOfCustomer;