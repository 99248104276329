import React, { useEffect, useState } from "react";
import {
  Button,
  FormInstance,
  PaginationProps,
  Space,
  Table,
  UploadFile,
  Row,
  Col,
  Spin,
  Dropdown,
  MenuProps
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { ExecutivesModel, IExecutivesModel } from "./executives-model";
import {
  GetApiCall
} from "../../../utils/apis";
import {
  executiveListUrl
} from "../../../utils/constant";
import {
  FileExcelOutlined,
  DownOutlined
} from "@ant-design/icons";
import "./executiveList-style.scss";
import { CSVLink } from "react-csv"

const ExecutiveListPage = () => {
  const [loading, setLoading] = useState(false);

  const [executiveList, setExecutiveList] = useState<IExecutivesModel[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const currentPageSize = 8;
  let userData: any = localStorage.getItem("user")
  let token: any = JSON.parse(userData)?.token;

  const onPaginationChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setCurrentPage(current);
  };

  useEffect(() => {
    getExecutivesList();
  }, []);
  const getExecutivesList = () => {
    setLoading(true)
    GetApiCall(executiveListUrl, token).then((data: ExecutivesModel) => {
      const users = data.user.filter(
        (user: IExecutivesModel) =>
          user.role_id === "3" && !user.is_active && !user.is_deleted
      );
      setExecutiveList(users);
      setLoading(false)
    });
  }
  const [open, setOpen] = useState(false);

  const getUserExportData = () => {
    let csv_arr: any[] = [];
    let user_filtered_data = executiveList.map((x: any, index: any) => {
      let idx = (index + 1).toString();
      return {
        sno: idx,
        name: x.name,
        email: x.email,
        role: x.role
      }
    })

    csv_arr = [...user_filtered_data];
    return csv_arr;
  }

  const formRef = React.createRef<FormInstance>();
  const [uploadFile, setUploadFile] = useState<UploadFile[]>([]);

  const onClick: MenuProps["onClick"] = ({ key }) => {

  };

  const actionOnClick = () => {
    
  };

  const headers = [
    { label: "S.No.", key: "sno" },
  { label: "Name", key: "name" },
  { label: "Email Id", key: "email" },
  { label: "Role", key: "role" }
  ];

  const items = [
    { key: "mobile", label: "Mobile" },
    { key: "doj", label: "Date of Joining" }
  ];

  const columns: ColumnsType<IExecutivesModel> = [
    {
      title: "Sl. No",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: number) =>
        (currentPage - 1) * currentPageSize + index + 1,
      width: 70
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
  ];

  return (
    <Spin spinning={loading} tip="Loading..." size="large">
      <div>
        <Row>
          <Col span={24} className="cms-main">
            <Col span={18}>
              <h4>List of Executives</h4>
            </Col>
            <Col span={6}>
              <Space>
                <Dropdown
                  menu={{ items, onClick }}
                  dropdownRender={(menu) => (
                    <div onClick={() => actionOnClick()}>{menu}</div>
                  )}>
                  <a onClick={(e) => e.preventDefault()}>
                    Sort by <DownOutlined />
                  </a>
                </Dropdown>
                <Dropdown
                  menu={{ items, onClick }}
                  dropdownRender={(menu) => (
                    <div onClick={() => actionOnClick()}>{menu}</div>
                  )}>
                  <a onClick={(e) => e.preventDefault()}>
                    Filter <DownOutlined />
                  </a>
                </Dropdown>
                <Button className="btn btn-primary add-button" type="primary">
                  <FileExcelOutlined />
                  <CSVLink
                    filename={"Executive_List.csv"}
                    data={getUserExportData()}
                    headers={headers}
                    className="btn btn-primary"
                  >
                    Download
                  </CSVLink>
                </Button>
              </Space>
            </Col>
          </Col>
        </Row>


        <div>
          <Table
            columns={columns}
            dataSource={executiveList}
            pagination={{
              pageSize: currentPageSize,
              onChange: onPaginationChange,
              position: ['bottomCenter']
            }}
          />
        </div>
      </div>
    </Spin>
  );
};

export default ExecutiveListPage;
