import { Component } from '@appTypes/index';
import { PrimaryButton } from '../../../common/components/elements/button';
import { P } from '../../../common/components/elements/text';
import Section from '../../../common/layouts/PageSection';
import clsx from 'clsx';

type Props = {
  secondary?: boolean;
  description?: any;
  href?: string;
  buttonText?: string;
};

const BankStatementNotAvailable: Component<Props> = ({
  secondary = false,
  description,
  href = '',
  buttonText,
}) => {
  return (
    <Section
      title="Bank Statement Analysis"
    >
      <div className="mx-auto py-9 px-4 md:py-5 md:px-2 flex flex-col sm:flex-row justify-center gap-8">
        <img
          src="../assets/images/bank_statement_svg.png"
          alt="Credit Builder Loan"
          width={200}
          height={200}
        />
        <div>
          <P className="text-gray-600">{description}</P>
        </div>
      </div>
    </Section>
  );
};

export default BankStatementNotAvailable;
