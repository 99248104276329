import { creditAnalysisName } from '../../utils/constants/index';
import GridContainer from '../../common/components/elements/GridContainer';
import CreditScoreCard from '../../modules/creditScore/components/CreditScoreCard';
import {
  getAverageMonthlyBalanceFlags, getLowestMonthlyBalanceFlags,
  getNetCashFlowFlags,
  getDefaultTransactionFlags
} from '../../utils/index';

import { useQueryClient } from '@tanstack/react-query';
import Section from '../../common/layouts/PageSection';
import { useFullPageLoader } from '../../common/hooks/use-full-page-loader';
import { useEffect, useState } from 'react';
import { CalculateBankStatementAnalysis } from '../../common/hooks/use-bank-statement';
import { Document } from '@appTypes/user-model';
import BankStatementNotAvailable from '../../modules/creditScore/components/BankStatementNotAvailable';

const CREDIT_SCORE = 'CREDIT_SCORE';
export const BANK_STATEMENT_RED_FLAG_KEY = 'BANK_STATEMENT_RED_FLAG_KEY';

  type Props = {
    userId: string;
    documents: Document[];
  };

type BankStatementAnalysisData = {
  averageMonthlyBalanceChange: number;
  lowestBalance: number;
  netCashFlow: number;
  defaultAmount: number;
  isBankStatementAvailable: boolean;
  isBankStatementEmpty: boolean;
  isBankStatementFailed: boolean;
};

const initialBankStatementAnalysisData: BankStatementAnalysisData = {
  averageMonthlyBalanceChange: 0,
  lowestBalance: 0,
  netCashFlow: 0,
  defaultAmount: 0,
  isBankStatementAvailable: false,
  isBankStatementEmpty: false,
  isBankStatementFailed: false,
};

const BankStatementAnalysis: React.FC<Props> = ({ userId, documents }) => {
  const queryClient = useQueryClient();
  const { showLoader, hideLoader } = useFullPageLoader();
  const [bankStatementAnalysisData, setBankStatementAnalysisData] =
    useState<BankStatementAnalysisData>(initialBankStatementAnalysisData);

  const getBankStatementData = async () => {
    showLoader();
    let bankStatementAnalysis = queryClient.getQueryData<{
      averageMonthlyBalanceChange: number;
      lowestBalance: number;
      netCashFlow: number;
      defaultAmount: number;
      isBankStatementAvailable: boolean;
      isBankStatementEmpty: boolean;
      isBankStatementFailed: boolean;
    }>([BANK_STATEMENT_RED_FLAG_KEY + userId]);

    if (bankStatementAnalysis) {
      setBankStatementAnalysisData({ ...bankStatementAnalysis });
      hideLoader();
    } else if (documents) {
      bankStatementAnalysis = await CalculateBankStatementAnalysis(documents);
      hideLoader();

      setBankStatementAnalysisData({ ...bankStatementAnalysis });
      if (
        bankStatementAnalysis.isBankStatementAvailable &&
        !bankStatementAnalysis.isBankStatementEmpty &&
        !bankStatementAnalysis.isBankStatementFailed
      ) {
        queryClient.setQueryData(
          [BANK_STATEMENT_RED_FLAG_KEY + userId],
          bankStatementAnalysis
        );
      }
    }
  };

  useEffect(() => {
    getBankStatementData();
  }, []);

  const {
    isBankStatementAvailable,
    isBankStatementEmpty,
    isBankStatementFailed,
  } = bankStatementAnalysisData;

  if (!isBankStatementAvailable) {
    return (
      <BankStatementNotAvailable
        description={[
          <span key="line1" className="text-black">
            📊 Exciting Insights Await! 📊
          </span>,
          <br key="line2" />,
          'Bank Statement Is Not Available',
        ]}
      />
    );
  }

  if (isBankStatementFailed) {
    return (
      <BankStatementNotAvailable
        description={[
          <span key="line1" className="text-black pb-4">
            ❌ Analyzing Bank Statement Failed ❌
          </span>,
          <br key="line2" />,
          'Oops! It seems there was an issue with your bank statement analysis. Please double-check the following:',
          <br key="line3" />,
          <>
            <ul className="space-y-2 text-gray-900 list-disc list-inside ">
              <li className="text-black text-base text-bold">
                Ensure the PDF password you provided is correct.
              </li>
              <li className="text-black text-base text-bold">
                Verify that the bank name and details are accurate.
              </li>
              <li className="text-black text-base text-bold">
                Make sure the bank statement document is in the correct format
                and not damaged.
              </li>
            </ul>
            <br key="line4" />
          </>,
          "Don't worry, we're here to assist you. Once you've addressed the above concerns, you can upload your bank statement again for analysis.",
          <br key="line5" />,
          'Your journey to deeper financial understanding is just a step away! ⏳',
        ]}
      />
    );
  }

  if (isBankStatementEmpty) {
    return (
      <BankStatementNotAvailable
        description={[
          <span key="line1" className="text-black">
            🔍 Analyzing Bank Statement 🔍
          </span>,
          <br key="line2" />,
          "We're hard at work unraveling the financial insights within your bank statement. Sit back and relax as our advanced algorithms crunch the numbers.",
          <br key="line3" />,
          'Your journey to deeper financial understanding is underway! ⏳',
        ]}
      />
    );
  }

  return (
    <Section title="Bank Statement Analysis">
      <GridContainer>
        {isBankStatementAvailable &&
          !isBankStatementEmpty &&
          !isBankStatementFailed && (
            <>
              <CreditScoreCard
                name={creditAnalysisName.netCashFlow}
                flag={getNetCashFlowFlags(
                  bankStatementAnalysisData.netCashFlow || 0
                )}
                href={`/customer/net-cash-flow/${userId}`}
              />
              <CreditScoreCard
                name={creditAnalysisName.lowestBalance}
                flag={getLowestMonthlyBalanceFlags(
                  bankStatementAnalysisData.lowestBalance || 0
                )}
                href={`/customer/monthly-lowest-balance/${userId}`}
              />
              <CreditScoreCard
                name={creditAnalysisName.avgMonthlyBalance}
                flag={getAverageMonthlyBalanceFlags(
                  bankStatementAnalysisData.averageMonthlyBalanceChange || 0
                )}
                href={`/customer/avg-monthly-balance/${userId}`}
              />
              <CreditScoreCard
                name={creditAnalysisName.defaultTransaction}
                flag={getDefaultTransactionFlags(
                  bankStatementAnalysisData.defaultAmount
                )}
                href={`/customer/default-transaction/${userId}`}
              />
            </>
          )}
      </GridContainer>
    </Section>
  );
};

export default BankStatementAnalysis;
