import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { useQuery } from '../../lib/react-query';
import { apiEndpoint } from '../../utils/endpoint';
import { useParams } from 'react-router-dom';
import UserSection from '../../common/sections/UserSection';
import { Customer } from '@appTypes/user-model';
import ErrorBox from '../../common/components/elements/ErrorBox';
import { withAuth } from '../../common/hoc/withAuth';
import ListOfExecutive from '../../modules/managers/components/ListOfExecutive';
import { useExecutive } from '../../common/hooks/use-executive';
import { useEffect, useState } from 'react';
import { P } from '../../common/components/elements/text';

const MANAGER_BY_ID_QUERY_KEY = 'MANAGER_BY_ID';

const ManagerProfile = () => {
  const router = useParams();
  const userId = router.id as string;
  const [listOfAssignExecutives, setListOfAssignExecutives] = useState<
    Customer[]
  >([]);

  const { data, isLoading } = useQuery<{ manager: Customer }>(
    apiEndpoint.owners.managers.getManagerById(userId),
    MANAGER_BY_ID_QUERY_KEY + userId
  );

  const {
    data: listOfExecutives,
    isLoading: listOfExecutiveLoading,
    refetch,
  } = useExecutive();

  useEffect(() => {
    if (
      !isLoading &&
      !listOfExecutiveLoading &&
      listOfExecutives?.executives.length > 0
    ) {
      const assignExecutive = listOfExecutives.executives.filter(
        (executive: Customer) =>
          executive?.manager_email === data?.manager?.profile.email
      );
      setListOfAssignExecutives(assignExecutive);
    }
  }, [isLoading, listOfExecutives, listOfExecutiveLoading, refetch, data]);

  if (isLoading || listOfExecutiveLoading)
    return <FullPageLoader isOpen={true} />;

  if (!data) {
    return (
      <ErrorBox
        title="Manager not found"
        text="Manager does not exist in the system!"
        redirectButton={{
          text: 'View All Manager',
          href: '/rolemanagement',
        }}
      />
    );
  }

  const { manager } = data;
  if (!manager) return <></>;

  return (
    <div className="grid gap-y-5">
      <UserSection
        user={{
          ...manager?.profile,
        }}
        showAllDetails={true}
        actionChildren={
          <>
            <P>No of Executive Assigned: {listOfAssignExecutives.length}</P>
          </>
        }
      />
      {listOfAssignExecutives.length > 0 && (
        <div className="overflow-hidden">
          <ListOfExecutive
            managerId={userId}
            executiveList={listOfAssignExecutives}
            assignButtonVisible={false}
            title="List of Executive Assigned to the Manager"
            refetch={refetch}
          />
        </div>
      )}
      <div className="overflow-hidden">
        <ListOfExecutive
          title="List Of All Executives"
          text="Select and assign executives to the current manager"
          executiveList={listOfExecutives?.executives || []}
          assignButtonVisible={true}
          managerId={userId}
          refetch={refetch}
        />
      </div>
    </div>
  );
};

export default withAuth()(ManagerProfile);
