import './App.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { Route, Routes, useNavigate } from 'react-router-dom';
import './index.css';
import {
  ContactsOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FormOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  SnippetsOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Col, Layout, Menu, Row, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import DashboardPage from './pages/dashboard/dashboard';
import RoleManagement from './pages/role_management/roleManagement';
import Subscriptions from './pages/subscriptions/subscriptions';
import { useDispatch, useSelector } from 'react-redux';
import { LoginActionTypes } from './pages/login/login-redux/login-enum';
import HelpPage from './pages/cms_management/help/help';
import AboutUsPage from './pages/cms_management/about_us/aboutUs';
import AboutUsForm from './pages/cms_management/about_us/aboutUsForm';
import ContactUsPage from './pages/cms_management/contact_us/contactUs';
import ServicePage from './pages/cms_management/services/services';
import FaqPage from './pages/cms_management/faq/faq';
import BlogsPage from './pages/cms_management/blogs/blogs';
import UserProfileview from './components/userprofile/customerProfile';
import PrivacyPolicyPage from './pages/cms_management/privacy_policy/privacyPolicy';
import TermsConditionsPage from './pages/cms_management/termsconditions/termsconditions';
import UserSignUpPage from './pages/report/user_signup/userSignUp';
import RegisteredUserPage from './pages/report/Registered User/registeredUser';
import ExecutiveListPage from './pages/report/Executive List/executives';
import ManagerListPage from './pages/report/Manager List/managers';
import DisputeResolutionPage from './pages/report/Dispute Resolution/disputeResolution';
import SubscriptionDetailsPage from './pages/report/Subscription Details/subscriptionDetails';
import UserSubscriptionDetailsPage from './pages/report/Subscription Details/userSubscriptionDetails';
import SettingPage from './pages/admin_settings/admin_setting';
import Topnav from './components/header/top-nav';
import AgeOfCredit from './modules/creditAnalysis/ageOfCredit';
import AllAccount from './modules/creditAnalysis/allAccount';
import AllEnquiry from './modules/creditAnalysis/allEnquiry';
import CreditBehavior from './modules/creditAnalysis/creditBehavior';
import CreditUtilizationRatio from './modules/creditAnalysis/creditUtilizationRatio';
import DelayHistory from './modules/creditAnalysis/delayHistory';
import NegativeAccount from './modules/creditAnalysis/NegativeAccount';
import OverDueAccount from './modules/creditAnalysis/overdueAccount';
import PaymentHistory from './modules/creditAnalysis/paymentHistory';
import { PAGE } from './utils/constants/page';
import { storage } from './utils/storage';
import { role } from './utils/constants/index';
import { useRole } from './common/hooks/use-user';
import AllCustomer from './pages/all_customer/allCustomer';
import ProfileIncomplete from 'components/dashboard/profile-incomplete';
import PartialDocument from 'components/dashboard/partial-document';
import CrifPullFailed from 'components/dashboard/crifPullFailed';
import CompleteProfile from './pages/all_customer/completeProfile';
import CustomerManagement from './pages/all_customer/customerManagement';
import ManagerProfile from './pages/manager/ManagerProfile';
import ExecutiveProfile from './pages/executive/ExecutiveProfile';
import UserProfile from './pages/user_management/userProfile';
import NetCashFlow from './modules/creditAnalysis/netCashFlow';
import DTIRatios from './modules/creditAnalysis/dtiRatio';
import MonthlyLowestBalance from './modules/creditAnalysis/monthlyLowestBalance';
import AverageMonthlyBalance from './modules/creditAnalysis/averageMonthlyBalance';
import DefaultTransaction from './modules/creditAnalysis/defaultTransaction';
import OTPRequest from './pages/all_customer/otpRequest';
const { Header, Content, Footer, Sider } = Layout;

declare global {
  interface Window {
    _mtm: any[];
    // @ts-ignore
    _paq: any[];
  }
}

type MenuItem = Required<MenuProps>['items'][number];

function menuItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  link?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

let items: MenuItem[] = [
  menuItem('Dashboard', '/dashboard', <HomeOutlined />, '/dashboard'),
  menuItem(
    'User Management',
    PAGE.userManagement.href,
    <UserOutlined />,
    PAGE.userManagement.href
  ),
  menuItem(
    'Role Management',
    '/rolemanagement',
    <TeamOutlined />,
    '/rolemanagement'
  ),
  menuItem(
    'CMS Management',
    '/cmsmanagement',
    <TeamOutlined />,
    '/cmsmanagement',
    [
      menuItem('About Us', '/aboutus', <InfoCircleOutlined />, '/aboutus'),
      menuItem('Contact Us', '/contactus', <ContactsOutlined />, '/contactus'),
      menuItem('Help', '/help', <QuestionCircleOutlined />, '/help'),
      menuItem(
        'Services',
        '/services',
        <CustomerServiceOutlined />,
        '/services'
      ),
      menuItem(
        'TermsConditions',
        '/termsconditions',
        <UnorderedListOutlined />,
        '/termsconditions'
      ),
      menuItem('FAQs', '/faq', <FileUnknownOutlined />, '/faq'),
      menuItem('Blogs', '/blogs', <FormOutlined />, '/blogs'),
      menuItem(
        'Privacy Policy',
        '/privacypolicy',
        <SnippetsOutlined />,
        '/privacypolicy'
      ),
    ]
  ),
  menuItem('Report', '/report', <FileTextOutlined />, '/report', [
    menuItem('User SignUp', '/usersignup', <UserAddOutlined />, '/usersignup'),
    // menuItem(
    //   'Negative Accounts',
    //   '/negativeaccounts',
    //   <QuestionCircleOutlined />,
    //   '/negativeaccounts'
    // ),
    // menuItem(
    //   'Dispute Resolution',
    //   '/disputeresolution',
    //   <QuestionCircleOutlined />,
    //   '/disputeresolution'
    // ),
    // menuItem(
    //   'Subscription Detais',
    //   '/subscriptiondetails',
    //   <QuestionCircleOutlined />,
    //   '/subscriptiondetails'
    // ),
  ]),
  menuItem('Settings', '/settings', <SettingOutlined />, '/settings'),
];

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardTitle = useSelector(
    (state: any) => state.GlobalState.dashboardTitle
  );
  // const loginState = useSelector((state: ILoginState) => state.LoginState);
  const [isLoggedIn, setIsLoggedIn] = useState(storage.getToken() !== null);
  const [menuSelected, setMenuSelected] = useState('Dashboard');

  const onMenuClick = (item: any) => {
    if (item.key === 'logout') {
      localStorage.clear();
      dispatch({ type: LoginActionTypes.LOGOUT });
      navigate(PAGE.login.href);
    } else {
      navigate(item.key);
      titleUpdate(item.key);
    }
  };

  const titleUpdate = (key: string) => {
    switch (key) {
      case '/dashboard':
        setMenuSelected('Dashboard');
        break;
      case PAGE.userManagement.href:
        setMenuSelected('User Management');
        break;
      case '/rolemanagement':
        setMenuSelected('Role Management');
        break;
      case '/subscriptions':
        setMenuSelected('Subscriptions');
        break;
      case '/aboutus':
        setMenuSelected('About Us');
        break;
      case '/contactus':
        setMenuSelected('Contact Us');
        break;
      case '/help':
        setMenuSelected('Help');
        break;
      case '/services':
        setMenuSelected('Services');
        break;
      case '/termsconditions':
        setMenuSelected('Terms & Conditions');
        break;
      case '/faq':
        setMenuSelected('FAQs');
        break;
      case '/blogs':
        setMenuSelected('Blogs');
        break;
      case '/privacypolicy':
        setMenuSelected('Privacy Policy');
        break;
      case '/userprofile':
        setMenuSelected('User Profile');
        break;
      case '/usersignup':
        setMenuSelected('Report');
        break;
      case '/registereduser':
        setMenuSelected('Report');
        break;
      case '/negativeaccounts':
        setMenuSelected('Report');
        break;
      case '/disputeresolution':
        setMenuSelected('Report');
        break;
      case '/subscriptiondetails':
        setMenuSelected('Report');
        break;
      case '/listofexecutives':
        setMenuSelected('Report');
        break;
      case '/listofmanagers':
        setMenuSelected('Report');
        break;
      case '/settings':
        setMenuSelected('Settings');
        break;
      default:
        break;
    }
  };

  const { currentUserRole } = useRole();

  useEffect(() => {
    var _paq = (window._paq = window._paq || []);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = '//oolka-matomo.cr.oolka.in/';
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', '2']);
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = u + 'matomo.js';
      s.parentNode!.insertBefore(g, s);
    })();

    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    (function () {
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = 'https://oolka-matomo.cr.oolka.in/js/container_lNazI5dU.js';
      s.parentNode!.insertBefore(g, s);
    })();

    const token = storage.getToken();
    if (token != null) {
      // const userProfile = JSON.parse(user);
      // dispatch({type: LoginActionTypes.LOGINSUCCESS, isLoggedIn: true});
      // if (userProfile.user.role_id === "3") {
      //     items = items.filter((menuItem) => menuItem?.key !== "/rolemanagement");
      // }
      setIsLoggedIn(true);

      if (
        currentUserRole === role.manager ||
        currentUserRole === role.executive
      ) {
        items = items.filter((menuItem) => menuItem?.key !== '/cmsmanagement');
      }

      if (currentUserRole === role.executive)
        items = items.filter(
          (menuItem) =>
            menuItem?.key !== PAGE.roleMmanagement.href &&
            menuItem?.key !== '/settings'
        );
    } else if (
      window.location.pathname.split('/')[1] !== PAGE.forgotPassword.key
    ) {
      navigate(PAGE.login.href);
      setIsLoggedIn(false);
    }
  }, [isLoggedIn, currentUserRole]);

  return (
    <>
      {storage.getToken() && (
        <Layout className="main-container">
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <Row
              onClick={() =>
                onMenuClick({ key: '/dashboard', label: 'Dashboard' })
              }
            >
              {/* <Row> */}
              <Col className="logo" span={24}>
                <img
                  style={{ cursor: 'pointer' }}
                  src="./assets/images/logo1.svg"
                  alt="logo"
                />
              </Col>
            </Row>
            <Menu
              className="site-sider-background"
              theme="dark"
              mode="inline"
              defaultSelectedKeys={['/dashboard']}
              items={items}
              onClick={onMenuClick}
            />
          </Sider>
          <Layout className="site-layout">
            <Header className="dashboard-header">
              <Row gutter={0}>
                <Col span={24} className="afterloginheader">
                  <Topnav></Topnav>
                </Col>
              </Row>
            </Header>
            <Content className="content-area">
              <Breadcrumb>
                <Breadcrumb.Item className="breadcrumb-link">
                  {menuSelected}
                </Breadcrumb.Item>
              </Breadcrumb>
              <div
                // style={{
                //   padding: 24,
                //   minHeight: '83vh',
                //   background: colorBgContainer,
                // }}
                className="container min-h-full mx-auto bg-gray-100"
              >
                <ContentBox />
              </div>
            </Content>
            <Footer className="footer">
              (C) 2024 Sixdis Tech Private Limited
            </Footer>
          </Layout>
        </Layout>
      )}
    </>
  );
};

function ContentBox() {
  return (
    <Routes>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path={PAGE.userManagement.href} element={<CustomerManagement />} />
      <Route path="/rolemanagement" element={<RoleManagement />} />
      <Route path="/userprofile" element={<UserProfile />} />
      <Route path="/customer/:id" element={<UserProfileview />} />
      <Route path="/manager/:id" element={<ManagerProfile />} />
      <Route path="/executive/:id" element={<ExecutiveProfile />} />
      <Route
        path={PAGE.dashboard.items.customer_profile_incomplete_count.action}
        element={<ProfileIncomplete />}
      />
      <Route
        path={PAGE.dashboard.items.customer_profile_complete_count.action}
        element={<CompleteProfile />}
      />
      <Route
        path={PAGE.dashboard.items.partial_docs_upload_count.action}
        element={<PartialDocument />}
      />
      <Route
        path={PAGE.dashboard.items.customers_with_valid_crif_pulls_count.action}
        element={<AllCustomer />}
      />
      <Route
        path={
          PAGE.dashboard.items.customers_with_invalid_crif_pulls_count.action
        }
        element={<CrifPullFailed />}
      />
      <Route
        path={PAGE.dashboard.items.customer_requested_otp_count.action}
        element={<OTPRequest />}
      />
      <Route path="/customer/age-of-credit/:id" element={<AgeOfCredit />} />
      <Route path="/customer/total-account/:id" element={<AllAccount />} />
      <Route path="/customer/credit-enquiry/:id" element={<AllEnquiry />} />
      <Route
        path="/customer/credit-behavior/:id"
        element={<CreditBehavior />}
      />
      <Route
        path="/customer/credit-utilization-ratio/:id"
        element={<CreditUtilizationRatio />}
      />
      <Route path="/customer/delay-history/:id" element={<DelayHistory />} />
      <Route
        path="/customer/negative-account/:id"
        element={<NegativeAccount />}
      />
      <Route
        path="/customer/overdue-account/:id"
        element={<OverDueAccount />}
      />
      <Route
        path="/customer/payment-history/:id"
        element={<PaymentHistory />}
      />
      <Route path="/customer/net-cash-flow/:id" element={<NetCashFlow />} />
      <Route path="/customer/dti-ratio/:id" element={<DTIRatios />} />
      <Route
        path="/customer/monthly-lowest-balance/:id"
        element={<MonthlyLowestBalance />}
      />
      <Route
        path="/customer/avg-monthly-balance/:id"
        element={<AverageMonthlyBalance />}
      />
      <Route
        path="/customer/default-transaction/:id"
        element={<DefaultTransaction />}
      />
      <Route path="/subscriptions" element={<Subscriptions />} />
      <Route path="/aboutus" element={<AboutUsPage />} />
      <Route path="/aboutusform" element={<AboutUsForm />} />
      <Route path="/contactus" element={<ContactUsPage />} />
      <Route path="/help" element={<HelpPage />} />
      <Route path="/services" element={<ServicePage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/termsconditions" element={<TermsConditionsPage />} />
      <Route path="/blogs" element={<BlogsPage />} />
      <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
      <Route path="/usersignup" element={<UserSignUpPage />} />
      <Route path="/registereduser" element={<RegisteredUserPage />} />
      <Route path="/listofexecutives" element={<ExecutiveListPage />} />
      {/* <Route path="/negativeaccounts" element={<NegtiveAccountsPagffe />} /> */}
      <Route path="/listofmanagers" element={<ManagerListPage />} />
      <Route path="/disputeresolution" element={<DisputeResolutionPage />} />
      <Route
        path="/subscriptiondetails"
        element={<SubscriptionDetailsPage />}
      />
      <Route
        path="/usersubscriptiondetails/:id"
        element={<UserSubscriptionDetailsPage />}
      />
      <Route path="/settings" element={<SettingPage />} />
    </Routes>
  );
}

export default App;
