import { useState, useEffect } from 'react';
import { useUser } from '../../common/hooks/use-user';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import SaveProfile from './saveProfile';
import { withAuth } from '../../common/hoc/withAuth';

const UserProfile = () => {
  const { data, isLoading } = useUser();
  const [profileInfo, setProfileInfo] = useState({});

  useEffect(() => {
    if (!isLoading && data) {
      const { manager, owner, executive } = data;
      const user = manager || owner || executive;
      const { dob, employer_name, pan_number } = user;
      const {
        email,
        first_name,
        last_name,
        phone_number,
        whatsapp_number,
        role,
      } = user?.profile;
      setProfileInfo({
        email,
        first_name,
        last_name,
        phone_number,
        whatsapp_number,
        dob,
        role,
        employer_name,
        pan_number,
      });
    }
  }, [data, isLoading]);

  if (isLoading) return <FullPageLoader isOpen={true} />;

  return (
    <>
      {Object.keys(profileInfo).length > 0 && (
        <SaveProfile userData={profileInfo} />
      )}
    </>
  );
};

export default withAuth()(UserProfile);
