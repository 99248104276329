import { dashboardState } from ".";

type Page = {
  key: string;
  title: string;
  href: string;
};

export const PAGE = {
  login: {
    key: "login",
    title: "Login",
    href: "/login",
  },
  register: {
    key: "register",
    title: "Register",
    href: "/register",
  },
  signUpOtpVerification: {
    key: "otp-verification",
    title: "OTP Verification",
    href: "/otp-verification",
  },
  forgotPassword: {
    key: "forgot-password",
    title: "Forgot Password",
    href: "/forgot-password",
  },
  resetPassword: {
    key: "create-new-password",
    title: "Create New Password",
    href: "/create-new-password",
  },
  acceptInvitation: {
    key: "accept-invite",
    title: "Accept Invite",
    href: "/accept-invite",
  },
  dashboard: {
    key: "dashboard",
    title: "Dashboard",
    href: "/dashboard",
    items: { ...dashboardState },
  },
  userManagement: {
    key: "user-management",
    title: "User Management",
    href: "/user-management",
  },
  roleMmanagement: {
    key: "rolemanagement",
    title: "Role Management",
    href: "/rolemanagement",
  },
  profile: {
    key: "profile",
    title: "Profile",
    href: "/profile",
  },
  notification: {
    key: "notification",
    title: "Notification",
    href: "/notification",
  },
  userSingups: {
    key: "usersingup",
    title: "User Signups",
    href: "/usersingup",
  },
  negativeAccounts: {
    key: "negativeaccounts",
    title: "Negative Accounts",
    href: "/negativeaccounts",
  },
  disputeResolutions: {
    key: "disputeresolution",
    title: "Dispute Resolutions",
    href: "/disputeresolution",
  },
  subscriptionDetails: {
    key: "subscriptiondetails",
    title: "Subscription Details",
    href: "/subscriptiondetails",
  },
  settings: {
    key: "settings",
    title: "Settings",
    href: "/settings",
  },
  services: {
    key: "services",
    title: "Services",
    href: "/services",
  },
  termsAndConditions: {
    key: "termsconditions",
    title: "Terms & Conditions",
    href: "/termsconditions",
  },
  blogs: {
    key: "blogs",
    title: "Blogs",
    href: "/blogs",
  },
  faqs: {
    key: "faq",
    title: "FAQS",
    href: "/faq",
  },
  privacyPolicy: {
    key: "privacypolicy",
    title: "Privacy Policy",
    href: "/privacypolicy",
  },
  aboutUs: {
    key: "aboutus",
    title: "About Us",
    href: "/aboutus",
  },
  contactUs: {
    key: "contactus",
    title: "Contact Us",
    href: "/contactus",
  },
  help: {
    key: "help",
    title: "Help",
    href: "/help",
  }
}
