import { Analyses } from "@appTypes/credit-score-model";
import {Document} from '@appTypes/user-model'
import { Subscription } from "@appTypes/plan-model";

export const getUserRole = (roleId: string) => {
  if (roleId === "1") {
    return "Owner";
  } else if (roleId === "2") {
    return "Manager";
  } else if (roleId === "3") {
    return "Executive";
  } else if (roleId === "4") {
    return "User";
  }
};


export const getPlanGroup = (planvalue: string) => {
  if (planvalue === "silver") {
    return "Silver";
  } else if (planvalue === "gold") {
    return "Gold";
  } else if (planvalue === "diamond") {
    return "Diamond";
  } 
};


export const getPlanType = (planvalue: Number) => {
  if (planvalue === 3) {
    return "3 Months";
  } else if (planvalue === 6) {
    return "6 Months";
  } 
};


export const filterValidAnalyses = (analyses: Analyses[]) => {
  return analyses.filter((analysis) => analysis?.score >= 0);
}

export const getNumberOfBankStatements = (data: Document[]) => {
  if (!data) return 0
  return data.filter((item) => item.type === 'bank_statement').length
}

export const getCurrentPaidSubscription = (subscriptions: Subscription[]): boolean => {
  if (!subscriptions || subscriptions?.length == 0) return false;

  const activeSubscription = subscriptions.find((subscription) => {
    const endDate = new Date(subscription?.end_date);
    const today = new Date();

    return today.getTime() <= endDate.getTime() && subscription.cashfree_order_status === "PAID";
  });

  return activeSubscription ? true : false;
}