import { GlobalActions, GlobalState } from './global-interface';
import { Reducer } from 'react';
import { GlobalActionTypes } from './global-enum';
import { initialifaqModel } from '../../pages/cms_management/faq/faq-model';

export const initialGlobalState: GlobalState = {
  menuName: '',
  startDate: '',
  endDate: '',
  dob: '',
  faq: initialifaqModel,
  dashboardTitle: 'Dashboard',
};

export const globalReducer: Reducer<GlobalState, GlobalActions> = (
  state = initialGlobalState,
  action: GlobalActions
) => {
  switch (action.type) {
    case GlobalActionTypes.MENUSELECTED:
      return { ...state, menuName: action.menuName };
    case GlobalActionTypes.STARTDURATIONOFPLAN:
      return { ...state, startDate: action.startDate };
    case GlobalActionTypes.ENDDURATIONOFPLAN:
      return { ...state, endDate: action.endDate };
    case GlobalActionTypes.DATEOFBIRTH:
      return { ...state, dob: action.dob };
    case GlobalActionTypes.FAQSELECTED:
      return { ...state, faq: action.faq };
    case GlobalActionTypes.DASHBOARDTITLE:
      return { ...state, dashboardTitle: action.dashboardTitle };
    default:
      return state;
  }
};
