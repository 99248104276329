import { P } from '../../common/components/elements/text';
import Divider from '../../common/components/elements/Divider';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { Key, useEffect, useState } from 'react';
// import Tag from '../../common/components/elements/Tag';
import { Tag } from 'antd';

type Props = {
  yearlyPaymentData: [];
};

const PaymentHistoryCard = ({ yearlyPaymentData }: any) => {
  const [currentYear, setCurrentYear] = useState(yearlyPaymentData[0].year);
  const [currentYearMonthyPayment, setCurrentYearMonthyPayment] = useState(
    yearlyPaymentData[0].monthly_data
  );

  const handleYearChange = (year: string) => {
    setCurrentYear(year);
    yearlyPaymentData.filter((yearlyPayment: any) => {
      if (yearlyPayment.year === year) {
        setCurrentYearMonthyPayment(yearlyPayment.monthly_data);
      }
    });
  };

  useEffect(() => {
    setCurrentYear(yearlyPaymentData[0].year);
    setCurrentYearMonthyPayment(yearlyPaymentData[0].monthly_data);
  }, [yearlyPaymentData]);

  return (
    <div className="bg-brand-orange-50 border border-brand-orange-500 rounded-md">
      <div className="flex justify-center items-center bg-brand-orange-100 py-4">
        <ArrowLongLeftIcon className="w-6 h-6 mr-4 cursor-pointer" />
        {yearlyPaymentData.map((yearlyPayment: any) => {
          return (
            <Tag
              color="orange"
              key={yearlyPayment.year}
              onClick={() => handleYearChange(yearlyPayment.year)}
              className={`cursor-pointer ${
                currentYear === yearlyPayment.year
                  ? 'bg-brand-orange-400 text-white'
                  : ''
              }`}
            >
              {yearlyPayment.year}
            </Tag>
          );
        })}
        <ArrowLongRightIcon className="w-6 h-6 ml-4 cursor-pointer" />
      </div>
      <div className="flex justify-center items-center my-4">
        {currentYearMonthyPayment.map((monthlyPayment: any, index: number) => {
          return (
            <div key={index} className="px-2">
              <P className="text-gray-600 text-bold">{monthlyPayment.month}</P>
              {monthlyPayment.delay_payment ? (
                <XCircleIcon className="fill-red-500 w-6 h-6" />
              ) : (
                <CheckCircleIcon className="fill-green-500 w-6 h-6" />
              )}
            </div>
          );
        })}
      </div>

      <Divider className="bg-brand-orange-500 text-brand-orange-500" />

      <div className="flex items-center justify-around py-4">
        <div className="flex">
          <CheckCircleIcon className="fill-green-500 w-6 h-6 mr-2" />
          <P className="text-gray-500">Pay on time</P>
        </div>
        <div className="flex">
          <XCircleIcon className="fill-red-500 w-6 h-6 mr-2" />
          <P className="text-gray-500">Delay or fail to pay</P>
        </div>
      </div>
    </div>
  );
};
export default PaymentHistoryCard;
