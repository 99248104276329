export const apiEndpoint = {
  common: {
    getStates: 'stats',
    getCustomersWithIncompleteProfile:  (skip: number, limit: number, orderBy: string, desc: boolean) => `list-customers?profile_completion_status=incomplete&skip=${skip}&limit=${limit}&order_by=${orderBy}&desc_order=${desc}`,
    getCustomersWithPartialDocument: (skip: number, limit: number, orderBy: string, desc: boolean) => `stats?partial_docs_upload=true&skip=${skip}&limit=${limit}&order_by=${orderBy}&desc_order=${desc}`,
    getCustomerWithCompleteProfile: (skip: number, limit: number, orderBy: string, desc: boolean) => `list-customers?profile_completion_status=complete&skip=${skip}&limit=${limit}&order_by=${orderBy}&desc_order=${desc}`,
    getCustomerWithSuccessfullCrifPull: (skip: number, limit: number, orderBy: string, desc: boolean) => `list-customers?crif_pull_status=valid&skip=${skip}&limit=${limit}&order_by=${orderBy}&desc_order=${desc}`,
    getCustomerWithFailedCrifPull: (skip: number, limit: number, orderBy: string, desc: boolean) => `list-customers?crif_pull_status=invalid&skip=${skip}&limit=${limit}&order_by=${orderBy}&desc_order=${desc}`,
    getCustomerWithOTPRequest: (skip: number, limit: number, orderBy: string, desc: boolean) => `list-customers?skip=${skip}&limit=${limit}&order_by=${orderBy}&desc_order=${desc}&requested_otp=true`,
    getCustomerById: (id: string) => 'get-customer/' + id,
    createBorrower: 'create-borrower',
    getExecutives: 'managers/list-executives',
    getExecutiveById: (id: string) => `managers/get-executive/${id}`,
    getCustomers: `list-customers`,
    getAllCustomers: (skip: number, limit: number, orderBy: string, desc: boolean, allQueryParam: string = '') => `list-customers?skip=${skip}&limit=${limit}&order_by=${orderBy}&desc_order=${desc}${allQueryParam}`,
    getListOfAssignedCustomers: (executiveEmail: string) => `list-customers?executive_email=${encodeURIComponent(executiveEmail)}`,
    searchCustomer: {
      customerWithCompleteProfile: (name: string) => `list-customers?profile_completion_status=complete&search_string=${name}`,
      customerWithIncompleteProfile: (name: string) => `list-customers?profile_completion_status=incomplete&search_string=${name}`,
      customerWithPartialDocument: (name: string) => `list-customers?partial_docs_upload=true&search_string=${name}`,
      customerWithSuccessfullCrifPull: (name: string) => `list-customers?crif_pull_status=valid&search_string=${name}`,
      customerWithFailedCrifPull: (name: string) => `list-customers?crif_pull_status=invalid&search_string=${name}`,
      customerWithOTPRequest: (name: string) => `list-customers?search_string=${name}&requested_otp=true`,
      allCustomers: (name: string) => `list-customers?search_string=${name}`,
      allAssignedCustomers: (name: string, executiveEmail: string) => `list-customers?search_string=${name}&executive_email=${encodeURIComponent(executiveEmail)}`
    },
    getCrifPullLimit: 'get-crif-limit',
    setCrifPullLimit: 'set-crif-limit',
    getAllPlans: 'get-all-plans',
    createPaymentLink: 'create-payment-link',
    getPaymentLinkHistory: 'get-payment-link-history',
    cancelPaymentLink: (linkId: string) => `cancel-payment-link/${linkId}`,
    refundSubscription: `create-refund`,
    getRefundHistory: (refundId: string) => `get-refund/${refundId}`,
  },
  creditAnalysis: {
    getCreditScore: (role: string, id: string) => `/get-crif-report/${id}?summarized=true&send_in_list=true`,
    updateCrifPull: (id: string) => `/get-crif-report/${id}?force_pull=true`,
    getPaymentHistory: (role: string, id: string) => `/get-crif-report/${id}?detailed_payment_history=true&send_in_list=true`,
    getCreditEnquiry: (role: string, id: string) => `/get-crif-report/${id}?detailed_all_inquiries=true&send_in_list=true`,
    getNegativeAccount: (role: string, id: string) => `/get-crif-report/${id}?detailed_negative_accounts=true&send_in_list=true`,
    getDelayHistory: (role: string, id: string) => `/get-crif-report/${id}?detailed_delay_history=true&send_in_list=true`,
    getCreditUtilizationRatio: (role: string, id: string) => `/get-crif-report/${id}?detailed_credit_utilization_ratio=true&send_in_list=true`,
    getCreditBehavior: (role: string, id: string) => `/get-crif-report/${id}?detailed_credit_behavior=true&send_in_list=true`,
    getOverdueAccount: (role: string, id: string) => `/get-crif-report/${id}?detailed_overdue_accounts=true&send_in_list=true`,
    getAgeOfCredit: (role: string, id: string) => `/get-crif-report/${id}?detailed_age_of_credit=true&send_in_list=true`,
    getTotalAccount: (role: string, id: string) => `/get-crif-report/${id}?detailed_all_accounts=true&send_in_list=true`,
    getMonthlyDebt: (id: string) => `/get-crif-report/${id}?detailed_monthly_debt=true&send_in_list=true`,
    getCrifJson: (id: string) => `/get-crif-report/${id}?crif_json=true`,
    getPersonalInformationVariation: (id: string) => `/get-crif-report/${id}?detailed_personal_info_variation=true`,
  },
  owners: {
    auth: {
      signin: 'owners/signin',
      signup: 'owners/signup',
      verifySignUpOtp: 'owners/confirm-signup',
      resendOtp: 'owners/resend-otp',
      resetPassword: 'owners/reset-password',
    },
    profile: {
      getProfile: 'owners/me',
    },
    customers: {
      getCustomers: 'owners/list-customers',
      getCrifReportOfCustomer: (id: string) => 'owners/get-crif-report/' + id,
      updateCrifPull: (id: string) => 'force-crif-report/' + id,
    },
    managers: {
      assignManagerToExecutive: 'owners/assign-executives-to-manager',
      inviteManager: 'owners/invite-manager',
      getManagers: 'owners/list-managers',
      getManagerById: (id: string) => `owners/get-manager/${id}`
    },
    executives: {
      getAllExecutives: 'owners/list-executives',
    }
  },
  managers: {
    auth: {
      signin: 'managers/signin',
      resendOtp: 'managers/resend-otp',
      resetPassword: 'managers/reset-password',
    },
    profile: {
      getProfile: 'managers/me',
      acceptInvitation: 'managers/accept-invite',
    },
    customers: {
      getCustomers: 'managers/list-customers',
      getCrifReportOfCustomer: (id: string) => 'managers/get-crif-report/' + id,
    },
    executives: {
      inviteExecutive: 'managers/invite-executive',
      assignCustomerToExecutive: 'managers/assign-customers-to-executive',
      getExecutives: 'managers/list-executives',
      getExecutiveById: (id: string) => `managers/get-executive/${id}`
    }
  },
  executives: {
    auth: {
      signin: 'executives/signin',
      resendOtp: 'executives/resend-otp',
      resetPassword: 'executives/reset-password',
    },
    profile: {
      getProfile: 'executives/me',
      acceptInvitation: 'executives/accept-invite',
    },
    customers: {
      getCustomers: 'executives/list-customers',
      getCrifReportOfCustomer: (id: string) => 'executives/get-crif-report/' + id,
      getCustomerById: (id: string) => 'executives/get-customer/' + id,
    },
  }
}