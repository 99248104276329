import Tag from '../elements/Tag';
import { P } from '../elements/text';
import { formatDate } from '../../../utils/formatter';
import { Subscription } from '@appTypes/plan-model';
import { Tooltip } from 'antd';

export const EmailVerificationCell = ({ row }: any) => (
  <div>
    {row?.original?.profile?.account_status == 'active' ? (
      <Tag className="text-green-500 bg-green-100 border border-green-500">
        Verified
      </Tag>
    ) : (
      <Tag className="text-red-500 bg-red-100 border border-red-500">
        Not Verified
      </Tag>
    )}
  </div>
);

export const ProfileCompletionCell = ({ value }: any) => (
  <div>
    {value ? (
      <Tag className="text-green-500 bg-green-100 border border-green-500">
        Completed
      </Tag>
    ) : (
      <Tag className="text-red-500 bg-red-100 border border-red-500">
        Not Completed
      </Tag>
    )}
  </div>
);

export const SubscriptionCell = ({ value }: { value: Subscription[] }) => {
  const isSubscribed =
    value.length > 0 &&
    value
      .filter((item) => new Date(item.end_date) > new Date())
      .sort(
        (a, b) =>
          new Date(b.end_date).getTime() - new Date(a.end_date).getTime()
      )[0];
  const isExpired =
    value.length > 0 &&
    value
      .filter((item) => new Date(item.end_date) < new Date())
      .sort(
        (a, b) =>
          new Date(b.end_date).getTime() - new Date(a.end_date).getTime()
      )[0];

  return (
    <div>
      {isSubscribed ? (
        <Tag className="text-green-500 bg-green-100 border border-green-500">
          <Tooltip title={`Expires on ${formatDate(isSubscribed.end_date)}`}>
            Active
          </Tooltip>
        </Tag>
      ) : isExpired ? (
        <Tag className="text-red-500 bg-red-100 border border-red-500">
          <Tooltip title={`Expired on ${formatDate(isExpired.end_date)}`}>
            Expired
          </Tooltip>
        </Tag>
      ) : (
        <Tag className="text-red-500 bg-red-100 border border-red-500">
          <Tooltip title="Customer has not subscribed yet.">
            Not Subscribed
          </Tooltip>
        </Tag>
      )}
    </div>
  );
};

export const SubscriptionEndDate = ({
  row,
}: {
  row: {
    original: {
      subscriptions: Subscription[];
    };
  };
}) => {
  const isSubscribedBefore =
    row.original.subscriptions.length > 0 &&
    row.original.subscriptions.sort(
      (a, b) => new Date(b.end_date).getTime() - new Date(a.end_date).getTime()
    )[0];

  return (
    <div>
      {isSubscribedBefore ? (
        <Tooltip
          title={`Expiry Date ${formatDate(isSubscribedBefore.end_date)}`}
        >
          <P>{formatDate(isSubscribedBefore.end_date)}</P>
        </Tooltip>
      ) : (
        <P>N/A</P>
      )}
    </div>
  );
};

export const LoanRequestCell = ({ value }: any) => (
  <div>
    {value ? (
      <Tag className="text-green-500 bg-green-100 border border-green-500">
        Requested
      </Tag>
    ) : (
      <Tag className="text-red-500 bg-red-100 border border-red-500">
        Not Requested
      </Tag>
    )}
  </div>
);

export const DocumentUploadedCell = ({ row }: any) => (
  <div>
    {row?.original?.is_profile_complete ? (
      <Tag className="text-green-500 bg-green-100 border border-green-500">
        Uploaded
      </Tag>
    ) : (
      <Tag className="text-red-500 bg-red-100 border border-red-500">
        Not Uploaded
      </Tag>
    )}
  </div>
);
