import { useMemo, useEffect, useState } from 'react';
import { TableHeaderBuilder } from 'react-table-utils';
import { SuccessButton } from '../../common/components/elements/button';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { ColumnDef } from '@tanstack/react-table';
import { useStatistics } from '../../common/hooks/use-statistics';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { dashboardState } from '../../utils/constants/index';
import DataTable from '../../common/components/tanstack-table';
import { H3, H4 } from '../../common/components/elements/text';
import { withAuth } from '../../common/hoc/withAuth';

interface TableData {
  content: string;
  count: number;
  action: string;
}

const DashboardDesign = () => {
  const { data: stats, isLoading, refetch } = useStatistics();
  const [data, setData] = useState({});

  const headerColumns = new TableHeaderBuilder<TableData>()
    .add('serial', (col) =>
      col.header('S.No').accessorFn((_, index) => index + 1)
    )
    .add('content', (col) =>
      col.header('Status').cell((value) => <H4>{value}</H4>)
    )
    .add('count', (col) =>
      col.header('Total Customers').cell((value) => <H3>{value}</H3>)
    )
    .add('action', (col) =>
      col
        .header('Action')
        .cell((value) => (
          <SuccessButton
            className="!p-1 !rounded-full"
            Icon={ArrowTopRightOnSquareIcon}
            href={`${value}`}
          />
        ))
    )
    .build();

  const columns: ColumnDef<TableData>[] = useMemo(() => headerColumns, []);

  useEffect(() => {
    if (!isLoading && Object.keys(stats).length > 0) {
      setData(stats);
    }
  }, [stats, isLoading]);

  if (isLoading) {
    return <FullPageLoader isOpen={true} />;
  }

  let tableData: TableData[] = [];

  if (Object.keys(data).length > 0) {
    tableData = Object.keys(data).map((key, index) => ({
      content: dashboardState[key].status,
      // @ts-ignore
      count: data[key],
      action: dashboardState[key].action,
    }));

    tableData = tableData.filter(
      (item) =>
        item.content !== dashboardState['partial_docs_upload_count'].status &&
        item.content !== dashboardState['customers_with_invalid_crif_pulls_count'].status &&
        item.content !== dashboardState['total_valid_crif_pulls_count'].status &&
        item.content !== dashboardState['total_invalid_crif_pulls_count'].status
    );
  }



  return (
    <>
      <div>
        <DataTable
          columns={columns}
          data={tableData}
          showColumnToggle={false}
        />
      </div>
    </>
  );
};
export default withAuth()(DashboardDesign);
