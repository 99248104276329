import { useEffect, useState } from 'react';
import * as y from 'yup';
import 'react-quill/dist/quill.snow.css';
import './admin_setting-style.scss';

import { withAuth } from '../../common/hoc/withAuth';
import { RequestType, useMutation, useQuery } from '../../lib/react-query';
import { apiEndpoint } from '../../utils/endpoint';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import Section from '../../common/layouts/PageSection';
import Card from '../../common/components/elements/Card';
import Form from '../../common/components/form';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import Input from '../../common/components/elements/form/Input';
import { showSuccessToast } from '../../utils/toast';
import { H5 } from '../../common/components/elements/text';
import axios from '../../lib/axios';

const initialValues = {
  limit: '',
};

const crifPullLimitSchema = y.object().shape({
  limit: y.number().required('Please enter limit'),
});

const SettingPage = () => {
  const [limitValue, setLimitValue] = useState('');

  const { data, isLoading, refetch } = useQuery(
    apiEndpoint.common.getCrifPullLimit,
    'CRIF_PULL_LIMIT'
  );
  const { mutateAsync } = useMutation(
    apiEndpoint.common.setCrifPullLimit,
    RequestType.Put,
    ''
  );

  useEffect(() => {
    if (!isLoading) {
      setLimitValue(data?.daily_crif_limit);
    }
  }, [isLoading, data]);

  if (isLoading) {
    <FullPageLoader isOpen={true} />;
  }

  return (
    <div>
      <Section title="Crif Pull Limit">
        <Card className="w-full max-w-auto sm:space-y-2 sm:p-8  border border-gray-300">
          <div>
            <H5>Current Crif Pull Limit: {limitValue}</H5>
          </div>
          <Form
            initialValues={initialValues}
            schema={crifPullLimitSchema}
            onSubmit={async (values) => {
              values.limit = parseInt(values.limit);
              const { data } = await axios.get(`set-crif-limit?limit=${values.limit}`);
              if (data) {
                await refetch();
                showSuccessToast('Limit updated successfully');
              }
            }}
            submitButton={{
              text: 'Update',
              IconRight: ArrowPathIcon,
              className: 'mx-auto',
            }}
          >
            <Input
              name="limit"
              label="Update Crif Pull Limit"
              type="number"
              required
            />
          </Form>
        </Card>
      </Section>
    </div>
  );
};

export default withAuth()(SettingPage);
