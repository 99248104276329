import FullPageLoader from "../components/elements/loader/FullPageLoader";
import { useModal } from "../layouts/Modal";
import React, { createContext } from "react";

type Props = {
  showLoader: () => void;
  hideLoader: () => void;
};

type LoaderProps = {
  children: React.ReactNode;
};

export const LoaderContext = createContext<Props>({
  showLoader: () => {},
  hideLoader: () => {},
});

const LoaderProvider: React.FC<LoaderProps> = ({ children }) => {
  const { openModal, closeModal, ...loaderProps } = useModal();

  const value = React.useMemo(
    () => ({ showLoader: openModal, hideLoader: closeModal }),
    []
  );

  return (
    <LoaderContext.Provider value={value}>
      {children}
      <FullPageLoader {...loaderProps} />
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
