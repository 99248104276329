import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  PaginationProps,
  Space,
  Table,
  UploadFile,
  Row,
  Col,
  Spin,
  Dropdown,
  MenuProps
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { DisputeResolutionModel, IDisputeResolutionModel } from "./dispute_resolution-model";
import {
  GetApiCall,
  PostApiCall
} from "../../../utils/apis";
import {
  negativeAccountsUrl,
  addFeedbckUsrAccUrl,
  getFeedbckUsrAccUrl,
  updateFeedbckUsrAccUrl
} from "../../../utils/constant";
import {
  FileExcelOutlined,
  DownOutlined,
  ReadOutlined
} from "@ant-design/icons";
import "./disputeResolution-style.scss";
import { CSVLink } from "react-csv"
import { withAuth } from "../../../common/hoc/withAuth";

const { TextArea } = Input;

const DisputeResolutionPage = () => {
  const [loading, setLoading] = useState(false);

  const [disputeResolutionList, setDisputeResolutionList] = useState<IDisputeResolutionModel[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [isFeedbackEdited, setIsFeedbackEdited] = useState(false);

  const currentPageSize = 8;
  let userData: any = localStorage.getItem("user")
  let token: any = JSON.parse(userData)?.token;

  const onPaginationChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setCurrentPage(current);
  };

  useEffect(() => {
    getDisputeResolutionList();
  }, []);
  const getDisputeResolutionList = () => {
    setLoading(true)
    GetApiCall(negativeAccountsUrl, token).then((data: DisputeResolutionModel) => {
      if (data.status === true) {
        const users = data.user;
        setDisputeResolutionList(users);
        setLoading(false)
      }
      else {
        setDisputeResolutionList([]);
        setLoading(false)
      }
    });
  }
  const [open, setOpen] = useState(false);

  const [isFeedbackAdded, setIsFeedbackAdded] = useState(false);

  const [feedbackValue, setFeedbackValue] = useState("");

  const [isSelectedAccount, setSelectedAccount] = useState({bcbUserId: "", lender_name: "", account_num: "", feedback: ""});

  const getUserExportData = () => {
    let csv_arr: any[] = [];

    if(disputeResolutionList.length > 0) {
      let user_filtered_data = disputeResolutionList.map((x: any, index: any) => {
        let idx = (index + 1).toString();
        return {
          sno: idx,
          name: x.name,
          negative_accounts: x.negative_accounts,
          lender_name: x.negative_acc_arr.map((x: any) => x.lender_name),
          account_num: x.negative_acc_arr.map((x: any) => x.account_num),
          pending_amt: x.negative_acc_arr.map((x: any) => x.pending_amt),
          status: x.negative_acc_arr.map((x: any) => x.status)
        }
      })
  
      csv_arr = [...user_filtered_data];
    }
    return csv_arr;
  }

  const formRef = React.createRef<FormInstance>();
  const [uploadFile, setUploadFile] = useState<UploadFile[]>([]);

  const onFinish = (values: any) => {
    if (isFeedbackEdited) {
      let data = {
        bcbUserId: isSelectedAccount.bcbUserId,
        account_number: isSelectedAccount.account_num,
        feedback: values.feedback
      };

      PostApiCall(updateFeedbckUsrAccUrl, data, token).then((data: any) => {
        if (data.status === true) {
          message.info(`Feedback updated successfully`);
          setIsFeedbackEdited(false);
          hideModal();
        }
      })
    }
    else {
      setIsFeedbackAdded(true);
      let data = {
        bcbUserId: isSelectedAccount.bcbUserId,
        account_number: isSelectedAccount.account_num,
        feedback: values.feedback,
        lender_name: isSelectedAccount.lender_name
      };
      PostApiCall(addFeedbckUsrAccUrl, data, token).then((data: any) => {
        if (data.status === true) {
          message.info(`Feedback added successfully`);
          setIsFeedbackAdded(false);
          hideModal();
        }
      })
    }
  }

  const onClick: MenuProps["onClick"] = ({ key }) => {

  };

  const actionOnClick = () => {
    
  };

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const addFeedback = (data: any, record: any) => {
    setIsFeedbackAdded(true);
    let feedbackModalData = {
      bcbUserId: record.id,
      lender_name: data.lender_name,
      account_num: data.account_num,
      feedback: ""
    }
    setSelectedAccount(feedbackModalData);
    showModal();
  };

  const showFeedback = (data: any, record: any) => {
    let body = {
      bcbUserId: record.id,
      account_number: data.account_num
    }
    setLoading(true)
    PostApiCall(getFeedbckUsrAccUrl, body, token).then((data: any) => {
      if (data.status === true) {
        let feedbackData = data.feedback;
        let feedbackModalData = {
          bcbUserId: record.id,
          lender_name: feedbackData.lender_name,
          account_num: feedbackData.account_number,
          feedback: feedbackData.feedback
        }
        setSelectedAccount(feedbackModalData);
        setFeedbackValue(feedbackData.feedback);
        setIsFeedbackEdited(true);
        setLoading(false);
        showModal();
      }
    });
  }

  const headers = [
    { label: "S.No.", key: "sno" },
    { label: "Name", key: "name" },
    { label: "Negative Account", key: "negative_accounts" },
    { label: "Lender Name", key: "lender_name" },
    { label: "Account Number", key: "account_num" },
    { label: "Pending Amount", key: "pending_amt" },
    { label: "Status", key: "status" }
  ];

  const items = [
    { key: "mobile", label: "Mobile" },
    { key: "doj", label: "Date of Joining" }
  ];

  const columns: ColumnsType<IDisputeResolutionModel> = [
    {
      title: "Sl. No",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: number) =>
        (currentPage - 1) * currentPageSize + index + 1,
      width: 70
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Negative Account",
      dataIndex: "negative_accounts",
      key: "negative_accounts",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.negative_accounts - b.negative_accounts
    },
    {
      title: "Lender Name",
      dataIndex: "negative_acc_arr",
      key: "negative_acc_arr",
      render: (negative_acc_arr, record) => (
        <>
        {
          negative_acc_arr.map((lender: any, index:any) => {
            return <a key={index} onClick={() => addFeedback(lender, record)}><p key={index}>{lender.lender_name}</p></a>
          })
        }
      </>
      ),
      width: 500
    },
    {
      title: "Account Number",
      dataIndex: "negative_acc_arr",
      key: "negative_acc_arr",
      render: (negative_acc_arr) => (
        <>
        {
          negative_acc_arr.map((account: any, index:any) => {
            return <p key={index}>{account.account_num}</p>
          })
        }
      </>
      ),
      width: 500
    },
    {
      title: "Pending Amount",
      dataIndex: "negative_acc_arr",
      key: "negative_acc_arr",
      render: (negative_acc_arr) => (
        <>
          {
            negative_acc_arr.map((pending: any, index:any) => {
              return <p key={index}>{pending.pending_amt}</p>
            })
          }
        </>
      ), 
      width: 300
    },
    {
      title: "Status",
      dataIndex: "negative_acc_arr",
      key: "negative_acc_arr",
      render: (negative_acc_arr) => (
        <>
          {
            negative_acc_arr.map((val: any, index:any) => {
              return <p key={index}>{val.status}</p>
            })
          }
        </>
      ), 
      width: 300
    },
    {
      title: "Feedback",
      dataIndex: "negative_acc_arr",
      key: "negative_acc_arr",
      render: (negative_acc_arr, record) => (
        <>
          {
            negative_acc_arr.map((lender: any, index:any) => {
              if(lender.isFeedback) {
                return <a key={index} onClick={() => showFeedback(lender, record)}><ReadOutlined /></a>
              }
              else {
                return null;
              }
            })
          }
        </>
      ), 
      width: 300
    }
  ];

  return (
    <Spin spinning={loading} tip="Loading..." size="large">
      <div>
        <Row>
          <Col span={24} className="cms-main">
            <Col span={18}>
              <h4>Dispute Resolution</h4>
            </Col>
            <Col span={6}>
              <Space>
                <Dropdown
                  menu={{ items, onClick }}
                  dropdownRender={(menu) => (
                    <div onClick={() => actionOnClick()}>{menu}</div>
                  )}>
                  <a onClick={(e) => e.preventDefault()}>
                    Sort by <DownOutlined />
                  </a>
                </Dropdown>
                <Dropdown
                  menu={{ items, onClick }}
                  dropdownRender={(menu) => (
                    <div onClick={() => actionOnClick()}>{menu}</div>
                  )}>
                  <a onClick={(e) => e.preventDefault()}>
                    Filter <DownOutlined />
                  </a>
                </Dropdown>
                <Button className="btn btn-primary add-button" type="primary">
                  <FileExcelOutlined />
                  <CSVLink
                    filename={"Dispute_Resolution.csv"}
                    data={getUserExportData()}
                    headers={headers}
                    className="btn btn-primary"
                  >
                    Download
                  </CSVLink>
                </Button>
              </Space>
            </Col>
            <Modal
            title={"Dispute Resolution"}
            open={open}
            footer={null}
            onCancel={hideModal}
            destroyOnClose={true}
          >
            <Row>
              <Col span={24}>
                <p><strong>Lender Name:</strong> {isSelectedAccount.lender_name}</p>
                <p><strong>Account Number:</strong> {isSelectedAccount.account_num}</p>
                <Form
                  name="complex-form"
                  layout="vertical"
                  ref={formRef}
                  onFinish={onFinish}
                >
                    <Row>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item label="Feedback">
                        <Space>
                            <Form.Item name="feedback" noStyle >
                            <TextArea rows={4} value={feedbackValue}/>
                            </Form.Item>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                  <Row>
                    <Col lg={{ span:8, offset: 16 }} md={24} sm={24} xs={24}>
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="primary" htmlType="submit" className="btn btn-primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Modal>
          </Col>
        </Row>


        <div>
          <Table
            columns={columns}
            dataSource={disputeResolutionList}
          />
        </div>
      </div>
    </Spin>
  );
};

export default withAuth()(DisputeResolutionPage);
