import { useEffect, useState } from 'react';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { useCustomerWithPartialDocument } from '../../common/hooks/use-customer';
import { IncompleteProfileColumn } from '../../common/components/data-table/TableColumn';
import Table from '../../common/components/data-table/paginateTable';
import { dashboardState, role } from '../../utils/constants/index';
import Section from '../../common/layouts/PageSection';
import { useRole } from '../../common/hooks/use-user';
import { useStatistics } from '../../common/hooks/use-statistics';
import { apiEndpoint } from '../../utils/endpoint';
import axios from '../../lib/axios';
import { withAuth } from '../../common/hoc/withAuth';

const PartialDocument = () => {
  const { currentUserRole } = useRole();
  const columns = IncompleteProfileColumn();
  const { data: customerData, isLoading } = useCustomerWithPartialDocument();
  const { data: stats, isLoading: statsLoading } = useStatistics();
  const [data, setData] = useState(customerData || []);
  const [originalData, setOriginalData] = useState(data);
  const [totalDataCount, setTotalDataCount] = useState(0);

  useEffect(() => {
    if (!isLoading && !statsLoading) {
      setData(customerData);
      setOriginalData(customerData);
      setTotalDataCount(stats?.partial_docs_upload_count);
    }
  }, [statsLoading, isLoading, customerData]);

  const resetData = () => {
    setData(originalData);
  };

  const paginateData = async (pageSize: number, pageIndex: number) => {
    // showLoader();

    const skip = pageSize * pageIndex;
    const limit = pageSize;

    const { data } = await axios.get(
      apiEndpoint.common.getCustomersWithPartialDocument(
        skip,
        limit,
        'updated_at',
        true
      )
    );

    setData([]);
    // @ts-ignore
    setData(data);
    // @ts-ignore
    setOriginalData(data);
  };

  const searchCustomer = async (
    name: string,
    pageIndex: number = 0,
    pageSize: number = 10
  ) => {
    if (name === '') {
      paginateData(pageSize, pageIndex);
    } else {
      const { data } = await axios.get(
        apiEndpoint.common.searchCustomer.customerWithPartialDocument(name)
      );
      setData([]);
      // @ts-ignore
      setData(data);
      // @ts-ignore
      setOriginalData(data);
    }
  };

  if (isLoading || statsLoading) return <FullPageLoader isOpen={true} />;
  const isExcelSheetButtonVisible =
    currentUserRole === role.owner || currentUserRole === role.manager;

  return (
    <Section title={dashboardState.partial_docs_upload_count.status}>
      <Table
        columns={columns}
        data={data}
        resetData={resetData}
        isExcelSheetButtonVisible={isExcelSheetButtonVisible}
        paginateData={paginateData}
        searchCustomer={searchCustomer}
        totalDataCount={totalDataCount}
        excelSheetUrl={`stats?partial_docs_upload=true&`}
        excelSheetName={`Partial_Docs_Uploads-${(new Date().toLocaleDateString()).replace(/[:\\/?*\[\]]/g, "-")}`}
      />
    </Section>
  );
};
export default withAuth()(PartialDocument);
