import { Component } from "../../types";
import { ButtonProps, DangerButton, PrimaryButton, SecondaryButton } from "../elements/button";
import clsx from "clsx";
import { Formik, FormikConfig, FormikHelpers, FormikProps } from "formik";
import React from "react";

interface SubComponent {
  Row: Component;
}
interface OnSubmitResult {
  [key: string]: any;
}

export interface FormProps<S = any> {
  className?: string;
  children?: React.ReactNode;
  schema?: FormikConfig<S>["validationSchema"];
  onSubmit?: (
    values: S,
    reset: () => void,
    helpers: FormikHelpers<S>
  ) => Promise<void | OnSubmitResult>;
  initialValues?: FormikProps<S>["initialValues"];
  submitButton?: ButtonProps;
  resetButton?: ButtonProps;
  handlePreviousButton?: () => void;
  previousButton?: ButtonProps;
  actionClassName?: string;
  action?: string;
  method?: "POST";
}

const Form: React.FC<FormProps> & SubComponent = ({
  className,
  actionClassName,
  initialValues = {},
  schema,
  onSubmit,
  children,
  submitButton,
  resetButton,
  previousButton,
  handlePreviousButton,
  action = "",
  method = "",
  ...props
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values, helpers) => {
        const reset = () => {
          helpers.resetForm({});
        };

        const errors = (await onSubmit?.(values, reset, helpers)) || {};

        if (Object.keys(errors).length > 0) {
          helpers.setErrors(errors);
        }
      }}
      validateOnBlur={false}
    >
      {({ handleSubmit, isSubmitting, handleReset, isValid, dirty }) => (
        <form
          className={clsx("grid gap-y-3 w-full", className)}
          {...(!action ? { onSubmit: handleSubmit } : { action, method })}
          {...props}
        >
          {children}

          {(submitButton || resetButton || previousButton) && (
            <div className={clsx("flex mt-3 gap-x-4", actionClassName)}>
              {previousButton && (
                <SecondaryButton
                  onClick={handlePreviousButton}
                  Icon={previousButton.Icon}
                  {...previousButton}
                />
              )}

              {submitButton && (
                <PrimaryButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={!(isValid && dirty)}
                  {...submitButton}
                />
              )}

              {resetButton && (
                <DangerButton
                  onClick={handleReset}
                  disabled={isSubmitting}
                  Icon={resetButton.Icon}
                  type="reset"
                  {...resetButton}
                />
              )}
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

// eslint-disable-next-line react/display-name
Form.Row = ({ className, children }) => (
  <div className={clsx("grid gap-3 sm:grid-flow-col", className)}>
    {children}
  </div>
);

export default Form;
