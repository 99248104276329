import React, { useEffect, useState, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Customer } from '@appTypes/user-model';
import FullPageLoader from '../../../common/components/elements/loader/FullPageLoader';
import { useQuery } from '../../../lib/react-query';
import { apiEndpoint } from '../../../utils/endpoint';
import DataTable from '../../../common/components/tanstack-table';
import { ManagerColumn } from '../../../common/components/data-table/TableColumn';
import Section from '../../../common/layouts/PageSection';

export const ALL_EXECUTIVE_QUERY_KEY = 'QUERY_ALL_EXECUTIVE';

interface ChildComponentProps {
  ref: any;
}

const ExecutiveList: React.ForwardRefRenderFunction<
  any,
  ChildComponentProps
> = (props, ref) => {
  const [listOfExecutives, setListOfExecutives] = useState([]);
  const { data, isLoading, refetch } = useQuery<{ executives: Customer[] }>(
    apiEndpoint.common.getExecutives,
    ALL_EXECUTIVE_QUERY_KEY,
    {},
    true,
    { staleTime: 0, cacheTime: 0 }
  );

  React.useImperativeHandle(ref, () => ({
    refetchData: refetch,
  }));

  useEffect(() => {
    if (!isLoading && data?.executives.length > 0) {
      const modifiedData = data?.executives.map((item: Customer) => ({
        ...item,
        combinedName: `${item.profile.first_name || ''} ${
          item.profile.middle_name || ''
        } ${item.profile.last_name || ''}`,
      }));
      setListOfExecutives(modifiedData);
    }
  }, [data, isLoading]);

  const columns: ColumnDef<Customer>[] = useMemo(
    () => ManagerColumn('executive'),
    []
  );
  if (isLoading) return <FullPageLoader isOpen={true} />;

  return (
    <Section title="List of Executives">
      <DataTable columns={columns} data={listOfExecutives} />
    </Section>
  );
};

export default React.forwardRef(ExecutiveList);
