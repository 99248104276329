import { Button, Col, Row, message, Spin } from "antd";
import  { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { GetApiCall, PutApiCall } from "../../../utils/apis";
import {
  cmsManagementUpdateUrl,
  cmsManagementUrl,
} from "../../../utils/constant";
import {
  initialICmsManagement,
} from "../cmsManagement-model";
import "./aboutUs-style.scss";

const AboutUsPage = () => {
  const [aboutUsEditor, setAboutUsEditor] = useState("");
  const [aboutUsData, setAboutUsData] = useState(initialICmsManagement);
  const [loading, setLoading] = useState(false);
  let userData:any = localStorage.getItem("user")
  let token:any = JSON.parse(userData)?.token;
  useEffect(() => {
    setLoading(true)
    GetApiCall(cmsManagementUrl, token).then((data) => {
      if(data.status === true) {
        const pageName = data.allpages.find(
          (about:any) => about.page_name === "about_us"
        );
  
        if (pageName !== undefined) {
          setAboutUsEditor(pageName.page_content);
          setAboutUsData(pageName);
          setLoading(false)
        }
        }
        else {
          message.error(data.message)
          setLoading(false);
        }
    });
  }, []);

  const updateTerms = () => {
    setLoading(true)
    const updateTermsForm = {
      id: aboutUsData.id,
      page_content: aboutUsEditor,
      page_name: "about_us",
      page_image: [],
      page_heading: "",
    };
    PutApiCall(cmsManagementUpdateUrl, updateTermsForm, token).then((data) => {
      if(data.status===true){
        setLoading(false)
        message.success(data.message)
      }
    });
  };

  return (
    <div>
      <Spin spinning={loading} tip="Loading..." size="large"> 
        <Row>
          <Col span={24}>
            <ReactQuill theme="snow" value={aboutUsEditor} onChange={setAboutUsEditor} />
          </Col>
        </Row>
        <Row>
          <Col lg={{ span:4, offset: 20 }} md={24} sm={24} xs={24} className="cms-pagesbtn-container">
            <Button className="btn btn-primary" onClick={updateTerms} type="primary">
              Submit
            </Button>
          </Col>
        </Row>
        </Spin>
    </div>
  );
};

export default AboutUsPage;
