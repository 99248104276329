import * as XLSX from 'xlsx';
import { IUserManagment } from '../../../pages/user_management/userManagement-model';
import { formatDateWithTime } from '../../../utils/formatter';
import { Customer } from '../../types/user-model';
import {
  filterValidAnalyses,
  getCurrentPaidSubscription,
  getNumberOfBankStatements,
} from '../../../utils/utils';
import { initialEmptyCrifData } from '../../types/credit-score-model';

interface Props {
  userManagementList: IUserManagment[];
}

const convertUserManagementListToExcel = async (
  userManagementList: any[],
  fileName: string = 'Users-List'
) => {
  console.log('Total Customers', userManagementList.length);

  let uniqueIdentifiers: any = {};
  const userManagementLists = userManagementList.filter((user: Customer) => {
    const identifier = user.id;
    if (!uniqueIdentifiers[identifier]) {
      uniqueIdentifiers[identifier] = true;
      return true;
    }
    return false;
  });

  const formattedList = userManagementLists.map((user: Customer) => {
    let formattedUser: any = {};

    formattedUser['S.No'] = userManagementLists.indexOf(user) + 1;

    if (user?.bcb_user_id) formattedUser['Customer ID'] = user?.bcb_user_id;
    const nameComponents = [
      user?.profile?.first_name,
      user?.profile?.middle_name,
      user?.profile?.last_name,
    ];

    formattedUser['Name'] = nameComponents
      .filter((component) => !!component)
      .join(' ');
    formattedUser['Name'] = formattedUser['Name'] || '';

    if (user?.profile?.phone_number)
      formattedUser['Mobile Number'] = user?.profile?.phone_number;
    if (user?.profile?.email) formattedUser['Mail ID'] = user?.profile?.email;

    if (user?.pan_number) formattedUser['PAN Number'] = user?.pan_number;

    const addressComponents = [user?.city, user?.state];
    formattedUser['Address'] = addressComponents
      .filter((component) => !!component)
      .join(' ');

    if (user?.pin_code) formattedUser['PIN Code'] = user?.pin_code;
    if (user?.state) formattedUser['State'] = user?.state;

    if (user?.created_at)
      formattedUser['SignUp Date'] = formatDateWithTime(user.created_at);
    if (user?.profile?.updated_at)
      formattedUser['Updated Date'] = formatDateWithTime(
        user?.profile?.updated_at
      );

    formattedUser['CBL Requested'] = user?.is_cbl_requested;
    formattedUser['Experiment Bucket'] = !user?.is_eligible_for_ocb
      ? 'Non-OCB'
      : user?.exp_ocb_wo_sub
      ? 'Non-Subs'
      : 'Subs';
    formattedUser['Eligible for CBL'] = user?.is_eligible_for_ocb;
    formattedUser['Subscribed'] = getCurrentPaidSubscription(
      user?.subscriptions
    );
    (formattedUser['Free Consultation Flag'] = user?.is_consultation_requested
      ? 'Yes'
      : 'No'),
      (formattedUser['Bank Statement Uploaded'] = getNumberOfBankStatements(
        user?.documents
      ));

    if (user?.subscriptions.length > 0) {
      user.subscriptions.sort((a: any, b: any) => {
        return new Date(b.end_date).getTime() - new Date(a.end_date).getTime();
      });

      formattedUser['Subscription Date'] = formatDateWithTime(
        user.subscriptions[0].start_date
      );
      formattedUser['Subscription Package'] = user.subscriptions[0].plan.name;

      formattedUser['Subscription End Date'] = formatDateWithTime(
        user.subscriptions[0].end_date
      );
      formattedUser['Subscription Status'] =
        user.subscriptions[0].cashfree_order_status;

      formattedUser['Invoice Number'] = user.subscriptions[0].invoice_number;
      // const plan = allPlans.find((plan: any) => plan.id === user.subscriptions[0].plan.id);
      const plan = user.subscriptions[0].plan;

      if (plan) {
        const DiscountedAmountOfPrice = Math.round(
          plan.price * (plan?.discount / 100)
        );
        const discountedPrice = plan.price - DiscountedAmountOfPrice;
        const gstOnDiscountedPrice = Math.round(discountedPrice * (18 / 100));

        formattedUser['Subscription Fees'] = discountedPrice;
        formattedUser['GST'] = gstOnDiscountedPrice;
      }

      formattedUser['Subscription Fee (With GST)'] =
        user.subscriptions[0].amount;

      formattedUser['Transaction ID/ Order ID'] =
        user.subscriptions[0].cashfree_order_id;
      formattedUser['Transaction Mode'] = 'Online';
    }

    const subscriptionHistory = user.subscriptions.map((item) => {
      return `plan: ${item.plan.name}, start_date: ${item.start_date}, end_date: ${item.end_date}, invoice_number: ${item.invoice_number}`;
    });

    formattedUser['Subscription History'] = subscriptionHistory.join('||');

    if (user?.employment_type)
      formattedUser['Employment Type'] = user?.employment_type;
    if (user?.annual_income)
      formattedUser['Annual Income'] = user?.annual_income;

    if (user?.employer_name)
      formattedUser['Employer Name'] = user?.employer_name;
    if (user?.business_nature)
      formattedUser['Business Nature'] = user?.business_nature;

    if (user?.analyses && user?.analyses.length !== 0) {
      const creditLen = user?.analyses;
      creditLen.sort((a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });

      let creditAnalysis = filterValidAnalyses(creditLen);
      if (creditAnalysis.length == 0) {
        // @ts-ignore
        creditAnalysis.push(initialEmptyCrifData);
      }

      console.log('credit age', creditAnalysis[0].age_of_credit)

      formattedUser['Credit Score'] = creditAnalysis[0].score;
      formattedUser[
        'Credit Usage'
      ] = `${creditAnalysis[0]?.credit_utilization_ratio}%`;
      formattedUser[
        'Payment History'
      ] = `${creditAnalysis[0]?.payment_history}%`;
      formattedUser[
        'Credit Behavior'
      ] = `${creditAnalysis[0]?.credit_behavior}%`;
      formattedUser['Age of Credit'] =
        creditAnalysis[0]?.age_of_credit + ' months';
      formattedUser['Total Active Accounts'] =
        creditAnalysis[0]?.active_accounts_count;
      formattedUser['Delay History'] =
        creditAnalysis[0]?.average_delay + ' days';
      formattedUser['No of Inquiries'] =
        creditAnalysis[0]?.credit_inquiry_count;
      formattedUser['Overdue Account'] =
        creditAnalysis[0]?.overdue_accounts_count;
      formattedUser['Latest Crif Pull Date'] = creditAnalysis[0].report_date;
      formattedUser['Crif Pull Count'] = creditAnalysis.length;

      if (creditAnalysis[0]?.age_of_credit)
        formattedUser['Is NTC?'] =
          creditAnalysis[0].score < 100 && creditAnalysis[0]?.age_of_credit < 6;

      const crifPullHistory = creditLen.map((item: any) => {
        return `score: ${item.score}, date: ${item.report_date}`;
      });
      formattedUser['Crif Pull History'] = crifPullHistory.join('||');
    }

    formattedUser['Profile Link'] = `https://crm.oolka.in/customer/${user?.id}`;

    return formattedUser;
  });

  const headers = [
    'S.No',
    'Customer ID',
    'Name',
    'Mobile Number',
    'Mail ID',
    'PAN Number',
    'Address',
    'PIN Code',
    'State',
    'SignUp Date',
    'Updated Date',
    'Is NTC?',
    'CBL Requested',
    'Eligible for CBL',
    'Subscribed',
    'Experiment Bucket',
    'Free Consultation Flag',
    'Bank Statement Uploaded',
    'Subscription Date',
    'Subscription Package',
    'Subscription End Date',
    'Subscription Status',
    'Invoice Number',
    'Subscription Fees',
    'GST',
    'Subscription Fee (With GST)',
    'Transaction ID/ Order ID',
    'Transaction Mode',
    'Subscription History',
    'Employment Type',
    'Annual Income',
    'Employer Name',
    'Business Nature',
    'CBL Requested',
    'Credit Score',
    'Payment History',
    'Credit Behavior',
    'Age of Credit',
    'Total Active Accounts',
    'Delay History',
    'No of Inquiries',
    'Overdue Account',
    'Latest Crif Pull Date',
    'Crif Pull Count',
    'Crif Pull History',
    'Profile Link',
  ];

  const workSheet = XLSX.utils.json_to_sheet(formattedList, {
    header: headers,
  });
  const workBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workBook, workSheet, fileName);

  const columns: any = Object.keys(workSheet).filter((key) => key !== '!ref');
  const columnWidths: number[] = columns.map((column: any) => {
    const columnData = workSheet[column];
    const columnWidth = columnData.wch || 10;
    const columnDataWidth = columnData.v ? columnData.v.toString().length : 0;
    return Math.max(columnWidth, columnDataWidth);
  });

  workSheet['!cols'] = columnWidths.map((width) => ({ wch: width }));

  XLSX.writeFile(workBook, `${fileName}.xlsx`);
  return <></>;
};

export default convertUserManagementListToExcel;
