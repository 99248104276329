export interface BlogsModel {
  status: boolean;
  allblogs: IBlogsModel[];
}

export interface IBlogsModel {
  id: string;
  blog_page_id: number;
  blog_page_heading: string;
  blog_page_content: string;
  blog_page_image: string;
  blog_page_is_deleted: boolean;
  blog_page_is_active: boolean;
}

export const initialBlogData: BlogsModel = {
  status: false,
  allblogs: [],
};

export const initialBlogModel : IBlogsModel = {
  id: "",
  blog_page_id: 0,
  blog_page_heading: "",
  blog_page_content: "",
  blog_page_image: "",
  blog_page_is_deleted: false,
  blog_page_is_active: false,
}

export interface BlogsModelResponse {
  status: boolean;
  message: string;
}
