import dayjs from 'dayjs';

export const formatMoney = (value: number) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);
};

export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-IN', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
};

export const formatDateOfDD_MM_YYYY = (inputDate: string) => {
  const [day, month, year] = inputDate.split('/').map(Number);

  const formattedDate = new Date(year, month - 1, day);

  return formattedDate.toLocaleDateString(undefined, { day: 'numeric', month: 'long', year: 'numeric' });
}

export const formatNumberToK = (number: number) => {
  if (number >= 1000 || number <= -1000) {
    const num = number / 1000;
    return num.toFixed(1) + 'k';
  } else {
    return number.toString();
  }
}

export const formatDateWithTime = (dateString: string | number | Date) => {
  return `${dayjs(dateString).format('DD-MM-YYYY')}`;
};

export const formatLocalDateString = (date: string) => {
  const parts = date.split('-'); 
  const day = parts[0];
  const month = parseInt(parts[1], 10) - 1;
  const year = parts[2];

  // @ts-ignore
  const formattedDate = new Date(year, month, day).toLocaleDateString('en-IN', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  return formattedDate;
};

export const formatTime = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  // const ISTOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
  // const ISTDate = new Date(date.getTime() + ISTOffset);
  // const localDateTime = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  const timeOptions = {
    hour: 'numeric' as const,
    minute: 'numeric' as const,
    hour12: true,
  };

  return date.toLocaleTimeString('en-IN', timeOptions);
}

export const formatDateWithTimeAndSeconds = (dateTimeString: string, hoursToAdd = 0) => {
  const date = new Date(dateTimeString);

  date.setHours(date.getHours() + hoursToAdd);
  const localDateTime = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  const dateTimeOptions = {
    day: 'numeric' as const,
    month: 'short' as const,
    year: 'numeric' as const,
    hour: 'numeric' as const,
    minute: 'numeric' as const,
    hour12: true,
  };

  return date.toLocaleDateString('en-IN', dateTimeOptions);
}

export const formatDateWithoutTime = (dateString: any) => {
  const [day, month, year] = dateString.split('/');

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const monthName = monthNames[parseInt(month, 10) - 1];
  return `${day} ${monthName} ${year}`;
}

export const formatDateToYYYYMMDD = (date: any) => {
  const inputDate = new Date(date);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}