import { Component } from '@appTypes/index';
import clsx from 'clsx';

type TooltipProps = {
  children: React.ReactNode;
  text?: string;
  className?: string;
};

const Tooltip: Component<TooltipProps> = ({ children, text, className, ...props }) => {
  return (
    <div className="group flex relative">
      <span
        className={clsx(" group-hover:opacity-100 transition-opacity bg-gray-800 px-2 text-lg text-gray-100 rounded-md absolute left-1/2 -translate-x-1/2 -translate-y-full opacity-0 m-4 mx-auto duration-150 ease-in-out", className
    )}
        style={{ top: '-1rem' }}
      >
        {text}
      </span>
      {children}
    </div>
  );
};

export default Tooltip;
