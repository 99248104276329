import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  PaginationProps,
  Popconfirm,
  Space,
  Table,
  Typography,
  DatePicker,
  Select,
  Row,
  Col
} from "antd";
import type { DatePickerProps } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  ISubscriptions,
  SubscriptionResponse,
  SubscriptionsModel,
} from "./subscriptions-model";
import { DeleteApiCall, GetApiCall, PostApiCall } from "../../utils/apis";
import {
  subscriptionUrl,
  updateSubscriptionPlanUrl,
  createSubscriptionPlanUrl,
  deleteSubscriptionPlanUrl,
} from "../../utils/constant";
import { getPlanGroup, getPlanType } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { GlobalActionTypes } from "../../utils/global-redux/global-enum";
import { IGlobalState } from "../../utils/global-redux/global-interface";
import { withAuth } from "../../common/hoc/withAuth";

const { TextArea } = Input;
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "plan_group" | "plan_type" | "text";
  datePickerType: string;
  record: ISubscriptions;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  datePickerType,
  record,
  index,
  children,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (dataIndex === "startdate") {
      dispatch({
        type: GlobalActionTypes.STARTDURATIONOFPLAN,
        startDate: date?.toISOString(),
      });
    } else {
      dispatch({
        type: GlobalActionTypes.ENDDURATIONOFPLAN,
        endDate: date?.toISOString(),
      });
    }
  };

  const inputNode =
    inputType === "plan_group" ? (
      <Select
        style={{ width: 120 }}
        options={[
          {
            value: "silver",
            label: "Silver",
          },
          {
            value: "gold",
            label: "Glod",
          },
          {
            value: "diamond",
            label: "Diamond",
          },
        ]}
      />
    ) : inputType === "plan_type" ? (
      <Select
        style={{ width: 120 }}
        options={[
          {
            value: 3,
            label: "3 Months",
          },
          {
            value: 6,
            label: "6 Months",
          }
        ]}
      />
    ): (
      <Input />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const SubscriptionsPage = () => {
  const [subscriptionsList, setsubscriptionsList] = useState<ISubscriptions[]>(
    []
  );
  const durationPlan = useSelector((state: IGlobalState) => state.GlobalState);
  let userData:any = localStorage.getItem("user")
  let token:any = JSON.parse(userData)?.token;
  const [isLoading, setIsLoading]=useState(false);
  useEffect(() => {
    GetApiCall(subscriptionUrl, token).then((data: SubscriptionsModel) => {
      setsubscriptionsList(data.planList);
      setIsLoading(false)
    });
  }, []);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
 
  const currentPageSize = 8;

  const isEditing = (record: ISubscriptions) => record.id === editingKey;

  const edit = (record: Partial<ISubscriptions>) => {
    form.setFieldsValue({ ...record });
    if (record.id !== undefined) {
      setEditingKey(record.id);
    }
  };

  const cancel = () => {
    setEditingKey("");
  };

  const updateSubscription = (updateSubscription: ISubscriptions) => {
    PostApiCall(updateSubscriptionPlanUrl, updateSubscription, token).then((data) => {
      if (data.status === true) {
        message.info(`Plan updated successfully`);
      }
    });
  };

  const deleteSubscription = (deleteSubscription: ISubscriptions) => {
    DeleteApiCall(deleteSubscriptionPlanUrl, deleteSubscription, token).then(
      (data) => {
        if (data.status === true) {
          const removeSubscription = [...subscriptionsList];
          const deleteIndex = subscriptionsList.findIndex(
            (subIndex) => subIndex.id === deleteSubscription.id
          );
          removeSubscription.splice(deleteIndex, 1);
          setsubscriptionsList(removeSubscription);
          message.info(`Plan deleted successfully`);
        }
      }
    );
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as ISubscriptions;
      const newData = [...subscriptionsList];
      const index = newData.findIndex((item) => key === item.id);
      if (index > -1) {
        const item = newData[index];
        if (durationPlan.startDate !== "") {
          row.startdate = durationPlan.startDate;
        }
        if (durationPlan.endDate !== "") {
          row.enddate = durationPlan.endDate;
        }
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setsubscriptionsList(newData);
        setEditingKey("");
        const updatedItem = newData.find((item) => key === item.id);
        if (updatedItem !== undefined) {
          updateSubscription(updatedItem);
        }
      } else {
        newData.push(row);
        setsubscriptionsList(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const onPaginationChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    cancel();
    setCurrentPage(current);
  };

  const { confirm } = Modal;
  const showDeleteConfirm = (deleteRecord: ISubscriptions) => {
    confirm({
      title: "Are you sure delete this subscription?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteSubscription(deleteRecord);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const columns = [
    {
      title: "Sl. No",
      dataIndex: "sno",
      key: "id",
      render: (text: any, record: any, index: number) =>
        (currentPage - 1) * 8 + index + 1,
        width:75
    },
    {
      title: "Plan Group",
      dataIndex: "plan_group",
      key: "plan_group",
      editable: true,
      render: (_: any, plan:any) => {
        
        const editable = isEditing(plan);
        return (
          <Select
            defaultValue={getPlanGroup(plan.plan_group)}
            style={{ width: 120 }}
            disabled={!editable}
            options={[
              {
                value: "silver",
                label: "Silver",
              },
              {
                value: "gold",
                label: "Glod",
              },
              {
                value: "diamond",
                label: "Diamond",
              },
            ]}
          />
        );
      },
    },
    {
      title: "Plan Duration",
      dataIndex: "plan_type",
      key: "plan_type",
      editable: true,
      render: (_: any, plan:any) => {
        
        const editable = isEditing(plan);
        return (
          <Select
            defaultValue={getPlanType(plan.plan_type)}
            style={{ width: 120 }}
            disabled={!editable}
            options={[
              {
                value: 3,
                label: "3 Months",
              },
              {
                value: 6,
                label: "6 Months",
              }
             
            ]}
          />
        );
      },
    },

    {
      title: "Plan Name",
      dataIndex: "plan_name",
      key: "plan_name",
      editable: true,
    },
    {
      title: "Price",
      dataIndex: "plan_price",
      key: "plan_price",
      editable: true,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "plan_desc",
      key: "plan_desc",
      editable: true,
    },
    {
      title: "Terms",
      dataIndex: "plan_terms",
      key: "plan_terms",
      editable: true,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_: any, record: ISubscriptions) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              Cancel
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined style={{ padding: 14 }} />
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => showDeleteConfirm(record)}
            >
              <DeleteOutlined />
            </Typography.Link>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ISubscriptions) => ({
        record,
        inputType:
        col.dataIndex === "plan_group"
            ? "plan_group" : col.dataIndex === "plan_type"
            ? "plan_type"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        datePickerType: col.dataIndex,
      }),
    };
  });

  const AddSubscriptionModal = () => {
    const formRef = React.createRef<FormInstance>();
    const onFinish = (values: any) => {
      setIsLoading(true)
      const addSubscription = {
        plan_name: values.plan_name,
        plan_price: values.plan_price,
        discount: values.discount,
        plan_terms: values.plan_terms,
        plan_desc: values.plan_desc,
        plan_group: values.plan_group,
        plan_type: values.plan_type
      };
      PostApiCall(createSubscriptionPlanUrl, addSubscription, token).then(
        (data: SubscriptionResponse) => {
          if (data.status) {
            const newSubscription = [...subscriptionsList];
            newSubscription.unshift(data.createdPlan);
            setsubscriptionsList(newSubscription);
            message.info(`Subcription added successfully`);
            formRef.current!.resetFields();
            hideModal();
            setIsLoading(false)
          } else if (!data.status) {
            message.info(data.message);
            setIsLoading(false)
          }
        }
      );
    };
    const [open, setOpen] = useState(false);

    const showModal = () => {
      setOpen(true);
    };

    const hideModal = () => {
      setOpen(false);
    };

    return (
      <>
      
        <Row>
          <Col span={24}>
            <Button className="btn-primary add-button" type="primary" onClick={showModal}>
            <PlusCircleFilled /> Add
            </Button>
            <Modal
              title="Create Subscription Plan"
              open={open}
              footer={null}
              onCancel={hideModal}
            >
              <Row>
                <Col span={24}>
                  <Form
                    name="complex-form"
                    layout="vertical"
                    ref={formRef}
                    onFinish={onFinish}
                  >
                    <Row>
                      <Col lg={12} md={24} sm={24} xs={24}>
                      <Form.Item label="Plan Group" required>
            <Space>
             <Form.Item
                  name="plan_group"
                  noStyle
                  rules={[{ required: true, message: "Plan group is required!" }]}
                >
                  <Select
                    
                    placeholder="Select a group"
                    allowClear
                    options={[
                      {
                        value: "silver",
                        label: "Silver",
                      },
                      {
                        value: "gold",
                        label: "Gold",
                      },
                      {
                        value: "diamond",
                        label: "Diamond",
                      },
                    ]}
                  />
                </Form.Item>
              </Space>
            </Form.Item>
                      </Col>
                      <Col lg={12} md={24} sm={24} xs={24}>
              
                    <Form.Item label="Plan Duration" required>
                      <Space>
                        <Form.Item
                          name="plan_type"
                          noStyle
                          rules={[{ required: true, message: "Plan duration is required!" }]}
                        >
                          <Select
                            placeholder="Select a plan duration"
                            allowClear
                            options={[
                              {
                                value: 3,
                                label: "3 Months",
                              },
                              {
                                value: 6,
                                label: "6 Months",
                              },
                              
                            ]}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>
                    </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item label="Plan Name">
                          <Space>
                            <Form.Item name="plan_name" noStyle >
                              <Input />
                            </Form.Item>
                          </Space>
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item label="Price" required>
                          <Space>
                            <Form.Item name="plan_price" noStyle rules={[{ required: true, message: "Price is required!" }]}>
                              <Input />
                            </Form.Item>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item label="Discount">
                          <Space>
                            <Form.Item name="discount" noStyle >
                              <Input />
                            </Form.Item>
                          </Space>
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24} sm={24} xs={24}>
                        <Form.Item label="Terms">
                          <Space>
                            <Form.Item name="plan_terms" noStyle >
                              <Input />
                            </Form.Item>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item label="Description">
                          <Space>
                            <Form.Item name="plan_desc" noStyle>
                                <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                            </Form.Item>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                    
                      <Col lg={12} md={24} sm={24} xs={24} className="subscriptionBtn">
                        <Form.Item wrapperCol={{ span: 24 }}>
                          <Button type="primary" htmlType="submit" className="btn btn-primary">
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Modal>
          </Col>
        </Row>
        
      </>
      
    );
  };

  return (
    <div>
      <div className="content-button-style">
        <Space>
          <AddSubscriptionModal />
        </Space>
      </div>
      <Form form={form} component={false}>
        <Table
          rowKey="id"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={subscriptionsList}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            pageSize: currentPageSize,
            onChange: onPaginationChange,
            position: ['bottomCenter']
          }}
          scroll={{ x: 1100 }}
        />
      </Form>
    </div>
  );
};

export default withAuth()(SubscriptionsPage);
