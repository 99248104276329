import React from 'react';
import { H3, P } from '../components/elements/text';
import { Component } from '@appTypes/.';
import { getDummyPicture } from '../../utils/.';
import clsx from 'clsx';
import { Profile } from '@appTypes/user-model';
import LinkedItem from '../components/elements/LinkedItem';

type Props = {
  user: Profile;
  actionChildren?: React.ReactNode;
  showAllDetails: boolean;
};

export const UserBox = ({
  first_name,
  last_name,
  email,
  picture_uri,
  role,
  phone_number,
  showAllDetails,
}: Profile & { showAllDetails: boolean }) => {
  return (
    <div className="flex items-center">
      <img
        className="rounded-full h-20 w-20"
        src={
          picture_uri
            ? picture_uri
            : getDummyPicture(first_name + ' ' + last_name || '')
        }
        alt=""
      />
      <div className="flex flex-col justify-center ml-4">
        <P>
          {first_name} {last_name}
        </P>
        <LinkedItem href={`mailto:${email}`}>
          <P>{email}</P>
        </LinkedItem>
        {showAllDetails && (
          <>
            {phone_number && (
              <LinkedItem href={`https://wa.me/${phone_number}`}>
                <P>{phone_number}</P>
              </LinkedItem>
            )}
            {role && <P className="capitalize">{role}</P>}
          </>
        )}
      </div>
    </div>
  );
};

const UserSection: Component<Props> = ({
  user,
  actionChildren,
  className,
  showAllDetails,
}) => {
  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center md:items-start sm:justify-between sm:flex-row',
        className
      )}
    >
      <UserBox {...user} showAllDetails={showAllDetails} />

      <div className="flex flex-wrap justify-center sm:justify-end gap-3 mt-4 sm:mt-0">
        {actionChildren}
      </div>
    </div>
  );
};

export default UserSection;
