export interface FaqModel {
  status: boolean;
  allFaqs: IFaqModel[];
}

export interface IFaqModel {
  id: string;
  faq_page_title: string;
  faq_question: string;
  faq_answer: string;
  faq_page_id: string;
  faq_is_deleted: boolean;
  faq_is_active: boolean;
}

export const initialifaqModel:IFaqModel={
  id: "",
  faq_page_title: "",
  faq_question: "",
  faq_answer: "",
  faq_page_id: "",
  faq_is_deleted: true,
  faq_is_active: true,
}

export const initialFaqModel: FaqModel = {
  status: false,
  allFaqs: [],
};

export interface FaqResponse {
  status: boolean;
  message: string;
}
