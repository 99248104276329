import { Component } from '@appTypes/index';
import Section from '../../common/layouts/PageSection';
import { H5, P } from '../../common/components/elements/text';

type Props = {
  scoringFactors: {
    positive_factors: string[];
    negative_factors: string[];
  };
};

const ScoringFactor: Component<Props> = ({ className, scoringFactors }) => {
  const negativeFactors = scoringFactors?.negative_factors;
  const positiveFactors = scoringFactors?.positive_factors;

  return (
    <Section
      title="Scoring Factors"
      text="Below are the factors that are affecting credit score."
      className={className}
      containerClassName="w-full max-w-3xl lg:max-w-5xl"
    >
      <div>
        {positiveFactors.length > 0 || negativeFactors.length > 0 ? (
          <ol className="mx-4 md:mx-0 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-top justify-items-start justify-start gap-x-8">
            {positiveFactors.map((factor, index) => {
              return (
                <ListItem
                  key={index}
                  count={index + 1}
                  text={factor}
                  className={`bg-green-500`}
                />
              );
            })}
            {negativeFactors.map((factor, index) => {
              return (
                <ListItem
                  key={index}
                  count={index + 1}
                  text={factor}
                  className={`bg-red-500`}
                />
              );
            })}
          </ol>
        ) : (
          <div className="rounded-md shadow-md py-4">
            <div className="flex flex-col items-center justify-center">
              <img
                src="../assets/images/oops.png"
                alt="Credit Score Not Available"
                width={200}
                height={200}
              />
              <P className="text-gray-500 text-center">
                CRIF has not shared any scoring factor for this report.
              </P>
            </div>
          </div>
        )}
      </div>
    </Section>
  );
};

export default ScoringFactor;

const ListItem = ({
  count,
  text,
  className,
}: {
  count: number;
  text: string;
  className: any;
}) => {
  return (
    <li className="text-gray-600 w-full py-2 flex text-base">
      <span className="relative z-10 mr-2.5 flex h-[22px] w-full max-w-[22px] items-center justify-center rounded text-base text-white">
        <span
          className={`absolute top-0 left-0 z-[-1] h-full w-full -rotate-45 rounded ${className}`}
        ></span>
        {count}
      </span>
      {text}
    </li>
  );
};
