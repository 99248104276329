import { apiEndpoint } from "../../utils/endpoint";
import { useQuery } from "../../lib/react-query";
import { Customer } from "@appTypes/user-model";

export const ALL_CUSTOMER_QUERY_KEY = "QUERY_ALL_CUSTOMER";
export const ALL_CUSTOMER_QUERY_KEY_BY_ID = "QUERY_ALL_CUSTOMER_BY_ID";
export const ALL_CUSTOMER_QUERY_KEY_WITH_INCOMPLETE_PROFILE = "QUERY_ALL_CUSTOMER_WITH_INCOMPLETE_PROFILE";
export const ALL_CUSTOMER_QUERY_KEY_WITH_COMPLETED_PROFILE = "QUERY_ALL_CUSTOMER_WITH_COMPLETED_PROFILE";
export const ALL_CUSTOMER_QUERY_KEY_WITH_INVALID_CRIF_PULLS = "QUERY_ALL_CUSTOMER_WITH_INVALID_CRIF_PULLS";
export const ALL_CUSTOMER_QUERY_KEY_WITH_SUCCESS_CRIF_PULLS = "QUERY_ALL_CUSTOMER_WITH_SUCCESS_CRIF_PULLS";
export const ALL_CUSTOMER_QUERY_KEY_WITH_PARTIAL_DOCUMENT = "QUERY_ALL_CUSTOMER_WITH_PARTIAL_DOCUMENT";

export const useCustomer = (): {
  data: any;
  isLoading: boolean;
  refetch: () => void;
} => {
  const { data, isLoading, refetch } = useQuery(apiEndpoint.common.getAllCustomers(0, 300, 'created_at', true), ALL_CUSTOMER_QUERY_KEY, {}, true, {
    staleTime: 300000,
    cacheTime: 300000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    data,
    isLoading,
    refetch
  };
};

export const useAssignedCustomer = (executiveEmail: string) => {
  const { data, isLoading, refetch } = useQuery(apiEndpoint.common.getListOfAssignedCustomers(executiveEmail), "ALL_CUSTOMER_ASSIGNED_KEY", {}, true, {
    staleTime: 300000,
    cacheTime: 300000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    data,
    isLoading,
    refetch
  };
}

export const useAllCustomer = (): {
  data: any;
  isLoading: boolean;
  refetch: () => void;
} => {
  const { data, isLoading, refetch } = useQuery(apiEndpoint.common.getAllCustomers(0, 10, 'updated_at', true), ALL_CUSTOMER_QUERY_KEY, {}, true, {
    staleTime: 300000,
    cacheTime: 300000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    data,
    isLoading,
    refetch
  };
};

export const useCustomerById = (id: string): {
  data: { customer: Customer };
  isLoading: boolean;
  refetch: () => void;
} => {
  const endpoint = apiEndpoint.common.getCustomerById(id);
  const { data, isLoading, refetch } = useQuery<{ customer: Customer }>(endpoint, ALL_CUSTOMER_QUERY_KEY_BY_ID + id, {}, true, {
    staleTime: 100000,
    cacheTime: 100000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    data,
    isLoading,
    refetch
  };
}

export const useCustomerWithIncompleteProfile = (): {
  data: any;
  isLoading: boolean;
  refetch: () => void;
} => {
  const { data, isLoading, refetch } = useQuery(apiEndpoint.common.getCustomersWithIncompleteProfile(0, 10, 'updated_at', true), ALL_CUSTOMER_QUERY_KEY_WITH_INCOMPLETE_PROFILE, {}, true, {
    staleTime: 300000,
    cacheTime: 300000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    data,
    isLoading,
    refetch,
  };
}

export const useCustomerWithCompletedProfile = (): {
  data: any;
  isLoading: boolean;
  refetch: () => void;
} => {
  const { data, isLoading, refetch } = useQuery(apiEndpoint.common.getCustomerWithCompleteProfile(0, 10, 'updated_at', true), ALL_CUSTOMER_QUERY_KEY_WITH_COMPLETED_PROFILE, {}, true, {
    staleTime: 300000,
    cacheTime: 300000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    data,
    isLoading,
    refetch
  };
}

export const useCustomerWithInvalidCrifPulls = (): {
  data: any;
  isLoading: boolean;
} => {
  const { data, isLoading } = useQuery(apiEndpoint.common.getCustomerWithFailedCrifPull(0, 10, 'updated_at', true), ALL_CUSTOMER_QUERY_KEY_WITH_INVALID_CRIF_PULLS, {}, true, {
    staleTime: 1000 * 10,
    cacheTime: 1000 * 10,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    data,
    isLoading,
  };
}

export const useCustomerWithSuccessCrifPulls = (): {
  data: any;
  isLoading: boolean;
  refetch: () => void;
} => {
  const { data, isLoading, refetch } = useQuery(apiEndpoint.common.getCustomerWithSuccessfullCrifPull(0, 10, 'updated_at', true), ALL_CUSTOMER_QUERY_KEY_WITH_SUCCESS_CRIF_PULLS, {}, true, {
    staleTime: 300000,
    cacheTime: 300000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    data,
    isLoading,
    refetch,
  };
}

export const useCustomerWithOTPRequest = (): {
  data: any;
  isLoading: boolean;
} => {
  const { data, isLoading } = useQuery(apiEndpoint.common.getCustomerWithOTPRequest(0, 10, 'updated_at', true), ALL_CUSTOMER_QUERY_KEY_WITH_PARTIAL_DOCUMENT, {}, true, {
    staleTime: 300000,
    cacheTime: 300000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    data,
    isLoading,
  };
}

export const useCustomerWithPartialDocument = (): {
  data: any;
  isLoading: boolean;
} => {
  const { data, isLoading } = useQuery(apiEndpoint.common.getCustomersWithPartialDocument(0, 10, 'updated_at', true), ALL_CUSTOMER_QUERY_KEY_WITH_PARTIAL_DOCUMENT, {}, true, {
    staleTime: 300000,
    cacheTime: 300000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    data,
    isLoading,
  };
}