import React, { useEffect, useState } from 'react';
import { Row, Col, Tooltip } from 'antd';
import './userprofile-style.scss';
import dayjs from 'dayjs';
import CreditAnalysisSection from './creditAnalysisSection';
import { H4, P } from '../../common/components/elements/text';
import { useCustomerById } from '../../common/hooks/use-customer';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import Fieldset from '../../common/components/elements/form/Fieldset';
import { formatDate, formatTime } from '../../utils/formatter';
import UserSection from '../../common/sections/UserSection';
import PreviousPlans from '../../modules/customer/section/PreviousPlans';
import { PAGE } from '../../utils/constants/page';
import ErrorBox from '../../common/components/elements/ErrorBox';
import { documentNameByType, role } from '../../utils/constants/index';
import { useRole, useUser } from '../../common/hooks/use-user';
import CustomerReference from '../../modules/customer/section/CustomerReference';
import AllDocument from '../../modules/customer/section/AllDocument';
import { Document } from '@appTypes/user-model';
import Indicator from '../../common/components/elements/text/Indicator';
import BankStatementAnalysis from './bankStatementAnalysisSection';
import BankAccount from '../../modules/customer/section/BankAccount';
import {
  PrimaryButton,
  SuccessButton,
} from '../../common/components/elements/button';
import { draftConfirmPIIData } from '@appTypes/bank-statement-model';
import { useCreateBorrowerMutation } from '../../common/hooks/mutations/use-assign-mutation';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import PaymentLinkModal from '../../modules/customer/components/PaymentLinkModal';
import BankStatementReference from './bankStatementReference';
import CrifReference from '../../modules/customer/section/CrifReference';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { withAuth } from '../../common/hoc/withAuth';
import { useQueryClient } from '@tanstack/react-query';
import CrifHistorySection from '../../modules/creditScore/CrifHistorySection';
import { getNumberOfBankStatements } from '../../utils/utils';

const initialBankAccountInformation = {
  account_number: '',
  account_holder_name: '',
  bank_name: '',
  ifsc: '',
  father_fname: '',
  father_lname: '',
};

type UserField = {
  title: string;
  data: string;
};

type DocumentField = {
  title: string;
  image: string;
  fileName: string;
  showFileName?: boolean;
  children: React.ReactNode;
};

export const UserInfoField = ({ title, data }: UserField) => {
  return (
    <div className="flex items-start justify-between py-2 w-full">
      <P className="mr-2">
        <strong>{title}</strong>
      </P>
      <P>{data}</P>
    </div>
  );
};

export const DocumentInfoField = ({
  title,
  image,
  fileName,
  showFileName = false,
  children,
}: DocumentField) => (
  <div className="flex-col">
    <P className="mr-2">
      <strong>{documentNameByType[title]}</strong>
    </P>
    <div className="flex justify-start gap-4 p-4 rounded-xl bg-primary-50">
      <div className="w-10 h-10">
        <img src={`../assets/images/${image}`} alt={title} />
      </div>
      <P className="text-gray-500 mt-2">
        {fileName !== '' ? (
          <span>{showFileName ? fileName : 'File Available'}</span>
        ) : (
          <span>No File Available.</span>
        )}
      </P>
      {children}
    </div>
  </div>
);

const CustomerProfile = () => {
  const { currentUserRole } = useRole();
  const queryClient = useQueryClient();
  const { trackEvent, pushInstruction } = useMatomo();
  const [isPaymentLinkModalOpen, setIsPaymentLinkModalOpen] =
    useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [bankAccountInformation, setBankAccountInformation] = useState<
    draftConfirmPIIData[]
  >([initialBankAccountInformation]);
  const [analysisStatus, setAnalysisStatus] = useState<boolean>(false);

  let UserId = window.location.pathname.split('/')[2];
  const { data: customerData, isLoading, refetch } = useCustomerById(UserId);

  const { data: currentUser, isLoading: currentUserLoading } = useUser();

  const mutateCreateBorrower = useCreateBorrowerMutation();

  useEffect(() => {
    if (!isLoading && customerData) {
      const customer = customerData?.customer;
      const documents = customer.documents;

      setDocuments([]);
      setDocuments([
        {
          name: 'pan_uri',
          uri: customer?.pan_uri,
          password: '',
          type: 'pancard',
        },
        ...documents,
      ]);
    }
  }, [isLoading, customerData]);

  useEffect(() => {
    if (!currentUserLoading && currentUser) {
      const { manager, owner, executive } = currentUser;
      const user = manager || owner || executive;
      const customer = customerData?.customer;

      trackEvent({
        category: 'View Customer',
        action: user.profile.email,
        name: `${customer?.profile.phone_number}`,
        value: 1,
      });

      queryClient.setQueryData(['email'], `${user.profile.email}`);
      pushInstruction('setUserId', `${user.profile.email}`);
      var _paq = window._paq || [];
      _paq.push(['setUserId', `${user.profile.email}`]);
    }
  }, [currentUserLoading, currentUser, isLoading]);

  if (isLoading) return <FullPageLoader isOpen={true} />;
  if (!customerData) {
    return (
      <ErrorBox
        title="Customer Not Found"
        text="Customer does not exist in the system!"
        redirectButton={{
          text: 'View Dashboard',
          href: PAGE.dashboard.href,
        }}
      />
    );
  }

  const customer = customerData?.customer;
  const {
    pan_number,
    aadhaar_number,
    dob,
    created_at,
    city,
    state,
    pin_code,
    executive_email,
    i2i_borrower_id,
    is_profile_complete,
    is_cbl_requested,
    is_consultation_requested,
    is_eligible_for_ocb,
    exp_ocb_wo_sub
  } = customer;
  const {
    first_name,
    middle_name,
    last_name,
    phone_number,
    whatsapp_number,
    email,
    gender,
    account_status,
  } = customer?.profile;

  const references = customer?.references;

  let subscription;
  if (customer?.subscriptions.length > 0) {
    subscription = customer?.subscriptions.find(
      (sub) =>
        sub.cashfree_order_status === 'PAID' &&
        dayjs(sub.end_date).isAfter(dayjs()) &&
        dayjs(sub.start_date).isBefore(dayjs())
    );
  }

  const handleCreateBorrower = () => {
    const payload = {
      ...bankAccountInformation[0],
      customer_id: customer.id,
      ifs_code: bankAccountInformation[0].ifsc,
    };

    mutateCreateBorrower(payload).then(() => {});
  };

  return (
    <div className="space-y-8">
      <div className="grid gap-y-5">
        <UserSection
          user={{
            ...customer?.profile,
          }}
          showAllDetails={false}
          actionChildren={
            <>
              {(currentUserRole === role.manager ||
                currentUserRole === role.owner) &&
                (executive_email !== '' ? (
                  <Tooltip title={executive_email}>
                    <Indicator
                      title="Assigned Executive"
                      className="bg-green-300"
                    />
                  </Tooltip>
                ) : (
                  <div className="group relative w-max">
                    <Indicator
                      title="Executive Not Assigned"
                      className="bg-red-300"
                    />
                  </div>
                ))}
              {i2i_borrower_id ? (
                <Tooltip title="Borrower already exists on i2i">
                  <Indicator
                    title="Borrower already exists"
                    className="bg-green-300"
                  />
                </Tooltip>
              ) : (
                (currentUserRole === role.manager ||
                  currentUserRole === role.owner) && (
                    <SuccessButton
                      onClick={handleCreateBorrower}
                      disabled={!analysisStatus}
                      Icon={UserPlusIcon}
                    >
                  <Tooltip
                    title="Create borrower profile on i2i"
                    className="w-60"
                  >
                      Create Borrower
                  </Tooltip>
                    </SuccessButton>
                )
              )}
                <PrimaryButton
                  Icon={UserPlusIcon}
                  onClick={() => setIsPaymentLinkModalOpen(true)}
                >
              <Tooltip
                title="Create or check payment link status"
                placement="top"
              >
                  Payment Link
              </Tooltip>
                </PrimaryButton>
            </>
          }
        />
        <div className="grid grid-row-auto md:grid-cols-2 lg:grid-cols-3 gap-y-4">
          <Fieldset
            title="Personal Details"
            className="w-full max-w-[300px] mx-auto"
          >
            <UserInfoField
              title="Name"
              data={`${first_name || ''} ${middle_name || ''} ${
                last_name || ''
              }`}
            />
            <UserInfoField
              title="DOB"
              data={`${dob ? dayjs(dob).format('DD-MM-YYYY') : ''}`}
            />
            <UserInfoField title="Gender" data={gender} />
            <UserInfoField title="PAN No" data={pan_number} />
            <UserInfoField title="Aadhaar No" data={aadhaar_number} />
          </Fieldset>
          <Fieldset
            title="Contact Details"
            className="w-full max-w-[300px]  mx-auto"
          >
            <UserInfoField title="WhatsApp Number" data={whatsapp_number} />
            <UserInfoField title="Phone Number" data={phone_number} />
            <UserInfoField title="Email" data={email} />
          </Fieldset>
          <Fieldset
            title="Profile Details"
            className="w-full max-w-[300px] mx-auto"
          >
            <UserInfoField title="Email Verified" data={account_status} />
            <UserInfoField
              title="Joining Date"
              data={`${formatDate(created_at)}`}
            />
            <UserInfoField
              title="Joining Time"
              data={`${formatTime(created_at)}`}
            />
          </Fieldset>
          <Fieldset title="CBL Details" className="w-full max-w-[300px] mx-auto">
            <UserInfoField
              title="CBL Requested"
              data={is_cbl_requested ? 'Requested' : 'Not Requested'}
            />
            <UserInfoField
              title="Free Consultation"
              data={is_consultation_requested ? 'Applied' : 'Not Applied'}
            />
            <UserInfoField
              title="Experiment Bucket"
              data={!is_eligible_for_ocb
                ? 'Non-OCB'
                : exp_ocb_wo_sub
                ? 'Non-Subscription'
                : 'Subscription'}
            />
          </Fieldset>
          {/* <Fieldset title="Address" className="w-full max-w-[300px] mx-auto">
            <UserInfoField title="City" data={city} />
            <UserInfoField title="State" data={state} />
            <UserInfoField title="Pin Code" data={pin_code} />
          </Fieldset> */}
          <Fieldset
            title="Account Details"
            className="w-full max-w-[300px] mx-auto"
          >
            <UserInfoField
              title="Account"
              data={account_status === 'active' ? 'Verified' : 'Not Verified'}
            />
            <UserInfoField
              title="Profile"
              data={is_profile_complete ? 'Completed' : 'Not Completed'}
            />
            <UserInfoField
              title="Bank Statement"
              data={getNumberOfBankStatements(
                documents
              ) > 0 ? 'Uploaded' : 'Not Uploaded'}
            />
            {/* <UserInfoField
              title="Loan"
              data={is_cbl_requested ? 'Requested' : 'Not Requested'}
            /> */}
          </Fieldset>
          <Row>
            <Col
              lg={{ span: 25, offset: 1 }}
              md={24}
              sm={24}
              xs={24}
              className="active-plan"
            >
              <Row>
                <Col span={24}>
                  <H4>Current Plan</H4>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="plan-card">
                  <img src="../assets/images/svg-card.svg" alt="svg-card" />
                  {subscription ? (
                    <div className="plan-details">
                      <div className="about-plan">
                        <div className="plan-name capitalize">
                          {subscription.plan.category} Plan
                        </div>
                        <div className="time-duration">
                          of {subscription.plan.validity} months
                        </div>
                        <div className="date-purchase">
                          Date of Purchase:{' '}
                          {formatDate(subscription.start_date)}
                        </div>
                        <div className="date-expire">
                          Date of Expire: {formatDate(subscription.end_date)}
                        </div>
                      </div>
                      <div className="plan-amount">
                        &#x20B9; {subscription.amount}
                      </div>
                    </div>
                  ) : (
                    <div className="no-active-plan">No Active Plan</div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      {documents.length > 0 && <AllDocument documentData={documents} />}
      {documents.length > 0 && (
        <BankAccount
          documents={documents}
          bankAccountInformation={bankAccountInformation}
          setBankAccountInformation={setBankAccountInformation}
          analysisStatus={analysisStatus}
          setAnalysisStatus={setAnalysisStatus}
        />
      )}
      {customer?.subscriptions.length > 0 && (
        <PreviousPlans allPlans={customer.subscriptions} allRefunds={customer.refunds}/>
      )}
      <CreditAnalysisSection
        userId={UserId}
        documents={customerData?.customer?.documents}
        annualIncome={customer.annual_income}
      />
      <CrifHistorySection customerId={UserId} />
      <BankStatementAnalysis
        userId={UserId}
        documents={customerData?.customer?.documents}
      />
      <BankStatementReference
        userId={UserId}
        documents={customerData?.customer?.documents}
      />
      <div className="flex justify-around">
        {references.length > 0 && (
          <CustomerReference referenceData={references} />
        )}
        <CrifReference userId={UserId} />
      </div>
      <PaymentLinkModal
        isModalOpen={isPaymentLinkModalOpen}
        setIsModalOpen={setIsPaymentLinkModalOpen}
        customerId={UserId}
        whatsAppNo={customer?.profile?.phone_number}
      />
    </div>
  );
};

export default withAuth()(CustomerProfile);
