import { Button, Col, Row } from "antd";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./help-style.scss";

const HelpPage = () => {

  const [value, setValue] = useState("");

  return (
    <div>
      <Row>
        <Col span={24}>
          <ReactQuill theme="snow" value={value} onChange={setValue} />
        </Col>
      </Row>
      <Row>
        <Col lg={{ span:4, offset: 20 }} md={24} sm={24} xs={24} className="cms-pagesbtn-container">
          <Button className="btn btn-primary" type="primary">Submit</Button>
        </Col>
      </Row>
  </div>
  );
};

export default HelpPage;
