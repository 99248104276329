import { LowestBalance } from '@appTypes/bank-statement-model';
import { Document } from '@appTypes/user-model';
import DataTable from '../../common/components/tanstack-table';
import { MONTHS_ARRAY } from '../../utils/constants/index';
import { H5, H1 } from '../../common/components/elements/text';
import { BankStatement } from '../../common/hooks/use-bank-statement';
import { useFullPageLoader } from '../../common/hooks/use-full-page-loader';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { formatMoney } from '../../utils/formatter';
import {
  filterBankStatementData,
  getLowestBalance,
  getLowestMonthlyBalanceFlags,
  removeCharacterFromAmount,
} from '../../utils/index';
import { Tag } from 'antd';
import { Flag } from 'aws-sdk/clients/iot';
import { useState, useEffect, useMemo } from 'react';
import { TableHeaderBuilder } from 'react-table-utils';
import MonthlyBalanceLineChart from './components/MonthlyBalance';
import { BANK_STATEMENT_ANALYSIS_KEY } from './netCashFlow';
import { useCustomerById } from '../../common/hooks/use-customer';
import { useParams } from 'react-router-dom';

type Props = {};

const MonthlyLowestBalance: React.FC<Props> = () => {
  const { showLoader, hideLoader } = useFullPageLoader();
  const queryClient = useQueryClient();
  const [flag, setFlag] = useState<Flag>();
  const [monthlyLowestBalanceData, setMonthlyLowestBalanceData] = useState<
    LowestBalance[]
  >([]);

  const [chartData, setChartData] = useState<{
    months: string[];
    lineChartData: [
      { label: string; data: number[]; color: string; borderColor: string }
    ];
  }>({
    months: [],
    lineChartData: [
      {
        label: 'Lowest balance',
        data: [],
        color: 'rgba(37, 99, 235, 0.1)',
        borderColor: 'rgb(37, 99, 235)',
      },
    ],
  });

  const router = useParams();
  const userId = router.id as string;

  const { data, isLoading } = useCustomerById(userId);

  const getMonthlyLowestBalanceAnalysis = async (documents: Document[]) => {
    showLoader();
    let completeAnalysisData;
    const bankStatementAnalysisData = queryClient.getQueryData([
      BANK_STATEMENT_ANALYSIS_KEY + userId,
    ]);

    if (bankStatementAnalysisData) {
      completeAnalysisData = bankStatementAnalysisData;
      hideLoader();
    } else {
      const bankStatement = filterBankStatementData(documents);
      const { data, isBankStatementAvailable, isBankStatementEmpty } =
        await BankStatement(bankStatement);
      hideLoader();

      if (!isBankStatementAvailable || isBankStatementEmpty) return;
      completeAnalysisData = data;
      queryClient.setQueryData([BANK_STATEMENT_ANALYSIS_KEY + userId], data);
    }

    const completeAnalysisByAccount =
      // @ts-ignore
      completeAnalysisData?.analysis?.by_account;
    const accountNumber = Object.keys(completeAnalysisByAccount)[0];
    const completeDetail = completeAnalysisByAccount[accountNumber];

    let lowestBalanceData = queryClient.getQueryData<LowestBalance[]>([
      'LOWEST_MONTHLY_BALANCE',
    ]);

    if (!lowestBalanceData) {
      lowestBalanceData = getLowestBalance(completeDetail.closing_balances);
      queryClient.setQueryData(['LOWEST_MONTHLY_BALANCE'], lowestBalanceData);
    }

    setChartData({
      months: [],
      lineChartData: [
        {
          label: 'Lowest balance',
          data: [],
          color: 'rgba(37, 99, 235, 0.1)',
          borderColor: 'rgb(37, 99, 235)',
        },
      ],
    });

    lowestBalanceData.forEach((item) => {
      setChartData((prev: any) => {
        const updatedLineChartData = prev.lineChartData.map(
          (chart: { label: string; data: any }) => {
            if (chart.label === 'Lowest balance') {
              return {
                ...chart,
                data: [...chart.data, item.lowest_balance],
              };
            }
            return chart;
          }
        );

        return {
          months: [
            ...prev.months,
            `${MONTHS_ARRAY[item.period.month]}, ${String(
              item.period.year
            ).slice(-2)}`,
          ],
          lineChartData: updatedLineChartData,
        };
      });
    });

    setMonthlyLowestBalanceData(lowestBalanceData);
  };

  useEffect(() => {
    if (!isLoading && data?.customer)
      getMonthlyLowestBalanceAnalysis(data.customer.documents);
  }, []);

  const headerColumns = new TableHeaderBuilder<LowestBalance>()
    .add('period', (col) =>
      col.header('Month').cell(({ month, year }: any) => (
        <H5>
          {MONTHS_ARRAY[month]}, {String(year).slice(-2)}
        </H5>
      ))
    )
    .add('lowest_balance', (col) =>
      col
        .header('Lowest Balance')
        .cell((value) => (
          <>
            {getLowestMonthlyBalanceFlags(removeCharacterFromAmount(value))
              .color === 'red' ? (
              <Tag className="text-red-600 bg-red-100">
                {formatMoney(removeCharacterFromAmount(value))}
              </Tag>
            ) : (
              <Tag className="text-green-600 bg-green-100">
                {formatMoney(removeCharacterFromAmount(value))}
              </Tag>
            )}
          </>
        ))
    )
    .build();

  const columns: ColumnDef<LowestBalance>[] = useMemo(() => headerColumns, []);

  return (
    <div>
      <H1 className="text-center">Monthly Lowest Balance</H1>
      <DataTable columns={columns} data={monthlyLowestBalanceData} />
      <MonthlyBalanceLineChart
        months={chartData.months}
        lineChartData={chartData.lineChartData}
        title="Lowest Balance"
      />
    </div>
  );
};

export default MonthlyLowestBalance;
