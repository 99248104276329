import Table from '../../../common/components/data-table/paginateTable';
import { role } from '../../../utils/constants/index';
import FullPageLoader from '../../../common/components/elements/loader/FullPageLoader';
import { useAllCustomer } from '../../../common/hooks/use-customer';
import { useStatistics } from '../../../common/hooks/use-statistics';
import { useRole } from '../../../common/hooks/use-user';
import { apiEndpoint } from '../../../utils/endpoint';
import { Customer } from '@appTypes/user-model';
import { useEffect, useState } from 'react';
import { useAssignCustomersMutation } from '../../../common/hooks/mutations/use-assign-mutation';
import Section from '../../../common/layouts/PageSection';
import { CustomerColumn } from '../../../common/components/data-table/TableColumn';
import axios from '../../../lib/axios';

type Props = {
  executiveId: string;
  refetch: () => void;
};

const ListOfAllCustomers: React.FC<Props> = ({ executiveId, refetch }) => {
  const { currentUserRole } = useRole();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>([]);
  const { data: stats, isLoading: statsLoading } = useStatistics();
  const { data: allCustomerData, isLoading: allCustomerDataLoading } =
    useAllCustomer();

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [originalData, setOriginalData] = useState<Customer[]>([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const mutateExecutive = useAssignCustomersMutation();

  useEffect(() => {
    if (!allCustomerDataLoading && !statsLoading) {
      setCustomers(allCustomerData?.customers);
      setOriginalData(allCustomerData?.customers);
      setTotalDataCount(
        stats?.customer_profile_complete_count +
          stats?.customer_profile_incomplete_count
      );
    }
  }, [statsLoading, allCustomerDataLoading, allCustomerData]);

  const paginateData = async (pageSize: number, pageIndex: number, orderBy: string = 'updated_at', desc: boolean = true) => {
    const skip = pageSize * pageIndex;
    const limit = pageSize;

    const { data } = await axios.get(
      apiEndpoint.common.getAllCustomers(skip, limit, orderBy, desc)
    );

    setCustomers([]);
    // @ts-ignore
    setCustomers(data?.customers);
    // @ts-ignore
    setOriginalData(data?.customers);
  };

  const searchCustomer = async (
    name: string,
    pageIndex: number = 0,
    pageSize: number = 10
  ) => {
    if (name === '') {
      paginateData(pageSize, pageIndex);
    } else {
      const { data } = await axios.get(
        apiEndpoint.common.searchCustomer.allCustomers(name)
      );

      setCustomers([]);
      // @ts-ignore
      setCustomers(data?.customers);
      // @ts-ignore
      setOriginalData(data?.customers);
    }
  };

  const toggleCheckbox = (row: any) => {
    row.toggleRowSelected();
    if (!row.isSelected) {
      setSelectedCustomerIds((prev) => {
        return [...prev, row.values.id];
      });
    } else {
      setSelectedCustomerIds((prev) => {
        return prev.filter((id) => id !== row.values.id);
      });
    }
  };

  let rowSelected = false;

  const toggleSelectAll = (rows: any) => {
    if (!rowSelected) {
      const allUserIds = rows.map((row: any) => row.original.id);
      setSelectedCustomerIds(allUserIds);
      rowSelected = true;
    } else {
      setSelectedCustomerIds([]);
      rowSelected = false;
    }
  };

  const handleAssign = () => {
    mutateExecutive({
      executive_id: executiveId,
      customer_ids: selectedCustomerIds,
    }).then(() => {
      refetch();
    })
  };

  let columns = CustomerColumn(toggleCheckbox, 'customer', toggleSelectAll);
  if (allCustomerDataLoading || statsLoading) {
    return <FullPageLoader isOpen={true} className="text-brand-orange-500" />;
  }

  return (
    <Section
      title="List of All Customers"
      text="Select and assign customers to the executive"
    >
      <Table
        columns={columns}
        // @ts-ignore
        data={customers}
        isExcelSheetButtonVisible={false}
        showAssignButton={true}
        paginateData={paginateData}
        searchCustomer={searchCustomer}
        totalDataCount={totalDataCount}
        handleAssign={handleAssign}
      />
    </Section>
  );
};

export default ListOfAllCustomers;
