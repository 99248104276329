import { Tag } from 'antd';
import { useEffect, useState } from 'react';
import { apiEndpoint } from '../../utils/endpoint';
import { useQueryData } from '../../lib/react-query';
import { useParams, useLocation } from 'react-router-dom';
import { H1, H4, H5 } from '../../common/components/elements/text';
import {
  getPaymentHistoryFlags,
  convertCardNumber,
  removeCharacterFromAmount,
} from '../../utils/index';
import { formatMoney } from '../../utils/formatter';
import Divider from '../../common/components/elements/Divider';
import PaymentHistoryCard from './paymentHistoryCard';
import { useRole } from '../../common/hooks/use-user';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import {
  IPaymentHistory,
  PaymentHistoryDetail,
} from '@appTypes/payment-history-model';
import { Flag } from '@appTypes/credit-analysis';

const PAYMENT_HISTORY = 'PAYMENT_HISTORY';

const PaymentHistory = () => {
  const [flag, setFlag] = useState<Flag>();
  const [paymentHistoryRate, setPaymentHistoryRate] = useState(0);
  const [paymentHistoryDetails, setPaymentHistoryDetails] = useState<
    PaymentHistoryDetail[]
  >([]);

  const router = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const reportId = queryParams.get('report_id');
  const userId = router.id as string;

  const { currentUserRole } = useRole();
  const { data, isLoading } = useQueryData(
    apiEndpoint.creditAnalysis.getPaymentHistory(currentUserRole, userId),
    PAYMENT_HISTORY + userId
  );

  useEffect(() => {
    if (!isLoading && data) {
      // @ts-ignore
      const { payment_history, payment_history_details } = data.filter(
        (item: IPaymentHistory) =>
          item.detailed_payment_history.report_id === reportId
      )[0].detailed_payment_history;
      setPaymentHistoryDetails(payment_history_details);

      if (payment_history === -1) setPaymentHistoryRate(0);
      else setPaymentHistoryRate(payment_history);
      setFlag(getPaymentHistoryFlags(payment_history));
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <FullPageLoader isOpen={true} />;
  }

  return (
    <div className="py-4">
      <H1 className="text-center">Payment History</H1>
      <div className="flex py-2">
        <H5 className="text-gray-500">Payment repaying till 2023: </H5>
        <H5
          className={`text-lg text-bold ${
            getPaymentHistoryFlags(paymentHistoryRate!).color === 'green'
              ? 'text-green-600'
              : 'text-red-600'
          } pl-2`}
        >
          {paymentHistoryRate}%{' '}
        </H5>
      </div>

      {paymentHistoryDetails.length > 0 &&
        paymentHistoryDetails.map((paymentHistory: any, index: number) => {
          return (
            <div
              key={index}
              className="border border-gray-300 py-4 p-4 md:p-8 rounded-lg my-4 bg-white"
            >
              <H5 className="text-gray-500 text-bold">Name</H5>
              <div className="flex">
                <H5 className="text-primary-500 tex-md sm:text-lg">
                  {paymentHistory.credit_guarantor}
                </H5>
                {paymentHistory.account_status === 'Closed' ? (
                  <Tag className="ml-4 h-6 mt-2 text-red-500 bg-red-100">
                    {paymentHistory.account_status}
                  </Tag>
                ) : (
                  <Tag className="ml-4 h-6 mt-2 text-green-500 bg-green-100">
                    {paymentHistory.account_status}
                  </Tag>
                )}
              </div>
              <div className="flex justify-between flex-col sm:flex-row gap-4 py-6">
                <div>
                  <H5 className="text-gray-500 text-bold">Credit Limit</H5>
                  <H4 className="text-primary-500">
                    {formatMoney(
                      removeCharacterFromAmount(paymentHistory.credit_limit)
                    )}
                  </H4>
                </div>
                <div>
                  <H5 className="text-gray-500 text-bold">Highest Credit</H5>
                  <H4 className="text-primary-500">
                    {formatMoney(
                      removeCharacterFromAmount(paymentHistory.highest_credit)
                    )}
                  </H4>
                </div>
                <div>
                  <H5 className="text-gray-500 text-bold">Amount Overdue</H5>
                  <H4 className="text-primary-500">
                    {formatMoney(
                      removeCharacterFromAmount(paymentHistory.amount_overdue)
                    )}
                  </H4>
                </div>
              </div>

              <Divider className="text-gray-200" />

              <div className="py-6">
                <div className="flex justify-between py-2 flex-col sm:flex-row gap-2">
                  <div className="flex">
                    <H5 className="text-gray-500 text-bold">
                      Open Date:&nbsp;
                    </H5>
                    <H5 className="text-primary-500">
                      {paymentHistory.open_date}
                    </H5>
                  </div>
                  <div className="flex">
                    <H5 className="text-gray-500 text-bold">
                      Last Payment Date:&nbsp;
                    </H5>
                    <H5 className="text-primary-500">
                      {paymentHistory.last_payment_date}
                    </H5>
                  </div>
                </div>
                <div className="flex py-2 flex-col sm:flex-row gap-4">
                  <div className="flex">
                    <H5 className="text-gray-500 text-bold">
                      On Time Payments&nbsp;
                    </H5>
                    <Tag className="rounded-full h-8 w-8 text-center text-green-500 bg-green-100">
                      {paymentHistory.on_time_payments}
                    </Tag>
                  </div>
                  <div className="border-l-2 border-gray-200 h-8 mx-2 hidden sm:block"></div>
                  <div className="flex">
                    <H5 className="text-gray-500 text-bold">
                      Missed Payments&nbsp;
                    </H5>
                    <Tag className="rounded-full h-8 w-8 text-center text-red-500 bg-red-100">
                      {paymentHistory.missed_payments}
                    </Tag>
                  </div>
                </div>
              </div>

              <Divider className="text-gray-200" />

              <div className="py-6">
                <div className="flex py-2 flex-col sm:flex-row">
                  <H4 className="text-primary-500 text-bold break-all">
                    Account - {convertCardNumber(paymentHistory.account_number)}
                    &nbsp;
                  </H4>
                  <H5 className="text-gray-400 text-xs">
                    ({paymentHistory.account_type})
                  </H5>
                </div>
                {paymentHistory?.yearly_data.length > 0 && (
                  <PaymentHistoryCard
                    yearlyPaymentData={paymentHistory.yearly_data}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PaymentHistory;
