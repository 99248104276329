import { apiEndpoint } from '../../utils/endpoint';
import { useQuery, useQueryData } from '../../lib/react-query';
import { useLocation, useParams } from 'react-router-dom';
import InlineLoader from '../../common/components/elements/loader/InlineLoader';
import { useEffect, useState } from 'react';
import { H1, H3, H5, P } from '../../common/components/elements/text';
import DataTable from '../../common/components/tanstack-table/index';
import {
  convertCardNumber,
  getOverdueAccountsFlags,
  removeCharacterFromAmount,
} from '../../utils/index';
import { formatMoney } from '../../utils/formatter';
import { TableHeaderBuilder } from 'react-table-utils';
import { IOverdueAccount, OverdueAccountElement } from '../../common/types/overdue-account-model';
import { Tag } from 'antd';
import { Flag } from '../../common/types/credit-analysis';
import { useRole } from '../../common/hooks/use-user';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';

const OVERDUE_ACCOUNT = 'OVERDUE_ACCOUNT';

const OverDueAccount = () => {
  const [totalOverdueAccount, setTotalOverdueAccount] = useState(0);
  const [totalOverdueAmount, setTotalOverdueAmount] = useState(0);
  const [overdueAccountData, setOverdueAccountData] = useState<
    OverdueAccountElement[]
  >([]);
  const [flag, setFlag] = useState<Flag>();

  const router = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const reportId = queryParams.get('report_id');
  const userId = router.id as string;

  const { currentUserRole } = useRole();
  const { data, isLoading } = useQueryData(
    apiEndpoint.creditAnalysis.getOverdueAccount(currentUserRole, userId),
    OVERDUE_ACCOUNT + userId
  );

  useEffect(() => {
    if (!isLoading && data) {
      const {
        overdue_accounts_count,
        overdue_accounts_details,
        total_overdue_amount,
        // @ts-ignore
      } = data.filter(
        (item: IOverdueAccount) =>
          item.detailed_overdue_accounts.report_id === reportId
      )[0].detailed_overdue_accounts;
      
      setTotalOverdueAccount(overdue_accounts_count);
      setTotalOverdueAmount(total_overdue_amount);
      setOverdueAccountData(overdue_accounts_details);
      setFlag(getOverdueAccountsFlags(overdue_accounts_count));
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <FullPageLoader isOpen={true} />;
  }

  // if (overdueAccountData.length === 0) {
  //   return <NotFoundBox title="Overdue Account" />;
  // }

  if (overdueAccountData.length) {
    overdueAccountData.forEach((item) => {
      // @ts-ignore
      item.account_combined = [];
      // @ts-ignore
      item.account_combined = [item.account, item.account_number];
    });
  }

  const headerColumns = new TableHeaderBuilder<OverdueAccountElement>()
    .add('account_combined', (col) =>
      col.header('ACCOUNT').cell((value) => (
        <>
          <H5 className="text-primary-500 py-1">{value[0]},</H5>
          <H5 className="text-primary-500 break-all">
            A/C- {convertCardNumber(value[1])}
          </H5>
        </>
      ))
    )
    .add('current_balance', (col) =>
      col
        .header('CURRENT BALANCE')
        .cell((value: string) => (
          <H5 className="text-primary-500">
            {formatMoney(removeCharacterFromAmount(value))}
          </H5>
        ))
    )
    .add('overdue_amount', (col) =>
      col
        .header('OVERDUE AMOUNT')
        .cell((value: string) => (
          <H5 className="text-primary-500 text-bold">
            {formatMoney(removeCharacterFromAmount(value))}
          </H5>
        ))
    )
    .add('account_status', (col) =>
      col
        .header('STATUS')
        .cell((value: string) => (
          <>
            {value === 'Active' ? (
              <Tag className="text-green-500 bg-green-100">{value}</Tag>
            ) : (
              <Tag className="text-red-500 bg-red-100">{value}</Tag>
            )}
          </>
        ))
    )
    .build();

  return (
    <div className="py-4">
      <H1 className="text-center">Overdue Accounts</H1>
      <div className="flex py-2">
        <H5 className="text-gray-500">Accounts overdue till 2023: </H5>
        <H5
          className={`text-lg text-bold ${
            flag?.color === 'green' ? 'text-green-600' : 'text-red-600'
          } pl-2`}
        >
          {totalOverdueAccount}
        </H5>
      </div>
      {overdueAccountData.length > 0 && (
        <DataTable columns={headerColumns} data={overdueAccountData} />
      )}
      <div className="flex py-2">
        <H5 className="text-gray-500">Total Overdue Amount: </H5>
        <H5 className="text-lg text-bold text-primary-500 pl-2">
          {formatMoney(totalOverdueAmount)}
        </H5>
      </div>
    </div>
  );
};

export default OverDueAccount;
