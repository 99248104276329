export interface ICreditReport {
  creditScore: number;
}

export interface Analyses {
  report_date: string;
  score: number;
  credit_utilization_ratio?: number;
  active_accounts_count?: number;
  age_of_credit?: number;
  credit_behavior?: number;
  overdue_accounts_count?: number;
  average_delay?: number;
  credit_inquiry_count?: number;
  payment_history?: number
}

export interface CrifData {
  full_name: string,
  request_date: string,
  report_date: string,
  updated_at: string,
  report_id: string,
  credit_score: number,
  credit_utilization_ratio: number,
  credit_behavior: number,
  negative_accounts_count: number,
  total_accounts_count: number,
  overdue_accounts_count: number,
  credit_inquiry_count: number,
  age_of_credit: {
      years: number,
      months: number,
      days: number
  },
  payment_history: number,
  maximum_delay: number,
  average_delay: number,
  active_accounts_count: number,
  closed_accounts_count: number,
  secured_loans_count: number,
  unsecured_loans_count: number,
  total_outstanding_amount: number,
  credit_cards_count: number,
  home_loans_count: number,
  personal_loans_count: number,
  other_loans_count: number,
  sanctioned_amount: number,
  total_active_debt: number,
  vintage_month?: number,
  scoring_factors: {
    negative_factors: string[],
    positive_factors: string[]
  },
}

export const initialCrifData: CrifData = {
  full_name: "",
  request_date: "",
  report_date: "",
  updated_at: "",
  report_id: "",
  credit_score: 0,
  credit_utilization_ratio: 0,
  credit_behavior: 0,
  negative_accounts_count: 0,
  total_accounts_count: 0,
  overdue_accounts_count: 0,
  credit_inquiry_count: 0,
  age_of_credit: {
      years: 0,
      months: 0,
      days: 0
  },
  payment_history: 0,
  maximum_delay: 0,
  average_delay: 0,
  active_accounts_count: 0,
  closed_accounts_count: 0,
  secured_loans_count: 0,
  unsecured_loans_count: 0,
  total_outstanding_amount: 0,
  credit_cards_count: 0,
  home_loans_count: 0,
  personal_loans_count: 0,
  other_loans_count: 0,
  sanctioned_amount: 0,
  total_active_debt: 0,
  scoring_factors: {
    negative_factors: [],
    positive_factors: []
  },
};

export const initialEmptyCrifData = {
  full_name: "NA",
  request_date: "NA",
  report_date: "NA",
  updated_at: "NA",
  report_id: "NA",
  credit_score: 'NA',
  score: 'NA',
  credit_utilization_ratio: 'NA',
  credit_behavior: 'NA',
  negative_accounts_count: 'NA',
  total_accounts_count: 'NA',
  overdue_accounts_count: 'NA',
  credit_inquiry_count: 'NA',
  age_of_credit: 'NA',
  payment_history: 'NA',
  maximum_delay: 'NA',
  average_delay: 'NA',
  active_accounts_count: 'NA',
  closed_accounts_count: 'NA',
  secured_loans_count: 'NA',
  unsecured_loans_count: 'NA',
  total_outstanding_amount: 'NA',
  credit_cards_count: 'NA',
  home_loans_count: 'NA',
  personal_loans_count: 'NA',
  other_loans_count: 'NA',
  sanctioned_amount: 'NA',
  total_active_debt: 'NA',
  scoring_factors: {
    negative_factors: [],
    positive_factors: []
  },
};

export type CrifHistory = {
  id: string;
  created_at: string;
  crif_json: any
};