import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import './login-style.scss';
import Form from '../../common/components/form';
import { initialLoginCredentials } from '../../common/types/auth-model';
import { loginSchema } from '../../common/schema/auth';
import Input from '../../common/components/elements/form/Input';
import { ROLE_KEY, role, roleOptions } from '../../utils/constants/index';
import ListInput from '../../common/components/elements/form/ListInput';
import LinkedItem from '../../common/components/elements/LinkedItem';
import { useLogin } from '../../common/hooks/mutations/use-auth';
import { PAGE } from '../../utils/constants/page';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { getRole } from '../../utils/index';
import { storage } from '../../utils/storage';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const LoginPage = () => {
  const queryClient = useQueryClient();
  const [currentRole, setCurrentRole] = useState(role.executive);
  const navigate = useNavigate();
  const { trackEvent, pushInstruction } = useMatomo();
  const { login } = useLogin();
  // TODO: Use Redux for login
  // const onFinish = (values: any) => {
  //   setIsLoading(true);
  //   dispatch({ type: LoginActionTypes.LOGINREQUEST, loading: true });
  //   PostApiCallLogin(`${url}managers/signin`, values).then((value: User) => {
  //     if (value.status === true) {
  //       localStorage.setItem('user', JSON.stringify(value));
  //       localStorage.setItem('User', JSON.stringify(value?.user));
  //       storage.setToken(value?.token);
  //       dispatch({ type: LoginActionTypes.LOGINSUCCESS, isLoggedIn: true });
  //       dispatch({ type: LoginActionTypes.LOGINREQUEST, loading: false });
  //       dispatch({ type: LoginActionTypes.LOGINGETUSER, User: value.user });
  //       setIsLoading(false);
  //       navigate('/dashboard');
  //     } else {
  //       messageApi.open({
  //         type: 'error',
  //         content: 'Please check the email and password',
  //       });
  //       setIsLoading(false);
  //     }
  //   });
  // };

  useEffect(() => {
    if (storage.getToken()) {
      navigate(PAGE.dashboard.href);
    }
  }, []);

  return (
    <div className="login-page">
      <Row gutter={0}>
        <Col xl={6} lg={8} md={12} sm={20} xs={24} className="login-box">
          <Row>
            <Col span={24}>
              <div className="illustration-wrapper">
                <img src="./assets/images/logo1.svg" alt="logo" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="mb-10">
                <Form
                  initialValues={initialLoginCredentials}
                  schema={loginSchema}
                  onSubmit={async (data, reset) => {
                    const response = await login(data);
                    const currentUserRole = getRole();

                    if (response) {
                      trackEvent({
                        category: 'Sign In',
                        action: 'Authenticates a user',
                        name: currentUserRole + ': ' + data.email,
                        value: 1,
                      });
                      pushInstruction('setUserId', `${data.email}`);
                      var _paq = window._paq || [];
                      queryClient.setQueryData(['email'], `${data.email}`);
                      _paq.push(['setUserId', `${data.email}`]);

                      queryClient.setQueryData([ROLE_KEY], currentUserRole);
                      navigate(PAGE.dashboard.href);
                    } else {
                      trackEvent({
                        category: 'Sign In',
                        action: 'Authenticates a user Failed',
                        name: currentUserRole + ': ' + data.email,
                        value: 0,
                      });
                      console.log('error');
                    }
                    // reset()
                  }}
                  submitButton={{
                    text: 'Login',
                    IconRight: ChevronDoubleRightIcon,
                    className: 'w-full mx-auto',
                  }}
                >
                  <h3>Welcome</h3>
                  <ListInput
                    options={roleOptions}
                    name="role"
                    label="Role"
                    onChange={(data) => {
                      setCurrentRole(data.value);
                    }}
                    required
                  />
                  <Input name="email" placeholder="Email" required />
                  <Input
                    name="password"
                    placeholder="Password"
                    type="password"
                    required
                  />
                  {currentRole === role.owner && (
                    <div className="text-sm text-right">
                      <LinkedItem
                        href={PAGE.register.href}
                        className="font-bold text-primary-300"
                      >
                        Create an account
                      </LinkedItem>
                    </div>
                  )}
                  {currentRole !== role.owner && (
                    <div className="text-sm text-right">
                      <LinkedItem
                        href={PAGE.acceptInvitation.href}
                        className="font-bold text-primary-300"
                      >
                        Accept Invite
                      </LinkedItem>
                    </div>
                  )}
                </Form>
                <div className="text-sm text-center mt-2">
                  <LinkedItem
                    href={PAGE.forgotPassword.href}
                    className="font-bold text-primary-300"
                  >
                    Forgot password?
                  </LinkedItem>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
