import React, { useState, useRef } from 'react';
import {
  Button,
  Form,
  Input,
  Select
} from 'antd';

const AboutUsForm = () => {
  const editor = useRef(null)
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
    >
    
      <Form.Item label="Input">
        <Input />
      </Form.Item>
      <Form.Item label="Select">
        <Select>
          <Select.Option value="demo">Demo</Select.Option>
        </Select>
      </Form.Item>
       <Form.Item label="editor">
       </Form.Item>
     
      <Form.Item>

        <Button>Button</Button>
      </Form.Item>
    </Form>
  );
};

export default AboutUsForm;