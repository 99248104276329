import React from "react";
import { Component } from "@appTypes/.";
import cx from "clsx";

type Props = {
  direction?: "row" | "col";
  children?: React.ReactNode;
};

const ButtonGroup: Component<Props> = ({
  children,
  className,
  direction = "col",
}) => {
  return (
    <div
      className={cx(
        "grid ml-auto gap-4",
        direction === "col" ? "grid-flow-col" : "grid-flow-row",
        className
      )}
    >
      {children}
    </div>
  );
};

export default ButtonGroup;
