import {
  NewspaperIcon,
  ArrowLongRightIcon,
  CalendarDaysIcon,
} from '@heroicons/react/24/outline';
import convertUserManagementListToExcel from '../../../common/components/form/ExcelSheet';
import { PrimaryButton } from '../../../common/components/elements/button';
import {
  showErrorToast,
  showInfoToast,
  showWarningToast,
} from '../../../utils/toast';
import axios from '../../../lib/axios';
import { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import {
  formatDate,
  formatDateToYYYYMMDD,
  formatDateWithTime,
  formatDateWithoutTime,
} from '../../../utils/formatter';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Tooltip } from 'antd';

type Props = {
  url: string;
  fileName?: string;
  totalCustomers?: number;
  apiUrl?: string;
};

const DownloadExcelSheet: React.FC<Props> = ({
  fileName = 'User-List',
  totalCustomers,
  apiUrl,
}) => {
  const { trackEvent } = useMatomo();
  const [isLoading, setIsLoading] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleSheetDownload = async () => {
    setIsLoading(true);
    const batchSize = 1000;
    showInfoToast('Hang on while we download the sheet');
    const allData = [];

    let offset = 0;
    while (offset < totalCustomers!) {
      const currentBatchSize = Math.min(batchSize, totalCustomers! - offset);

      try {
        const { data } = await axios.get(
          `${apiUrl}skip=${offset}&limit=${currentBatchSize}&order_by=updated_at&desc_order=true&start_date=${formatDateToYYYYMMDD(
            dateRange[0].startDate
          )}&end_date=${formatDateToYYYYMMDD(dateRange[0].endDate)}`
        );

        if (data.customers.length == 0) break;
        allData.push(...data.customers);
      } catch (error) {
        showErrorToast('Something went wrong while downloading sheet');
        console.error('Error fetching data:', error);
      }

      offset += currentBatchSize;
    }

    trackEvent({
      category: 'Download Sheet',
      action: fileName,
      name: `range: ${formatDateToYYYYMMDD(
        dateRange[0].startDate
      )} - ${formatDateToYYYYMMDD(dateRange[0].startDate)}, customers: ${
        allData.length
      }`,
      value: 1,
    });

    convertUserManagementListToExcel(allData, fileName);
    setIsLoading(false);
  };

  const handleSelect = (ranges: any) => {
    let { startDate, endDate } = ranges.selection;

    const rangeInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    if (rangeInDays > 31) {
      showWarningToast('Please select a date range of 31 days or less.');
      return;
    }

    setDateRange([{ startDate, endDate, key: 'selection' }]);
  };

  return (
    <div className="relative">
      <div className="flex space-x-4">
          <PrimaryButton
            Icon={NewspaperIcon}
            onClick={handleSheetDownload}
            loading={isLoading}
          >
        <Tooltip
          title={`This will download data between dates ${formatDateWithTime(
            dateRange[0].startDate
          )} to ${formatDateWithTime(
            dateRange[0].endDate
          )}. Use the date range filter to select a custom date range.`}
          placement="top"
        >
            Download Sheet
        </Tooltip>
          </PrimaryButton>
        <div>
          {showDatePicker && (
            <div className="relative">
              <DateRangePicker
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
                rangeColors={['#0052ff']}
                months={1}
                ranges={dateRange}
                maxDate={new Date()}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
                inputRanges={[]}
                className="z-50 absolute top-11 left-0 p-4 border-2 border-gray-300 rounded-md shadow-md bg-white"
              />
              <div onClick={() => setShowDatePicker(!showDatePicker)}>
                <XCircleIcon className="z-50 absolute top-10 left-[565px] cursor-pointer w-6 h-6 mx-2 text-red-600" />
              </div>
            </div>
          )}
          <Tooltip
            title="Choose a start date and end date for customer data within a maximum range of 31 days."
            placement="top"
          >
            <div
              className={`flex p-2 cursor-pointer border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none ring-brand-orange-300 focus:ring ${
                showDatePicker && 'ring-2 ring-brand-orange-500'
              }}`}
              onClick={() => setShowDatePicker(!showDatePicker)}
            >
              <div className="text-gray-600">
                <span className="font-bold">Start Date: </span>
                {dateRange[0].startDate.toLocaleDateString('en-IN')}
              </div>
              <ArrowLongRightIcon className="w-6 h-6 mx-2 text-gray-400" />
              <br />
              <div className="text-gray-600">
                <span className="font-bold">End Date: </span>
                {dateRange[0].endDate.toLocaleDateString('en-IN')}
              </div>
              <br />
              <CalendarDaysIcon className="w-6 h-6 mx-2 text-gray-400" />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default DownloadExcelSheet;
