import clsx from 'clsx';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const GridContainer = ({ children, className }: Props) => {
  return (
    <div
      className={clsx(
        'w-fit mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center justify-center gap-y-12 gap-x-8 mt-10 mb-10',
        className
      )}
    >
      {children}
    </div>
  );
};

export default GridContainer;
