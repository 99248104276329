import * as y from 'yup';

export const profileSchema = y.object().shape({
  first_name: y.string().required("First name is required"),
  last_name: y.string().required("Last name is required"),
  email: y.string().email("Invalid email").required("Email is required"),
  dob: y
    .date()
    .required('Date of birth is required')
    .min(new Date('1900-01-01'), 'Year must be 1900 or greater')
    .max(new Date(), 'Date of birth cannot be in the future')
    .test('is-of-age', 'Age must be 18 or greater', (value) => {
      const currentDate = new Date();
      const dob = new Date(value!);
      const age = currentDate.getFullYear() - dob.getFullYear();
      const monthDiff = currentDate.getMonth() - dob.getMonth();

      if (age > 18) {
        return true;
      } else if (age === 18) {
        return monthDiff > 0 || (monthDiff === 0 && currentDate.getDate() >= dob.getDate());
      } else {
        return false;
      }
    }),
  pan_number: y
    .string()
    .required('PAN card is required')
    .matches(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/, 'Invalid PAN card format'),
  employer_name: y.string().nullable().default(''),
  whatsapp_number: y
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .length(10, "Whatsapp number must be of 10 digits")
    .required("Whatsapp number is required"),
  phone_number: y
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .length(10, "Phone number must be of 10 digits")
    .required("Phone number is required"),
  role: y.string().required("Role is required"),
})