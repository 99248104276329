import React from 'react';
import cx from 'clsx';
import { Link } from 'react-router-dom';

export type Props = {
  href: string;
  target?: '_self' | '_blank';
  className?: string;
  shallow?: boolean;
  mailto?: boolean;
  tel?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

const LinkedItem: React.FC<Props> = ({
  href,
  target = '_self',
  className,
  children,
  shallow = false,
  mailto = false,
  tel = false,
  onClick,
}) => {
  const hrefWithProtocol = mailto
    ? `mailto:${href}`
    : tel
    ? `tel:${href}`
    : href;

  return (
    <Link
      to={hrefWithProtocol}
      className={cx(
        'text-primary-300 hover:text-primary-600 transition-colors',
        className
      )}
      {...{ target, onClick }}
    >
      {children}
    </Link>
  );
};

export default LinkedItem;
