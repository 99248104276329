import {
  MonthlyBalanceResponse,
  MonthlySummaryResponse,
} from '@appTypes/bank-statement-model';
import { Document } from '@appTypes/user-model';
import DataTable from '../../common/components/tanstack-table';
import { MONTHS_ARRAY } from '../../utils/constants/index';
import { H5, H1 } from '../../common/components/elements/text';
import { BankStatement } from '../../common/hooks/use-bank-statement';
import { useFullPageLoader } from '../../common/hooks/use-full-page-loader';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { formatMoney } from '../../utils/formatter';
import {
  filterBankStatementData,
  removeCharacterFromAmount,
  getAverageMonthlyBalanceFlags,
} from '../../utils/index';
import { Tag } from 'antd';
import { Flag } from 'aws-sdk/clients/iot';
import { useState, useEffect, useMemo } from 'react';
import { TableHeaderBuilder } from 'react-table-utils';
import MonthlyBalanceLineChart from './components/MonthlyBalance';
import { BANK_STATEMENT_ANALYSIS_KEY } from './netCashFlow';
import { useCustomerById } from '../../common/hooks/use-customer';
import { useParams } from 'react-router-dom';

type Props = {};

const AverageMonthlyBalance: React.FC<Props> = () => {
  const { showLoader, hideLoader } = useFullPageLoader();
  const queryClient = useQueryClient();
  const [flag, setFlag] = useState<Flag>();
  const [avgMonthlyBalanceData, setAvgMonthlyBalanceData] = useState<
    MonthlyBalanceResponse[]
  >([]);
  const [chartData, setChartData] = useState<{
    months: string[];
    lineChartData: [
      { label: string; data: number[]; color: string; borderColor: string },
      { label: string; data: number[]; color: string; borderColor: string }
    ];
  }>({
    months: [],
    lineChartData: [
      {
        label: 'Closing balance',
        data: [],
        color: 'rgba(37, 99, 235, 0.1)',
        borderColor: 'rgb(37, 99, 235)',
      },
      {
        label: 'Average Monthly Balance',
        data: [],
        color: 'rgba(60, 186, 159, 0.1)',
        borderColor: 'rgb(60, 186, 159)',
      },
    ],
  });

  const router = useParams();
  const userId = router.id as string;

  const { data, isLoading } = useCustomerById(userId);

  const getAverageMonthlyBalanceAnalysis = async (documents: Document[]) => {
    showLoader();
    let completeAnalysisData;
    const bankStatementAnalysisData = queryClient.getQueryData([
      BANK_STATEMENT_ANALYSIS_KEY + userId,
    ]);

    if (bankStatementAnalysisData) {
      completeAnalysisData = bankStatementAnalysisData;
      hideLoader();
    } else {
      const bankStatement = filterBankStatementData(documents);
      const { data, isBankStatementAvailable, isBankStatementEmpty } =
        await BankStatement(bankStatement);
      hideLoader();

      if (!isBankStatementAvailable || isBankStatementEmpty) return;
      completeAnalysisData = data;
      queryClient.setQueryData([BANK_STATEMENT_ANALYSIS_KEY + userId], data);
    }

    const completeAnalysisByAccount =
      // @ts-ignore
      completeAnalysisData?.analysis?.by_account;
    const accountNumber = Object.keys(completeAnalysisByAccount)[0];
    const completeDetail = completeAnalysisByAccount[accountNumber];

    const averageMonthlyBalanceData: MonthlyBalanceResponse[] =
      completeDetail?.monthly_average_balance;
    let monthlySummary: MonthlySummaryResponse[] =
      completeDetail?.monthly_summary;

    averageMonthlyBalanceData.forEach((item, index) => {
      item['4_days'].balance = monthlySummary[index].closing_balance.actual;
    });

    setAvgMonthlyBalanceData(averageMonthlyBalanceData);

    setChartData({
      months: [],
      lineChartData: [
        {
          label: 'Closing balance',
          data: [],
          color: 'rgba(37, 99, 235, 0.1)',
          borderColor: 'rgb(37, 99, 235)',
        },
        {
          label: 'Average Monthly Balance',
          data: [],
          color: 'rgba(60, 186, 159, 0.1)',
          borderColor: 'rgb(60, 186, 159)',
        },
      ],
    });

    averageMonthlyBalanceData.forEach((item: MonthlyBalanceResponse) => {
      setChartData((prev: any) => {
        const updatedLineChartData = prev.lineChartData.map(
          (chart: { label: string; data: any }) => {
            if (chart.label === 'Closing balance') {
              return {
                ...chart,
                data: [...chart.data, item['4_days'].balance],
              };
            }
            if (chart.label === 'Average Monthly Balance') {
              return {
                ...chart,
                data: [...chart.data, item.all_days.balance],
              };
            }
            return chart;
          }
        );

        return {
          months: [
            ...prev.months,
            `${MONTHS_ARRAY[item.period.month]}, ${String(
              item.period.year
            ).slice(-2)}`,
          ],
          lineChartData: updatedLineChartData,
        };
      });
    });

    hideLoader();
  };

  useEffect(() => {
    if (!isLoading && data?.customer)
      getAverageMonthlyBalanceAnalysis(data.customer.documents);
  }, [isLoading, data]);

  const headerColumns = new TableHeaderBuilder<MonthlyBalanceResponse>()
    .add('period', (col) =>
      col.header('Month').cell(({ month, year }: any) => (
        <H5>
          {MONTHS_ARRAY[month]}, {String(year).slice(-2)}
        </H5>
      ))
    )
    .add('4_days.balance', (col) =>
      col
        .header('Closing Balance')
        .cell((value) => (
          <H5>{formatMoney(removeCharacterFromAmount(value))}</H5>
        ))
    )
    .add('all_days.balance', (col) =>
      col
        .header('Avg. Monthly Balance')
        .cell((value) => (
          <H5>{formatMoney(removeCharacterFromAmount(value))}</H5>
        ))
    )
    .add('all_days.change.percent', (col) =>
      col
        .header('% Change')
        .cell((value) => (
          <>
            {removeCharacterFromAmount(value) !== 0 ? (
              getAverageMonthlyBalanceFlags(removeCharacterFromAmount(value))
                .color === 'red' ? (
                <Tag className="text-red-600 bg-red-100">{value}%</Tag>
              ) : (
                <Tag className="text-green-600 bg-green-100">{value}%</Tag>
              )
            ) : (
              <>-</>
            )}
          </>
        ))
    )
    .build();

  const columns: ColumnDef<MonthlyBalanceResponse>[] = useMemo(
    () => headerColumns,
    []
  );

  return (
    <div className="space-y-4">
      <H1 className="text-center">Average Monthly Balance</H1>
      <DataTable columns={columns} data={avgMonthlyBalanceData} />
      <MonthlyBalanceLineChart
        months={chartData.months}
        lineChartData={chartData.lineChartData}
        title="Average Monthly Balance"
      />
    </div>
  );
};

export default AverageMonthlyBalance;
