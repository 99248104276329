import { Reference } from '@appTypes/user-model';
import DataTable from '../../../common/components/tanstack-table';
import { P } from '../../../common/components/elements/text';
import Section from '../../../common/layouts/PageSection';
import { useMemo } from 'react';
import { TableHeaderBuilder } from 'react-table-utils';

type Props = {
  referenceData: Reference[];
};

const CustomerReference: React.FC<Props> = ({ referenceData }) => {
  const headerColumns = new TableHeaderBuilder<Reference>()
    .add('name', (col) =>
      col.header('Name').cell((value: any) => (
        <>
          <P>{value}</P>
        </>
      ))
    )
    .add('phone_number', (col) =>
      col.header('Phone Number').cell((value: any) => (
        <>
          <P>{value}</P>
        </>
      ))
    )
    .build();

  const columns = useMemo(() => headerColumns, []);

  return (
    <Section title="Customer Reference">
      <DataTable columns={columns} data={referenceData} showColumnToggle={false}/>
    </Section>
  );
};

export default CustomerReference;
