import { useEffect, useState } from 'react';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { useCustomerWithIncompleteProfile } from '../../common/hooks/use-customer';
import { IncompleteProfileColumn } from '../../common/components/data-table/TableColumn';
import Table from '../../common/components/data-table/paginateTable';
import { dashboardState, role } from '../../utils/constants/index';
import Section from '../../common/layouts/PageSection';
import { useRole } from '../../common/hooks/use-user';
import axios from '../../lib/axios';
import { useStatistics } from '../../common/hooks/use-statistics';
import { apiEndpoint } from '../../utils/endpoint';
import { withAuth } from '../../common/hoc/withAuth';

const ProfileIncomplete: React.FC = () => {
  const { currentUserRole } = useRole();
  const columns = IncompleteProfileColumn();
  const {
    data: customerData,
    isLoading,
    refetch,
  } = useCustomerWithIncompleteProfile();
  const { data: stats, isLoading: statsLoading } = useStatistics();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState(data);
  const [totalDataCount, setTotalDataCount] = useState(0);

  useEffect(() => {
    if (!isLoading && !statsLoading) {
      setData(customerData.customers);
      setOriginalData(customerData.customers);
      setTotalDataCount(stats?.customer_profile_incomplete_count);
    }
  }, [customerData, isLoading, statsLoading]);

  const resetData = () => {
    setData(originalData);
  };

  const paginateData = async (pageSize: number, pageIndex: number, orderBy: string = 'updated_at', desc: boolean = true) => {
    const skip = pageSize * pageIndex;
    const limit = pageSize;

    const { data } = await axios.get(
      apiEndpoint.common.getCustomersWithIncompleteProfile(
        skip,
        limit,
        orderBy,
        desc
      )
    );

    setData([]);
    // @ts-ignore
    setData(data.customers);
    // @ts-ignore
    setOriginalData(data.customers);
    // hideLoader();
  };

  const searchCustomer = async (
    name: string,
    pageIndex: number = 0,
    pageSize: number = 10
  ) => {
    if (name === '') {
      paginateData(pageSize, pageIndex);
    } else {
      const { data } = await axios.get(
        apiEndpoint.common.searchCustomer.customerWithIncompleteProfile(name)
      );
      setData([]);
      // @ts-ignore
      setData(data.customers);
      // @ts-ignore
      setOriginalData(data.customers);
    }
  };


  if (isLoading) return <FullPageLoader isOpen={true} />;

  const isExcelSheetButtonVisible =
    currentUserRole === role.owner || currentUserRole === role.manager;

  return (
    <Section title={dashboardState.customer_profile_incomplete_count.status}>
      <Table
        columns={columns}
        data={data}
        resetData={resetData}
        isExcelSheetButtonVisible={isExcelSheetButtonVisible}
        paginateData={paginateData}
        searchCustomer={searchCustomer}
        totalDataCount={totalDataCount}
        excelSheetUrl={`list-customers?profile_completion_status=incomplete&`}
        excelSheetName={`Incomplete_Profiles-${(new Date().toLocaleDateString("en-IN")).replace(/[:\\/?*\[\]]/g, "-")}`}

      />
    </Section>
  );
};

export default withAuth()(ProfileIncomplete);
