import React, { forwardRef, PropsWithoutRef, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import clsx from 'clsx';
import ErrorText from './ErrorText';

interface Props extends PropsWithoutRef<JSX.IntrinsicElements['input']> {
  type?:
    | 'text'
    | 'password'
    | 'email'
    | 'number'
    | 'url'
    | 'datetime-local'
    | 'tel'
    | 'date'
    | 'checkbox'
  name: string;
  label?: string;
  inputClassName?: string;
  inputIcon?: React.ReactNode;
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    { name, id = name, label, className, inputClassName, inputIcon, ...inputProps },
    ref
  ) => {
    const [input] = useField(name);
    const { isSubmitting } = useFormikContext();

    id = `${id}-${Date.now()}`;

    return (
      <div className={className}>
        {!!label && (
          <label
            htmlFor={id}
            className="block mb-1 text-sm font-medium text-left text-gray-700"
          >
            {label}{' '}
            {inputProps.required && <span className="text-red-600">*</span>}
          </label>
        )}

        <div className="flex">
          {inputIcon}
          <input
            id={id}
            {...input}
            value={input.value || ''}
            disabled={isSubmitting}
            {...inputProps}
            ref={ref}
            className={clsx(
              'block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none sm:text-sm',
              !inputProps.disabled &&
                !inputProps.readOnly &&
                'ring-blue-300 focus:ring',
              inputClassName
            )}
          />
        </div>

        <ErrorText name={name} />
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;

