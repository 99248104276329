import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { Component } from '../../common/types/index';
import Section from '../../common/layouts/PageSection';
import { useRole } from '../../common/hooks/use-user';
import { CrifData } from '@appTypes/credit-score-model';
import CreditReportChart from '../../modules/creditAnalysis/components/CreditReportChart';
import { formatLocalDateString } from '../../utils/formatter';
import CreditScoreSvgZeroVeryPoor from '../../utils/credit-score-svg/zeroVeryPoorSvg';
import Card from '../../common/components/elements/Card';
import CreditScoreSvgVeryPoor from '../../utils/credit-score-svg/veryPoorSvg';
import CreditScoreSvgPoor from '../../utils/credit-score-svg/poorSvg';
import CreditScoreSvgGood from '../../utils/credit-score-svg/goodSvg';
import CreditScoreSvgVeryGood from '../../utils/credit-score-svg/veryGoodSvg';
import CreditScoreSvgExcellent from '../../utils/credit-score-svg/excellentSvg';
import { H4, P } from '../../common/components/elements/text';
import { PrimaryButton } from '../../common/components/elements/button';

type Props = {
  creditAnalysis: CrifData[];
  handleFreshPull: () => void;
};

const CreditScoreSection: Component<Props> = ({
  creditAnalysis,
  handleFreshPull,
}) => {
  const { currentUserRole } = useRole();
  const creditScore = creditAnalysis[0]?.credit_score;
  const lastUpdate = creditAnalysis[0]?.report_date;
  const userName = creditAnalysis[0]?.full_name;

  return (
    <Section title="Credit Score">
      {creditAnalysis.length > 1 ? (
        <>
          <div className="mx-auto flex justify-center text-center">
            <PrimaryButton
              className={`my-2 ${currentUserRole !== 'owner' && 'hidden'}`}
              text="Fresh Pull"
              onClick={() => handleFreshPull()}
              IconRight={ArrowPathIcon}
            />
          </div>
          <div className="flex items-center justify-center">
            <CreditReportChart
              labels={creditAnalysis
                .map((item) => formatLocalDateString(item.report_date))
                .reverse()}
              datasets={creditAnalysis
                .map((item) => item.credit_score)
                .reverse()}
                // @ts-ignore
              vintageMonth={creditAnalysis
                .map((item) => item?.vintage_month)
                .reverse() || []}
            />
          </div>
        </>
      ) : (
        <Card className="flex flex-col bg-white justify-center items-center py-4 w-full mx-auto">
          {creditScore >= 0 && creditScore <= 299 && (
            <CreditScoreSvgZeroVeryPoor creditScore={creditScore} />
          )}
          {creditScore >= 300 && creditScore <= 619 && (
            <CreditScoreSvgVeryPoor creditScore={creditScore} />
          )}
          {creditScore >= 620 && creditScore <= 659 && (
            <CreditScoreSvgPoor creditScore={creditScore} />
          )}
          {creditScore >= 660 && creditScore <= 719 && (
            <CreditScoreSvgGood creditScore={creditScore} />
          )}
          {creditScore >= 720 && creditScore <= 749 && (
            <CreditScoreSvgVeryGood creditScore={creditScore} />
          )}
          {creditScore >= 750 && (
            <CreditScoreSvgExcellent creditScore={creditScore} />
          )}
          <div className="flex justify-center items-center mt-4">
            <P className="text-xs text-gray-400">Last update:&nbsp;</P>
            <P className="text-xs text-gray-400">{lastUpdate}</P>
          </div>
          <div>
            <H4 className="text-bold">{userName}&nbsp;</H4>
          </div>
          <P className="text-xs text-gray-400 flex justify-center items-center">
            Powered by CRIF
          </P>
          <PrimaryButton
            className={`mt-4 ${currentUserRole !== 'owner' && 'hidden'}`}
            text="Fresh Pull"
            onClick={() => handleFreshPull()}
            IconRight={ArrowPathIcon}
          />
        </Card>
      )}
    </Section>
  );
};

export default CreditScoreSection;
