import { AxiosRequestConfig } from "axios";
import axios from '../lib/axios';
import { useMutation as rqUseMutation, useQuery as rqUseQuery, useQueryClient } from '@tanstack/react-query';
import { showErrorAlert } from '../utils/alert';

export enum RequestType {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Patch = 'patch',
  Delete = 'delete',
}

export interface ErrorObject {
  [x: string]: any;
  error: {
    message: string;
    error_message: string;
    // data: {
    //   [x: string]: any;
    //   message: string;
    // }
    question_one: string;
    question_two: string;
    question_three: string;
    question_one_type: string;
    question_two_type: string;
    question_three_type: string;
    answer_one: string;
    answer_two: string;
    answer_three: string;
    options_one_list: string[];
    options_two_list: string[];
    options_three_list: string[];
    report_id: string;
    order_id: string;
  };
}

export interface Response {
  data: object;
  message?: string
}

export const useQuery = <T>(
  url: string,
  queryKey: string = '',
  config?: AxiosRequestConfig<unknown>,
  toShowAlert: boolean = true,
  cacheConditions?: {}
) => {
  return rqUseQuery(
    [queryKey],
    async () => {
      const { data } = await axios.get<any>(url, config);
      return data;
    },
    {
      ...cacheConditions,
      retry: false,
      onError: (err: ErrorObject | undefined) => {
        const errorMessage = err?.error?.message || 'Internal Server Error';
        if (toShowAlert) showErrorAlert('', errorMessage);
      },
    }
  );
};

export const useMutation = <T>(
  url: string,
  requestType: RequestType = RequestType.Post,
  queryKey: string = '',
  toShowAlert: boolean = true,
  cacheConditions?: {}
) => {
  return rqUseMutation(
    [queryKey],
    (data: any) => axios[requestType]<T>(url, data),
    {
      ...cacheConditions,
      onError: (err: ErrorObject | undefined) => {
        const errorMessage = err?.error?.message || 'Internal Server Error';
        if (toShowAlert) showErrorAlert('', errorMessage);
      },
    }
  );
};

export const useQueryData = <T>(
  url: string,
  queryKey: string = '',
  config?: AxiosRequestConfig<unknown>,
  toShowAlert: boolean = true,
  cacheConditions?: {}
) => {
  return rqUseQuery(
    [queryKey],
    async () => {
      const data = await axios.get<any>(url, config);
      return data;
    },
    {
      ...cacheConditions,
      retry: false,
      onError: (err: ErrorObject | undefined) => {
        const errorMessage = err?.error?.message || 'Internal Server Error';
        if (toShowAlert) showErrorAlert('', errorMessage);
      },
    }
  );
};

