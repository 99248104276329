import { ICreditReport } from '@appTypes/creditAnalysis';

export const CreditScoreSvg = ({creditScore}: any) => (
  <svg className="w-10 h-10 mr-2">
    <circle cx="18" cy="20" r="19" fill="#0052ff" />
    <text
      x="50%"
      y="50%"
      textAnchor="middle"
      fill="#ffffff"
      fontSize="16px"
      fontFamily="Arial"
      dy=".3em"
    >
      {creditScore}
    </text>
  </svg>
);
