import { useEffect, useState } from 'react';
import { apiEndpoint } from '../../utils/endpoint';
import { useQuery, useQueryData } from '../../lib/react-query';
import { useLocation, useParams } from 'react-router-dom';
import { H1, H5, P } from '../../common/components/elements/text';
import DataTable from '../../common/components/tanstack-table/index';
import {
  convertCardNumber,
  getNegativeAccountFlags,
  removeCharacterFromAmount,
} from '../../utils/index';
import { Tag } from 'antd';
import { TableHeaderBuilder } from 'react-table-utils';
import { NegativeAccDetail } from '../../common/types/negative-account-model';
import { formatMoney } from '../../utils/formatter';
import { Flag } from '../../common/types/credit-analysis';
import { withAuth } from '../../common/hoc/withAuth';
import { useRole } from '../../common/hooks/use-user';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { INegativeAccount } from '@pages/report/Negative Accounts/negative_accounts-model';

const NEGATIVE_ACCOUNT = 'NEGATIVE_ACCOUNT';

const NegativeAccount = () => {
  const [negativeAccountCount, setNegativeAccountCount] = useState(0);
  const [negativeAccountData, setNegativeAccountData] = useState<
    NegativeAccDetail[]
  >([]);
  const [flag, setFlag] = useState<Flag>();

  const router = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const reportId = queryParams.get('report_id');
  const userId = router.id as string;

  const { currentUserRole } = useRole();
  const { data, isLoading } = useQueryData(
    apiEndpoint.creditAnalysis.getNegativeAccount(currentUserRole, userId),
    NEGATIVE_ACCOUNT + userId
  );

  useEffect(() => {
    if (!isLoading && data) {
      const { negative_accounts_count, negative_accounts_details } =
        // @ts-ignore
        data.filter(
          (item: INegativeAccount) =>
            item.detailed_negative_accounts.report_id === reportId
        )[0].detailed_negative_accounts;
        
      setNegativeAccountData(negative_accounts_details);
      setNegativeAccountCount(negative_accounts_count);
      setFlag(getNegativeAccountFlags(negative_accounts_count));
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <FullPageLoader isOpen={true} />;
  }

  // if (negativeAccountData.length === 0) {
  //   return <NotFoundBox title="Negative Account" />;
  // }

  const headerColumns = new TableHeaderBuilder<NegativeAccDetail>()
    .add('lender_name', (col) =>
      col
        .header('LENDER NAME')
        .cell((value) => <H5 className="text-primary-500 py-1">{value},</H5>)
    )
    .add('account_number', (col) =>
      col
        .header('ACCOUNT NUMBER')
        .cell((value: string) => (
          <H5 className="text-primary-500 break-all">
            A/C- {convertCardNumber(value)}
          </H5>
        ))
    )
    .add('pending_amount', (col) =>
      col
        .header('PENDING AMOUNT')
        .cell((value: string) => (
          <H5 className="text-primary-500 text-bold">
            {formatMoney(removeCharacterFromAmount(value))}
          </H5>
        ))
    )
    .add('status', (col) =>
      col
        .header('STATUS')
        .cell((value: string) => (
          <>
            {value === 'Active' ? (
              <Tag className="text-green-500 bg-green-100">{value}</Tag>
            ) : (
              <Tag className="text-red-500 bg-red-100">{value}</Tag>
            )}
          </>
        ))
    )
    .build();

  return (
    <div>
      <H1 className="text-center">Negative History</H1>
      <div className="flex">
        <H5 className="text-gray-500">Negative account till 2023: </H5>
        <H5
          className={`text-lg text-bold ${
            flag?.color === 'green' ? 'text-green-600' : 'text-red-600'
          } pl-2`}
        >
          {negativeAccountCount}{' '}
        </H5>
      </div>
      {negativeAccountData.length > 0 && (
        <DataTable columns={headerColumns} data={negativeAccountData} />
      )}
    </div>
  );
};

export default withAuth()(NegativeAccount);
