import React, { forwardRef, PropsWithoutRef } from "react";
import { useField } from "formik";
import { useFormikContext } from "formik";
import ErrorText from "./ErrorText";
import { Label } from "../text";
import cx from "clsx";

interface Props extends PropsWithoutRef<JSX.IntrinsicElements["textarea"]> {
  id?: string;
  name: string;
  label?: string;
  inputClassName?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    { name, id = name, label, className = "", inputClassName, ...props },
    ref
  ) => {
    const [input] = useField(name);
    const { isSubmitting } = useFormikContext();

    return (
      <div className={className}>
        {!!label && (
          <Label className="mb-1">
            {label} {props.required && <span className="text-red-600">*</span>}
          </Label>
        )}

        <textarea
          id={id}
          {...input}
          value={input.value || ""}
          disabled={isSubmitting}
          {...props}
          ref={ref}
          className={cx(
            "w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500",
            inputClassName
          )}
        />

        <ErrorText name={name} />
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
