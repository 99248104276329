import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login/login';
import ForgetPassword from './pages/forgetpassword/forgetpassword';
import AcceptInvite from './pages/forgetpassword/acceptInvite';
import CreateNewPassword from './pages/forgetpassword/createNewPassword';
import { Provider } from 'react-redux';
import store from './utils/store';
import NewLoginPage from './pages/login/newlogin';
import OTPVerificationBox from './pages/login/OTPVerificationBox';
import { PAGE } from './utils/constants/page';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import generateUniqueId from 'generate-unique-id';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const instance = createInstance({
  urlBase: 'https://oolka-matomo.cr.oolka.in/',
  siteId: 2,
  disabled: false,
  userId:
    queryClient.getQueryData(['email']) || generateUniqueId({ length: 10 }),
  trackerUrl: 'https://oolka-matomo.cr.oolka.in/matomo.php',
  srcUrl: 'https://oolka-matomo.cr.oolka.in/matomo.js',
  linkTracking: true,
  configurations: { disableCookies: true },
  heartBeat: {
    active: true,
    seconds: 30,
  },
});

root.render(
  <React.StrictMode>
    {/* @ts-ignore*/}
    <MatomoProvider value={instance}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Router>
            <Routes>
              <Route path={PAGE.login.href} element={<LoginPage />} />
              <Route
                path={PAGE.forgotPassword.href}
                element={<ForgetPassword />}
              />
              <Route
                path={PAGE.acceptInvitation.href}
                element={<AcceptInvite />}
              />
              <Route
                path={PAGE.resetPassword.href}
                element={<CreateNewPassword />}
              />
              <Route path={PAGE.register.href} element={<NewLoginPage />} />
              <Route
                path={PAGE.signUpOtpVerification.href}
                element={<OTPVerificationBox />}
              />
            </Routes>
            <App />
          </Router>
        </Provider>
      </QueryClientProvider>
    </MatomoProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
