import { Col, Row } from 'antd';
import Form from '../../common/components/form';
import { useNavigate } from 'react-router-dom';
import { initialForgotPasswordCredentials } from '../../common/types/auth-model';
import { forgotPasswordSchema, loginSchema } from '../../common/schema/auth';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import Input from '../../common/components/elements/form/Input';
import LinkedItem from '../../common/components/elements/LinkedItem';
import { useOTPVerify } from '../../common/hooks/mutations/use-auth';
import { role, roleOptions } from '../../utils/constants/index';
import ListInput from '../../common/components/elements/form/ListInput';
import { PAGE } from '../../utils/constants/page';
import { useEffect } from 'react';
import { storage } from '../../utils/storage';
import { useQueryClient } from '@tanstack/react-query';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const Forgetpassword = () => {
  const navigate = useNavigate();
  const { resendOTP } = useOTPVerify();
  const queryClient = useQueryClient();
  const { trackEvent, pushInstruction } = useMatomo();

  useEffect(() => {
    if (storage.getToken()) {
      navigate(PAGE.dashboard.href);
    }
  }, []);

  return (
    <div className="login-page">
      <Row>
        <Col xl={6} lg={8} md={12} sm={20} xs={24} className="login-box">
          <Row>
            <Col span={24}>
              <div className="illustration-wrapper">
                <img src="./assets/images/logo1.svg" alt="logo" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="mb-10">
                <Form
                  initialValues={initialForgotPasswordCredentials}
                  schema={forgotPasswordSchema}
                  onSubmit={async (data) => {
                    const response = await resendOTP(data);
                    if (response) {
                      const { email, role } = data;

                      trackEvent({
                        category: 'Forgot Password',
                        action: 'Authenticates a user',
                        name: role + ': ' + email,
                        value: 1,
                      });

                      pushInstruction('setUserId', `${email}`);
                      var _paq = window._paq || [];
                      queryClient.setQueryData(['email'], `${email}`);
                      _paq.push(['setUserId', `${email}`]);

                      navigate(PAGE.resetPassword.href, {
                        state: { email, role },
                      });
                    }
                  }}
                  submitButton={{
                    text: 'Send Link',
                    IconRight: ChevronDoubleRightIcon,
                    className: 'w-full mx-auto',
                  }}
                >
                  <h3>Forgotten Password</h3>
                  <p>We&rsquo;ll send you an OTP to reset your password.</p>
                  <ListInput
                    options={roleOptions}
                    name="role"
                    label="Role"
                    required
                  />
                  <Input name="email" placeholder="Email" required />
                </Form>
                <div className="text-sm text-center mt-2">
                  <LinkedItem
                    href={PAGE.login.href}
                    className="font-bold text-primary-300"
                  >
                    Back to login
                  </LinkedItem>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Forgetpassword;
