import { useEffect, useRef, useState } from 'react';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { useCustomerWithCompletedProfile } from '../../common/hooks/use-customer';
import Table from '../../common/components/data-table/paginateTable';
import {
  CompleteProfileColumn
} from '../../common/components/data-table/TableColumn';
import { dashboardState, role } from '../../utils/constants/index';
import Section from '../../common/layouts/PageSection';
import { useRole } from '../../common/hooks/use-user';
import { useQueryClient } from '@tanstack/react-query';
import axios from '../../lib/axios';
import { useFullPageLoader } from '../../common/hooks/use-full-page-loader';
import { useStatistics } from '../../common/hooks/use-statistics';
import { apiEndpoint } from '../../utils/endpoint';
import { withAuth } from '../../common/hoc/withAuth';

const CompleteProfile: React.FC = () => {
  const queryClient = useQueryClient();
  const [totalDataCount, setTotalDataCount] = useState(0);
  const { showLoader, hideLoader } = useFullPageLoader();
  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);
  const skipResetRef = useRef(false);
  const { currentUserRole } = useRole();
  const columns = CompleteProfileColumn(downloadLinkRef);

  const {
    data: customersData,
    isLoading,
    refetch,
  } = useCustomerWithCompletedProfile();
  const { data: stats, isLoading: statsLoading } = useStatistics();
  const [data, setData] = useState(
    []
  );
  const [originalData, setOriginalData] = useState(data);

  useEffect(() => {
    if (!isLoading && !statsLoading) {
      setData(customersData?.customers);
      setOriginalData(customersData?.customers);
      setTotalDataCount(stats?.customer_profile_complete_count);
    }
  }, [customersData, isLoading, statsLoading, stats]);

  const resetData = () => {
    skipResetRef.current = true;
    setData(originalData);
  };

  const paginateData = async (pageSize: number, pageIndex: number, orderBy: string = 'updated_at', desc: boolean = true) => {
    showLoader();

    const skip = pageSize * pageIndex;
    const limit = pageSize;

    const { data } = await axios.get(
      apiEndpoint.common.getCustomerWithCompleteProfile(
        skip,
        limit,
        orderBy,
        desc
      )
    );

    setData([]);
    // @ts-ignore
    setData(data.customers);
    // @ts-ignore
    setOriginalData(data.customers);
    hideLoader();
  };

  const searchCustomer = async (
    name: string,
    pageIndex: number = 0,
    pageSize: number = 10
  ) => {
    if (name === '') {
      paginateData(pageSize, pageIndex);
    } else {
      const { data } = await axios.get(
        apiEndpoint.common.searchCustomer.customerWithCompleteProfile(name)
      );
      setData([]);
      // @ts-ignore
      setData(data.customers);
      // @ts-ignore
      setOriginalData(data.customers);
    }
  };

  if (isLoading) {
    return <FullPageLoader isOpen={true} className="text-brand-orange-500" />;
  }

  const isExcelSheetButtonVisible =
    currentUserRole === role.owner || currentUserRole === role.manager;

  return (
    <Section title={dashboardState.customer_profile_complete_count.status}>
      <Table
        columns={columns}
        data={data}
        resetData={resetData}
        isExcelSheetButtonVisible={isExcelSheetButtonVisible}
        paginateData={paginateData}
        searchCustomer={searchCustomer}
        totalDataCount={totalDataCount}
        excelSheetUrl={`list-customers?profile_completion_status=complete&`}
        excelSheetName={`Completed_Profiles-${(new Date().toLocaleDateString()).replace(/[:\\/?*\[\]]/g, "-")}`}
      />
    </Section>
  );
};

export default withAuth()(CompleteProfile);
