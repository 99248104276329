import { Col, Row } from 'antd';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import { otpVerificationSchema } from '../../common/schema/auth';
import LinkedItem from '../../common/components/elements/LinkedItem';
import { useState, useRef, ChangeEvent, useEffect } from 'react';
import { H4, H5, P } from '../../common/components/elements/text';
import { showInfoToast } from '../../utils/toast';
import Form from '../../common/components/form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOTPVerify } from '../../common/hooks/mutations/use-auth';
import { role } from '../../utils/constants/index';
import InlineLoader from '../../common/components/elements/loader/InlineLoader';
import { PAGE } from '../../utils/constants/page';

const initialOtpValue = {
  otp: ['', '', '', '', '', ''],
};

type Props = {};

const OTPVerificationBox: React.FC<Props> = () => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [seconds, setSeconds] = useState(59);

  const inputRefs = useRef<HTMLInputElement[]>([]);
  const lastEmailSentAt = useRef<number | undefined>();

  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState(location.state);

  const { verifySignUpOtp, resendOTP, isLoading } = useOTPVerify();

  const handleInputChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const updatedOTP = [...otp];
    updatedOTP[index] = value;
    setOtp(updatedOTP);

    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleBackspace = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Backspace' && otp[index] === '') {
      const previousInput = inputRefs.current[index - 1];
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const handleResenOTP = () => {
    if (
      lastEmailSentAt.current &&
      new Date().getTime() - lastEmailSentAt.current <= seconds * 1000
    ) {
      showInfoToast(`You need to wait for ${seconds} seconds!`);
      return;
    }

    lastEmailSentAt.current = new Date().getTime();

    resendOTP({ email, role: role.owner });
    setSeconds(59);
  };

  const isSubmitDisabled = otp.some((value) => value === '');

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(
        () => setSeconds((prevSeconds) => prevSeconds - 1),
        1000
      );

      return () => clearInterval(timer);
    }
  }, [seconds]);

  return (
    <div className="login-page">
      <Row gutter={0}>
        <Col xl={6} lg={8} md={12} sm={20} xs={24} className="login-box">
          <Row>
            <Col span={24}>
              <div className="illustration-wrapper">
                <img src="./assets/images/logo1.svg" alt="logo" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="mb-10">
                <Form
                  initialValues={initialOtpValue}
                  schema={otpVerificationSchema}
                  submitButton={{
                    text: 'Verify',
                    IconRight: ChevronDoubleRightIcon,
                    disabled: isSubmitDisabled,
                    className: 'mx-auto',
                  }}
                  onSubmit={async (data) => {
                    const response = await verifySignUpOtp({
                      email,
                      otp: otp.join(''),
                    });
                    if (response) navigate(PAGE.dashboard.href);
                  }}
                >
                  <H4 className="text-center">OTP Verification</H4>
                  <div className="text-center">
                    <P className="text-center">Enter the OTP you received at</P>
                    <H5>{email}</H5>
                  </div>
                  <div className="flex flex-row justify-center mt-2">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        pattern="\d{1}"
                        className="m-1 w-8 h-8 rounded-md sm:h-10 sm:w-10 text-center form-control sm:rounded-xl arrows-none  border border-gray-300 focus:outline-none text-lg bg-white focus:ring ring-blue-300 appearance-none"
                        maxLength={1}
                        value={digit}
                        ref={(ref) => {
                          if (ref) {
                            inputRefs.current[index] = ref;
                          }
                        }}
                        onChange={(event) => handleInputChange(index, event)}
                        onKeyDown={(event) => handleBackspace(index, event)}
                      />
                    ))}
                  </div>
                  <div className="text-center">
                    {seconds === 0 ? (
                      <span className="text-red-500 text-md sm:text-lg">
                        OTP verification time has expired!
                      </span>
                    ) : (
                      <P className="text-gray-600">
                        Time Left&nbsp;
                        <span className="text-green-500 font-bold">
                          00:{seconds}
                        </span>
                      </P>
                    )}
                  </div>
                  {isLoading ? (
                    <div className="flex justify-end">
                      <InlineLoader className="text-primary-500" />
                    </div>
                  ) : (
                    <div className="text-sm text-right">
                      <LinkedItem
                        href="#"
                        className="font-bold text-primary-300"
                        onClick={() => handleResenOTP()}
                      >
                        Resend OTP
                      </LinkedItem>
                    </div>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default OTPVerificationBox;
