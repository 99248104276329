import { useEffect, useState } from 'react';
import { apiEndpoint } from '../../utils/endpoint';
import { useQuery, useQueryData } from '../../lib/react-query';
import { useLocation, useParams } from 'react-router-dom';
import InlineLoader from '../../common/components/elements/loader/InlineLoader';
import { H1, H3, H5, P } from '../../common/components/elements/text';
import DataTable from '../../common/components/tanstack-table/index';
import { convertCardNumber, getTotalAccountFlags } from '../../utils/index';
import { Tag } from 'antd';
import { Flag } from '@appTypes/credit-analysis';
import { AllAccDetail, ITotalAccount } from '@appTypes/total-accounts-model';
import { TableHeaderBuilder } from 'react-table-utils';
import { useRole } from '../../common/hooks/use-user';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';

const ALL_ACCOUNT = 'ALL_ACCOUNT';

const AllAccount = () => {
  const [totalAccount, setTotalAccount] = useState(0);
  const [totalAccountData, setTotalAccountData] = useState<AllAccDetail[]>([]);
  const [flag, setFlag] = useState<Flag>();

  const router = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const reportId = queryParams.get('report_id');
  const userId = router.id as string;

  const {currentUserRole} = useRole();
  const { data, isLoading } = useQueryData(
    apiEndpoint.creditAnalysis.getTotalAccount(currentUserRole, userId),
    ALL_ACCOUNT + userId
  );

  useEffect(() => {
    if (!isLoading && data) {
      // @ts-ignore
      const { all_accounts_count, all_accounts_details } = data.filter(
        (item: ITotalAccount) =>
          item.detailed_all_accounts.report_id === reportId
      )[0].detailed_all_accounts;

      setTotalAccount(all_accounts_count);
      setTotalAccountData(all_accounts_details);
      setFlag(getTotalAccountFlags(all_accounts_count));
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <FullPageLoader isOpen={true} />;
  }

  // if (totalAccountData.length === 0) {
  //   return <NotFoundBox title="Total Account" />;
  // }

  if (totalAccountData.length) {
    totalAccountData.forEach((item) => {
      // @ts-ignore
      item.account_combined = [];
      // @ts-ignore
      item.account_combined = [item.account, item.account_number];
    });
  }

  const headerColumns = new TableHeaderBuilder<AllAccDetail>()
    .add('account_combined', (col) =>
      col.header('ACCOUNT').cell((value) => (
        <>
          <H5 className="text-primary-500 py-1">{value[0]},</H5>
          <H5 className="text-primary-500 break-all">
            A/C- {convertCardNumber(value[1])}
          </H5>
        </>
      ))
    )
    .add('opening_date', (col) =>
      col
        .header('OPENING DATE')
        .cell((value: string) => <H5 className="text-primary-500">{value}</H5>)
    )
    .add('status', (col) =>
      col
        .header('STATUS')
        .cell((value: string) => (
          <>
            {value === 'Active' ? (
              <Tag className="text-green-500 bg-green-100">{value}</Tag>
            ) : (
              <Tag className="text-red-500 bg-red-100">{value}</Tag>
            )}
          </>
        ))
    )
    .build();

  return (
    <div className="py-4">
      <H1 className="text-center">All Accounts</H1>
      <div className="flex py-2">
        <H5 className="text-gray-500">Number of account till 2023: </H5>
        <H5
          className={`text-lg text-bold ${
            flag?.color === 'green' ? 'text-green-600' : 'text-red-600'
          } pl-2`}
        >
          {totalAccount}
        </H5>
      </div>
      {totalAccountData.length > 0 && (
        <DataTable columns={headerColumns} data={totalAccountData} />
      )}
    </div>
  );
};

export default AllAccount;
