import React, { useMemo } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
  useAsyncDebounce,
  useGlobalFilter,
} from 'react-table';

import matchSorter from 'match-sorter';
import { SuccessButton } from '../elements/button';
import {
  ArrowTopRightOnSquareIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline';
import { FunnelIcon as SolidFunnelIcon } from '@heroicons/react/20/solid';
import { filterOptions } from '../../../utils/constants/index';
import LinkedItem from '../elements/LinkedItem';
import { storeSingleItem } from '../../../utils/storage';

// Define a default UI for filtering
export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search ${count} records...`}
        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none sm:text-sm ring-blue-300 focus:ring"
      />
    </span>
  );
};

export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}: {
  column: { filterValue: any; preFilteredRows: any; setFilter: any };
}) => {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
      className="block w-full px-2 py-2 min-w-fit placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none sm:text-sm ring-blue-300 focus:ring"
    />
  );
};

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export const SliderColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: {
  column: { filterValue: any; preFilteredRows: any; setFilter: any; id: any };
}) => {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
};

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export const NumberRangeColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: {
  column: { filterValue: any; preFilteredRows: any; setFilter: any; id: any };
}) => {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  );
};

export function fuzzyTextFilterFn<T>(rows: T[], id: keyof T, filterValue: any) {
  return matchSorter(rows, filterValue, { keys: [(row: any) => row[id]] });
}

fuzzyTextFilterFn.autoRemove = (val: any) => !val;

export const SelectColumnFilterFromValue = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: {
  column: { filterValue: any; preFilteredRows: any; setFilter: any; id: any };
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      className="bg-white"
    >
      <option value="">All</option>
      {options.map((option: any, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export const SelectColumnFilter = ({ column }: any) => {
  const { filterValue, setFilter, preFilteredRows, id } = column;
  const options = React.useMemo(
    () => getOptions(preFilteredRows, id),
    [id, preFilteredRows]
  );

  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      className="bg-white"
    >
      <option value="">All</option>
      {options.map((option: any, i) => (
        <option key={i} value={option}>
          {option ? filterOptions[column.id][0] : filterOptions[column.id][1]}
        </option>
      ))}
    </select>
  );
};

// Define the getOptions function based on your data
function getOptions(rows: any[], id: any) {
  const options = new Set();
  rows.forEach((row: any) => {
    options.add(row.values[id]);
  });
  return [...options.values()];
}

export const filterGreaterThan = (rows: [], id: any, filterValue: any) => {
  return rows.filter((row: any) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
};

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: any) => typeof val !== 'number';

export const documentUploadedFilter = (
  rows: any[],
  id: any,
  filterValue: any
) => {
  const filterrows = rows.filter((row: any) => {
    if (filterValue === 'uploaded') {
      return row.original.is_profile_complete;
    } else if (filterValue === 'not_uploaded') {
      return !row.original.is_profile_complete;
    } else {
      return true;
    }
  });

  return filterrows;
};

export const profileCompletionFilter = (
  rows: any[],
  id: any,
  filterValue: any
) => {
  const filterrows = rows.filter((row: any) => {
    if (filterValue === 'completed') {
      return row.original.is_profile_complete;
    } else if (filterValue === 'not_completed') {
      return !row.original.is_profile_complete;
    } else {
      return true;
    }
  });

  return filterrows;
}

export const CBLRequestedFilter = ({
  column: { filterValue, setFilter, id },
}: {
  column: any;
}) => {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        storeSingleItem.setItem('currentFilterOrder', e.target.value === 'requested' ? '1' : e.target.value === 'not_requested' ? '0' : 'undefined')
        setFilter(e.target.value || undefined);
      }}
      className="bg-white"
    >
      <option value="all">All</option>
      <option value="requested">Requested</option>
      <option value="not_requested">Not Requested</option>
    </select>
  );
};

export const AccountStatusFilter = ({
  column: { filterValue, setFilter, id },
}: {
  column: any;
}) => {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        storeSingleItem.setItem('currentFilterOrder', e.target.value === 'verified' ? '1' : e.target.value === 'not_verified' ? '0' : 'undefined')
        setFilter(e.target.value || undefined);
      }}
      className="bg-white"
    >
      <option value="all">All</option>
      <option value="verified">Verified</option>
      <option value="not_verified">Not Verified</option>
    </select>
  );
};

export const DocumentUploadedFilter = ({
  column: { filterValue, setFilter, id },
}: {
  column: any;
}) => {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        storeSingleItem.setItem('currentFilterOrder', e.target.value === 'uploaded' ? '1' : e.target.value === 'not_uploaded' ? '0' : 'undefined')
        setFilter(e.target.value || undefined);
      }}
      className="bg-white"
    >
      <option value="all">All</option>
      <option value="uploaded">Uploaded</option>
      <option value="not_uploaded">Not Uploaded</option>
    </select>
  );
};

export const ProfileCompletionFilter = ({
  column: { filterValue, setFilter, id },
}: {
  column: any;
}) => {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        storeSingleItem.setItem('currentFilterOrder', e.target.value === 'completed' ? '1' : e.target.value === 'not_completed' ? '0' : 'undefined')
        setFilter(e.target.value || undefined);
      }}
      className="bg-white"
    >
      <option value="all">All</option>
      <option value="completed">Completed</option>
      <option value="not_completed">Not Completed</option>
    </select>
  );
};

export const SubscriptionFilter = ({
  column: { filterValue, setFilter, id },
}: {
  column: any;
}) => {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        storeSingleItem.setItem('subscriptionFilter', e.target.value)
        setFilter(e.target.value || undefined);
      }}
      className="bg-white"
    >
      <option value="both">All</option>
      <option value="subscribed">Subscribed</option>
      <option value="not_subscribed">Not Subscribed</option>
    </select>
  );
};

export const CreditScoreFilter = ({
  column: { filterValue, setFilter },
}: {
  column: { filterValue: any; setFilter: any };
}) => {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
        storeSingleItem.setItem('creditScoreFilter', e.target.value || "10")
      }}
      className="bg-white"
    >
      <option value="10">All</option>
      <option value="-1">Invalid</option>
      <option value="0">0-99</option>
      <option value="1">100-199</option>
      <option value="2">200-299</option>
      <option value="3">300-399</option>
      <option value="4">400-499</option>
      <option value="5">500-599</option>
      <option value="6">600-699</option>
      <option value="7">700-799</option>
      <option value="8">800-899</option>
      <option value="9">900</option>
    </select>
  );
}

export const subscriptionFilter = (rows: any[], id: any, filterValue: any) => {
  const filterRows = rows.filter((row: any) => {
    if (filterValue === 'subscribed' && row.values[id]) {
      return Object.keys(row.values[id]).length > 0;
    } else if (filterValue === 'subscribed' && !row.values[id]) {
      return false;
    } else if (filterValue === 'not_subscribed' && row.values[id]) {
      return Object.keys(row.values[id]).length === 0;
    } else if (filterValue === 'not_subscribed' && !row.values[id]) {
      return true;
    } else {
      return true;
    }
  });

  return filterRows;
};

export const DateRangeColumnFilter = ({
  column: { filterValue, setFilter },
}: {
  column: { filterValue: any; setFilter: any };
}) => {
  const [showFilter, setShowFilter] = React.useState(false);

  const handleStartDateChange = (event: any) => {
    setFilter((prev: any) => ({
      ...prev,
      startDate: event.target.value,
    }));
  };

  const handleEndDateChange = (event: any) => {
    setFilter((prev: any) => ({
      ...prev,
      endDate: event.target.value,
    }));
  };

  return (
    <div className="flex flex-col space-y-2">
      {showFilter ? (
        <LinkedItem href="" onClick={() => setShowFilter(false)}>
          <SolidFunnelIcon className="h-4 w-4" />
        </LinkedItem>
      ) : (
        <LinkedItem href="" onClick={() => setShowFilter(true)}>
          <FunnelIcon className="h-4 w-4" />
        </LinkedItem>
      )}
      {showFilter && (
        <div className="flex">
          <input
            type="date"
            value={filterValue?.startDate || ''}
            onChange={handleStartDateChange}
          />
          &nbsp;to&nbsp;
          <input
            type="date"
            value={filterValue?.endDate || ''}
            onChange={handleEndDateChange}
          />
        </div>
      )}
    </div>
  );
};

export const NumberRangeColumnFilters = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: {
  column: { filterValue: any; preFilteredRows: any; setFilter: any; id: any };
}) => {
  const [min, max] = useMemo(() => {
    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;
    preFilteredRows.forEach((row: any) => {
      const value = row.original.analyses[0]?.score;
      min = Math.min(value, min);
      max = Math.max(value, max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue?.[0] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val !== '' ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue?.[1] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val !== '' ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  );
};

// Function to get nested property value
function getValue(object: any, path: string) {
  return object?.analyses?.[0]?.score;
}

export const SingleDateColumnFilter = ({
  column: { filterValue, setFilter },
}: {
  column: { filterValue: any; setFilter: any };
}) => {
  const [showFilter, setShowFilter] = React.useState(false);
  const handleDateChange = (e: any) => {
    setFilter(e.target.value || undefined);
  };

  return (
    <div className="flex flex-col space-y-2">
      {showFilter ? (
        <LinkedItem href="" onClick={() => setShowFilter(false)}>
          <SolidFunnelIcon className="h-4 w-4" />
        </LinkedItem>
      ) : (
        <LinkedItem href="" onClick={() => setShowFilter(true)}>
          <FunnelIcon className="h-4 w-4" />
        </LinkedItem>
      )}
      {showFilter && (
        <input
          type="date"
          value={filterValue || ''}
          onChange={handleDateChange}
          placeholder="Select Date"
        />
      )}
    </div>
  );
};

export const ActionButtons = ({ id, prefix }: any) => (
  <div className="flex gap-4">
    <SuccessButton
      className="!p-1 !rounded-full"
      Icon={ArrowTopRightOnSquareIcon}
      href={`/${prefix}/${id}`}
    />
  </div>
);

const customFilter = (rows: any[], id: any, filterValue: any) => {
  return rows.filter((row) => {
    const creditScore = row.original.analyses[0]?.score;

    if (creditScore >= 300)
      console.log('creditScore >= 300', creditScore >= 300);

    if (creditScore >= 300 && creditScore <= 500)
      console.log(
        'creditScore >= 300 && creditScore <= 500',
        creditScore >= 300 && creditScore <= 500
      );
    return creditScore >= 300 && creditScore <= 500;
  });
};
