import Form from '../../common/components/form';
import Input from '../../common/components/elements/form/Input';
import { profileSchema } from '../../common/schema/user';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';

type Props = {
  userData: any;
};

const saveProfile: React.FC<Props> = ({ userData }) => {
  return (
    <Form
      initialValues={userData}
      schema={profileSchema}
      onSubmit={async (values) => {
      }}
      submitButton={{
        text: 'Update Profile',
        IconRight: ChevronDoubleRightIcon,
        className: 'w-full sm:w-auto',
      }}
      className="gap-y-6"
    >
      <Form.Row>
        <Input name="first_name" label="First Name" required />
        <Input name="last_name" label="Last Name" required />
      </Form.Row>
      <Form.Row>
        <Input
          name="email"
          label="Email"
          type="email"
          required
          disabled={true}
        />
        <Input name="role" label="Role" required  disabled={true}/>
      </Form.Row>
      <Form.Row>
        <Input
          name="phone_number"
          label="Phone Number"
          type="number"
          required
          disabled={true}
        />
        <Input
          name="whatsapp_number"
          label="WhatsApp Number"
          type="number"
          required
        />
      </Form.Row>

      <Form.Row>
        <Input
          name="dob"
          label="Date of Birth"
          type="date"
          required
          pattern="\d{4}-\d{2}-\d{2}"
        />
        <Input
          name="pan_number"
          label="PAN Number"
          required
        />
      </Form.Row>
    </Form>
  );
};

export default saveProfile;
