import { apiEndpoint } from "../../utils/endpoint";
import { ROLE_KEY, role } from "../../utils/constants/index";
import { getRole } from "../../utils/index";
import { useQueryClient } from "@tanstack/react-query";
import { useQuery } from "../../lib/react-query";

export const USER_PROFILE_QUERY_KEY = "QUERY_USER_PROFILE";

export const useUser = (): {
  data: any;
  authorized: boolean;
  isLoading: boolean;
  currentUserRole: string;
} => {
  const { currentUserRole } = useRole();
  const endpoint = currentUserRole === role.owner ? apiEndpoint.owners.profile.getProfile : currentUserRole === role.manager ? apiEndpoint.managers.profile.getProfile : apiEndpoint.executives.profile.getProfile;

  const { data, isLoading } = useQuery(endpoint, USER_PROFILE_QUERY_KEY, {}, false, {
    staleTime: 1000 * 60 * 60 * 24 * 1,
    cacheTime: 1000 * 60 * 60 * 24 * 1,
  });

  return {
    data,
    authorized: !!(data),
    isLoading,
    currentUserRole,
  };
};

export const useRole = (): {
  currentUserRole: string;
} => {
  const queryClient = useQueryClient();
  let currentUserRole = queryClient.getQueryData<string>([ROLE_KEY]);

  if (!currentUserRole) {
    currentUserRole = getRole();
    queryClient.setQueryData([ROLE_KEY], currentUserRole);
  }

  return { currentUserRole };
}