import { Button, Col, Row, Spin, message } from "antd";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { GetApiCall, PutApiCall } from "../../../utils/apis";
import {
  cmsManagementUpdateUrl,
  cmsManagementUrl,
} from "../../../utils/constant";
import {
  initialICmsManagement,
} from "../cmsManagement-model";
import "./termsConditions-style.scss";

const TermsConditionsPage = () => {
  const [termsConditionsEditor, setTermsConditionsEditor] = useState("");
  const [termsData, setTermsData] = useState(initialICmsManagement);
  const [loading, setLoading] = useState(false)
  let userData:any = localStorage.getItem("user")
  let token:any = JSON.parse(userData)?.token;
  useEffect(() => {
    setLoading(true)
    GetApiCall(cmsManagementUrl, token).then((data) => {
      if(data.status === true) {
        const pageName = data.allpages.find(
          (terms:any) => terms.page_name === "term&condition"
        );
  
        if (pageName !== undefined) {
          setTermsConditionsEditor(pageName.page_content);
          setTermsData(pageName);
          setLoading(false)
        }
        }
        else {
          message.error(data.message)
          setLoading(false);
        }
    });
  }, []);

  const updateTerms = () => {
    setLoading(true)
    const updateTermsForm = {
      id: termsData.id,
      page_content: termsConditionsEditor,
      page_name: "term&condition",
      page_image: [],
      page_heading: "",
    };
    PutApiCall(cmsManagementUpdateUrl, updateTermsForm, token).then((data) => {
      if(data.status===true){
        setLoading(false)
        message.success(data.message)
      }
    });
  };

  return (
    <Spin spinning={loading} tip="Loading..." size="large"> 
    <div>
        <Row>
          <Col span={24}>
            <ReactQuill
              theme="snow"
              value={termsConditionsEditor}
              onChange={setTermsConditionsEditor}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={{ span:4, offset: 20 }} md={24} sm={24} xs={24} className="cms-pagesbtn-container">
            <Button className="btn btn-primary" onClick={updateTerms} type="primary">
              Submit
            </Button>
          </Col>
        </Row>
    </div>
    </Spin>
  );
};

export default TermsConditionsPage;
