import { creditAnalysisName } from '../../utils/constants/index';
import GridContainer from '../../common/components/elements/GridContainer';
import InlineLoader from '../../common/components/elements/loader/InlineLoader';
import CreditScoreSection from '../../modules/creditScore/CreditScoreSection';
import CreditScoreCard from '../../modules/creditScore/components/CreditScoreCard';
import {
  getCurrDetailsFlags,
  getPaymentHistoryFlags,
  getAgeCreditFlags,
  getCreditEnquiryFlags,
  getNegativeAccountFlags,
  getTotalAccountFlags,
  getDelayHistoryFlags,
  getOverdueAccountsFlags,
  calculateTotalDTIRatio,
  getDTIRatioFlags,
} from '../../utils/index';

import ErrorBox from '../../common/components/elements/ErrorBox';
import { useRole } from '../../common/hooks/use-user';
import { apiEndpoint } from '../../utils/endpoint';
import { PAGE } from '../../utils/constants/page';
import { useQueryData } from '../../lib/react-query';
import Section from '../../common/layouts/PageSection';
import { Document } from '@appTypes/user-model';
import {
  useCRIFJsonMutation,
  useFreshPullMutation,
} from '../../common/hooks/mutations/use-customer-mutation';
import { useQueryClient } from '@tanstack/react-query';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useEffect, useState } from 'react';
import { CrifData } from '@appTypes/credit-score-model';
import { CreditScoreSvg } from '../../utils/credit-score-svg/creditScoreSvg';
import { formatLocalDateString } from '../../utils/formatter';
import ScoringFactor from '../../modules/creditScore/ScoringFactor';
import { H5, P } from '../../common/components/elements/text';
import { Card, Tooltip } from 'antd';
import Divider from '../../common/components/elements/Divider';

const CREDIT_SCORE = 'CREDIT_SCORE';
export const BANK_STATEMENT_RED_FLAG_KEY = 'BANK_STATEMENT_RED_FLAG_KEY';

type Props = {
  userId: string;
  documents: Document[];
  annualIncome: number;
};

const CreditAnalysisSection = ({ userId, documents, annualIncome }: Props) => {
  const queryClient = useQueryClient();
  const { trackEvent } = useMatomo();
  const { currentUserRole } = useRole();
  const [creditAnalysis, setCreditAnalysis] = useState<CrifData[]>([]);
  const [currentTab, setCurrentTab] = useState('');
  const { data, isLoading, error, refetch } = useQueryData(
    apiEndpoint.creditAnalysis.getCreditScore(currentUserRole, userId),
    CREDIT_SCORE + userId,
    {},
    false,
    {
      staleTime: 300000,
      cacheTime: 300000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
  const mutateFreshPull = useFreshPullMutation(userId);
  const mutateJsonData = useCRIFJsonMutation(userId);

  useEffect(() => {
    if (!isLoading) {
      // @ts-ignore
      if (!data || data.length === 0) return;
      // @ts-ignore
      let analysisData: CrifData[] = data.map(
        (data: { analysis_data: CrifData }) => {
          return {
            ...data.analysis_data,
          };
        }
      );

      analysisData = analysisData
        .filter((a) => a.credit_score >= 0)
        .sort((a, b) => {
          return (
            new Date(b.report_date).getTime() -
            new Date(a.report_date).getTime()
          );
        })
        .sort((a, b) => {
          return (b.vintage_month || 0) - (a.vintage_month || 0);
        });

      setCreditAnalysis(analysisData);
      setCurrentTab(analysisData[0].report_id);
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <InlineLoader />;
  }

  // @ts-ignore
  if (!isLoading && !data) {
    if (error?.error?.question_one) {
      return (
        <Section title="Questionnaire Based Authentication">
          <Card className=" bg-white py-4 w-full mx-auto">
            <div className=" mx-auto py-9 px-4 md:py-5 md:px-2 flex flex-col md:flex-row justify-center gap-8">
              <img
                src="../assets/images/oops.png"
                alt="Credit Builder Loan"
                width={200}
                height={200}
              />
              <div>
                {error?.error?.question_one && (
                  <>
                    <H5 className="text-gray-600">
                      Q1{') '}
                      {error?.error?.question_one}
                    </H5>
                    <P className="text-gray-600">
                      User answered: {error?.error?.answer_one || 'N/A'}
                    </P>
                  </>
                )}
                <Divider className="my-2" />
                {error?.error?.question_two && (
                  <>
                    <H5 className="text-gray-600">
                      Q2{') '}
                      {error?.error?.question_two}
                    </H5>
                    <P className="text-gray-600">
                      User answered: {error?.error?.answer_two || 'N/A'}
                    </P>
                  </>
                )}
                <Divider className="my-2" />
                {error?.error?.question_three && (
                  <>
                    <H5 className="text-gray-600">
                      Q3{') '}
                      {error?.error?.question_three}
                    </H5>
                    <P className="text-gray-600">
                      User answered: {error?.error?.answer_three || 'N/A'}
                    </P>
                  </>
                )}
              </div>
            </div>
          </Card>
        </Section>
      );
    }

    return (
      <ErrorBox
        title="No CRIF High Mark Score Found!"
        text={`${error?.error?.message} | ${
          error?.error?.error_message ? error?.error?.error_message : ''
        }`}
        redirectButton={{
          text: 'Go to User Management',
          href: PAGE.userManagement.href,
        }}
      />
    );
  }

  const handleFreshPull = () => {
    const email = queryClient.getQueryData(['email']);
    if (email) {
      trackEvent({
        category: 'Hard Fresh Pull',
        action: 'Fresh Pull',
        name: `${email}`,
        value: 1,
      });
    }
    mutateFreshPull(userId).then(() => {
      refetch();
    });
  };

  const handleCrifJsonPull = () => {
    mutateJsonData(userId);
  };

  return (
    <>
      {creditAnalysis.length > 0 && (
        <CreditScoreSection
          creditAnalysis={creditAnalysis}
          handleFreshPull={handleFreshPull}
        />
      )}
      <div className="w-full max-w-sm md:max-w-screen-lg mx-auto">
        <ul className="flex flex-row overflow-x-scroll overflow-hidden items-center justify-start -mb-px text-sm font-medium text-center ">
          {creditAnalysis.length > 1 &&
            creditAnalysis.map((data, index) => {
              return (
                <Tooltip
                  title={`Score: ${
                    data.credit_score
                  } | Pull-Date: ${formatLocalDateString(data.report_date)}`}
                  key={index}
                  placement="top"
                >
                  <li
                    className="mr-2 cursor-pointer border-brand-orange-500"
                    key={index}
                    onClick={() => {
                      setCurrentTab(data.report_id);
                    }}
                  >
                    <a
                      className={`w-44 inline-flex items-center justify-center p-4 border-b-2  rounded-t-lg hover:border-b-3 hover:border-brand-orange-300 rounded-lg shadow-md group ${
                        currentTab === data.report_id &&
                        'border-1 text-brand-orange-600 border-brand-orange-600 hover:border-brand-orange-600 shadow-brand-orange-200 font-semibold bg-gray-100'
                      }`}
                    >
                      <CreditScoreSvg creditScore={'M' + data.vintage_month} />
                    </a>
                  </li>
                </Tooltip>
              );
            })}
        </ul>
      </div>
      {creditAnalysis
        .filter((a) => a.report_id === currentTab)
        .map((analyzedCredit, index) => {
          return (
            <>
              <ScoringFactor scoringFactors={analyzedCredit.scoring_factors} />
              <Section
                key={index}
                title="Credit Factors"
                text={`Below are all the credit factors that are affecting  ${analyzedCredit.full_name} overall score`}
              >
                <GridContainer>
                  <CreditScoreCard
                    name={creditAnalysisName.cur}
                    flag={getCurrDetailsFlags(
                      analyzedCredit.credit_utilization_ratio
                    )}
                    href={`/customer/credit-utilization-ratio/${userId}?report_id=${analyzedCredit.report_id}`}
                  />
                  <CreditScoreCard
                    name={creditAnalysisName.creditBehavior}
                    flag={getCurrDetailsFlags(analyzedCredit.credit_behavior)}
                    href={`/customer/credit-behavior/${userId}?report_id=${analyzedCredit.report_id}`}
                  />
                  <CreditScoreCard
                    name={creditAnalysisName.payment_history}
                    flag={getPaymentHistoryFlags(
                      analyzedCredit.payment_history
                    )}
                    href={`/customer/payment-history/${userId}?report_id=${analyzedCredit.report_id}`}
                  />
                  <CreditScoreCard
                    name={creditAnalysisName.AgeofCredit}
                    flag={getAgeCreditFlags(analyzedCredit.age_of_credit)}
                    href={`/customer/age-of-credit/${userId}?report_id=${analyzedCredit.report_id}`}
                  />
                  <CreditScoreCard
                    name={creditAnalysisName.creditEnquiryCount}
                    flag={getCreditEnquiryFlags(
                      analyzedCredit.credit_inquiry_count
                    )}
                    href={`/customer/credit-enquiry/${userId}?report_id=${analyzedCredit.report_id}`}
                  />
                  <CreditScoreCard
                    name={creditAnalysisName.negativeAccount}
                    flag={getNegativeAccountFlags(
                      analyzedCredit.negative_accounts_count
                    )}
                    href={`/customer/negative-account/${userId}?report_id=${analyzedCredit.report_id}`}
                  />
                  <CreditScoreCard
                    name={creditAnalysisName.totalAccount}
                    flag={getTotalAccountFlags(
                      analyzedCredit.total_accounts_count
                    )}
                    href={`/customer/total-account/${userId}?report_id=${analyzedCredit.report_id}`}
                  />
                  <CreditScoreCard
                    name={creditAnalysisName.avgDelay}
                    flag={getDelayHistoryFlags(analyzedCredit.average_delay)}
                    href={`/customer/delay-history/${userId}?report_id=${analyzedCredit.report_id}`}
                  />
                  <CreditScoreCard
                    name={creditAnalysisName.OverdueAccCount}
                    flag={getOverdueAccountsFlags(
                      analyzedCredit.overdue_accounts_count
                    )}
                    href={`/customer/overdue-account/${userId}?report_id=${analyzedCredit.report_id}`}
                  />
                  {annualIncome && (
                    <CreditScoreCard
                      name={creditAnalysisName.dtiRatio}
                      flag={getDTIRatioFlags(
                        calculateTotalDTIRatio(
                          analyzedCredit?.total_active_debt,
                          // @ts-ignore
                          annualIncome
                        )
                      )}
                      href={`/customer/dti-ratio/${userId}?report_id=${analyzedCredit.report_id}`}
                    />
                  )}
                </GridContainer>
              </Section>
            </>
          );
        })}
    </>
  );
};

export default CreditAnalysisSection;
