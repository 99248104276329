import { storage } from "../utils/storage";
import Axios, { AxiosRequestConfig } from "axios";

const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    return Promise.reject(error?.response?.data?.detail);
  }
);

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = storage.getToken();

  if (token) {
    config!.headers!.Authorization = token ? `Bearer ${token}` : "";
  }

  return config;
});

export default axios;
