import { Transaction } from '@appTypes/bank-statement-model';
import Section from '../../../common/layouts/PageSection';
import { TableHeaderBuilder } from 'react-table-utils';
import { ColumnDef } from '@tanstack/react-table';
import { H5 } from '../../../common/components/elements/text';
import { useMemo } from 'react';
import DataTable from '../../../common/components/tanstack-table';
import {
  removeCharacterFromAmount,
  truncateString,
} from '../../../utils/index';
import {
  formatDate,
  formatDateWithoutTime,
  formatMoney,
} from '../../../utils/formatter';

type Props = {
  transactions: Transaction[];
};

const AllIncomeTransactions: React.FC<Props> = ({ transactions }) => {
  const headerColumns = new TableHeaderBuilder<Transaction>()
    .add('date', (col) =>
      col
        .header('Date')
        .cell((value) => <H5>{formatDateWithoutTime(value)}</H5>)
    )
    .add('particular', (col) =>
      col.header('Particular').cell((value) => (
        <>
          <div className="group flex relative">
            <span
              className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 text-lg text-gray-100 rounded-md absolute left-1/2
            -translate-x-1/2 -translate-y-full opacity-0 m-4 mx-auto duration-150 ease-in-out"
            style={{ top: '-1rem' }}
            >
              {value}
            </span>
            <H5 className="">{truncateString(value, 40)}</H5>
          </div>
        </>
      ))
    )
    .add('party', (col) =>
      col.header('Party').cell((value) => <H5>{value}</H5>)
    )
    .add('deposit', (col) =>
      col
        .header('Credits')
        .cell((value) => (
          <H5 className="text-green-600 tooltip">
            {formatMoney(removeCharacterFromAmount(value))}
          </H5>
        ))
    )
    .add('balance', (col) =>
      col
        .header('Balance')
        .cell((value) => (
          <H5>{formatMoney(removeCharacterFromAmount(value))}</H5>
        ))
    )
    .build();

  const columns: ColumnDef<Transaction>[] = useMemo(() => headerColumns, []);

  return (
    <Section title="All Income Transactions">
      <DataTable columns={columns} data={transactions} />
    </Section>
  );
};

export default AllIncomeTransactions;
