import { useCallback, useEffect, useMemo, useState } from 'react';
import { TransactionReference } from '../../common/hooks/use-bank-statement';
import { useQueryClient } from '@tanstack/react-query';
import { Document } from '@appTypes/user-model';
import { BankStatementReferenceColumn } from '../../common/components/data-table/TableColumn';
import Section from '../../common/layouts/PageSection';
import Table from '../../common/components/data-table';
import { TableHeaderBuilder } from 'react-table-utils';
import {
  Transaction,
  ITransactionReferences,
} from '@appTypes/bank-statement-model';
import { H5, P } from '../../common/components/elements/text';
import { formatDateOfDD_MM_YYYY, formatMoney } from '../../utils/formatter';
import { truncateString } from '../../utils/index';
import DataTable from '../../common/components/tanstack-table';
import Tooltip from '../../common/components/elements/Tooltip';

type Props = {
  userId: string;
  documents: Document[];
};

const TRANSACTION_REFERENCE_KEY = 'TRANSACTION_REFERENCE_KEY';

const BankStatementReference: React.FC<Props> = ({ userId, documents }) => {
  const queryClient = useQueryClient();
  const [transactionReferenceData, setTransactionReferenceData] = useState(
    [] as ITransactionReferences[]
  );

  useEffect(() => {
    getTransactionReference();
  }, []);

  const getTransactionReference = async () => {
    const transactionReferenceCache = queryClient.getQueryData<{
      status: string;
      transaction_reference: ITransactionReferences[];
    }>([TRANSACTION_REFERENCE_KEY + userId]);

    if (transactionReferenceCache) {
      setTransactionReferenceData(
        transactionReferenceCache.transaction_reference
      );
    } else if (documents) {
      const transactionReference = await TransactionReference(documents);

      if (transactionReference.status == 'succeeded') {
        setTransactionReferenceData(transactionReference.transaction_reference);
        setTransactionReferenceData(transactionReference.transaction_reference);
        queryClient.setQueryData(
          [TRANSACTION_REFERENCE_KEY + userId],
          transactionReference
        );
      }
    }
  };

  const columns = BankStatementReferenceColumn();

  const headerColumns = new TableHeaderBuilder<Transaction>()
    .add('date', (col) =>
      col.header('Date').cell((value: any) => (
        <Tooltip text={value}>
          <P className="md:text-lg whitespace-nowrap break-words">
            {formatDateOfDD_MM_YYYY(value)}
          </P>
        </Tooltip>
      ))
    )
    .add('particular', (col) =>
      col.header('Particular').cell((value: any) => (
        <>
          <div className="group flex relative">
            <span
              className="group-hover:opacity-100 transition-opacity bg-gray-800 px-2 text-lg text-gray-100 rounded-md absolute left-1/2
            -translate-x-1/2 -translate-y-full opacity-0 m-4 mx-auto duration-150 ease-in-out"
              style={{ top: '-1rem' }}
            >
              {value}
            </span>
            <P className="">{truncateString(value, 50)}</P>
          </div>
        </>
      ))
    )
    .add('deposit', (col) =>
      col
        .header('Deposit')
        .cell((value: any) =>
          value ? (
            <P className="text-green-600 tooltip">{formatMoney(value)}</P>
          ) : (
            <P className="text-gray-500">-</P>
          )
        )
    )
    .add('withdrawal', (col) =>
      col
        .header('Withdrawal')
        .cell((value: any) =>
          value ? (
            <P className="text-red-600 tooltip">{formatMoney(value)}</P>
          ) : (
            <P className="text-gray-500">-</P>
          )
        )
    )
    .build();

  const rowSubComponentColumns = useMemo(() => headerColumns, [headerColumns]);

  const renderRowSubComponent = useCallback(
    ({ row }: any) => (
      <div className="px-10 py-4 bg-gray-100 border-2 border-gray-400">
        <DataTable
          columns={rowSubComponentColumns}
          data={row.original.transactions as Transaction[]}
          showColumnToggle={false}
        />
      </div>
    ),
    []
  );

  if (!transactionReferenceData || transactionReferenceData.length == 0)
    return <></>;

  return (
    <Section title="Transaction References">
      <Table
        columns={columns}
        data={transactionReferenceData}
        renderRowSubComponent={renderRowSubComponent}
      />
    </Section>
  );
};

export default BankStatementReference;
