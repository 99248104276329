import { ROLE_KEY, role } from "../../../utils/constants/index";
import { RequestType, useMutation } from "../../../lib/react-query";
import { apiEndpoint } from "../../../utils/endpoint";
import { showSuccessToast } from "../../../utils/toast";
import { useRole } from "../../hooks/use-user";
import { draftConfirmPIIData } from "@appTypes/bank-statement-model";

export const useInviteMutation = () => {
  const { currentUserRole } = useRole()
  const endpoint = currentUserRole === role.owner ? apiEndpoint.owners.managers.inviteManager : apiEndpoint.managers.executives.inviteExecutive;
  const mutation = useMutation(endpoint, RequestType.Post, '');

  const mutateAsync = async (data: any) => {
    return await mutation.mutateAsync(data).then((value) => {
      // @ts-ignore
      showSuccessToast(value?.message || "Invitation sent successfully!")
    })
  }

  return mutateAsync;
}

export const useAssignExecutivesMutation = () => {
  const mutation = useMutation(apiEndpoint.owners.managers.assignManagerToExecutive, RequestType.Put, '');

  const mutateAsync = async (data: any) => {
    return await mutation.mutateAsync(data).then((value) => {
      showSuccessToast("Assigned Executives successfully!")
    }).catch((error) => {
    })
  }

  return mutateAsync;
};

export const useAssignCustomersMutation = () => {
  const mutation = useMutation(apiEndpoint.managers.executives.assignCustomerToExecutive, RequestType.Put, '');

  const mutateAsync = async (data: any) => {
    return await mutation.mutateAsync(data).then(() => {
      showSuccessToast("Assigned Customers successfully!")
    })
  }

  return mutateAsync;
};

export const useCreateBorrowerMutation = () => {
  const mutation = useMutation(apiEndpoint.common.createBorrower, RequestType.Post, '');

  const mutateAsync = async (data: draftConfirmPIIData & {customer_id: string}) => {
    return await mutation.mutateAsync(data).then(() => {
      showSuccessToast("Borrower created successfully!")
    }).catch((error) => {
      console.log('error', error);
    })
  }

  return mutateAsync;
}