import AWS from 'aws-sdk';

const AWS_BUCKET_NAME ='bcb-docs';
const AWS_BUCKET_REGION ='us-east-1';
const AWS_ACCESS_KEY ='AKIAQ3NW2N2C2F5WVNPN';
const AWS_SECRET_KEY ='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';

type Props = {
  file: File;
  fieldName: string;
};

const s3 = new AWS.S3({
  region: AWS_BUCKET_REGION,
  credentials: {
    accessKeyId: AWS_ACCESS_KEY!,
    secretAccessKey: AWS_SECRET_KEY!,
  },
});

export const getDocumentFromS3 = (file: string) => {
  const s3Bucket = 'https://bcb-docs.s3.amazonaws.com/';
  const relativePath = file.replace(s3Bucket, '');
  const fileLocation = relativePath.replace(/\//g, '/');

  const params = {
    Bucket: AWS_BUCKET_NAME!,
    Key: fileLocation,
  };
  return s3.getObject(params).promise();
};

export const getInvoiceFromS3 = (fileName: string, fileCategory: string) => {
  const params = { Bucket: AWS_BUCKET_NAME!, Key: `${process.env.REACT_APP_API_ENV}/${fileCategory}/${fileName}`};
  return s3.getObject(params).promise();
}

export const getJsonFromS3 =async (fileLocation: string) => {
  try {
    const path = fileLocation.replace('https://bcb-docs.s3.amazonaws.com/', '');
    const params = {
      Bucket: AWS_BUCKET_NAME!,
      Key: `${path.replace('.pdf', '_analyzed.json')}`,
    };

    const data: any = await s3.getObject(params).promise();

    const jsonContent = data.Body.toString('utf-8');
    return JSON.parse(jsonContent);
  } catch (error) {
    console.error('Error fetching data from S3:', error);
    throw error; 
  }
};
