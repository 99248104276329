const ACCESS_TOKEN_KEY = "token";

export const storage = {
  getToken: (): string | null =>
    typeof window !== "undefined"
      ? window.localStorage.getItem(ACCESS_TOKEN_KEY)
      : null,
  setToken: (token: string) =>
    typeof window !== "undefined" &&
    window.localStorage.setItem(ACCESS_TOKEN_KEY, token),
  clearToken: () =>
    typeof window !== "undefined" &&
    window.localStorage.removeItem(ACCESS_TOKEN_KEY),
};

export const storeSingleItem = {
  getItem: (key: string) =>
    typeof window !== "undefined"
      ? (window.localStorage.getItem(key) || "")
      : "",
  setItem: (key: string, value: string) =>
    typeof window !== "undefined" &&
    window.localStorage.setItem(key, value),
  clearItem: (key: string) =>
    typeof window !== "undefined" &&
    window.localStorage.removeItem(key),
}  