import { draftConfirmPIIData } from '@appTypes/bank-statement-model';
import { H3, H5 } from '../../../common/components/elements/text';
import { useEffect } from 'react';
import { Document } from '@appTypes/user-model';
import { BankAccountInformation } from '../../../common/hooks/use-bank-statement';
import Card from '../../../common/components/elements/Card';

type Props = {
  documents: Document[];
  bankAccountInformation: draftConfirmPIIData[];
  setBankAccountInformation: (data: draftConfirmPIIData[]) => void;
  analysisStatus: boolean;
  setAnalysisStatus: (data: boolean) => void;
};

const BankAccount: React.FC<Props> = ({
  documents,
  bankAccountInformation,
  setBankAccountInformation,
  analysisStatus,
  setAnalysisStatus,
}) => {
  useEffect(() => {
    getBankAccountInformation(documents);
  }, []);

  const getBankAccountInformation = async (documents: Document[]) => {
    const bankAccountInformation = await BankAccountInformation(documents);

    if (bankAccountInformation.status === 'succeeded') {
      const {
        account_holder_name,
        account_number,
        ifsc,
        bank_name,
        father_fname,
        father_lname,
      } = bankAccountInformation;
      setBankAccountInformation([]);
      setBankAccountInformation([
        {
          account_number: account_number || '',
          account_holder_name: account_holder_name || '',
          ifsc: ifsc || '',
          bank_name: bank_name || '',
          father_fname: father_fname || '',
          father_lname: father_lname || '',
        },
      ]);
      setAnalysisStatus(true);
    } else {
      setAnalysisStatus(false);
    }
  };

  if (!analysisStatus) return <></>;

  return (
    <Card className="w-full max-w-auto sm:space-y-2 sm:p-8  border border-gray-300">
      <H3 className="text-lg text-center font-medium leading-6 text-gray-900">
        Personally Identifiable Information
      </H3>
      <div className="flex justify-center">
        <div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <H5 className="text-sm font-medium text-gray-500">
              Account Holder
            </H5>
            <H5 className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {bankAccountInformation[0].account_holder_name}
            </H5>
          </div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <H5 className="text-sm font-medium text-gray-500">Bank Name</H5>
            <H5 className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {bankAccountInformation[0].bank_name}
            </H5>
          </div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <H5 className="text-sm font-medium text-gray-500">
              Father First Name
            </H5>
            <H5 className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {bankAccountInformation[0].father_fname}
            </H5>
          </div>
        </div>
        <div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <H5 className="text-sm font-medium text-gray-500">
              Account Number
            </H5>
            <H5>{bankAccountInformation[0].account_number}</H5>
          </div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
            <H5 className="text-sm font-medium text-gray-500">IFSC</H5>
            <H5 className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {bankAccountInformation[0].ifsc}
            </H5>
          </div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <H5 className="text-sm font-medium text-gray-500">
              Father Last Name
            </H5>
            <H5 className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {bankAccountInformation[0].father_lname}
            </H5>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BankAccount;
