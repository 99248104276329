import * as y from "yup";

export const LENGTH_MOBILE = 10;
const MIN_LENGTH_PASSWORD = 8;

export const registerSchema = y.object().shape({
  email: y.string().email("Invalid email").required("Email is required"),
  password: y.string()
    .min(MIN_LENGTH_PASSWORD, `Password must be at least ${MIN_LENGTH_PASSWORD} characters long`)
    .required('Password is required'),
  phone_number: y
    .string()
    .matches(/^[0-9]+$/, 'Must be onily dgits')
    .length(10, "Phone number must be of 10 digits")
    .required("Phone number is required"),
});

export const loginSchema = y.object().shape({
  email: y.string().email("Invalid email").required("Email is required"),
  password: y.string()
    .min(MIN_LENGTH_PASSWORD, `Password must be at least ${MIN_LENGTH_PASSWORD} characters long`)
    .required('Password is required'),
  role: y.string().required("Role is required"),
});

export const resetPasswordSchema = y.object().shape({
  email: y.string().email("Invalid email").required("Email is required"),
  password: y.string()
    .min(MIN_LENGTH_PASSWORD, `Password must be at least ${MIN_LENGTH_PASSWORD} characters long`)
    .required('Password is required'),
  // .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
  // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
  // .matches(/\d/, 'Password must contain at least one number')
  // .matches(/[!@#$%^&*]/, 'Password must contain at least one special character'),
  otp: y.string().min(6, 'OTP must be 6 digits').max(6, 'OTP must be 6 digits').required('OTP is required'),
  role: y.string().required("Role is required"),
});

export const forgotPasswordSchema = y.object().shape({
  email: y.string().email("Invalid email").required("Email is required"),
})

export const otpVerificationSchema = y.object().shape({
  otp: y.array()
    .of(y.string().matches(/^\d$/, 'Enter a valid digit'))
    .min(6, 'Enter the complete OTP')
    .max(6, 'Enter only 4 digits')
    .required('OTP is required'),
});