import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import './login-style.scss';
import Form from '../../common/components/form';
import { initialRegisterCredentials } from '../../common/types/auth-model';
import { registerSchema } from '../../common/schema/auth';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import Input from '../../common/components/elements/form/Input';
import LinkedItem from '../../common/components/elements/LinkedItem';
import { useRegister } from '../../common/hooks/mutations/use-auth';
import { PAGE } from '../../utils/constants/page';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const NewLoginPage = () => {
  const navigate = useNavigate();
  const { register } = useRegister();
  const { trackEvent } = useMatomo();

  return (
    <div className="login-page">
      <Row gutter={0}>
        <Col xl={6} lg={8} md={12} sm={20} xs={24} className="login-box">
          <Row>
            <Col span={24}>
              <div className="illustration-wrapper">
                <img src="./assets/images/logo1.svg" alt="logo" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="mb-10">
                <Form
                  initialValues={initialRegisterCredentials}
                  schema={registerSchema}
                  onSubmit={async (data) => {
                    data.phone_number = `${data.phone_number}`;
                    const response = await register(data);
                    if (response) {
                      trackEvent({
                        category: 'Sign UP',
                        action: 'Authenticates a user',
                        name: 'owner' + ': ' + data.email,
                        value: 1,
                      });
                      navigate(PAGE.signUpOtpVerification.href, {
                        state: data.email,
                      });
                    }
                  }}
                  submitButton={{
                    text: 'Register',
                    IconRight: ChevronDoubleRightIcon,
                    className: 'w-full mx-auto',
                  }}
                >
                  <h3>Welcome</h3>
                  <Input name="email" placeholder="Email" required />
                  <Input
                    name="phone_number"
                    placeholder="Phone Number"
                    type="number"
                    inputClassName="arrows-none"
                    required
                  />
                  <Input
                    name="password"
                    placeholder="Password"
                    type="password"
                    required
                  />
                  <div className="text-sm text-right">
                    <LinkedItem
                      href="/login"
                      className="font-bold text-primary-300"
                    >
                      Have an account? Login
                    </LinkedItem>
                  </div>
                </Form>
                <div className="text-sm text-center mt-2">
                  <LinkedItem
                    href={PAGE.forgotPassword.href}
                    className="font-bold text-primary-300"
                  >
                    Forgot password?
                  </LinkedItem>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default NewLoginPage;
