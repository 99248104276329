import { apiEndpoint } from "../../utils/endpoint";
import { useQuery } from "../../lib/react-query";

export const STATS_QUERY_KEY = "QUERY_STATS";

export const useStatistics = (): {
  data: any;
  isLoading: boolean;
  refetch: () => void;
} => {
  const { data, isLoading, refetch } = useQuery(apiEndpoint.common.getStates, STATS_QUERY_KEY, {}, true, {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    retry: 2,
    staleTime: 30000,
    cacheTime: 30000,
  })
  return {
    data,
    isLoading,
    refetch,
  };
};