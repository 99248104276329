import { showSuccessToast } from "../../../utils/toast";
import { RequestType, useMutation } from "../../../lib/react-query";
import { apiEndpoint } from "../../../utils/endpoint";

export const useFreshPullMutation = (customerId: string) => {
  const mutation = useMutation(apiEndpoint.creditAnalysis.updateCrifPull(customerId), RequestType.Get, '');

  const mutateAsync = async (data: any) => {
    return await mutation.mutateAsync(data).then((value) => {
      showSuccessToast("Crif Report pulled successfully!")
    }).catch((error) => {
    })
  }

  return mutateAsync;
}

export const useCRIFJsonMutation = (customerId: string) => {
  const mutation = useMutation(apiEndpoint.creditAnalysis.getCrifJson(customerId), RequestType.Get, '');

  const mutateAsync = async (data: any) => {
    return await mutation.mutateAsync(data).then((value: any) => {
      const jsonData = value.data.crif_json;
      const jsonBlob = new Blob([JSON.stringify(jsonData)], {
        type: 'application/json',
      });
      
      const jsonBlobURL = URL.createObjectURL(jsonBlob);
      const downloadLink = document.createElement('a');
      downloadLink.href = jsonBlobURL;
      downloadLink.download = `${customerId}.json`;

      downloadLink.click();
      URL.revokeObjectURL(jsonBlobURL);
    }).catch((error) => {
    })
  }

  return mutateAsync;
}
