import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { RectangleStackIcon } from '@heroicons/react/24/outline';
import { Table } from '@tanstack/react-table';

type Props<T> = {
  table: Table<T>;
};

const DataTableColumnTogglerButton = ({ table }: any) => {
  const { allColumns } = table;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>
          <RectangleStackIcon className="w-5 h-5" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 w-56 p-2 mt-2 capitalize origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="mb-1 text-xs text-gray-400">Show/hide columns</div>

          {allColumns.map((column: any) => {
            return (
              <div key={column.id} className="p-1">
                <label className="cursor-pointer">
                  <input
                    type="checkbox"
                    checked={column.isVisible}
                    onChange={() =>
                      column.toggleHidden()
                    }
                    className="mr-1"
                  />
                  {column.Header}
                </label>
              </div>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DataTableColumnTogglerButton;
