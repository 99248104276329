import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { storage } from '../../../utils/storage';
import { showErrorAlert } from '../../../utils/alert';
import { apiEndpoint } from '../../../utils/endpoint';
import { showSuccessToast } from '../../../utils/toast';
import axios from '../../../lib/axios';
import { LoginCredentials, RegisterCredentials, confirmRegisterCredentials, ForgotPasswordCredentials, ResetPasswordCredentials, AcceptInvitationCredentials } from '../../types/auth-model';
import { OWNER_NOT_VERIFIED, ROLE_KEY, role } from '../../../utils/constants/index';
import { useNavigate } from 'react-router-dom';
import { USER_PROFILE_QUERY_KEY } from '../use-user';

function handleUserResponse(response: any, customMessage: string) {
  const { data, message } = response;
  showSuccessToast(message ? message : customMessage);
  data?.access_token && storage.setToken(data?.access_token);
  return true;
}

function handleAuthResponse(response: any) {
  const { data, message } = response;
  showSuccessToast(message);
  return data;
}

export function useLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  async function login(credentials: LoginCredentials) {
    setIsLoading(true);
    try {
      const endpoint = credentials.role === role.owner ? apiEndpoint.owners.auth.signin : credentials.role === role.manager ? apiEndpoint.managers.auth.signin : apiEndpoint.executives.auth.signin;
      const response = await axios.post(endpoint, credentials);
      return handleUserResponse(response, "Login Successful");
    } catch ({ message }: any) {
      storage.clearToken();
      if (message === OWNER_NOT_VERIFIED) navigate('/otp-verification', { state: credentials.email })
      showErrorAlert("Login Unsuccessful", `${message}!`);
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return { login, isLoading };
}

export function useRegister() {
  const [isLoading, setIsLoading] = useState(false);

  async function register(credentials: RegisterCredentials) {
    setIsLoading(true);
    try {
      const response = await axios.post(apiEndpoint.owners.auth.signup, credentials);
      return handleUserResponse(response, 'OTP sent to your email!');
    } catch ({ message }: any) {
      storage.clearToken();
      showErrorAlert("Registration Unsuccessful", `${message}!`);
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return { register, isLoading };
}

export function useOTPVerify() {
  const [isLoading, setIsLoading] = useState(false);

  async function verifySignUpOtp(credentials: confirmRegisterCredentials) {
    try {
      const response = await axios.post(apiEndpoint.owners.auth.verifySignUpOtp, credentials);
      return handleUserResponse(response, 'Registration Successful');
    } catch (error: any) {
      showErrorAlert(error?.message, 'Please resend OTP!');
      return false;
    }
  }

  async function resendOTP(credentials: ForgotPasswordCredentials) {
    setIsLoading(true);
    try {
      const endpoint = credentials.role === role.owner ? apiEndpoint.owners.auth.resendOtp : credentials.role === role.manager ? apiEndpoint.managers.auth.resendOtp : apiEndpoint.executives.auth.resendOtp;
      const response = await axios.post(endpoint, credentials);
      return handleUserResponse(response, 'OTP sent to your email!');
    } catch (error: any) {
      showErrorAlert(error?.message, '');
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return { verifySignUpOtp, resendOTP, isLoading };
}

export function useResetPassword() {

  async function resetPassword(credentials: ResetPasswordCredentials) {
    try {
      const endpoint = credentials.role === role.owner ? apiEndpoint.owners.auth.resetPassword : credentials.role === role.manager ? apiEndpoint.managers.auth.resetPassword : apiEndpoint.executives.auth.resetPassword;
      const response = await axios.post(endpoint, credentials);
      return handleUserResponse(response, 'Password reset successful');
    } catch (error: any) {
      showErrorAlert(error?.message, 'Please resend OTP!');
      return false;
    }
  }

  return { resetPassword };
}

export function useLogout() {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  async function logout() {
    setIsLoading(true);
    try {
      storage.clearToken();
      await queryClient.invalidateQueries();
      queryClient.clear();
      await queryClient.resetQueries({ queryKey: [ROLE_KEY] })
      queryClient.removeQueries({queryKey: [ROLE_KEY], exact: true});
      queryClient.removeQueries({queryKey: [USER_PROFILE_QUERY_KEY], exact: true})
      queryClient.removeQueries();
      return true;
    } catch (errors) {
      const { message }: any = errors;
      showErrorAlert('', message);
    } finally {
      setIsLoading(false);
    }
  }

  return { logout, isLoading };
}

export function useInvite() {

  async function acceptInvitation(credentials: AcceptInvitationCredentials) {
    try {
      const endpoint = credentials.role === role.manager ? apiEndpoint.managers.profile.acceptInvitation : apiEndpoint.executives.profile.acceptInvitation;
      const response = await axios.post(endpoint, credentials);
      return handleUserResponse(response, 'Password reset successful');
    } catch (error: any) {
      if (error?.message === "OTP has expired.") {
        resendOTP({ email: credentials.email, role: credentials.role });
      }
      showErrorAlert(error?.message, 'Please resend OTP!');
      return false;
    }
  }

  return { acceptInvitation };
}

export function useAuth() {
  const { login } = useLogin();
  const { register } = useRegister();
  const { verifySignUpOtp, resendOTP, isLoading } = useOTPVerify();
  const { logout } = useLogout();
  const { resetPassword } = useResetPassword();
  const { acceptInvitation } = useInvite();

  return {
    login,
    register,
    verifySignUpOtp,
    resendOTP,
    isLoading,
    resetPassword,
    logout,
    acceptInvitation
  };
}

async function resendOTP(credentials: ForgotPasswordCredentials) {
  try {
    const endpoint = credentials.role === role.owner ? apiEndpoint.owners.auth.resendOtp : credentials.role === role.manager ? apiEndpoint.managers.auth.resendOtp : apiEndpoint.executives.auth.resendOtp;
    const response = await axios.post(endpoint, credentials);
    return handleUserResponse(response, 'OTP sent to your email!');
  } catch (error: any) {
    showErrorAlert(error?.message, '');
    return false;
  }
}