import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGE } from '../../utils/constants/page';
import FullPageLoader from '../components/elements/loader/FullPageLoader';
import { useUser } from '../hooks/use-user';
import { storage } from '../../utils/storage';
import { useQueryClient } from '@tanstack/react-query';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export const withAuth =
  <T extends object>() =>
  (Component: React.ComponentType<T>): React.ComponentType<T> => {
    const WithAuth: React.FC<T> = (props) => {
      const queryClient = useQueryClient();
      const navigate = useNavigate();
      const { pushInstruction } = useMatomo();
      const { data, authorized, isLoading } = useUser();

      useEffect(() => {
        const invalidateToken = async () => {
          await queryClient.invalidateQueries();
          storage.clearToken();
          navigate(PAGE.login.href);
        };

        if (storage.getToken() === null) invalidateToken();
        if (isLoading) return;

        if (!authorized) invalidateToken();
        // MATOMO: TRACKING CODE
        if (authorized && !isLoading && data) {
          const { manager, owner, executive } = data;
          const user = manager || owner || executive;
          
          queryClient.setQueryData(['email'], `${user.profile.email}`);
          pushInstruction('setUserId', `${user.profile.email}`);
          var _paq = window._paq || [];
          _paq.push(['setUserId', `${user.profile.email}`]);
        }
      }, [isLoading, data, authorized]);

      if (isLoading) {
        return <FullPageLoader isOpen={true} />;
      }

      return <Component {...props} />;
    };

    WithAuth.displayName = `withAuth(${
      Component.displayName || Component.name
    })`;

    return WithAuth;
  };
