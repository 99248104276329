import { NetCashFlowData } from '@appTypes/bank-statement-model';
import DataTable from '../../common/components/tanstack-table';
import { H5, H1 } from '../../common/components/elements/text';
import { BankStatement } from '../../common/hooks/use-bank-statement';
import { useFullPageLoader } from '../../common/hooks/use-full-page-loader';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { formatMoney } from '../../utils/formatter';
import {
  filterBankStatementData,
  removeCharacterFromAmount,
  getNetCashFlowFlags,
} from '../../utils/index';
import { Tag } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { TableHeaderBuilder } from 'react-table-utils';
import GrossIncomeBarChart from './components/GrossIncomeBarChart';
import { MONTHS_ARRAY } from '../../utils/constants/index';
import {Document} from '@appTypes/user-model';
import { useParams } from 'react-router-dom';
import { useCustomerById } from '../../common/hooks/use-customer';

export const BANK_STATEMENT_ANALYSIS_KEY = 'bank-statement-analysis';

type Props = {};

const NetCashFlow: React.FC<Props> = () => {
  const { showLoader, hideLoader } = useFullPageLoader();
  const queryClient = useQueryClient();
  const [netCashFlowData, setNetCashFlowData] = useState<NetCashFlowData[]>([]);
  const [chartData, setChartData] = useState<{
    months: string[];
    grossIncomeData: number[];
  }>({
    months: [],
    grossIncomeData: [],
  });

  const router = useParams();
  const userId = router.id as string;

  const {data, isLoading} = useCustomerById(userId);

  const getNetCashFlowAnalysis = async (documents: Document[]) => {
    showLoader();

    let completeAnalysisData;
    const bankStatementAnalysisData = queryClient.getQueryData([
      BANK_STATEMENT_ANALYSIS_KEY + userId,
    ]);

    if (bankStatementAnalysisData) {
      completeAnalysisData = bankStatementAnalysisData;
      hideLoader();
    } else {
      const bankStatement = filterBankStatementData(documents);
      const { data, isBankStatementAvailable, isBankStatementEmpty } =
        await BankStatement(bankStatement);
      hideLoader();

      if (!isBankStatementAvailable || isBankStatementEmpty) return;
      completeAnalysisData = data;
      queryClient.setQueryData([BANK_STATEMENT_ANALYSIS_KEY + userId], data);
    }
    const completeAnalysisByAccount =
      // @ts-ignore
      completeAnalysisData?.analysis?.by_account;
    const accountNumber = Object.keys(completeAnalysisByAccount)[0];
    const completeDetail = completeAnalysisByAccount[accountNumber];

    const netCashFlowData = completeDetail?.net_cash_flow;
    setNetCashFlowData(netCashFlowData);

    setChartData({
      months: [],
      grossIncomeData: [],
    });

    netCashFlowData.forEach((item: NetCashFlowData) => {
      setChartData((prev) => ({
        months: [
          ...prev.months,
          `${MONTHS_ARRAY[item.period.month]}, ${String(item.period.year).slice(
            -2
          )}`,
        ],
        grossIncomeData: [...prev.grossIncomeData, item.income],
      }));
    });
  };

  useEffect(() => {
    if (!isLoading && data?.customer) getNetCashFlowAnalysis(data.customer.documents);
  }, []);

  const headerColumns = new TableHeaderBuilder<NetCashFlowData>()
    .add('period', (col) =>
      col.header('Month').cell(({ month, year }: any) => (
        <H5>
          {MONTHS_ARRAY[month]}, {String(year).slice(-2)}
        </H5>
      ))
    )
    .add('credits', (col) =>
      col
        .header('Credits (C)')
        .cell((value) => (
          <H5 className="text-primary-500">
            {formatMoney(removeCharacterFromAmount(value))}
          </H5>
        ))
    )
    .add('debits', (col) =>
      col
        .header('Debits (D)')
        .cell((value) => (
          <H5 className="text-primary-500">
            {formatMoney(removeCharacterFromAmount(value))}
          </H5>
        ))
    )
    .add('income', (col) =>
      col
        .header('Gross Income (C-D)')
        .cell((value) => (
          <>
            {getNetCashFlowFlags(removeCharacterFromAmount(value)).color ===
            'red' ? (
              <Tag className="text-red-600 bg-red-100">
                {formatMoney(removeCharacterFromAmount(value))}
              </Tag>
            ) : getNetCashFlowFlags(removeCharacterFromAmount(value)).color ===
              'orange' ? (
              <Tag className="text-brand-orange-600 bg-orange-100">
                {formatMoney(removeCharacterFromAmount(value))}
              </Tag>
            ) : (
              <Tag className="text-green-600 bg-green-100">
                {formatMoney(removeCharacterFromAmount(value))}
              </Tag>
            )}
          </>
        ))
    )
    .build();

  const columns: ColumnDef<NetCashFlowData>[] = useMemo(
    () => headerColumns,
    []
  );

  return (
    <div>
      <H1 className="text-center">Net Cash Flow</H1>
      <DataTable columns={columns} data={netCashFlowData} />
      <GrossIncomeBarChart
        months={chartData.months}
        grossIncomeData={chartData.grossIncomeData}
      />
    </div>
  );
};

export default NetCashFlow;
