import { P } from '@elements/text';

import type { TextProps } from '@elements/text';
import clsx from 'clsx';
import React from 'react';

import type { Component } from '@appTypes/.';

const Tag: Component<TextProps> = ({ className, children, title }) => {
  return (
    <span
      className={`
        "bg-gray-100 rounded-full px-3 py-1 !text-sm cursor-default capitalize inline-block",
        ${className}`}
      title={title}
    >
      {children}
    </span>
  );
};

export default Tag;
