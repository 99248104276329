import React, { useEffect, useState } from 'react';
import { Avatar, Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { ILoginState } from '../../pages/login/login-redux/login-interface';
import { imageUrl } from '../../utils/constant';
import './admin.scss';
import { log } from 'console';
import { PAGE } from '../../utils/constants/page';
import { useLogout } from '../../common/hooks/mutations/use-auth';
import { useRole, useUser } from '../../common/hooks/use-user';
import { getDummyPicture } from '../../utils/index';
import { P } from '../../common/components/elements/text';
import { withAuth } from '../../common/hoc/withAuth';

const Topnav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUserRole } = useRole();
  const [profileToggle, setprofileToggle] = useState(false);
  const [notificationToggle, setnotificationToggle] = useState(false);
  const loginState = useSelector((state: ILoginState) => state.LoginState);
  const { logout } = useLogout();

  const { data, isLoading } = useUser();

  if (isLoading) return <></>;

  window.onclick = (e: any) => {
    if (!e.target.closest('.alertArea') && notificationToggle) {
      setnotificationToggle(!notificationToggle);
    } else if (!e.target.closest('.avatar-style') && profileToggle) {
      setprofileToggle(!profileToggle);
    }
  };

  const { manager, owner, executive } = data;
  const user = manager || owner || executive;

  const { first_name, last_name } = user?.profile;

  const handleProfile = () => {
    setprofileToggle(!profileToggle);
  };
  const handleNotification = () => {
    setnotificationToggle(!notificationToggle);
  };
  const handleLogout = () => {
    logout();
    // dispatch({ type: LoginActionTypes.LOGOUT });
    navigate(PAGE.login.href);
  };
  const handleOpenprofile = () => {
    navigate('/userprofile');
  };

  return (
    <div className="innerHeader">
      <div className="details-area">
        <Avatar
          className="avatar-style"
          size={40}
          icon={
            <img src={getDummyPicture(first_name + ' ' + last_name || '')} />
          }
          onClick={handleProfile}
        ></Avatar>
        <div className="alertArea">
          <BellOutlined onClick={handleNotification} />
          <Badge count={10}></Badge>
        </div>
      </div>
      {profileToggle && (
        <div className="moreOptions">
          <ul>
            <li className="text-center">
              <P className='text-bold'>{first_name ? first_name : ''} {last_name ? last_name : ''}</P>
            </li>
            <li onClick={handleOpenprofile} className="capitalize">
              <UserOutlined /> {currentUserRole}
            </li>
            <li onClick={handleLogout}>
              <LogoutOutlined /> Logout
            </li>
          </ul>
        </div>
      )}
      {notificationToggle && (
        <div className="notificationContainer">
          <ul>
            <li>Lorem ipsum dolor sit amet consectetur</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
            <li>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
              ipsum officia odit
            </li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
          </ul>
          <button className="btn-primary">view all</button>
        </div>
      )}
    </div>
  );
};

export default withAuth()(Topnav);
