export interface CmsManagementModel {
  status: boolean;
  allpages: ICmsManagment[];
}

export interface ICmsManagment {
  id: string;
  page_name: string;
  page_id: string;
  page_content: string;
  page_image: string;
  page_heading: string;
  is_active: boolean;
  is_deleted: boolean;
}

export const initialICmsManagement : ICmsManagment = {
    id: '',
    page_name: '',
    page_id: '',
    page_content: '',
    page_image: '',
    page_heading: '',
    is_active: false,
    is_deleted: false
  }

export const initialRoleManagement: CmsManagementModel = {
  status: false,
  allpages: [],
};
