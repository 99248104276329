import { H4, P } from '../text';
import clsx from 'clsx';
import React from 'react';

type Props = {
  title?: string;
  className?: string;
  children?: React.ReactNode;
};

const Fieldset: React.FC<Props> = ({ className, title, children }) => {
  return (
    <fieldset
      className={clsx('border-2 border-gray-300 bg-white rounded-md p-4', className)}
    >
      {title && (
        <legend>
          <H4>{title}</H4>
        </legend>
      )}
      {children}
    </fieldset>
  );
};

export default Fieldset;
