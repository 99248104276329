import { TableHeaderBuilder } from 'react-table-utils';
import { useRef, useState } from 'react';
import LinkedItem from '../../../common/components/elements/LinkedItem';
import { H5, P } from '../../../common/components/elements/text';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { formatDate, formatMoney } from '../../../utils/formatter';
import { DocumentInfoField } from 'components/userprofile/customerProfile';
import { Refund, Subscription } from '../../../common/types/plan-model';
import DataTable from '../../../common/components/tanstack-table';
import Section from '../../../common/layouts/PageSection';
import { handleInvoiceDownload } from '../components/DownloadDocuments';
import RefundModal from '../components/RefundModal';
import { useRole } from '../../../common/hooks/use-user';
import { filterSubscriptionTransactions } from '../../../utils/index';
import Tag from '../../../common/components/elements/Tag';
import { CASHFREE_STATUS, role } from '../../../utils/constants/index';
import { Tooltip } from 'antd';

type PreviousPlansProps = {
  allPlans: Subscription[];
  allRefunds: Refund[];
};

const PreviousPlans: React.FC<PreviousPlansProps> = ({
  allPlans,
  allRefunds,
}) => {
  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);
  const { currentUserRole } = useRole();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
  const [selectedRefundAmount, setSelectedRefundAmount] = useState(0);
  const [validSubscriptions, setValidSubscriptions] = useState<Subscription[]>(
    filterSubscriptionTransactions(allPlans, allRefunds)
  );

  const headerColumns = new TableHeaderBuilder<Subscription>()
    .add('plan', (col) =>
      col.header('Plan').cell((value: any) => (
        <>
          <P className="text-center capitalize ">{value.category}</P>
          <P className="rounded-full mt-2 px-1 text-center capitalize bg-gray-200">
            {value.validity} Months
          </P>
        </>
      ))
    )
    .add('created_at', (col) =>
      col
        .header('Purchase Date')
        .cell((value: any) => (
          <P className="md:text-lg whitespace-nowrap break-words">
            {formatDate(value)}
          </P>
        ))
    )
    .add('start_date', (col) =>
      col
        .header('Subscription Start Date')
        .cell((value: any) => (
          <P className="md:text-lg whitespace-nowrap break-words">
            {formatDate(value)}
          </P>
        ))
    )
    .add('end_date', (col) =>
      col
        .header('Subscription End Date')
        .cell((value: any) => (
          <P className="md:text-lg  whitespace-nowrap break-words">
            {formatDate(value)}
          </P>
        ))
    )
    .add('amount', (col) =>
      col.header('Price').cell((value: any) => (
        <>
          <P className="text-primary-500 md:text-lg">{formatMoney(value)}</P>
        </>
      ))
    )
    .add('invoice_number', (col) =>
      col.header('Invoice').cell((value: any) => (
        <DocumentInfoField
          title=""
          image="invoice1.png"
          fileName={value}
          showFileName={true}
        >
          <LinkedItem
            href=""
            className={`mt-2`}
            onClick={() => handleInvoiceDownload(value, downloadLinkRef)}
          >
            <ArrowDownTrayIcon className="h-6 w-6 text-primary-500" />
          </LinkedItem>
          <a ref={downloadLinkRef} style={{ display: 'none' }} />
        </DocumentInfoField>
      ))
    )
    .add('combined_key', (col) =>
      col.header('Status').cell((value: any) => (
        <>
          {value[0] === CASHFREE_STATUS.PAID ? (
            <Tag className="text-green-800 bg-green-100 border border-green-800 rounded-md">
              {value[0]}
            </Tag>
          ) : (
            <Tag className="text-blue-800 bg-blue-100 border border-blue-800 rounded-md">
              {value[0]}
            </Tag>
          )}
          {currentUserRole === role.owner &&
            value[0] === CASHFREE_STATUS.PAID &&
            new Date(value[4]) > new Date() && (
              <Tooltip title="Click here to initiate the refund">
                <LinkedItem
                  href={''}
                  onClick={() => handleModalOpen(value[1], value[2])}
                >
                  <P className="underline text-red-800">Click to Refund</P>
                </LinkedItem>
              </Tooltip>
            )}
        </>
      ))
    )
    .add('refund_amount', (col) =>
      col
        .header('Refund Amount')
        .cell((value: any) =>
          value ? (
            <P className="text-primary-500 md:text-lg">{formatMoney(value)}</P>
          ) : (
            <P>N/A</P>
          )
        )
    )
    .add('refund_date', (col) =>
      col
        .header('Refund Date')
        .cell((value: any) =>
          value ? (
            <P className="md:text-lg whitespace-nowrap break-words">
              {formatDate(value)}
            </P>
          ) : (
            <P>N/A</P>
          )
        )
    )
    .add('credit_note_number', (col) =>
      col.header('Credit Note').cell((value: any) =>
        value ? (
          <DocumentInfoField
            title=""
            image="invoice1.png"
            fileName={value}
            showFileName={true}
          >
            <LinkedItem
              href=""
              className={`mt-2`}
              onClick={() =>
                handleInvoiceDownload(value, downloadLinkRef, 'credit_note')
              }
            >
              <ArrowDownTrayIcon className="h-6 w-6 text-primary-500" />
            </LinkedItem>
            <a ref={downloadLinkRef} style={{ display: 'none' }} />
          </DocumentInfoField>
        ) : (
          <P>N/A</P>
        )
      )
    )
    .add('refund_note', (col) =>
      col
        .header('Refund Note')
        .cell((value: any) =>
          value ? (
            <P className="md:text-lg whitespace-nowrap break-words">{value}</P>
          ) : (
            <P>N/A</P>
          )
        )
    )
    .build();

  const handleModalOpen = (subscriptionId: string, refundAmount: number) => {
    setSelectedSubscriptionId(subscriptionId);
    setSelectedRefundAmount(refundAmount);
    setIsModalOpen(true);
  };

  return (
    <Section title="Plan History">
      <DataTable
        columns={headerColumns}
        data={validSubscriptions}
        showColumnToggle={false}
      />
      <RefundModal
        subscriptionId={selectedSubscriptionId}
        refundAmount={selectedRefundAmount}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Section>
  );
};

export default PreviousPlans;
