import { Button, Col, Row, Spin, message} from "antd";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { GetApiCall, PutApiCall } from "../../../utils/apis";
import {
  cmsManagementUpdateUrl,
  cmsManagementUrl,
} from "../../../utils/constant";
import {
  initialICmsManagement,
} from "../cmsManagement-model";
import "./privacyPolicy-style.scss";

const PrivacyPolicyPage = () => {
    const [privacyPolicyEditor, setPrivacyPolicyEditor] = useState("");
  const [privacyPolicyData, setPrivacyPolicyData] = useState(initialICmsManagement);
  const [loading, setLoading] = useState(false);
  let userData:any = localStorage.getItem("user")
  let token:any = JSON.parse(userData)?.token;
  useEffect(() => {
    setLoading(true)
    GetApiCall(cmsManagementUrl, token).then((cmsManagement) => {
      if(cmsManagement.status === true) {
        const pageName = cmsManagement.allpages.find(
          (privacy:any) => privacy.page_name === "privacy_policy"
        );
  
        if (pageName !== undefined) {
          setPrivacyPolicyEditor(pageName.page_content);
          setPrivacyPolicyData(pageName);
          setLoading(false)
        }
      }
      else {
        message.error(cmsManagement.message)
        setLoading(false);
      }
    });
  }, []);

  const updateTerms = () => {
    setLoading(true )
    const updateTermsForm = {
      id: privacyPolicyData.id,
      page_content: privacyPolicyEditor,
      page_name: "privacy_policy",
      page_image: [],
      page_heading: "",
    };
    PutApiCall(cmsManagementUpdateUrl, updateTermsForm, token).then((data) => {
      if(data.status===true){
        setLoading(false)
        message.success(data.message)
      }
    });
  };

  return (
    <Spin spinning={loading} tip="Loading..." size="large"> 
      <div>
          <Row>
            <Col span={24}>
              <ReactQuill
                theme="snow"
                value={privacyPolicyEditor}
                onChange={setPrivacyPolicyEditor}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={{ span:4, offset: 20 }} md={24} sm={24} xs={24} className="cms-pagesbtn-container">
              <Button className="btn btn-primary" onClick={updateTerms} type="primary">
                Submit
              </Button>
            </Col>
          </Row>
      </div>
      </Spin>
  );
};

export default PrivacyPolicyPage;
