export const IMAGE_TYPES = ["JPEG", "PNG", "JPG", "PDF"];
export const IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];
export const VIDEO_TYPES = ["MP4", "MPEG", "OGG"];
export const VIDEO_EXTENSIONS = ['.mp4', '.mpeg', '.ogg'];
export const DOCUMENT_TYPES = ["PDF"];
export const DOCUMENT_EXTENSIONS = ['.pdf'];

export const MONTHS_ARRAY = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const creditAnalysisName = {
  cur: "CUR",
  creditBehavior: "Credit Behavior",
  payment_history: "Payment History",
  AgeofCredit: "Age of Credit",
  creditEnquiryCount: "Credit Enquiry",
  negativeAccount: "Negative Account",
  totalAccount: "Total Accounts",
  avgDelay: "Delay History",
  OverdueAccCount: "Over Due Accounts",
  dtiRatio: "DTI Ratio",
  lowestBalance: "Lowest Balance",
  avgMonthlyBalance: "Average Monthly Balance",
  netCashFlow: "Net Cash Flow",
  defaultTransaction: "Default Amount"
}

export const redFlags = [
  { performance: "Very Good", suggestion: "Excellent", color: "green" },
  { performance: "Good", suggestion: "Keep it up", color: "green" },
  { performance: "Good", suggestion: "Keep it up", color: "green" },
  { performance: "Poor", suggestion: "Need Improvement", color: "red" },
  { performance: "Very Poor", suggestion: "Need Improvement", color: "red" },
]

export const dashboardState: {
  [key: string]: {
    status: string;
    action: string;
  };
} = {
  customer_profile_incomplete_count: { status: "Number of Customer who did not complete profile", action: "/dashboard/customer-incomplete-profile" },
  partial_docs_upload_count: { status: "Number of Customer who uploaded partial documents", action: "/dashboard/customer-partial-docs" },
  customer_requested_otp_count: { status: "Number of Customer who requested OTP", action: "/dashboard/customer-requested-otp" },
  customer_profile_complete_count: { status: "Number of Customer who completed profile", action: "/dashboard/customer-complete-profile" },
  customers_with_valid_crif_pulls_count: { status: "Number of CRIF Reports Processed Successfully", action: "/dashboard/customer-valid-crif-pulls" },
  customers_with_invalid_crif_pulls_count: { status: "Number of Customer who failed to pull CRIF report", action: "/dashboard/customer-invalid-crif-pulls" },
  total_valid_crif_pulls_count: { status: "Total number of CRIF pulls", action: '/dashboard' },
  total_invalid_crif_pulls_count: { status: "Total number of failed CRIF pulls", action: '/dashboard' },
}

export const role = {
  owner: "owner",
  manager: "manager",
  executive: "executive",
}

export const roleOptions = [
  { value: role.owner, label: role.owner },
  { value: role.manager, label: role.manager },
  { value: role.executive, label: role.executive },
]

export const linkModeType = {
  whatsApp: "whats_app",
  email: "email",
  sms: "sms",
}

export const paymentLinkModeOptions
  = [
    { value: linkModeType.whatsApp, label: "Whats App" },
    { value: linkModeType.email, label: "Email" },
    { value: linkModeType.sms, label: "SMS" },
  ];

  export const refundTypeOptions  = [
    { value: "STANDARD", label: "Standard" },
    { value: "INSTANT", label: "Instant" },
  ]

export const roleOptionsWithoutOwner = [
  { value: role.manager, label: role.manager },
  { value: role.executive, label: role.executive },
]

export const OWNER_NOT_VERIFIED = "owner is not verified.";
export const ROLE_KEY = "Role";
export const ALL_CUSTOMER_KEY = "ALL_CUSTOMERS";

export const CASHFREE_STATUS = {
  PAID: 'PAID',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  REFUNDED: 'REFUNDED',
  SUCCESS: 'SUCCESS',
  REFUND_PENDING: 'REFUND_PENDING',
}

type DocumentTypeMapping = {
  [key: string]: string;
};

export const documentNameByType: DocumentTypeMapping = {
  itr_year_1: "ITR Year-1",
  itr_year_2: "ITR Year-2",
  gst_cert: "GST Certificate",
  bank_statement: "Bank Statement",
  selfie: "Selfie",
  itr_cert: "ITR Certificate",
  pan_uri: "PAN Card",
}

export const planByAmount: {
  [key: number]: {
    category: string;
    duration: string;
    price: number;
  };
} = {
  118.00: { category: "Silver", duration: "1", price: 100.00 },
  139.24: { category: "Silver", duration: "1", price: 118.00 },
  590.00: { category: "Silver", duration: "6", price: 500.00 },
  589.98: { category: "Silver", duration: "6", price: 500.00 },
  354.00: { category: "Silver", duration: "3", price: 354.00 },
  1416.00: { category: "Silver", duration: "12", price: 1200.00 },
  2088.60: { category: "Silver", duration: "12", price: 1770.00 },
  1770.00: { category: "Gold", duration: "3", price: 1500.00 },
  2949.00: { category: "Gold", duration: "6", price: 2500.00 },
  3481.00: { category: "Gold", duration: "6", price: 3500.00 },
}

// export const filterOptions: {
//   [key: string]: {
//     first: string;
//     second: string;
//   };
// } = {
//   is_cbl_requested: {first: "Requested", second: "Not Requested"},
//   is_profile_complete: {first: "Complete", second: "Incomplete"},
// }

export const filterOptions: {
  [key: string]: ["Requested", "Not Requested"] | ["Completed", "Not Completed"] | ["Subscribed", "Not Subscribed"] | ["Active", "UnVerified"];
} = {
  is_cbl_requested: ["Requested", "Not Requested"],
  is_profile_complete: ["Completed", "Not Completed"],
  subscription: ["Subscribed", "Not Subscribed"]
}