type ProgressBarProps = {
  percentage: string;
  color: string;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage, color }) => {
  return (
    <>
      <div className="w-36">
        <div className="flex h-2 overflow-hidden rounded bg-red-200 text-xs">
          <div
            style={{ width: percentage }}
            className={`flex flex-col justify-center whitespace-nowrap ${
              color === 'red' ? 'bg-red-500' : 'bg-green-500'
            } text-center text-white shadow-none`}
          ></div>
        </div>

        <div className="flex items-center justify-between">
          <div>
            <span
              className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full ${
                color === 'red' ? 'text-red-600' : 'text-green-600'
              }`}
            >
              0%
            </span>
          </div>
          <div className="text-right">
            <span
              className={`text-xs font-semibold inline-block ${
                color === 'red' ? 'text-red-600' : 'text-green-600'
              }`}
            >
              100%
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
