export interface User {
  status: boolean;
  token: string;
  user: UserDetails;
}

export interface UserDetails {
  id: string;
  name: string;
  empstatus: null;
  email: string;
  employer: null;
  anualincom: null;
  dob: null;
  panno: null;
  whatsappno: null;
  mobile_num: string;
  is_active: boolean;
  role_id: string;
  is_deleted: boolean;
  otp: null;
  expiration_time: null;
  is_login: boolean;
  is_suspended: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export const initialUserDetails: UserDetails = {
  id: "",
  name: "",
  empstatus: null,
  email: "",
  employer: null,
  anualincom: null,
  dob: null,
  panno: null,
  whatsappno: null,
  mobile_num: "",
  is_active: false,
  role_id: "",
  is_deleted: false,
  otp: null,
  expiration_time: null,
  is_login: false,
  is_suspended: false,
  createdAt: new Date(),
  updatedAt: new Date(),
}

export const initialUser: User = {
  status: false,
  token: "",
  user: initialUserDetails
}


