import { role } from "../../utils/constants/index";

export type RegisterCredentials = {
  email: string;
  password: string;
  phone_number: string;
};

export const initialRegisterCredentials: RegisterCredentials = {
  email: "",
  password: "",
  phone_number: "",
};

export type confirmRegisterCredentials = {
  email: string;
  otp: string;
};

export const initialConfirmRegisterCredentials: confirmRegisterCredentials = {
  email: "",
  otp: "",
};

export type LoginCredentials = {
  email: string;
  password: string;
  role: string
};

export const initialLoginCredentials: LoginCredentials = {
  email: "",
  password: "",
  role: role.executive,
};

export type ResetPasswordCredentials = {
  email: string;
  otp: string;
  password: string;
  role: string
};

export const initialResetPasswordCredentials: ResetPasswordCredentials = {
  email: "",
  otp: "",
  password: '',
  role: role.executive,
};

export type ForgotPasswordCredentials = {
  email: string;
  role: string,
}

export const initialForgotPasswordCredentials: ForgotPasswordCredentials = {
  email: "",
  role: role.executive,
}

export type AcceptInvitationCredentials = {
  email: string;
  otp: string;
  password: string;
  role: string
};

export const initialAcceptInvitationCredentials: AcceptInvitationCredentials = {
  email: "",
  otp: "",
  password: '',
  role: role.executive,
};