import { useEffect, useRef, useState } from 'react';
import { Customer } from '@appTypes/user-model';
import { useAllCustomer } from '../../common/hooks/use-customer';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { CompleteProfileColumn } from '../../common/components/data-table/TableColumn';
import Table from '../../common/components/data-table/paginateTable';
import { dashboardState, role } from '../../utils/constants/index';
import Section from '../../common/layouts/PageSection';
import { useRole, useUser } from '../../common/hooks/use-user';
import { useQueryClient } from '@tanstack/react-query';
import { useStatistics } from '../../common/hooks/use-statistics';
import axios from '../../lib/axios';
import { apiEndpoint } from '../../utils/endpoint';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { withAuth } from '../../common/hoc/withAuth';
import { storeSingleItem } from '../../utils/storage';

const CustomerManagement: React.FC = () => {
  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);
  const queryClient = useQueryClient();
  const { pushInstruction } = useMatomo();
  const skipResetRef = useRef(false);
  const { currentUserRole } = useRole();
  const columns = CompleteProfileColumn(downloadLinkRef);
  const { data: stats, isLoading: statsLoading } = useStatistics();
  const { data: allCustomerData, isLoading: allCustomerDataLoading } =
    useAllCustomer();

  const [data, setData] = useState<Customer[]>([]);
  const { data: currentUserData, isLoading: currentUserDataLoading } =
    useUser();
  const [originalData, setOriginalData] = useState<Customer[]>([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const { data: currentUser, isLoading: currentUserLoading } = useUser();

  useEffect(() => {
    if (!allCustomerDataLoading && !statsLoading) {
      setData(allCustomerData?.customers);
      setOriginalData(allCustomerData?.customers);
      setTotalDataCount(
        stats?.customer_profile_complete_count +
          stats?.customer_profile_incomplete_count
      );
    }
  }, [statsLoading, allCustomerDataLoading, allCustomerData]);

  // MATOMO: TRACKING CODE
  useEffect(() => {
    const email = queryClient.getQueryData(['email']);
    if (email) {
      pushInstruction('setUserId', `${email}`);
      var _paq = window._paq || [];
      _paq.push(['setUserId', `${email}`]);
    }

    if (!currentUserLoading && currentUser) {
      const { manager, owner, executive } = currentUser;
      const user = manager || owner || executive;

      queryClient.setQueryData(['email'], `${user.profile.email}`);
      pushInstruction('setUserId', `${user.profile.email}`);
      var _paq = window._paq || [];
      _paq.push(['setUserId', `${user.profile.email}`]);
    }
  }, [currentUserLoading, currentUser]);

  useEffect(() => {
    storeSingleItem.setItem('subscriptionFilter', 'both');
    storeSingleItem.setItem('creditScoreFilter', '10');
  }, []);

  const resetData = () => {
    skipResetRef.current = true;
    setData(originalData);
  };

  const paginateData = async (
    pageSize: number,
    pageIndex: number,
    orderBy: string = 'updated_at',
    desc: boolean = true,
    allQueryParam: string = ''
  ) => {
    const skip = pageSize * pageIndex;
    const limit = pageSize;

    const { manager, owner, executive } = currentUserData;
    const user = manager || owner || executive;
    const executiveEmail = user?.profile.email;

    const endpoint =
      currentUserRole === role.executive
        ? `list-customers?skip=${skip}&limit=${limit}&order_by=${orderBy}&desc_order=${desc}&executive_email=${encodeURIComponent(
            executiveEmail
          )}`
        : apiEndpoint.common.getAllCustomers(
            skip,
            limit,
            orderBy,
            desc,
            allQueryParam
          );

    const { data } = await axios.get(endpoint);

    setData([]);
    // @ts-ignore
    setData(data?.customers);
    // @ts-ignore
    setOriginalData(data?.customers);
  };

  const searchCustomer = async (
    name: string,
    pageIndex: number = 0,
    pageSize: number = 10
  ) => {
    if (name === '') {
      paginateData(pageSize, pageIndex);
    } else {
      const { data } = await axios.get(
        apiEndpoint.common.searchCustomer.allCustomers(name)
      );

      setData([]);
      // @ts-ignore
      setData(data?.customers);
      // @ts-ignore
      setOriginalData(data?.customers);
    }
  };

  if (allCustomerDataLoading && statsLoading) {
    return <FullPageLoader isOpen={true} className="text-brand-orange-500" />;
  }

  const isExcelSheetButtonVisible =
    currentUserRole === role.owner || currentUserRole === role.manager;
  const currentPathName = window.location.pathname;

  return (
    <Section
      title={
        currentPathName === '/user-management'
          ? 'User Management'
          : dashboardState.customer_requested_otp_count.status
      }
    >
      <Table
        columns={columns}
        data={data}
        resetData={resetData}
        isExcelSheetButtonVisible={isExcelSheetButtonVisible}
        paginateData={paginateData}
        searchCustomer={searchCustomer}
        totalDataCount={totalDataCount}
        excelSheetUrl="list-customers?"
        excelSheetName={`User_Data_Report-${new Date()
          .toLocaleDateString('en-IN')
          .replace(/[:\\/?*\[\]]/g, '-')}`}
      />
    </Section>
  );
};

export default withAuth()(CustomerManagement);
