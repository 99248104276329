import { useParams } from 'react-router-dom';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { apiEndpoint } from '../../utils/endpoint';
import { useQuery } from '../../lib/react-query';
import UserSection from '../../common/sections/UserSection';
import ErrorBox from '../../common/components/elements/ErrorBox';
import { useAssignedCustomer } from '../../common/hooks/use-customer';
import ListOfCustomer from '../../modules/executives/components/ListOfCustomer';
import ListOfAllCustomers from '../../modules/executives/components/ListOfAllCustomers';
import { useEffect, useState } from 'react';
import { Customer } from '@appTypes/user-model';
import { P } from '../../common/components/elements/text';
import axios from '../../lib/axios';
import InlineLoader from '../../common/components/elements/loader/InlineLoader';
import { withAuth } from '../../common/hoc/withAuth';

const EXECUTIVE_BY_ID_QUERY_KEY = 'MANAGER_BY_ID';

const ExecutiveProfile = () => {
  const router = useParams();
  const userId = router.id as string;
  const [listOfAssignCustomerLen, setListOfAssignCustomerLen] = useState(0);
  const [listOfAssignCustomer, setListOfAssignCustomer] = useState<Customer[]>(
    []
  );
  const [isAssignCustomerLoading, setIsAssignCustomerLoading] = useState(false);
  const [executiveEmail, setExecutiveEmail] = useState('');

  const { data, isLoading } = useQuery(
    apiEndpoint.common.getExecutiveById(userId),
    EXECUTIVE_BY_ID_QUERY_KEY + userId
  );

  useEffect(() => {
    const getAssignCustomers = async (email: string) => {
      setExecutiveEmail(email);
      setIsAssignCustomerLoading(true);
      const { data } = await axios.get(
        apiEndpoint.common.getListOfAssignedCustomers(email)
      );
      setListOfAssignCustomer(data?.customers);
      setIsAssignCustomerLoading(false);
    };

    if (!isLoading && data) getAssignCustomers(data?.executive?.profile?.email);

  }, [isLoading, data, executiveEmail]);

  if (isLoading) return <FullPageLoader isOpen={true} />;

  if (!data) {
    return (
      <ErrorBox
        title="Executive not found"
        text="Executive does not exist in the system!"
        redirectButton={{
          text: 'View All Executive',
          href: '/rolemanagement',
        }}
      />
    );
  }

  const handleAssignCustomerLength = (len: number) => {
    setListOfAssignCustomerLen(len);
  };

  const refetch = async () => {
    const { data } = await axios.get(
      apiEndpoint.common.getListOfAssignedCustomers(executiveEmail)
    );
    setListOfAssignCustomer(data?.customers);
  };

  const { executive } = data;
  if (!executive) return <></>;
  const { profile } = executive;

  return (
    <div className="grid gap-y-5">
      <UserSection
        user={{
          ...profile,
        }}
        showAllDetails={true}
        actionChildren={
          <>
            <P>No of Customers Assigned: {listOfAssignCustomer.length}</P>
          </>
        }
      />
      {listOfAssignCustomer.length > 0 && (
        <div className="overflow-hidden">
          <ListOfCustomer
            executiveId={userId}
            customerList={listOfAssignCustomer}
            assignButtonVisible={false}
            title="List of Customers Assigned to the Executive"
            refetch={refetch}
          />
        </div>
      )}
      {isAssignCustomerLoading && <InlineLoader className='text-primary-500' />}
      <div className="overflow-hidden">
        <ListOfAllCustomers executiveId={userId} refetch={refetch}/>
      </div>
    </div>
  );
};

export default withAuth()(ExecutiveProfile);
