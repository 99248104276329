import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
  useGlobalFilter,
} from 'react-table';

import TablePagination from './DataTablePagination';
import Card from '../elements/Card';
import { PrimaryButton, SuccessButton, InfoButton } from '../elements/button';
import {
  NewspaperIcon,
  UserPlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import convertUserManagementListToExcel from '../../components/form/ExcelSheet';
import {
  GlobalFilter,
  DefaultColumnFilter,
  fuzzyTextFilterFn,
} from './TableFilter';
import ButtonGroup from '../elements/button/ButtonGroup';
import DataTableColumnTogglerButton from './DataTableColumnToggler';

type Props<T> = {
  columns: ColumnDef<T>[];
  data: T[];
};

type ColumnInstance<T> = {
  getHeaderProps: any;
  getSortByToggleProps: any;
  getGroupByToggleProps: any;
  id: string;
  Header: string | React.ReactNode;
  accessor: keyof T;
  canGroupBy?: boolean;
  isGrouped?: boolean;
  isSorted?: boolean;
  isSortedDesc?: boolean;
  canFilter?: boolean;
  render: (key: any) => React.ReactNode;
};

type TableProps<T> = {
  columns: any;
  data: T[];
  resetData?: () => void;
  isExcelSheetButtonVisible?: boolean;
  showAssignButton?: boolean;
  handleAssign?: () => void;
  renderRowSubComponent: (props: any) => React.ReactNode;
  isSearchable?: boolean;
};

const Table = <T extends {}>({
  columns: userColumns,
  data,
  resetData,
  isExcelSheetButtonVisible = false,
  showAssignButton = false,
  handleAssign,
  renderRowSubComponent,
  isSearchable = false,
}: TableProps<T>) => {
  const originalData = data;
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const table = useTable(
    {
      columns: userColumns,
      data,
      // @ts-ignore
      defaultColumn,
      initialState: {
        // @ts-ignore
        pageIndex: 0,
        pageSize: 10,
        sortBy: [
          {
            id: 'profile.updated_at',
            desc: true,
          },
        ],
        filters: [],
        globalFilter: '',
      },
      autoResetPage: false,
      autoResetSelectedRows: false,
      disableMultiSort: true,
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    setGlobalFilter,
    // @ts-ignore
    preGlobalFilteredRows,
    // @ts-ignore
    visibleColumns,
    state: {
      // @ts-ignore
      globalFilter,
    },
  } = table;

  return (
    <>
      <div className="flex flex-col md:flex-row-reverse mb-4 justify-between">
        {isSearchable && (
          <div className="flex space-x-2">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <InfoButton onClick={resetData} Icon={TrashIcon} type="reset">
              Clear Filter
            </InfoButton>
            <ButtonGroup>
              <DataTableColumnTogglerButton table={table} />
            </ButtonGroup>
          </div>
        )}
        <div className="flex space-x-2">
          {isExcelSheetButtonVisible && (
            <PrimaryButton
              Icon={NewspaperIcon}
              onClick={() => convertUserManagementListToExcel(originalData)}
            >
              Download Sheet
            </PrimaryButton>
          )}
          {showAssignButton && (
            <SuccessButton Icon={UserPlusIcon} onClick={handleAssign}>
              Assign
            </SuccessButton>
          )}
        </div>
      </div>
      <Card className="!p-0 overflow-hidden">
        <div className="block min-w-full overflow-x-scroll overflow-y-hidden">
          <table
            {...getTableProps()}
            className="w-full overflow-hidden divide-y divide-gray-300 rounded-lg"
          >
            <thead className="bg-gray-100">
              {headerGroups.map((headerGroup: any) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="py-3.5 sm:pr-3 pl-2 pr-1 text-left text-xs md:text-lg font-semibold bg-primary-50 text-primary-500 capitalize"
                    >
                      <div>
                        <span {...column.getSortByToggleProps()}>
                          {column.render('Header')}
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </div>
                      {column.canFilter && (
                        <div className="inline-flex group select-none">
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className="bg-white divide-y divide-gray-200"
            >
              {page.map((row: any, rowIndex: any) => {
                prepareRow(row);
                return (
                  <>
                    <tr key={rowIndex} {...row.getRowProps()}>
                      {row.cells.map((cell: any, cellIndex: any) => {
                        return (
                          <td
                            key={cellIndex}
                            {...cell.getCellProps()}
                            className={`py-4 pl-2 sm:pr-3 text-xs md:text-md text-gray-900 break-words md:whitespace-nowrap sm:pl-6 ${
                              cellIndex % 2 != 0 &&
                              'bg-primary-50 bg-opacity-30'
                            }`}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded ? (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          {renderRowSubComponent({ row })}
                        </td>
                      </tr>
                    ) : null}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        {data.length >= 10 && <TablePagination table={table} />}
      </Card>
    </>
  );
};

export default Table;
