import { Customer } from '@appTypes/user-model';
import { useExecutive } from '../../../common/hooks/use-executive';
import { useEffect, useState } from 'react';
import InlineLoader from '../../../common/components/elements/loader/InlineLoader';
import Table from '../../../common/components/data-table';
import {
  AssignExecutiveColumn,
  CustomerColumn,
  ExecutiveColumn,
} from '../../../common/components/data-table/TableColumn';
import { useAssignExecutivesMutation } from '../../../common/hooks/mutations/use-assign-mutation';
import Section from '../../../common/layouts/PageSection';

type Props = {
  executiveList: Customer[];
  assignButtonVisible: boolean;
  managerId: string;
  title: string;
  text?: string;
  refetch: () => void;
};

const ListOfExecutive: React.FC<Props> = ({
  executiveList,
  assignButtonVisible,
  managerId,
  title,
  text,
  refetch,
}) => {
  const [selectedExecutiveIds, setSelectedExecutiveIds] = useState<string[]>(
    []
  );
  const [executives, setExecutives] = useState<Customer[]>(executiveList);
  const [originalExecutives, setOriginalExecutive] =
    useState<Customer[]>(executives);

  const mutateManager = useAssignExecutivesMutation();

  useEffect(() => {
    setExecutives(executiveList);
    setOriginalExecutive(executiveList);
  }, [executiveList]);

  const toggleCheckbox = (row: any) => {
    row.toggleRowSelected();
    if (!row.isSelected) {
      setSelectedExecutiveIds((prev) => {
        return [...prev, row.values.id];
      });
    } else {
      setSelectedExecutiveIds((prev) => {
        return prev.filter((id) => id !== row.values.id);
      });
    }
  };

  let columns = ExecutiveColumn(toggleCheckbox, 'executive');
  if (!assignButtonVisible) columns = AssignExecutiveColumn('executive');

  const resetData = () => setExecutives(originalExecutives);

  const handleAssign = () => {
    mutateManager({
      manager_id: managerId,
      executive_ids: selectedExecutiveIds,
    }).then(() => {
      refetch();
    })
  };

  return (
    <Section
      title={title}
      text={text}
    >
      <Table
        columns={columns}
        data={executives}
        resetData={resetData}
        isExcelSheetButtonVisible={false}
        handleAssign={handleAssign}
        showAssignButton={assignButtonVisible}
        renderRowSubComponent={() => null}
        isSearchable={true}
      />
    </Section>
  );
};

export default ListOfExecutive;
