import jwtDecode from "jwt-decode";
import { CASHFREE_STATUS, DOCUMENT_EXTENSIONS, IMAGE_EXTENSIONS, redFlags, role } from "./constants";
import { storage } from "./storage";
import { OldestAccountAge } from "@appTypes/age-of-credit-model";
import { Flag } from "@appTypes/credit-analysis";
import { formatMoney } from "./formatter";
import { Document } from "@appTypes/user-model";
import { ClosingBalance, LowestBalance, MonthlyBalanceResponse, MonthlyTransactionSummaryResponse, NetCashFlowData, Transaction } from "@appTypes/bank-statement-model";
import { Refund, Subscription } from "@appTypes/plan-model";
import { createAvatar } from '@dicebear/core';
import { lorelei } from '@dicebear/collection';

export const getDummyPicture = (
  keyword: string,
  category: string = "initials"
) => {
  return createAvatar(lorelei, {
    size: 128,
    seed: keyword
  }).toDataUriSync();
}

export const getBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export type FlagType = "percentage" | "number" | "string";

export type flag = {
  performance: string;
  suggestion: string;
  value: any;
  type: string;
  color: string;
};

export type redFlagsType = {
  performance: string;
  suggestion: string;
  color: string;
}

export const FLAG_TYPES = {
  percentage: "percentage",
  number: "number",
  string: "string",
}

export const getImageByType = (imageLocation: string) => {
  const lowerCaseImageLocation = imageLocation.toLowerCase();

  if (IMAGE_EXTENSIONS.some((ext) => lowerCaseImageLocation.endsWith(ext))) {
    return 'image'
  } else if (DOCUMENT_EXTENSIONS.some((ext) => lowerCaseImageLocation.endsWith(ext))) {
    return 'application'
  } else {
    return 'video'
  }
}

export const shortURL = (url: string) => {
  const parts = url.split('/');
  return parts[parts.length - 1];
}

export const convertAgeOfCreditToMonths = (ageOfCredit: OldestAccountAge): number => {
  const { years, months } = ageOfCredit;
  return years * 12 + months;
};

export const convertAgeOfCredit = (ageOfCredit: OldestAccountAge): string => {
  const { years, months, days } = ageOfCredit;
  const yearString = years > 1 ? 'years' : 'year';
  const monthString = months > 1 ? 'months' : 'month';
  const dayString = days > 1 ? 'days' : 'day';

  return `${years} ${yearString} ${months} ${monthString} ${days} ${dayString}`;
};


export const getCurrDetailsFlags = (percentage: number): flag => {
  let flag: redFlagsType;

  if (percentage >= 0 && percentage <= 9) {
    flag = redFlags[0];
  } else if (percentage >= 10 && percentage <= 29) {
    flag = redFlags[1];
  } else if (percentage >= 30 && percentage <= 49) {
    flag = redFlags[2];
  } else if (percentage >= 50 && percentage <= 74) {
    flag = redFlags[3];
  } else {
    flag = redFlags[4];
  }

  return { ...flag, type: FLAG_TYPES.percentage, value: percentage };
}

export const getPaymentHistoryFlags = (percentage: number): flag => {
  let flag: redFlagsType;

  if (percentage === -1) percentage = 0;

  if (percentage == 100) {
    flag = redFlags[0];
  } else if (percentage >= 95 && percentage <= 99) {
    flag = redFlags[1];
  } else if (percentage >= 90 && percentage <= 94) {
    flag = redFlags[2];
  } else if (percentage >= 80 && percentage <= 90) {
    flag = redFlags[3];
  } else {
    flag = redFlags[4];
  }

  return { ...flag, type: FLAG_TYPES.percentage, value: percentage };
}

export const getAgeCreditFlags = (ageOfCredit: OldestAccountAge): Flag => {
  let flag: redFlagsType;
  const month = convertAgeOfCreditToMonths(ageOfCredit);

  if (month > 60) { // 5 years
    flag = redFlags[0];
  } else if (month > 36 && month <= 60) {
    flag = redFlags[1];
  } else if (month > 12 && month <= 36) {
    flag = redFlags[2];
  } else if (month == 12) {
    flag = redFlags[3];
  } else {
    flag = redFlags[4];
  }

  return { ...flag, type: FLAG_TYPES.string, value: `${month} Months` };
}

export const getNegativeAccountFlags = (count: number): flag => {
  let flag: redFlagsType;

  if (count == 0) {
    flag = redFlags[1];
  } else if (count >= 1 && count <= 3) {
    flag = redFlags[3];
  } else {
    flag = redFlags[4];
  }

  return { ...flag, type: FLAG_TYPES.number, value: count };
}

export const getOverdueAccountsFlags = (overdueCount: number): flag => {
  let flag: redFlagsType;

  if (overdueCount === 0) {
    flag = redFlags[1];
  } else if (overdueCount === 1) {
    flag = redFlags[3];
  } else {
    flag = redFlags[4];
  }

  return { ...flag, type: FLAG_TYPES.number, value: overdueCount };
};

export const getCreditEnquiryFlags = (enquiry: number): flag => {
  let flag: redFlagsType;

  if (enquiry == 0 || enquiry <= 2) {
    flag = redFlags[0];
  } else if (enquiry >= 3 && enquiry <= 5) {
    flag = redFlags[1];
  } else if (enquiry >= 6 && enquiry <= 9) {
    flag = redFlags[2];
  } else if (enquiry > 9 && enquiry <= 15) {
    flag = redFlags[3];
  } else {
    flag = redFlags[4];
  }

  return { ...flag, type: FLAG_TYPES.number, value: enquiry };
}

export const getTotalAccountFlags = (count: number): flag => {
  let flag: redFlagsType;

  if (count > 10) {
    flag = redFlags[0];
  } else if (count >= 5 && count <= 10) {
    flag = redFlags[1];
  } else if (count >= 2 && count <= 4) {
    flag = redFlags[2];
  } else if (count == 1) {
    flag = redFlags[3];
  } else {
    flag = redFlags[4];
  }

  return { ...flag, type: FLAG_TYPES.number, value: count };
}

export const getDelayHistoryFlags = (count: number): flag => {
  let flag: redFlagsType;

  if (count == 0) {
    flag = redFlags[1];
  } else if (count >= 1 && count <= 10) {
    flag = redFlags[3];
  } else {
    flag = redFlags[4];
  }

  return { ...flag, type: FLAG_TYPES.string, value: count };
}

export const getDTIRatioFlags = (dtiRatio: number): Flag => {
  let flag: redFlagsType;

  if (dtiRatio <= 30) {
    flag = redFlags[1];
  } else if (dtiRatio > 30 && dtiRatio <= 50) {
    flag = redFlags[3];
  } else {
    flag = redFlags[4];
  }

  return { ...flag, type: FLAG_TYPES.number, value: formatMoney(parseFloat((dtiRatio).toFixed(2))) };
}

export const getDefaultTransactionFlags = (defaultAmount: number): Flag => {
  let flag: redFlagsType;

  if (defaultAmount === 0) flag = redFlags[0];
  else flag = redFlags[4];
  return { ...flag, type: FLAG_TYPES.string, value: formatMoney(parseFloat((removeCharacterFromAmount(defaultAmount)).toFixed(2))) }
}

export const getAverageMonthlyBalanceFlags = (avgMonthlyBalanceChange: number): Flag => {
  let flag: redFlagsType;

  if (avgMonthlyBalanceChange > 0) {
    flag = redFlags[0];
  } else if (avgMonthlyBalanceChange >= 0.05) {
    flag = redFlags[1];
  } else if (avgMonthlyBalanceChange >= 0) {
    flag = redFlags[2];
  } else if (avgMonthlyBalanceChange >= -0.05) {
    flag = redFlags[3];
  } else {
    flag = redFlags[4];
  }

  return { ...flag, type: FLAG_TYPES.number, value: formatMoney(parseFloat((avgMonthlyBalanceChange).toFixed(2))) };
}

export const getNetCashFlowFlags = (netCashFlow: number): Flag => {
  let flag: redFlagsType;

  if (netCashFlow <= 1000) {
    flag = redFlags[4];
  } else if (netCashFlow > 10000) {
    flag = redFlags[0];
  } else {
    flag = redFlags[3];
    flag.color = "orange";
  }

  return { ...flag, type: FLAG_TYPES.number, value: formatMoney(parseFloat((netCashFlow).toFixed(2))) };
}

export const getLowestMonthlyBalanceFlags = (lowestMonthlyBalance: number): Flag => {
  let flag: redFlagsType;

  if (lowestMonthlyBalance > 1000) {
    flag = redFlags[0];
  } else {
    flag = redFlags[4];
  }

  return { ...flag, type: FLAG_TYPES.number, value: formatMoney(parseFloat((lowestMonthlyBalance).toFixed(2))) };
}

export const convertCardNumber = (cardNumber: string) => {
  if (!cardNumber) return '';

  const firstFourDigits = cardNumber.slice(0, 4);
  const lastThreeDigits = cardNumber.slice(-3);
  const maskedDigits = 'X'.repeat(Math.max(cardNumber.length - 7, 0));

  return `${firstFourDigits}${maskedDigits}${lastThreeDigits}`;
}

export const removeCharacterFromAmount = (amount: any) => {
  if (amount == undefined) return 0;
  if (typeof amount === 'number' || !amount.includes(',')) return amount;
  return parseInt(amount.replace(/,/g, ''));
}

export const getRole = () => {
  const token = storage.getToken();
  if (!token) return role.executive;
  const { role: userRole } = jwtDecode<{ role: string }>(token);
  return userRole;
}

export const calculateNetCashFlow = (netCashFlowData: NetCashFlowData[]): number => {
  let totalCredit = 0;
  let totalDebit = 0;

  netCashFlowData.forEach((item) => {
    totalCredit += item.credits;
    totalDebit += item.debits;
  });

  return totalCredit - totalDebit;
}

export const getLowestBalance = (closingBalance: ClosingBalance[]) => {
  const monthlyData: LowestBalance[] = closingBalance.reduce((accumulator: LowestBalance[], current) => {
    const dateParts = current.date.split('/');
    const month = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);

    const monthYear = `${month}/${year}`;

    const existingItem = accumulator.find(item => item.period.month === month && item.period.year === year);
    if (!existingItem || current.balance < existingItem.lowest_balance) {
      const newItem: LowestBalance = {
        period: {
          month,
          year
        },
        lowest_balance: current.balance
      };
      if (!existingItem) {
        accumulator.push(newItem);
      } else {
        Object.assign(existingItem, newItem);
      }
    }

    return accumulator;
  }, []);

  return monthlyData;
}

export const getAverageMonthlyBalance = (monthlySummary: MonthlyBalanceResponse[]) => {
  let maximumAvgBalance: number = monthlySummary[0].all_days.balance;

  monthlySummary.forEach((item: MonthlyBalanceResponse) => {
    if (item.all_days.balance > maximumAvgBalance) maximumAvgBalance = item.all_days.balance;
  });

  return maximumAvgBalance;
}

// income_transactions
export const getGrossMonthlyIncome = (monthlyIncome: MonthlyTransactionSummaryResponse[]) => {
  let totalIncome = 0;
  monthlyIncome.forEach((item) => {
    totalIncome += item.credit?.amount || 0;
  })

  return totalIncome;
}

export const getMonthlyIncome = (incomeTransactions: MonthlyTransactionSummaryResponse[]) => {
  // income_transactions have credits and debits
  const monthlyWiseIncome = [];
  incomeTransactions.forEach((item) => {
    monthlyWiseIncome.push({})
  })
}

export const calculateTotalDTIRatio = (sumOfDebt: number, annualIncome: number) => {
  if (sumOfDebt === 0 || !sumOfDebt || annualIncome == null) return 0;
  return (sumOfDebt / (annualIncome / 12));
}

export const getDefaultAmount = (defaultTransactions: Transaction[]) => {
  let totalDefaultAmount = 0;

  defaultTransactions.forEach((item) => {
    totalDefaultAmount += item.withdrawal;
  });

  return totalDefaultAmount;
}

export const getMonthlyDebtByPeriod = (monthlyDebt: any) => {
  const debtData = [];

  for (const year in monthlyDebt) {
    for (const month in monthlyDebt[year]) {
      const period = {
        month: parseInt(month),
        year: parseInt(year)
      };
      const debt = monthlyDebt[year][month];

      debtData.push({
        period,
        debt
      });
    }
  }

  return debtData;
}

export const getAllTransactions = (monthWiseTransactions: MonthlyTransactionSummaryResponse[]) => {
  let allTransactions: Transaction[] = [];

  monthWiseTransactions.forEach((item) => {
    return allTransactions.push(...item.transactions);
  });

  return allTransactions;
}

export const getLast6MonthPeriod = () => {
  const today = new Date();
  const lastSixMonths = [];

  for (let i = 1; i <= 7; i++) {
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    lastSixMonths.unshift({
      period: {
        month,
        year,
      },
    });

    today.setMonth(today.getMonth() - 1);
  }

  lastSixMonths.pop();
  return lastSixMonths;
}

export const truncateString = (sentence: string, lines: number) => {
  if (sentence.length <= lines) {
    return sentence;
  }
  return sentence.slice(0, lines) + '...'
}

export const filterBankStatementData = (documents: Document[]) => {
  const bankStatementDocuments = documents.filter((document) => document.type === "bank_statement");
  const sortedBankStatementDocuments = bankStatementDocuments.sort((a, b) => {
    const aDate = new Date(a.created_at!);
    const bDate = new Date(b.created_at!);
    return bDate.getTime() - aDate.getTime();
  });

  return sortedBankStatementDocuments[0];
}

export const filterDefaultTransactions = (transactions: Transaction[]) => {
  return transactions.filter((_, index) => index % 2 === 0);
}

export const filterPaymentLinkTransactions = (transactions: Subscription[]) => {
  const today = new Date();
  const twoDaysAgo = new Date(today.setDate(today.getDate() - 2));
  const twoDaysAgoString = twoDaysAgo.toISOString();

  return transactions.filter((transaction) => transaction.payment_link_url && transaction.created_at! > twoDaysAgoString).sort((a, b) => {
    const aDate = new Date(a.created_at!);
    const bDate = new Date(b.created_at!);
    return bDate.getTime() - aDate.getTime();
  }).map((transaction) => {
    return {
      ...transaction,
      combined_key: [transaction.link_id, transaction.cashfree_order_status],
    }
  });
}

export const filterSubscriptionTransactions = (transactions: Subscription[], refunds: Refund[]) => {
  const mergedRefunds: Refund[] = [];
  refunds.forEach((refund) => {
    const existingRefund = mergedRefunds.find((item) => item.subscription_id === refund.subscription_id);
    if (!existingRefund) {
      mergedRefunds.push(refund);
    } else {
      existingRefund.refund_amount += refund.refund_amount;
      if (new Date(existingRefund.updated_at!) < new Date(refund.updated_at!)) {
        existingRefund.updated_at = refund.updated_at;
      }
    }
  });

  return transactions.map((transaction) => {
    const existingRefund = mergedRefunds.find((item) => item.subscription_id === transaction.id);
    if (!existingRefund) return transaction;
    return {
      ...transaction,
      refund_amount: existingRefund.refund_amount,
      refund_date: existingRefund.updated_at,
      refund_note: existingRefund.refund_note,
      refund_status: existingRefund.refund_status,
      credit_note_number: existingRefund.credit_note_number,
    }
  }).filter((transaction) => transaction.cashfree_order_status === CASHFREE_STATUS.PAID || transaction.cashfree_order_status === CASHFREE_STATUS.REFUNDED || transaction.cashfree_order_status === CASHFREE_STATUS.REFUND_PENDING).sort((a, b) => {
    const aDate = new Date(a.created_at!);
    const bDate = new Date(b.created_at!);
    return aDate.getTime() - bDate.getTime();
  })
    .sort((a, b) => {
      const aStatus = a.cashfree_order_status;
      const bStatus = b.cashfree_order_status;
      if (aStatus === CASHFREE_STATUS.PAID && bStatus !== CASHFREE_STATUS.PAID) return -1;
      if (aStatus !== CASHFREE_STATUS.PAID && bStatus === CASHFREE_STATUS.PAID) return 1;
      if (aStatus === CASHFREE_STATUS.REFUNDED && bStatus === CASHFREE_STATUS.REFUND_PENDING) return -1;
      if (aStatus === CASHFREE_STATUS.REFUND_PENDING && bStatus === CASHFREE_STATUS.REFUNDED) return 1;
      return 0;
    })
    .map((transaction) => {
      return {
        ...transaction,
        combined_key: [transaction.cashfree_order_status, transaction.id, transaction.amount, transaction.refund_status, transaction.end_date],
      }
    });
}

export const groupObjectsByPhoneNumber = (objects: Transaction[]) => {
  return objects.filter(obj => obj.phone_number !== null && obj.phone_number !== undefined).reduce((grouped: any, obj: any) => {
    const phoneNumber = obj.phone_number;
    if (!grouped[phoneNumber]) {
      grouped[phoneNumber] = {
        objects: [],
        depositsSum: 0,
        withdrawalsSum: 0,
        frequency: 0,
      };
    }

    grouped[phoneNumber].objects.push(obj);
    grouped[phoneNumber].depositsSum += obj.deposit || 0;
    grouped[phoneNumber].withdrawalsSum += obj.withdrawal || 0;
    grouped[phoneNumber].frequency += 1;

    return grouped;
  }, {});
}