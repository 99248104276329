import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  PaginationProps,
  Table,
  Select
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { ContactusModel, IContactusModel } from "./contactus-model";
import {
  GetApiCall
} from "../../../utils/apis";
import { useDispatch } from "react-redux";
import { contactusUrl } from "../../../utils/constant";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "status" | "text" ;
  record: IContactusModel;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const dispatch = useDispatch();
  
  const inputNode =
    inputType === "status" ? (
      <Select
        defaultValue="Pending"
        style={{ width: 120 }}
        options={[
          {
            value: "pending",
            label: "Pending",
          },
          {
            value: "resolved",
            label: "Resolved",
          },
         
        ]}
      />
    ) : (
      <Input />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ContactUsPage = () => {

  const [form] = Form.useForm();
  const [contactusList, setContactusList] = useState<IContactusModel[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageSize = 8;
  
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record: IContactusModel) => record.id === editingKey;
  

  const edit = (record: Partial<IContactusModel>) => {
    form.setFieldsValue({ name: "", age: "", address: "", ...record });
    if (record.id !== undefined) {
      setEditingKey(record.id);
    }
  };

  const cancel = () => {
    setEditingKey("");
  };

 
  const onPaginationChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setCurrentPage(current);
  };


  const columns: ColumnsType<IContactusModel> = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      width: 10,
    },

    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile No.",
      dataIndex: "mobile_num",
      key: "mobile_num",
      width: 250,
    },
    {
      title: "Description",
      dataIndex: "message",
      key: "message",
      width: 550,
    }
  ];
  let userData:any = localStorage.getItem("user")
  let token:any = JSON.parse(userData)?.token;
  useEffect(() => {
    GetApiCall(contactusUrl, token).then((data: ContactusModel) => {
      setContactusList(data.allContactus);
    });
  }, []);

  return (
    <div>
        <div>
        <Table
          columns={columns}
          dataSource={contactusList}
          pagination={{
            pageSize: currentPageSize,
            onChange: onPaginationChange,
            position: ['bottomCenter']
          }}
          scroll={{ x: 1100 }}
        />
      </div>
    </div>
  );
};

export default ContactUsPage;
