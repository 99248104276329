import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  PaginationProps,
  Space,
  Table,
  Typography,
  Row,
  Col,
  Spin
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { IFaqModel, initialifaqModel } from "./faq-model";
import {
  DeleteApiCall,
  GetApiCall,
  PostApiCall,
  PutApiCall,
} from "../../../utils/apis";
import {
  createFaqUrl,
  deleteFaqUrl,
  faqUrl,
  updateFaqUrl,
} from "../../../utils/constant";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import "./faq-style.scss";

// import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { GlobalActionTypes } from "../../../utils/global-redux/global-enum";
import { IGlobalState } from "../../../utils/global-redux/global-interface";
const { TextArea } = Input;

const FaqPage = () => {
  const dispatch = useDispatch();
  const faqSelector = useSelector((state: IGlobalState) => state.GlobalState);

  const [faqEditor, setFaqEditor] = useState("");

  const [faqList, setFaqList] = useState<IFaqModel[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [isEnableEdit, setEnableEdit] = useState(false);
  const currentPageSize = 8;
  let userData:any = localStorage.getItem("user")
  let token:any = JSON.parse(userData)?.token;

  const onPaginationChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setCurrentPage(current);
  };

  const columns: ColumnsType<IFaqModel> = [
    {
      title: "Sl. No",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: number) =>
        (currentPage - 1) * 8 + index + 1,
        width: 70
    },
    {
      title: "Question",
      dataIndex: "faq_question",
      key: "faq_question",
    },
    {
      title: "Answer",
      dataIndex: "faq_answer",
      key: "faq_answer",
      width:1000
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_: any, record) => {
        const { confirm } = Modal;
        const showDeleteConfirm = () => {
          confirm({
            title: "Are you sure delete this Faq?",
            icon: <ExclamationCircleFilled />,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
              deleteFaq(record);
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        };
        return (
          <>
            <Typography.Link onClick={() => editFAQ(record)}>
              <EditOutlined style={{ padding: 14 }} />
            </Typography.Link>
            <Typography.Link onClick={showDeleteConfirm}>
              <DeleteOutlined />
            </Typography.Link>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getFaqList();
  }, []);
const getFaqList=()=>{
  setLoading(true)
  GetApiCall(faqUrl, token).then((data) => {
    if (data.status === true) {
      setFaqList(data.allFaqs);
      setLoading(false)
    }
    else {
      message.error(data.message)
      setLoading(false);
    }
  });
}
 

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setEnableEdit(false);
    setFaqEditor("");
    dispatch({
      type: GlobalActionTypes.FAQSELECTED,
      faq: initialifaqModel,
    });
    setOpen(false);
    
  };

  const formRef = React.createRef<FormInstance>();
  const onFinish = (values: any) => {
  
    if (isEnableEdit) {
      
      const faqBody = {
        id: faqSelector.faq.id,
        faq_question: values.faq_question,
        faq_answer: values.faq_answer,
        faq_page_id: 4,
      };
      PutApiCall(updateFaqUrl, faqBody, token).then((data) => {
        hideModal();
        dispatch({
          type: GlobalActionTypes.FAQSELECTED,
          faq: initialifaqModel,
        });
        setFaqEditor("");
        message.info(`FAQ updated successfully`);
        setEnableEdit(false);
        getFaqList()
      });
    } else {
      const faqBody = {
        faq_question: values.faq_question,
        faq_answer: values.faq_answer,
        faq_page_id: 4,
      };
      PostApiCall(createFaqUrl, faqBody, token).then((data) => {
        
        if(faqList?.length){
          const newFAQ= [...faqList];
          newFAQ.unshift(data.faqCreated);
          setFaqList(newFAQ);
        }
         
        formRef.current!.resetFields();
        hideModal();
        dispatch({
          type: GlobalActionTypes.FAQSELECTED,
          faq: initialifaqModel,
        });
        setFaqEditor("");
        message.info(`FAQ added successfully`);
        getFaqList()
      });
    }
  };

  const deleteFaq = (deleteFaq: IFaqModel) => {
    setLoading(true)
    DeleteApiCall(deleteFaqUrl, deleteFaq, token).then((data) => {
      if (data.status === true) {
        const removeSubscription = [...faqList];
        const deleteIndex = faqList.findIndex(
          (subIndex) => subIndex.id === deleteFaq.id
        );
        removeSubscription.splice(deleteIndex, 1);
        setFaqList(removeSubscription);
        setLoading(false)
        message.info(`FAQ deleted successfully`);
      }
    });
  };

  const editFAQ = (editFaq: IFaqModel) => {
    
    dispatch({
      type: GlobalActionTypes.FAQSELECTED,
      faq: editFaq,
    });
    setEnableEdit(true);
    setFaqEditor(editFaq.faq_answer);
    showModal();
  };

  return (
    <Spin spinning={loading} tip="Loading..." size="large"> 
    <div>
      <Row>
        <Col span={24} className="cms-main">
          <Button className="btn btn-primary add-button" type="primary" onClick={showModal}>
          <PlusCircleFilled />  Add
          </Button>
          <Modal
            title={isEnableEdit ? "Update FAQ" : "Create FAQ"}
            open={open}
            footer={null}
            onCancel={hideModal}
            destroyOnClose={true}
          >
            <Row>
              <Col span={24}>
                <Form
                  name="complex-form"
                  layout="vertical"
                  ref={formRef}
                  initialValues={{
                    faq_question: faqSelector.faq.faq_question,
                    faq_answer: faqSelector.faq.faq_answer
                  }}
                  onFinish={onFinish}
                >
                  <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                      <Form.Item label="FAQ Question">
                        <Space>
                          <Form.Item name="faq_question" noStyle
                            rules={[
                              {
                                required: true,
                                message: "Question is required",
                              },
                            ]}>
                            <Input />
                          </Form.Item>
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                      <Form.Item label="FAQ Answer" name="faq_answer" className="blog-content-area">
                        <TextArea rows={8}/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={{ span:8, offset: 16 }} md={24} sm={24} xs={24}>
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="primary" htmlType="submit" className="btn btn-primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
      <div>
        
        <Table
          columns={columns}
          rowKey="id"
          dataSource={faqList}
          pagination={{
            pageSize: currentPageSize,
            onChange: onPaginationChange,
            position: ['bottomCenter']
          }}
          scroll={{ x: 1100 }}
        />
      </div>
    </div>
    </Spin> 
  );
};

export default FaqPage;
