import { apiEndpoint } from '../../utils/endpoint';
import { useQueryData } from '../../lib/react-query';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { H1, H5 } from '../../common/components/elements/text';
import DataTable from '../../common/components/tanstack-table/index';
import { convertCardNumber, getDelayHistoryFlags } from '../../utils/index';
import { TableHeaderBuilder } from 'react-table-utils';
import { DelayDetail, IDelayHistory } from '../../common/types/delay-hostory-model';
import { Tag } from 'antd';
import { Flag } from '../../common/types/credit-analysis';
import { useRole } from '../../common/hooks/use-user';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';

const DELAY_HISTORY = 'DELAY_HISTORY';

const DelayHistory = () => {
  const [peakDelay, setPeakDelay] = useState(0);
  const [avgDelay, setAvgDelay] = useState(0);
  const [delayHistoryData, setDelayHistoryData] = useState<DelayDetail[]>([]);
  const [flag, setFlag] = useState<Flag>();

  const router = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const reportId = queryParams.get('report_id');
  const userId = router.id as string;

  const { currentUserRole } = useRole();
  const { data, isLoading } = useQueryData(
    apiEndpoint.creditAnalysis.getDelayHistory(currentUserRole, userId),
    DELAY_HISTORY + userId
  );

  useEffect(() => {
    if (!isLoading && data) {
      // @ts-ignore
      const { average_delay, delay_history_details, peak_delay } = data.filter(
        (item: IDelayHistory) =>
          item.detailed_delay_history.report_id === reportId
      )[0].detailed_delay_history;

      setPeakDelay(peak_delay);
      setAvgDelay(average_delay);
      setDelayHistoryData(delay_history_details);
      setFlag(getDelayHistoryFlags(peak_delay));
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <FullPageLoader isOpen={true} />;
  }

  // if (delayHistoryData.length === 0) {
  //   return <NotFoundBox title="Delay History" />;
  // }

  if (delayHistoryData.length) {
    delayHistoryData.sort((a, b) => {
      return b.delay - a.delay;
    });

    delayHistoryData.forEach((item) => {
      // @ts-ignore
      item.account_combined = [];
      // @ts-ignore
      item.account_combined = [item.account, item.account_number];
      // @ts-ignore
      item.status = item.delay === peakDelay ? 'Highest Delay' : '';
    });
  }

  const headerColumns = new TableHeaderBuilder<DelayDetail>()
    .add('account_combined', (col) =>
      col.header('ACCOUNT').cell((value) => (
        <>
          <H5 className="text-primary-500 py-1">{value[0]},</H5>
          <H5 className="text-primary-500 break-all">
            A/C- {convertCardNumber(value[1])}
          </H5>
        </>
      ))
    )
    .add('delay', (col) =>
      col
        .header('DELAY')
        .cell((value) => (
          <H5
            className={`${
              parseInt(value) > 30 ? 'text-red-500' : 'text-green-500'
            }`}
          >
            {value} Days
          </H5>
        ))
    )
    .add('status', (col) =>
      col
        .header('STATUS')
        .cell((value: string) => (
          <>
            {value === 'Highest Delay' && (
              <Tag className="text-red-500 bg-red-100">{value}</Tag>
            )}
          </>
        ))
    )
    .build();

  return (
    <div className="py-4">
      <H1 className="text-center">Delay History</H1>
      <div className="flex py-2">
        <H5 className="text-gray-500">Peak Delay</H5>
        <H5
          className={`text-lg text-bold ${
            getDelayHistoryFlags(peakDelay!).color === 'green'
              ? 'text-green-600'
              : 'text-red-600'
          } pl-2`}
        >
          {peakDelay}{' '}
        </H5>
      </div>
      <div className="flex py-2">
        <H5 className="text-gray-500">Average Delay</H5>
        <H5
          className={`text-lg text-bold ${
            getDelayHistoryFlags(avgDelay!).color === 'green'
              ? 'text-green-600'
              : 'text-red-600'
          } pl-2`}
        >
          {avgDelay}{' '}
        </H5>
      </div>
      {delayHistoryData.length > 0 && (
        <DataTable columns={headerColumns} data={delayHistoryData} />
      )}
    </div>
  );
};

export default DelayHistory;
