import { TableHeaderBuilder } from 'react-table-utils';
import { apiEndpoint } from '../../utils/endpoint';
import { useQueryData } from '../../lib/react-query';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import InlineLoader from '../../common/components/elements/loader/InlineLoader';
import { H1, H3, H5, P } from '../../common/components/elements/text';
import DataTable from '../../common/components/tanstack-table/index';
import {
  convertAgeOfCredit,
  convertAgeOfCreditToMonths,
  convertCardNumber,
  getAgeCreditFlags,
} from '../../utils/index';
import {
  AgeCreditDetail,
  IAgeOfCredit,
  OldestAccountAge,
} from '../../common/types/age-of-credit-model';
import { Flag } from '../../common/types/credit-analysis';
import { useRole } from '../../common/hooks/use-user';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';

const AGE_OF_CREDIT = 'AGE_OF_CREDIT';

const AgeOfCredit = () => {
  const [oldestAccountAge, setOldestAccountAge] = useState<OldestAccountAge>({
    years: 0,
    months: 0,
    days: 0,
  });
  const [ageOfCreditData, setAgeOfCreditData] = useState<AgeCreditDetail[]>([]);
  const [flag, setFlag] = useState<Flag>();

  const router = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const reportId = queryParams.get('report_id');
  const userId = router.id as string;

  const { currentUserRole } = useRole();
  const { data, isLoading } = useQueryData(
    apiEndpoint.creditAnalysis.getAgeOfCredit(currentUserRole, userId),
    AGE_OF_CREDIT + userId
  );

  useEffect(() => {
    if (!isLoading && data) {
      const { age_of_credit_details, oldest_account_age_of_credit } =
      // @ts-ignore
        data.filter(
          (item: IAgeOfCredit) =>
            item.detailed_age_of_credit.report_id === reportId
        )[0].detailed_age_of_credit;
      setOldestAccountAge(oldest_account_age_of_credit);
      setAgeOfCreditData(age_of_credit_details);
      setFlag(getAgeCreditFlags(oldest_account_age_of_credit));
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <FullPageLoader isOpen={true} />;
  }

  // if (ageOfCreditData.length === 0) {
  //   return <NotFoundBox title="Age of Credit" />;
  // }

  if (ageOfCreditData.length) {
    ageOfCreditData.forEach((item) => {
      // @ts-ignore
      item.account_combined = [];
      // @ts-ignore
      item.account_combined = [item.account, item.account_number];
    });
  }

  const headerColumns = new TableHeaderBuilder<AgeCreditDetail>()
    .add('account_combined', (col) =>
      col.header('ACCOUNT NO.').cell((value) => (
        <>
          <H5 className="text-primary-500 py-1">{value[0]},</H5>
          <H5 className="text-primary-500 break-all">
            A/C- {convertCardNumber(value[1])}
          </H5>
        </>
      ))
    )
    .add('opening_date', (col) =>
      col
        .header('OPENING DATE')
        .cell((value: string) => <H5 className="text-primary-500">{value}</H5>)
    )
    .add('account_age_of_account', (col) =>
      col
        .header('AGE OF ACCOUNT')
        .cell((value: any) => (
          <H5 className="text-primary-500 text-bold">
            {convertAgeOfCredit(value)}
          </H5>
        ))
    )
    .build();

  return (
    <div className="py-4">
      <H1 className="text-center">Age of Credit</H1>
      <div className="flex py-2">
        {oldestAccountAge && (
          <>
            <H5 className="text-gray-500">The oldest account age: </H5>
            <H5
              className={`text-lg text-bold ${
                flag?.color === 'green' ? 'text-green-600' : 'text-red-600'
              } pl-2`}
            >
              {convertAgeOfCreditToMonths(oldestAccountAge!)} Months
            </H5>
          </>
        )}
      </div>
      {ageOfCreditData.length > 0 && (
        <DataTable columns={headerColumns} data={ageOfCreditData} />
      )}
    </div>
  );
};

export default AgeOfCredit;
