import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { formatMoney } from '../../../utils/formatter';
import { H3 } from '../../../common/components/elements/text';
import Card from '../../../common/components/elements/Card';
import { Component } from '@appTypes/index';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export interface lineChartData {
  label: string;
  data: number[];
  color: string;
  borderColor: string;
}

type Props = {
  months: string[];
  lineChartData: lineChartData[];
  title: string;
};

const LineChart: Component<Props> = ({ months, lineChartData, title }) => {
  const data = {
    labels: months,
    datasets: lineChartData.map((item) => ({
      label: item.label,
      data: item.data,
      borderColor: item.borderColor,
      backgroundColor: item.color,
      fill: true,
      borderWidth: 2,
      lineTension: 0.5,
      pointBackgroundColor: 'rgba(75, 192, 192, 0.2)',
      pointRadius: 6,
      className: 'flex text-center',
      spanGaps: true,
      pointRotation: 180,
      pointHoverRadius: 8,
    })),
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: any) => `${value}`,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
  };

  return (
    <Card className="w-full max-w-auto sm:space-y-2 sm:p-8   border border-gray-300">
      <div className="flex justify-between">
        <H3 className="text-primary-500">{title}</H3>
      </div>
      {/* @ts-ignore */}
      <Line data={data} options={options} />
    </Card>
  );
};

export default LineChart;
