import React from "react";
import { Component } from "@appTypes/.";
import cx from "clsx";

type Props = {
  Icon?: any;
  onClick?: () => void;
};

const ActionIcon: Component<Props> = ({ Icon, className, onClick }) => {
  return (
    <Icon
      className={cx("w-5 h-5 cursor-pointer active:scale-[0.95]", className)}
      onClick={onClick}
    />
  );
};

export default ActionIcon;
