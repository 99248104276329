import { withAuth } from "../../common/hoc/withAuth";
import DashboardComponent from "../../components/dashboard/dashboard"

const DashboardPage = () => {
  return (
    <div>
      <div><DashboardComponent/></div>
    </div>
  );
};

export default withAuth()(DashboardPage);
