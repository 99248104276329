import { Document } from '@appTypes/user-model';
import {
  DTIRatioDetails,
  IDTIRatio,
} from '@appTypes/dti-ratio-model';
import DataTable from '../../common/components/tanstack-table';
import { MONTHS_ARRAY } from '../../utils/constants/index';
import { H5, H1 } from '../../common/components/elements/text';
import { ColumnDef } from '@tanstack/react-table';
import { formatMoney } from '../../utils/formatter';
import {
  removeCharacterFromAmount,
  getDTIRatioFlags,
  filterBankStatementData,
  getMonthlyDebtByPeriod,
  getLast6MonthPeriod,
  getAllTransactions,
} from '../../utils/index';
import { Tag } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { TableHeaderBuilder } from 'react-table-utils';
import LineChart from './components/lineChart';
import { useLocation, useParams } from 'react-router-dom';
import { useCustomerById } from '../../common/hooks/use-customer';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { useFullPageLoader } from '../../common/hooks/use-full-page-loader';
import { useQueryClient } from '@tanstack/react-query';
import { BANK_STATEMENT_ANALYSIS_KEY } from './netCashFlow';
import { BankStatement } from '../../common/hooks/use-bank-statement';
import { useQuery, useQueryData } from '../../lib/react-query';
import { apiEndpoint } from '../../utils/endpoint';
import { DetailedMonthlyDebt } from '@appTypes/monthly-debt-model';
import AllIncomeTransactions from './components/AllIncomeTransactions';
import { Transaction } from '@appTypes/bank-statement-model';

type Props = {};

const DTIRatios: React.FC<Props> = () => {
  const { showLoader, hideLoader } = useFullPageLoader();
  const queryClient = useQueryClient();
  const [dtiRatioData, setDTIRatioData] = useState<DTIRatioDetails[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [chartData, setChartData] = useState<{
    months: string[];
    lineChartData: [
      { label: string; data: number[]; color: string; borderColor: string },
      { label: string; data: number[]; color: string; borderColor: string }
    ];
  }>({
    months: [],
    lineChartData: [
      {
        label: 'DTI Ratio of Declared Income',
        data: [],
        color: 'rgba(37, 99, 235, 0.1)',
        borderColor: 'rgb(37, 99, 235)',
      },
      {
        label: 'DTI Ratio of Bank Statement Income',
        data: [],
        color: 'rgba(60, 186, 159, 0.1)',
        borderColor: 'rgb(60, 186, 159)',
      },
    ],
  });

  const router = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const reportId = queryParams.get('report_id');
  const userId = router.id as string;

  const { data, isLoading } = useCustomerById(userId);

  const { data: monthlyDebt, isLoading: monthlyDebtLoading } = useQueryData<{
    detailed_monthly_debt: DetailedMonthlyDebt;
  }>(
    apiEndpoint.creditAnalysis.getMonthlyDebt(userId),
    'MONTHLY_DEBT' + userId
  );

  useEffect(() => {
    const getDTIRatioAnalysis = async (documents: Document[]) => {
      showLoader();

      let completeAnalysisData;
      let monthlyIncome: any[] = [];
      const bankStatementAnalysisData = queryClient.getQueryData([
        BANK_STATEMENT_ANALYSIS_KEY + userId,
      ]);

      if (bankStatementAnalysisData) {
        completeAnalysisData = bankStatementAnalysisData;
      } else {
        const bankStatement = filterBankStatementData(documents);
        const { data, isBankStatementAvailable, isBankStatementEmpty } =
          await BankStatement(bankStatement);

        if (!isBankStatementAvailable || isBankStatementEmpty) {
          hideLoader();
        } else {
          completeAnalysisData = data;
          queryClient.setQueryData(
            [BANK_STATEMENT_ANALYSIS_KEY + userId],
            data
          );
          const completeAnalysisByAccount =
            // @ts-ignore
            completeAnalysisData?.analysis?.by_account;
          const accountNumber = Object.keys(completeAnalysisByAccount)[0];
          const completeDetail = completeAnalysisByAccount[accountNumber];

          monthlyIncome = completeDetail?.income_transactions?.month_wise;
        }
      }

      setChartData({
        months: [],
        lineChartData: [
          {
            label: 'DTI Ratio of Declared Income',
            data: [],
            color: 'rgba(37, 99, 235, 0.1)',
            borderColor: 'rgb(37, 99, 235)',
          },
          {
            label: 'DTI Ratio of Bank Statement Income',
            data: [],
            color: 'rgba(60, 186, 159, 0.1)',
            borderColor: 'rgb(60, 186, 159)',
          },
        ],
      });
      setDTIRatioData([]);

      const debtData: DTIRatioDetails[] = getMonthlyDebtByPeriod(
        // @ts-ignore
        monthlyDebt.filter(
          (item: IDTIRatio) => item.detailed_monthly_debt.report_id === reportId
        )[0].detailed_monthly_debt?.monthly_debt_details
      );
      let allTransactions: Transaction[] = getAllTransactions(monthlyIncome);

      debtData.forEach((item: any) => {
        item.declared_income = data?.customer.annual_income / 12;
        const income = monthlyIncome.find(
          (incomeItem: { period: { month: any; year: any } }) =>
            incomeItem.period.month === item.period.month &&
            incomeItem.period.year === item.period.year
        );

        item.dti_ratio_of_declared_income = parseFloat(
          (removeCharacterFromAmount(item.debt) / item.declared_income).toFixed(
            2
          )
        );

        if (income && income.credit.amount !== 0) {
          item.dti_ratio_of_bank_statement_income = parseFloat(
            (
              removeCharacterFromAmount(item.debt) / income.credit.amount
            ).toFixed(2)
          );
          item.income_from_bank_statement = income?.credit?.amount;
        }

        setChartData((prev: any) => {
          const updatedLineChartData = prev.lineChartData.map((chart: any) => {
            if (chart.label === 'DTI Ratio of Declared Income') {
              return {
                ...chart,
                data: [...chart.data, item.dti_ratio_of_declared_income],
              };
            } else if (chart.label === 'DTI Ratio of Bank Statement Income') {
              return {
                ...chart,
                data: [...chart.data, item.dti_ratio_of_bank_statement_income],
              };
            }

            return chart;
          });

          return {
            months: [
              ...prev.months,
              `${MONTHS_ARRAY[item.period.month]}, ${String(
                item.period.year
              ).slice(-2)}`,
            ],
            lineChartData: updatedLineChartData,
          };
        });
      });

      if (debtData.length === 0) {
        // @ts-ignore
        debtData = getLast6MonthPeriod();
        debtData.forEach((item: any) => {
          item.declared_income = data?.customer.annual_income / 12;
          const income = monthlyIncome.find(
            (incomeItem: { period: { month: any; year: any } }) =>
              incomeItem.period.month === item.period.month &&
              incomeItem.period.year === item.period.year
          );

          if (income) {
            item.income_from_bank_statement = income?.credit?.amount;
          }
        });
      }

      setDTIRatioData(debtData);
      setTransactions(allTransactions);
      hideLoader();
    };

    if (!isLoading && !monthlyDebtLoading && data?.customer && monthlyDebt) {
      getDTIRatioAnalysis(data?.customer?.documents);
    }
  }, [isLoading, data, monthlyDebtLoading, monthlyDebt]);

  const headerColumns = new TableHeaderBuilder<DTIRatioDetails>()
    .add('period', (col) =>
      col.header('Month').cell(({ month, year }: any) => (
        <H5>
          {MONTHS_ARRAY[month]}, {String(year).slice(-2)}
        </H5>
      ))
    )
    .add('declared_income', (col) =>
      col
        .header('Declared Income')
        // @ts-ignore
        .cell((value) => (value ? <H5>{formatMoney(value)}</H5> : <>-</>))
    )
    .add('income_from_bank_statement', (col) =>
      col
        .header('Income from Bank Statement')
        // @ts-ignore
        .cell((value) =>
          value ? (
            <H5>{formatMoney(removeCharacterFromAmount(value))}</H5>
          ) : (
            <>-</>
          )
        )
    )
    .add('debt', (col) =>
      col
        .header('Debt')
        .cell((value) =>
          value ? (
            <H5>{formatMoney(removeCharacterFromAmount(value))}</H5>
          ) : (
            <>-</>
          )
        )
    )
    .add('dti_ratio_of_declared_income', (col) =>
      col
        .header('DTI Ratio of Declared Income')
        .cell((value) => (
          <>
            {value ? (
              getDTIRatioFlags(removeCharacterFromAmount(value)).color ===
              'red' ? (
                <Tag className="text-red-600 bg-red-100">{value}</Tag>
              ) : (
                <Tag className="text-green-600 bg-green-100">{value}</Tag>
              )
            ) : (
              <>-</>
            )}
          </>
        ))
    )
    .add('dti_ratio_of_bank_statement_income', (col) =>
      col
        .header('DTI Ratio of Bank Statement Income')
        .cell((value) => (
          <>
            {value ? (
              getDTIRatioFlags(removeCharacterFromAmount(value)).color ===
              'red' ? (
                <Tag className="text-red-600 bg-red-100">{value}</Tag>
              ) : (
                <Tag className="text-green-600 bg-green-100">{value}</Tag>
              )
            ) : (
              <>-</>
            )}
          </>
        ))
    )
    .build();

  const columns: ColumnDef<DTIRatioDetails>[] = useMemo(() => headerColumns, []);
  if (isLoading || monthlyDebtLoading) return <FullPageLoader isOpen={true} />;

  return (
    <div className="space-y-4">
      <H1 className="text-center">DTI Ratio</H1>
      {dtiRatioData.length > 0 && (
        <>
          <DataTable columns={columns} data={dtiRatioData} />
          <LineChart
            months={chartData.months}
            lineChartData={chartData.lineChartData}
            title="DTI Ratio"
          />
          <AllIncomeTransactions transactions={transactions} />
        </>
      )}
    </div>
  );
};

export default DTIRatios;
