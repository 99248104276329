import { useEffect, useRef, useState } from 'react';
import FullPageLoader from '../../common/components/elements/loader/FullPageLoader';
import { useCustomerWithSuccessCrifPulls } from '../../common/hooks/use-customer';
import Table from '../../common/components/data-table/paginateTable';
import { CompleteProfileColumn } from '../../common/components/data-table/TableColumn';
import { dashboardState, role } from '../../utils/constants/index';
import Section from '../../common/layouts/PageSection';
import { useRole, useUser } from '../../common/hooks/use-user';
import axios from '../../lib/axios';
import { apiEndpoint } from '../../utils/endpoint';
import { useStatistics } from '../../common/hooks/use-statistics';
import { withAuth } from '../../common/hoc/withAuth';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useQueryClient } from '@tanstack/react-query';

const AllCustomer: React.FC = () => {
  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);
  const skipResetRef = useRef(false);
  const queryClient = useQueryClient();
  const { currentUserRole } = useRole();
  const { pushInstruction } = useMatomo();
  const columns = CompleteProfileColumn(downloadLinkRef);

  const {
    data: customersData,
    isLoading,
    refetch,
  } = useCustomerWithSuccessCrifPulls();
  const { data: stats, isLoading: statsLoading } = useStatistics();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState(data);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const { data: currentUser, isLoading: currentUserLoading } = useUser();

  useEffect(() => {
    if (!isLoading && !statsLoading) {
      setData(customersData?.customers);
      setTotalDataCount(stats?.customers_with_valid_crif_pulls_count);
    }
  }, [customersData, isLoading, statsLoading]);

  useEffect(() => {
    const email = queryClient.getQueryData(['email']);
    if (email) {
      pushInstruction('setUserId', `${email}`);
      var _paq = window._paq || [];
      _paq.push(['setUserId', `${email}`]);
    }

    if (!currentUserLoading && currentUser) {
      const { manager, owner, executive } = currentUser;
      const user = manager || owner || executive;

      queryClient.setQueryData(['email'], `${user.profile.email}`);
      pushInstruction('setUserId', `${user.profile.email}`);
      var _paq = window._paq || [];
      _paq.push(['setUserId', `${user.profile.email}`]);
    }
  }, [currentUserLoading, currentUser]);

  const resetData = () => {
    skipResetRef.current = true;
    setData(originalData);
  };

  const paginateData = async (
    pageSize: number,
    pageIndex: number,
    orderBy: string = 'updated_at',
    desc: boolean = true
  ) => {
    const skip = pageSize * pageIndex;
    const limit = pageSize;

    const { data } = await axios.get(
      apiEndpoint.common.getCustomerWithSuccessfullCrifPull(
        skip,
        limit,
        orderBy,
        desc
      )
    );

    setData([]);
    // @ts-ignore
    setData(data.customers);
    // @ts-ignore
    setOriginalData(data.customers);
  };

  const searchCustomer = async (
    name: string,
    pageIndex: number = 0,
    pageSize: number = 10
  ) => {
    if (name === '') {
      paginateData(pageSize, pageIndex);
    } else {
      const { data } = await axios.get(
        apiEndpoint.common.searchCustomer.customerWithSuccessfullCrifPull(name)
      );
      setData([]);
      // @ts-ignore
      setData(data.customers);
      // @ts-ignore
      setOriginalData(data.customers);
    }
  };

  if (isLoading || statsLoading) {
    return <FullPageLoader isOpen={true} className="text-brand-orange-500" />;
  }

  const isExcelSheetButtonVisible =
    currentUserRole === role.owner || currentUserRole === role.manager;

  return (
    <Section
      title={dashboardState.customers_with_valid_crif_pulls_count.status}
    >
      <Table
        columns={columns}
        data={data}
        resetData={resetData}
        isExcelSheetButtonVisible={isExcelSheetButtonVisible}
        paginateData={paginateData}
        searchCustomer={searchCustomer}
        totalDataCount={totalDataCount}
        excelSheetUrl={`list-customers?crif_pull_status=valid&`}
        excelSheetName={`Success_CRIF_Pulls-${new Date()
          .toLocaleDateString('en-IN')
          .replace(/[:\\/?*\[\]]/g, '-')}`}
      />
    </Section>
  );
};

export default withAuth()(AllCustomer);
